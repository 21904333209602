import React, { useEffect, useRef, useState } from 'react';
import Highlight from 'react-highlight';
// TODO Temporarily removed Kendo
import {
    Grid, GridColumn as Column, GridContainer, Checkbox, Badge, Stack, GridCellProps, GridHeaderCellProps,
    Button, NumericInput, Radio, classNameHelper
} from '@jkhy/vsg-loanvantage-design-system';

import { getDataUI } from '../../helpers/helpers';

const productsGrid =
    [{
        "ProductID": 1,
        "ProductName": "Chai",
        "SupplierID": 1,
        "CategoryID": 1,
        "QuantityPerUnit": "10 boxes x 20 bags",
        "UnitPrice": 18.0000,
        "UnitsInStock": 39,
        "UnitsOnOrder": 0,
        "ReorderLevel": 10,
        "Discontinued": false,
        "Category": {
            "CategoryID": 1,
            "CategoryName": "Beverages",
            "Description": "Soft drinks, coffees, teas, beers, and ales"
        }
    }, {
        "ProductID": 2,
        "ProductName": "Chang",
        "SupplierID": 1,
        "CategoryID": 1,
        "QuantityPerUnit": "24 - 12 oz bottles",
        "UnitPrice": 19.0000,
        "UnitsInStock": 17,
        "UnitsOnOrder": 40,
        "ReorderLevel": 25,
        "Discontinued": false,
        "Category": {
            "CategoryID": 1,
            "CategoryName": "Beverages",
            "Description": "Soft drinks, coffees, teas, beers, and ales"
        }
    }, {
        "ProductID": 3,
        "ProductName": "Aniseed Syrup",
        "SupplierID": 1,
        "CategoryID": 2,
        "QuantityPerUnit": "12 - 550 ml bottles",
        "UnitPrice": 10.0000,
        "UnitsInStock": 13,
        "UnitsOnOrder": 70,
        "ReorderLevel": 25,
        "Discontinued": false,
        "Category": {
            "CategoryID": 2,
            "CategoryName": "Condiments",
            "Description": "Sweet and savory sauces, relishes, spreads, and seasonings"
        }
    }, {
        "ProductID": 4,
        "ProductName": "Chef Anton's Cajun Seasoning",
        "SupplierID": 2,
        "CategoryID": 2,
        "QuantityPerUnit": "48 - 6 oz jars",
        "UnitPrice": 22.0000,
        "UnitsInStock": 53,
        "UnitsOnOrder": 0,
        "ReorderLevel": 0,
        "Discontinued": false,
        "Category": {
            "CategoryID": 2,
            "CategoryName": "Condiments",
            "Description": "Sweet and savory sauces, relishes, spreads, and seasonings"
        }
    }, {
        "ProductID": 5,
        "ProductName": "Chef Anton's Gumbo Mix",
        "SupplierID": 2,
        "CategoryID": 2,
        "QuantityPerUnit": "36 boxes",
        "UnitPrice": 21.3500,
        "UnitsInStock": 0,
        "UnitsOnOrder": 0,
        "ReorderLevel": 0,
        "Discontinued": true,
        "Category": {
            "CategoryID": 2,
            "CategoryName": "Condiments",
            "Description": "Sweet and savory sauces, relishes, spreads, and seasonings"
        }
    }, {
        "ProductID": 6,
        "ProductName": "Grandma's Boysenberry Spread",
        "SupplierID": 3,
        "CategoryID": 2,
        "QuantityPerUnit": "12 - 8 oz jars",
        "UnitPrice": 25.0000,
        "UnitsInStock": 120,
        "UnitsOnOrder": 0,
        "ReorderLevel": 25,
        "Discontinued": false,
        "Category": {
            "CategoryID": 2,
            "CategoryName": "Condiments",
            "Description": "Sweet and savory sauces, relishes, spreads, and seasonings"
        }
    }, {
        "ProductID": 7,
        "ProductName": "Uncle Bob's Organic Dried Pears",
        "SupplierID": 3,
        "CategoryID": 7,
        "QuantityPerUnit": "12 - 1 lb pkgs.",
        "UnitPrice": 30.0000,
        "UnitsInStock": 15,
        "UnitsOnOrder": 0,
        "ReorderLevel": 10,
        "Discontinued": false,
        "Category": {
            "CategoryID": 7,
            "CategoryName": "Produce",
            "Description": "Dried fruit and bean curd"
        }
    }, {
        "ProductID": 8,
        "ProductName": "Northwoods Cranberry Sauce",
        "SupplierID": 3,
        "CategoryID": 2,
        "QuantityPerUnit": "12 - 12 oz jars",
        "UnitPrice": 40.0000,
        "UnitsInStock": 6,
        "UnitsOnOrder": 0,
        "ReorderLevel": 0,
        "Discontinued": false,
        "Category": {
            "CategoryID": 2,
            "CategoryName": "Condiments",
            "Description": "Sweet and savory sauces, relishes, spreads, and seasonings"
        }
    }, {
        "ProductID": 9,
        "ProductName": "Mishi Kobe Niku",
        "SupplierID": 4,
        "CategoryID": 6,
        "QuantityPerUnit": "18 - 500 g pkgs.",
        "UnitPrice": 97.0000,
        "UnitsInStock": 29,
        "UnitsOnOrder": 0,
        "ReorderLevel": 0,
        "Discontinued": true,
        "Category": {
            "CategoryID": 6,
            "CategoryName": "Meat/Poultry",
            "Description": "Prepared meats"
        }
    }, {
        "ProductID": 10,
        "ProductName": "Ikura",
        "SupplierID": 4,
        "CategoryID": 8,
        "QuantityPerUnit": "12 - 200 ml jars",
        "UnitPrice": 31.0000,
        "UnitsInStock": 31,
        "UnitsOnOrder": 0,
        "ReorderLevel": 0,
        "Discontinued": false,
        "Category": {
            "CategoryID": 8,
            "CategoryName": "Seafood",
            "Description": "Seaweed and fish"
        }
    }];

const businessRules = [
    {
        "AmountToLoanCode": 'A1',
        "AmountToLoan": 8500,
        "BusinessRule": 'New Vehicle',
        "ConditionRules": [{
            'condition': 'New = Yes',
            'logicalOperator': 'AND',
            'conditionResult': false
        }
        ]
    },
    {
        "AmountToLoanCode": 'A2',
        "AmountToLoan": 76590,
        "BusinessRule": 'Used Vehicle 2019 or newer',
        "ConditionRules": [{
            'condition': 'Used = Yes',
            'logicalOperator': 'AND',
            'conditionResult': true
        }, {
            'condition': 'Collateral type some other text',
            'logicalOperator': 'AND',
            'conditionResult': true,
        }, {
            'condition': 'Year>=2019',
            'logicalOperator': null,
            'conditionResult': true
        }]
    }
];


const parties = [
    {
        "ID": 2,
        "isPrimary": false,
        "FullName": "Some Name one",
        "Industry": "Some text",
        "includedGCF": false,
        "Prior": "",
        "Current": "",
        "Projeection": "",
        "Stressed": ""
    },
    {
        "ID": 3,
        "isPrimary": true,
        "FullName": "Some Name two",
        "Industry": "Some text",
        "includedGCF": false,
        "Prior": "",
        "Current": "",
        "Projeection": "",
        "Stressed": ""
    },
    {
        "ID": 5,
        "isPrimary": false,
        "FullName": "Some Name three",
        "Industry": "Some text",
        "includedGCF": false,
        "Prior": "",
        "Current": "",
        "Projeection": "",
        "Stressed": ""
    },
    {
        "ID": 6,
        "isPrimary": false,
        "FullName": "Some Name four",
        "Industry": "Some text",
        "includedGCF": false,
        "Prior": "",
        "Current": "",
        "Projeection": "",
        "Stressed": ""
    },
];


const CUSTOM_CELL_COLUMNS_TEMPLATE = 'minmax(120px, 1fr) minmax(50px, 120px) minmax(50px, 120px)';

const CustomHeader = (props: GridHeaderCellProps) => {
    const headerRef = useRef() as any;

    useEffect(() => {
        if (headerRef) {
            const divWrapper = headerRef.current as HTMLElement;
            const parent = divWrapper.parentElement as HTMLTableCellElement;
            parent.colSpan = 3;
            parent.classList.add('p-0');
        }
    }, [])

    return (
        <div ref={headerRef} className='height-full'>
            <Stack
                className='height-full align-items-center'
                direction='column'
                style={{
                    gridTemplateColumns: CUSTOM_CELL_COLUMNS_TEMPLATE
                }}
            >
                <div className='lv-th-custom height-full'>
                    <div className='lv-th-custom-text'>
                        Conditions
                    </div>
                </div>

                <div className='lv-th-custom height-full'>
                    <div className='lv-th-custom-text'>
                        Logical Operator
                    </div>
                </div>

                <div className='lv-th-custom height-full'>
                    <div className='lv-th-custom-text'>
                        Conditions Result
                    </div>
                </div>
            </Stack>
        </div>
    )
}

const CustomCell = ({ dataItem, ...rest }: GridCellProps) => {
    const conditionRules = dataItem.ConditionRules as any[];

    return (
        <td colSpan={3} className='p-0' >
            <Stack
                className='height-full align-items-center'
                style={{
                    gridTemplateRows: `repeat(${conditionRules.length}, ${conditionRules.length > 1 ? 'min-content' : '1fr'})`,
                }}

            >
                {conditionRules.map((conditionRule: any, index) => {
                    const currentLogicalOperator = conditionRule.logicalOperator || '';
                    const isSuccessConditonResult = conditionRule.conditionResult;
                    return (
                        <Stack
                            key={`${conditionRule.condition}-${index}`}
                            className='lv-row-custom height-full'
                            style={{
                                gridTemplateColumns: CUSTOM_CELL_COLUMNS_TEMPLATE,
                            }}
                        >
                            <div className='lv-td-custom'>
                                {conditionRule.condition}
                            </div>
                            <div className='lv-td-custom'>
                                {currentLogicalOperator}
                            </div>
                            <div className='lv-td-custom'>
                                <Badge
                                    noMaxWidth
                                    className="justify-content-start"
                                    type={`${isSuccessConditonResult ? 'success' : 'danger'}`}
                                    icon={`fal ${isSuccessConditonResult ? "fa-check-circle " : "fa-times-circle"}`}
                                    dataUI={getDataUI()}
                                >
                                    {isSuccessConditonResult ? 'True' : 'Flase'}
                                </Badge>
                            </div>
                        </Stack>
                    )
                })}
            </Stack>
        </td>
    )

}


const CustomFooterCellTitles = () => {

    return (
        <td colSpan={7} className='text-right'>
            <div className='lv-footer-custom justify-content-end text-bold'>
                Cash Flow Available For Debt Service
            </div>
            <div className='lv-footer-custom justify-content-end text-bold'>Adjustments To Income</div>
            <div className='lv-footer-custom justify-content-end text-bold'>Adjustments To Debt</div>
            <div className='lv-footer-custom justify-content-end text-bold'>Annual Debt Service</div>
            <div className='lv-footer-custom justify-content-end text-bold'>Cash Flow Surplus(Deficit)</div>
            <div className='lv-footer-custom justify-content-end text-bold'>Global DSCR</div>
        </td>
    )
}


const CustomFooterCellEmpty = () => {
    return null
}

const Table: React.FC = () => {
    const [tableParties, setTableParties] = useState([...parties]);
    const [selectedParty, setSelectedParty] = useState(parties.find(p => p.isPrimary));

    const onChangeParty = (partyID: number, value: boolean) => {
        const updateParies = tableParties.map((p) => {
            let isPrimary = false;
            if (p.ID === partyID) {
                isPrimary = true
            };

            return {
                ...p,
                isPrimary
            }

        });

        setTableParties(updateParies);
        setSelectedParty(updateParies.find(p => p.isPrimary))
    }

    const CustomFooterCell = ({ field, ...rest }:any) => {
        return (
            <td colSpan={1} className={classNameHelper(field === 'Prior' && 'border-color-text')}>
                <div className='lv-footer-custom lv-footer-custom-heading'>
                    <div className='text-truncated'>{field}</div>
                </div>
                <div className='lv-footer-custom'>
                    <div className='text-truncated'>{selectedParty?.FullName}</div>
                </div>
                <div className='lv-footer-custom'>
                    <div className='text-truncated'>Adjustments To Income</div>
                </div>
                <div className='lv-footer-custom'><NumericInput type="percent" dataUI={getDataUI()} /></div>
                <div className='lv-footer-custom'><NumericInput type="percent" dataUI={getDataUI()} /></div>
                <div className='lv-footer-custom'><NumericInput type="percent" dataUI={getDataUI()} /></div>
                <div className='lv-footer-custom'>
                    <div className='text-truncated'>Some text</div>
                </div>
            </td>
        )
    }
    return <div>
        <h1 className="mb-s">Table</h1>
        <p className="mb-s">
            Tables in LoanVantage Design System exports <a href="https://www.telerik.com/kendo-react-ui/components/grid/get-started/"
                target="_blank" rel="noopener noreferrer">Kendo React Grid</a>:<br />
            - components : all from <strong>'@progress/kendo-react-grid'</strong><br />
            - utility functions : all from <strong>'@progress/kendo-data-query'</strong>
        </p>
        <p>
            <strong>Note</strong>: Always wrap <strong>Grid</strong> component in <strong>GridContainer</strong> to prevent styling issues.<br />
            <strong>Note</strong>: When you want to force table to be full width set class <strong>.lv-grid-full-width</strong> to <strong>GridContainer</strong>.<br />
            <strong>Note</strong>: When you want to set table to fill available space in scrollable container set class<strong>.d-flex</strong> to <strong>GridContainer</strong> and style <strong>maxHeight: 100%</strong> to <strong>GridContainer</strong> and <strong>Grid</strong>.
        </p>

        <GridContainer dataUI={getDataUI()} className='mb-xl'>
            <Grid
                data={[...productsGrid]}
                reorderable
            >
                <Column field="ProductID" title="ID" />
                <Column field="ProductName" title="Name" width="250px" />
                <Column field="Category.CategoryName" title="CategoryName" />
                <Column field="UnitPrice" title="Price" />
                <Column field="UnitsInStock" title="In stock" />
                <Column
                    field="Discontinued"
                    cell={(props: any) => {
                        return (
                            <td>
                                <Checkbox dataUI={getDataUI()} disabled checked={props.dataItem[props.field as any]} />
                            </td>
                        )
                    }}
                />
            </Grid>
        </GridContainer>

        <h3 className="mb-s">Table with custom cells in grid cell</h3>
        <GridContainer dataUI={getDataUI()}>
            <Grid data={[...businessRules.slice(0, 3)]}>
                <Column field="AmountToLoanCode" title="Amount To Loan Code" />
                <Column field="AmountToLoan" title="Amount To Loan" />
                <Column field="BusinessRule" title="Business Rule" />
                <Column field='ConditionRules' headerCell={CustomHeader} cell={CustomCell} />
            </Grid>
        </GridContainer>


        <h3 className="mb-s">Table with footer cells</h3>
        <GridContainer dataUI={getDataUI()}>
            <Grid data={[...tableParties]} resizable>
                <Column width={45} headerCell={() => null} cell={() => <td><Button btnType="icon" icon="fal fa-pen" dataUI={getDataUI()} /></td>} footerCell={CustomFooterCellTitles} />
                <Column field="ID" title="Is Primary" cell={({ dataItem, ...rest }: any) => {
                    return (<td className='text-center'>
                        <Radio
                            dataUI={getDataUI()}
                            checked={dataItem.isPrimary}
                            onChange={(e: any) => {
                                onChangeParty(dataItem.ID, e.target.checked)
                            }}
                        />
                    </td>)
                }} footerCell={CustomFooterCellEmpty} />
                <Column width={45} headerCell={() => null} cell={() => <td><Button btnType="icon" icon="fal fa-file-alt" dataUI={getDataUI()} /></td>} footerCell={CustomFooterCellEmpty} />
                <Column field="FullName" title="Full Name" footerCell={CustomFooterCellEmpty} />
                <Column title="Remove Item" cell={() => <td><Button btnType="icon" icon="fal fa-file-alt" className="mr-s" dataUI={getDataUI()} /></td>} footerCell={CustomFooterCellEmpty} />
                <Column field="Industry" title="Industry" footerCell={CustomFooterCellEmpty} />
                <Column field="includedGCF" title="includedGCF" cell={() => <td><Button btnType="icon" icon="fal fa-file-alt" className="mr-s" dataUI={getDataUI()} /></td>} footerCell={CustomFooterCellEmpty} />
                <Column title="Prior" field="Prior" footerCell={CustomFooterCell} />
                <Column title="Current" field="Current" footerCell={CustomFooterCell} />
                <Column title="Projection" field="Projection" footerCell={CustomFooterCell} />
                <Column title="Stressed" field="Stressed" footerCell={CustomFooterCell} />
            </Grid>
        </GridContainer>

        <Highlight className="React mb-l">
            {
                `
import React from 'react';
import { 
    Grid, GridColumn as Column, GridContainer, Checkbox,
 } from '@jkhy/vsg-loanvantage-design-system';
 import { getDataUI } from '../../helpers/helpers';

 const productsGrid =
 [{
     "ProductID": 1,
     "ProductName": "Chai",
     "SupplierID": 1,
     "CategoryID": 1,
     "QuantityPerUnit": "10 boxes x 20 bags",
     "UnitPrice": 18.0000,
     "UnitsInStock": 39,
     "UnitsOnOrder": 0,
     "ReorderLevel": 10,
     "Discontinued": false,
     "Category": {
         "CategoryID": 1,
         "CategoryName": "Beverages",
         "Description": "Soft drinks, coffees, teas, beers, and ales"
     }
 }, {
     "ProductID": 2,
     "ProductName": "Chang",
     "SupplierID": 1,
     "CategoryID": 1,
     "QuantityPerUnit": "24 - 12 oz bottles",
     "UnitPrice": 19.0000,
     "UnitsInStock": 17,
     "UnitsOnOrder": 40,
     "ReorderLevel": 25,
     "Discontinued": false,
     "Category": {
         "CategoryID": 1,
         "CategoryName": "Beverages",
         "Description": "Soft drinks, coffees, teas, beers, and ales"
     }
 },
 .....
];

const businessRules = [
    {
        "AmountToLoanCode": 'A1',
        "AmountToLoan": 8500,
        "BusinessRule": 'New Vehicle',
        "ConditionRules": [{
            'condition': 'New = Yes',
            'logicalOperator': 'AND',
            'conditionResult': false
        }
        ]
    },
    {
        "AmountToLoanCode": 'A2',
        "AmountToLoan": 76590,
        "BusinessRule": 'Used Vehicle 2019 or newer',
        "ConditionRules": [{
            'condition': 'Used = Yes',
            'logicalOperator': 'AND',
            'conditionResult': true
        }, {
            'condition': 'Collateral type some other text',
            'logicalOperator': 'AND',
            'conditionResult': true,
        }, {
            'condition': 'Year>=2019',
            'logicalOperator': null,
            'conditionResult': true
        }]
    }
];

// Custom heading for table with custom cells in grid cell
const CustomHeader = (props: GridHeaderCellProps) => {
    const headerRef = useRef() as any;

    useEffect(() => {
        if (headerRef) {
            const divWrapper = headerRef.current as HTMLElement;
            const parent = divWrapper.parentElement as HTMLTableCellElement;
            parent.colSpan = 3;
            parent.classList.add('p-0');
        }
    }, [])

    return (
        <div ref={headerRef} className='height-full'>
            <Stack
                className='height-full align-items-center'
                direction='column'
                style={{
                    gridTemplateColumns: CUSTOM_CELL_COLUMNS_TEMPLATE
                }}
            >
                <div className='lv-th-custom height-full'>
                    <div className='lv-th-custom-text'>
                        Conditions
                    </div>
                </div>

                <div className='lv-th-custom height-full'>
                    <div className='lv-th-custom-text'>
                        Logical Operator
                    </div>
                </div>

                <div className='lv-th-custom height-full'>
                    <div className='lv-th-custom-text'>
                        Conditions Result
                    </div>
                </div>
            </Stack>
        </div>
    )
}
// Custom cell for table with custom cells in grid cell
const CustomCell = ({ dataItem, ...rest }: GridCellProps) => {
    const conditionRules = dataItem.ConditionRules as any[];

    return (
        <td colSpan={3} className='p-0' >
            <Stack
                className='height-full align-items-center'
                style={{
                    gridTemplateRows: \`repeat(\${conditionRules.length}, \${conditionRules.length > 1 ? 'min-content' : '1fr'})\`,
                }}

            >
                {conditionRules.map((conditionRule: any, index) => {
                    const currentLogicalOperator = conditionRule.logicalOperator || '';
                    const isSuccessConditonResult = conditionRule.conditionResult;
                    return (
                        <Stack
                            key={\`\${conditionRule.condition}-\${index}\`}
                            className='lv-row-custom height-full'
                            style={{
                                gridTemplateColumns: CUSTOM_CELL_COLUMNS_TEMPLATE,
                            }}
                        >
                            <div className='lv-td-custom'>
                                {conditionRule.condition}
                            </div>
                            <div className='lv-td-custom'>
                                {currentLogicalOperator}
                            </div>
                            <div className='lv-td-custom'>
                                <Badge
                                    noMaxWidth
                                    className="justify-content-start"
                                    type={\`\${isSuccessConditonResult ? 'success' : 'danger'}\`}
                                    icon={\`fal \${isSuccessConditonResult ? "fa-check-circle " : "fa-times-circle"}\`}
                                    dataUI={getDataUI()}
                                >
                                    {isSuccessConditonResult ? 'True' : 'Flase'}
                                </Badge>
                            </div>
                        </Stack>
                    )
                })}
            </Stack>
        </td>
    )
}

<GridContainer dataUI={getDataUI()} className='mb-xl'>
    <Grid
        data={[...productsGrid]}
        reorderable
    >
        <Column field="ProductID" title="ID" />
        <Column field="ProductName" title="Name" width="250px" />
        <Column field="Category.CategoryName" title="CategoryName" />
        <Column field="UnitPrice" title="Price" />
        <Column field="UnitsInStock" title="In stock" />
        <Column
            field="Discontinued"
            cell={(props) => {
                return (
                    <td>
                        <Checkbox dataUI={getDataUI()} disabled checked={props.dataItem[props.field as any]} />
                    </td>
                )
            }}
        />
    </Grid>
</GridContainer>

// Table with custom cells in grid cell
<GridContainer dataUI={getDataUI()}>
    <Grid data={[...businessRules.slice(0, 3)]}>
        <Column field="AmountToLoanCode" title="Amount To Loan Code" />
        <Column field="AmountToLoan" title="Amount To Loan" />
        <Column field="BusinessRule" title="Business Rule" />
        <Column field='ConditionRules' headerCell={CustomHeader} cell={CustomCell} />
    </Grid>
</GridContainer>
`}
        </Highlight>

        <h2 className="mb-m">GridContainer API</h2>
        <table className="doc-table mb-l">
            <thead>
                <tr>
                    <th colSpan={4}>Props</th>
                </tr>
                <tr>
                    <td>
                        <strong>Name</strong>
                    </td>
                    <td>
                        <strong>Options</strong>
                    </td>
                    <td>
                        <strong>Default</strong>
                    </td>
                    <td>
                        <strong>Description</strong>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>dataLoaded</td>
                    <td className='text-center'>boolean</td>
                    <td className='text-center'>-</td>
                    <td>When this prop value is changed component will run table scrollbar check.</td>
                </tr>
                <tr>
                    <td>dataLength</td>
                    <td className='text-center'>number</td>
                    <td className='text-center'>-</td>
                    <td>When this prop value is changed component will run table scrollbar check.</td>
                </tr>
                <tr>
                    <td>all native div attributes</td>
                    <td className='text-center'>-</td>
                    <td className='text-center'>-</td>
                    <td>You can use all native div attributes like: "style", "className", "data", etc.</td>
                </tr>
            </tbody>
        </table>
    </div>;
}

export default Table;
