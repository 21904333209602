/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Highlight from 'react-highlight';
import { Container } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const ButtonPage: React.FC = () => {
    return (
        <div>
            <h1 className="mb-s">Container</h1>
            <p className="mb-l">The Container component manages in flex layout of immediate children along the vertical or horizontal axis with spacing between each child</p>

            <h3 className="mb-s">Examples</h3>
            <p className="mb-l">
                Depending on the settings you apply, you can achieve different looks for the container
            </p>

            <div className="mb-m">
                <Container className="mb-m" background='color-1' padding='M' radius='small' gap="S" isBorder dataUI={getDataUI()}>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has</p>
                </Container>

                <Container className='mb-m' background='color-2' padding='M' gap="S" dataUI={getDataUI()}>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has</p>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has</p>
                </Container>

                <Container className='mb-m' background='color-1' padding='S' radius='small' gap="S" flexColumn isShadow dataUI={getDataUI()}>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has</p>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has</p>
                </Container>
            </div>

            <Highlight className="React">
                {
                    `
import React from 'react';
import { Container } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const Example: React.FC = (props) => {
    return (
      <div>
            <Container className="mb-m" background='color-1' padding='M' radius='small' gap="S" isBorder dataUI={getDataUI()}>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has</p>
            </Container>

            <Container className='mb-m' background='color-2' padding='M' gap="S" dataUI={getDataUI()}>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has</p>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has</p>
            </Container>

            <Container className='mb-m' background='color-1' padding='S' radius='small' gap="S" flexColumn isShadow dataUI={getDataUI()}>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has</p>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has</p>
            </Container>
      </div>
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>padding</td>
                        <td>'0' | 'XS' | 'S' | 'M' | 'L' | 'XL' | 'XXL'</td>
                        <td className="text-center">-</td>
                        <td>Sets component padding.</td>
                    </tr>
                    <tr>
                        <td>gap</td>
                        <td>'0' | 'XS' | 'S' | 'M' | 'L' | 'XL' | 'XXL'</td>
                        <td className="text-center">-</td>
                        <td>Sets component gap.</td>
                    </tr>
                    <tr>
                        <td>background</td>
                        <td>'color-select' | 'color-select-opacity' | 'color-1' | 'color-2'</td>
                        <td className="text-center">-</td>
                        <td>Sets component background.</td>
                    </tr>
                    <tr>
                        <td>radius</td>
                        <td>'small' | 'big'</td>
                        <td className="text-center">-</td>
                        <td>Sets component radius.</td>
                    </tr>
                    <tr>
                        <td>flexColumn</td>
                        <td>boolean</td>
                        <td className="text-center">-</td>
                        <td>Sets component flex-column styles.</td>
                    </tr>
                    <tr>
                        <td>isBorder</td>
                        <td>boolean</td>
                        <td className="text-center">-</td>
                        <td>Sets component border.</td>
                    </tr>
                    <tr>
                        <td>isShadow</td>
                        <td>boolean</td>
                        <td className="text-center">-</td>
                        <td>Sets component shadow.</td>
                    </tr>
                    <tr>
                        <td>all native div attributes</td>
                        <td className="text-center">-</td>
                        <td className="text-center">-</td>
                        <td>You can use all native div attributes like: "className", etc.</td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default ButtonPage;
