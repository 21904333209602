import React, { useRef } from 'react';
import { useState } from 'react';

import {
    MainContainerHeader, ReactAux, ActionContainer, ActionContainerHeader, ActionContainerCrumbs,
    Button, Modal, Badge, SimpleTableContainer, MainContainerScrollable, ModalScrollableContainer,
    Toolbar, TabContent, TabContentItem, TabItem, TabItemTitle, TabList, Tabs, Label, Col, Row,
    Radio, Select, Input, Checkbox, ConfirmationDialog, Icon, Textarea,
    InfoBox, Tooltip, FileUpload, FILE_UPLOAD_DEFAULT_ERROR_MSG, UploadedFileRow,
} from '@jkhy/vsg-loanvantage-design-system';

import {
    UploadedFileRowProps, examplePDF, exampleText
} from '../helpers/fileUploadHelpers';
import { getDataUI } from '../helpers/helpers';


const DemoCovenant = () => {
    const demoFilesUnmountedRows = useRef({}) as any;

    const [editCovenantModal, setEditCovenantModal] = useState(false);
    const [addCovenantModal, setAddCovenantModal] = useState(false);
    const [editMeasurementModal, setEditMeasurementModal] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [tabIndexMeasurement, setTabIndexMeasurement] = useState(0);
    const [confirmationDialogShow1, setConfirmationDialogShow1] = useState(false);
    const [confirmationDialogShow2, setConfirmationDialogShow2] = useState(false);
    const [confirmationDialogShow3, setConfirmationDialogShow3] = useState(false);
    const [confirmationDialogShow4, setConfirmationDialogShow4] = useState(false);
    const [confirmationDialogShow5, setConfirmationDialogShow5] = useState(false);
    const [confirmationDialogShow6, setConfirmationDialogShow6] = useState(false);
    const [confirmationDialogShow7, setConfirmationDialogShow7] = useState(false);
    const [confirmationDialogShow8, setConfirmationDialogShow8] = useState(false);
    const [select1, setSelect1] = useState('1');
    const [select2, setSelect2] = useState('1');
    const [select3, setSelect3] = useState('1');
    const [select4, setSelect4] = useState('1');
    const [select5, setSelect5] = useState('1');
    const [select6, setSelect6] = useState('1');
    const [select7, setSelect7] = useState('1');
    const [sourceRadio, setSourceRadio] = useState('1');
    const [demoFiles, setDemoFiles] = useState<UploadedFileRowProps[]>([exampleText, examplePDF]);


    const simulateUploading = (updateProgress: any, fileData: any) => {
        // Simulated uploading with random time for upload.
        const time = +((Math.floor(Math.random() * 10) + 1) + '000');
        let progress = fileData.uploadedFile.progress || 0;

        const interval = setInterval(() => {
            progress += 1;
            if (demoFilesUnmountedRows.current[fileData.key] || progress > 100) {
                clearInterval(interval);
                return;
            }
            updateProgress(progress)
        }, time / 100)
    }

    const onDeleteFile = (fileKey: any) => {
        demoFilesUnmountedRows.current[fileKey] = true;
        setDemoFiles(demoFiles.filter((f: any) => f.key !== fileKey));
    }

    const getErrorMsg = (err: any) => {
        let title = 'Error';
        let description = 'Try againg.';
        if (err === FILE_UPLOAD_DEFAULT_ERROR_MSG.invalidType) {
            title = 'Invalid File type';
            description = 'You are tring to upload invalid file type.';
        } else if (err === FILE_UPLOAD_DEFAULT_ERROR_MSG.sizeExceeded) {
            title = 'File size exceeded';
            description = 'Try to upload file with smaller size'
        } else if (err === FILE_UPLOAD_DEFAULT_ERROR_MSG.pdfPasswordProtected) {
            title = 'File is password protected';
            description = 'Uploaded file appears to have a password protection. Please upload a file that is not password protected.'
        }

        return {
            title,
            description
        }
    }

    const onAddFile = (files: any) => {
        const filesToUpload = [...files];
        let filesWithKeys = [...demoFiles];

        for (let i = 0; i < filesToUpload.length; i++) {
            const isError = !!filesToUpload[i].error;
            const newFileRow = {
                uploadedFile: {
                    file: filesToUpload[i].file,
                    uploading: !isError,
                    uploadError: isError,
                    errorInfo: filesToUpload[i].error ? getErrorMsg(filesToUpload[i].error) : '',
                },
                key: `${filesToUpload[i].file.name}-${filesWithKeys.length + 1}`
            } as UploadedFileRowProps;

            demoFilesUnmountedRows.current[newFileRow.key] = false;

            filesWithKeys = [
                ...filesWithKeys,
                newFileRow,
            ];
        }

        setDemoFiles(filesWithKeys);
    }

    const updateUploadedFileRowProp = (fileData: any, prop: 'uploading' | 'uploadDone', value: any) => {
        setDemoFiles(
            demoFiles.map((file: any) => {
                if (file.key === fileData.key) {
                    const copyFile = { ...file };
                    copyFile.uploadedFile[prop] = value;
                    return copyFile;
                }
                return file;
            })
        )
    }

    const renderFileRows = () => {
        const files = [...demoFiles];

        if (files.length === 0) {
            return null;
        }

        return files.map((fileData: any) => {
            const isError = fileData.uploadedFile.uploadError;
            const isUploading = fileData.uploadedFile.uploading;

            return (
                <UploadedFileRow
                    dataUI={getDataUI()} 
                    key={fileData.key}
                    viewMode={fileData.viewMode}
                    uploadedFile={{
                        ...fileData.uploadedFile,
                        uploadError: isError,
                    }}
                    onProgress={(updateProgress: any) => {
                        if (isUploading) {
                            simulateUploading(updateProgress, fileData);
                        }
                    }}
                    onSuccessUploaded={(progress: any, setUploadSuccessful: any) => {
                        if (progress === 100) {
                            setUploadSuccessful(true);
                            updateUploadedFileRowProp(fileData, "uploading", false)
                        }
                    }}
                    onUploadDone={(uploadSuccessful: any, setUploadDone: any) => {
                        if (uploadSuccessful) {
                            setTimeout(() => {
                                setUploadDone(true);
                                updateUploadedFileRowProp(fileData, "uploadDone", true);
                            }, 3000);
                        }
                    }}
                    onDelete={() => {
                        onDeleteFile(fileData.key);
                    }}
                />
            );
        })
    }


    return (
        <ReactAux>
            <MainContainerHeader dataUI={getDataUI()} >
                <ActionContainer dataUI={getDataUI()} >
                    <ActionContainerHeader dataUI={getDataUI()} >
                        <ActionContainerCrumbs crumbs={['Covenant']} dataUI={getDataUI()}  />
                    </ActionContainerHeader>
                </ActionContainer>
            </MainContainerHeader>

            <MainContainerScrollable>
                <Button dataUI={getDataUI()}  className="mt-l mr-m" icon="fal fa-plus" onClick={() => setAddCovenantModal(true)}>Add Covenant Modal</Button>
                <Button dataUI={getDataUI()}  className="mt-l" icon="fal fa-pen" onClick={() => setEditCovenantModal(true)}>Edit Covenant Modal</Button>
            </MainContainerScrollable>

            <Modal dataUI={getDataUI()}  title="Add Covenant" size="M" isVisible={addCovenantModal} onClose={() => setAddCovenantModal(false)}>
                <Toolbar dataUI={getDataUI()}  className="mb-m"
                    leftSide={
                        <>
                            <Button dataUI={getDataUI()}  className="mr-s" icon="fal fa-save" onClick={() => setAddCovenantModal(false)}>Save</Button>
                            <Button dataUI={getDataUI()}  className="mr-s" icon="fal fa-save" onClick={() => setAddCovenantModal(false)}>Save and Close</Button>
                            <Button dataUI={getDataUI()}  icon="far fa-times-circle" btnType="secondary" onClick={() => setAddCovenantModal(false)}>Cancel</Button>
                        </>
                    }
                />

                <ModalScrollableContainer>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label dataUI={getDataUI()} className="justify-content-end text-right">Covenant Type</Label>
                        </Col>
                        <Col xs={6}>
                            <Radio dataUI={getDataUI()} className="mr-s" name="covenant-type" defaultChecked>Party</Radio>
                            <Radio dataUI={getDataUI()} className="mr-s" name="covenant-type">Loan</Radio>
                            <Radio dataUI={getDataUI()} name="covenant-type">Collateral</Radio>
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label dataUI={getDataUI()} className="justify-content-end text-right">Entities</Label>
                        </Col>
                        <Col xs={6}>
                            <Tooltip title="Add">
                                <Button dataUI={getDataUI()}  btnType="icon" icon="fal fa-plus" />
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label dataUI={getDataUI()} className="justify-content-end text-right">Covenant Status</Label>
                        </Col>
                        <Col xs={6}>
                            <Radio dataUI={getDataUI()} className="mr-s" name="covenant-status" defaultChecked>Open</Radio>
                            <Radio dataUI={getDataUI()} name="covenant-status">Closed</Radio>
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label dataUI={getDataUI()} className="justify-content-end text-right">Correspondent</Label>
                        </Col>
                        <Col xs={6}>
                            <Select
                                dataUI={getDataUI()} 
                                options={[
                                    { value: '1', label: 'SHAHZAD QADIR' },
                                    { value: '2', label: 'Option 2' },
                                ]}
                                value={select1}
                                onChange={(obj: any) => {
                                    setSelect1(obj.value);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label dataUI={getDataUI()} className="justify-content-end text-right">Category</Label>
                        </Col>
                        <Col xs={6}>
                            <Select
                                dataUI={getDataUI()} 
                                options={[
                                    { value: '1', label: 'Option 1' },
                                    { value: '2', label: 'Option 2' },
                                ]}
                                value={select1}
                                onChange={(obj: any) => {
                                    setSelect1(obj.value);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label dataUI={getDataUI()} className="justify-content-end text-right">Covenant</Label>
                        </Col>
                        <Col xs={6}>
                            <Select
                                dataUI={getDataUI()} 
                                options={[
                                    { value: '1', label: 'Option 1' },
                                    { value: '2', label: 'Option 2' },
                                ]}
                                value={select1}
                                onChange={(obj: any) => {
                                    setSelect1(obj.value);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label dataUI={getDataUI()} className="justify-content-end text-right">Covenant Designation</Label>
                        </Col>
                        <Col xs={6}>
                            <Radio dataUI={getDataUI()} className="mr-s" name="covenant-standard" defaultChecked>Standard</Radio>
                            <Radio dataUI={getDataUI()} name="covenant-standard">Non-Standard</Radio>
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label dataUI={getDataUI()} className="justify-content-end text-right">Default Description</Label>
                        </Col>
                        <Col xs={6}>
                            <Label dataUI={getDataUI()} ><strong>Imaging test</strong></Label>
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label dataUI={getDataUI()} className="justify-content-end text-right">Required Value</Label>
                        </Col>
                        <Col xs={6}>
                            <Input dataUI={getDataUI()} />
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label dataUI={getDataUI()} className="justify-content-end text-right">Statement Date</Label>
                        </Col>
                        <Col xs={6}>
                            <Input dataUI={getDataUI()} />
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label dataUI={getDataUI()} className="justify-content-end text-right">Grace Days</Label>
                        </Col>
                        <Col xs={6}>
                            <Input dataUI={getDataUI()} />
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label dataUI={getDataUI()} className="justify-content-end text-right">Frequency</Label>
                        </Col>
                        <Col xs={6}>
                            <Select
                                dataUI={getDataUI()} 
                                options={[
                                    { value: '1', label: 'Semi Annually' },
                                    { value: '2', label: 'Option 2' },
                                ]}
                                value={select4}
                                onChange={(obj: any) => {
                                    setSelect4(obj.value);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label dataUI={getDataUI()} className="justify-content-end text-right">Recurring (Auto-Create)</Label>
                        </Col>
                        <Col xs={6}>
                            <Checkbox dataUI={getDataUI()}  defaultChecked />
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label dataUI={getDataUI()} className="justify-content-end text-right">Primary Officer</Label>
                        </Col>
                        <Col xs={6}>
                            <div className="d-flex">
                                <Input dataUI={getDataUI()} className="width-full" />
                                <Tooltip title="Updated from core">
                                    <Icon dataUI={getDataUI()} className="ml-s" icon="fal fa-sync" size="M" />
                                </Tooltip>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label dataUI={getDataUI()} className="justify-content-end text-right">Secondary Officer</Label>
                        </Col>
                        <Col xs={6}>
                            <div className="d-flex">
                                <Input dataUI={getDataUI()} className="width-full" />
                                <Tooltip title="Updated from core">
                                    <Icon dataUI={getDataUI()} className="ml-s" icon="fal fa-sync" size="M" />
                                </Tooltip>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label dataUI={getDataUI()} className="justify-content-end text-right">Branch</Label>
                        </Col>
                        <Col xs={6}>
                            <div className="d-flex">
                                <Input dataUI={getDataUI()} className="width-full" />
                                <Tooltip title="Updated from core">
                                    <Icon dataUI={getDataUI()} className="ml-s" icon="fal fa-sync" size="M" />
                                </Tooltip>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label dataUI={getDataUI()} className="justify-content-end text-right">Region</Label>
                        </Col>
                        <Col xs={6}>
                            <Select
                                dataUI={getDataUI()} 
                                options={[
                                    { value: '1', label: 'Semi Annually' },
                                    { value: '2', label: 'Option 2' },
                                ]}
                                value={select5}
                                onChange={(obj: any) => {
                                    setSelect5(obj.value);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label dataUI={getDataUI()} className="justify-content-end text-right">Monitored by</Label>
                        </Col>
                        <Col xs={6}>
                            <Select
                                dataUI={getDataUI()} 
                                options={[
                                    { value: '1', label: 'Jak Test' },
                                    { value: '2', label: 'Option 2' },
                                ]}
                                value={select6}
                                onChange={(obj: any) => {
                                    setSelect6(obj.value);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label dataUI={getDataUI()} className="justify-content-end text-right">Comments</Label>
                        </Col>
                        <Col xs={6}>
                            <Textarea dataUI={getDataUI()}  name="textarea-comments" />
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label dataUI={getDataUI()} className="justify-content-end text-right">Last Status Change</Label>
                        </Col>
                        <Col xs={6}>
                            <Label dataUI={getDataUI()} ></Label>
                        </Col>
                    </Row>
                    <Row className="mb-s">
                        <Col xs={3}>
                            <Label dataUI={getDataUI()} className="justify-content-end text-right">FYE</Label>
                        </Col>
                        <Col xs={6}>
                            <Label dataUI={getDataUI()} ><strong>12/31</strong></Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={3}>
                            <Label dataUI={getDataUI()} className="justify-content-end text-right">Subject To</Label>
                        </Col>
                        <Col xs={6}>
                            <Checkbox dataUI={getDataUI()}  defaultChecked />
                        </Col>
                    </Row>
                </ModalScrollableContainer>
            </Modal>


            <Modal dataUI={getDataUI()} title="Covenant 53891" size="L" isVisible={editCovenantModal} onClose={() => setEditCovenantModal(false)}>
                <Toolbar dataUI={getDataUI()}  className="mb-m"
                    leftSide={
                        <>
                            <Button dataUI={getDataUI()}  className="mr-s" icon="fal fa-envelope" btnType="secondary">Email</Button>
                            <Button dataUI={getDataUI()}  className="mr-s" icon="fal fa-envelope-open-text" btnType="secondary">Letter</Button>
                            <Button dataUI={getDataUI()}  icon="fal fa-phone" btnType="secondary">Call</Button>
                        </>
                    }
                    rightSide={
                        <>
                            {isEditMode ? <>
                                <Button dataUI={getDataUI()}  className="mr-s" icon="fal fa-save" onClick={() => setIsEditMode(false)}>Save</Button>
                                <Button dataUI={getDataUI()}  className="mr-s" icon="fal fa-save" onClick={() => { setEditCovenantModal(false); setIsEditMode(false); }}>Save and Close</Button>
                                <ConfirmationDialog
                                    dataUI={getDataUI()} 
                                    show={confirmationDialogShow1}
                                    render={() => <>
                                        <p className="text-center mb-m">Are you sure?</p>
                                        <Toolbar dataUI={getDataUI()}  leftSide={<>
                                            <Button dataUI={getDataUI()}  onClick={() => setConfirmationDialogShow1(false)} btnType="secondary" icon='fal fa-thumbs-up' className="mr-s" >Yes</Button>
                                            <Button dataUI={getDataUI()}  onClick={() => setConfirmationDialogShow1(false)} btnType="primary" icon='fal fa-thumbs-down' className="mr-s" >No</Button></>
                                        } />
                                    </>}
                                    onClose={() => setConfirmationDialogShow1(false)}
                                >
                                    <Button dataUI={getDataUI()}  className="mr-s" icon="fal fa-trash" btnType="secondary" onClick={() => setConfirmationDialogShow1(true)}>Delete</Button>
                                </ConfirmationDialog>
                                <Button dataUI={getDataUI()}  icon="far fa-times-circle" btnType="secondary" onClick={() => setIsEditMode(false)}>Cancel</Button>
                            </>
                                :
                                <>
                                    <Button dataUI={getDataUI()}  className="mr-s" icon="fal fa-pen" onClick={() => setIsEditMode(true)}>Edit</Button>
                                    <Button dataUI={getDataUI()}  icon="far fa-times-circle" btnType="secondary" onClick={() => setEditCovenantModal(false)}>Close</Button>
                                </>}
                        </>
                    }
                />

                <Tabs dataUI={getDataUI()}  onTabIndexChange={(index: number) => setTabIndex(index)} currentlyActiveTabIndex={tabIndex}>
                    <TabList dataUI={getDataUI()} >
                        <TabItem dataUI={getDataUI()} >
                            <TabItemTitle dataUI={getDataUI()}>Details</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()} >
                            <TabItemTitle dataUI={getDataUI()}>Measurements</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()} >
                            <TabItemTitle dataUI={getDataUI()}>Notes</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()} >
                            <TabItemTitle dataUI={getDataUI()}>Communication</TabItemTitle>
                        </TabItem>
                    </TabList>
                    <TabContent dataUI={getDataUI()} >
                        <TabContentItem dataUI={getDataUI()} >
                            <ModalScrollableContainer>
                                <div className="d-flex">
                                    <div className="section-container flex-1 mr-m" style={{ maxWidth: '50%' }}>
                                        <div className="d-flex align-items-center mb-m">
                                            <h2 className="mr-s">Covenant Info</h2>
                                            <Badge dataUI={getDataUI()}  type="danger" icon="fal fa-exclamation-triangle">Past Due</Badge>
                                        </div>
                                        <hr className="mb-m" />
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right">Covenant Type</Label>
                                            </Col>
                                            <Col xs={8}>
                                                <Radio dataUI={getDataUI()} className="mr-s" readOnly={!isEditMode} name="covenant-type" defaultChecked>Party</Radio>
                                                <Radio dataUI={getDataUI()} className="mr-s" readOnly={!isEditMode} name="covenant-type">Loan</Radio>
                                                <Radio dataUI={getDataUI()} readOnly={!isEditMode} name="covenant-type">Collateral</Radio>
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right">Entities</Label>
                                            </Col>
                                            <Col xs={8}>
                                                <Label dataUI={getDataUI()} >
                                                    <Tooltip title="BFT 15977, SHAHZAD QADIR, Another Entity's Name">
                                                        <strong className="white-space-nowrap text-overflow-ellipsis overflow-hidden">BFT 15977, SHAHZAD QADIR, Another Entity’s Name</strong>
                                                    </Tooltip>
                                                    {isEditMode &&
                                                        <Tooltip title="Edit">
                                                            <Button dataUI={getDataUI()}  btnType="icon" icon="fal fa-pen" className="ml-s" />
                                                        </Tooltip>
                                                    }
                                                </Label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right">Covenant Status</Label>
                                            </Col>
                                            <Col xs={8}>
                                                <Radio dataUI={getDataUI()} className="mr-s" readOnly={!isEditMode} name="covenant-status" defaultChecked>Open</Radio>
                                                <Radio dataUI={getDataUI()} readOnly={!isEditMode} name="covenant-status">Closed</Radio>
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right">Correspondent</Label>
                                            </Col>
                                            <Col xs={8}>
                                                {isEditMode ? <div className="d-flex align-items-center">
                                                    <Select
                                                        dataUI={getDataUI()} 
                                                        className="width-full"
                                                        options={[
                                                            { value: '1', label: 'SHAHZAD QADIR' },
                                                            { value: '2', label: 'Option 2' },
                                                        ]}
                                                        value={select1}
                                                        onChange={(obj: any) => {
                                                            setSelect1(obj.value);
                                                        }}
                                                    />
                                                    {isEditMode &&
                                                        <Tooltip title="Edit">
                                                            <Button dataUI={getDataUI()}  btnType="icon" icon="fal fa-pen" className="ml-s" />
                                                        </Tooltip>
                                                    }
                                                </div> :
                                                    <Label dataUI={getDataUI()} ><strong>SHAHZAD QADIR</strong></Label>
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right">Category</Label>
                                            </Col>
                                            <Col xs={8}>
                                                <Label dataUI={getDataUI()} ><strong>NonFinancial Covenant</strong></Label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right">Covenant</Label>
                                            </Col>
                                            <Col xs={8}>
                                                <Label dataUI={getDataUI()} ><strong>Available for Det PCF {'<'} 1800,000</strong></Label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right">Covenant Designation</Label>
                                            </Col>
                                            <Col xs={8}>
                                                <Radio dataUI={getDataUI()} className="mr-s" readOnly={!isEditMode} name="covenant-standard" defaultChecked>Standard</Radio>
                                                <Radio dataUI={getDataUI()} readOnly={!isEditMode} name="covenant-standard">Non-Standard</Radio>
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right">Default Description</Label>
                                            </Col>
                                            <Col xs={8}>
                                                <Label dataUI={getDataUI()} ></Label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right">Required Value</Label>
                                            </Col>
                                            <Col xs={8}>
                                                {isEditMode ? <div className="d-flex">
                                                    <Select
                                                        dataUI={getDataUI()} 
                                                        className="mr-s width-full"
                                                        options={[
                                                            { value: '1', label: '<' },
                                                            { value: '2', label: 'Option 2' },
                                                        ]}
                                                        value={select2}
                                                        onChange={(obj: any) => {
                                                            setSelect2(obj.value);
                                                        }}
                                                    />
                                                    <Select
                                                        dataUI={getDataUI()} 
                                                        className="width-full"
                                                        options={[
                                                            { value: '1', label: '1800000' },
                                                            { value: '2', label: 'Option 2' },
                                                        ]}
                                                        value={select3}
                                                        onChange={(obj: any) => {
                                                            setSelect3(obj.value);
                                                        }}
                                                    />
                                                </div> :
                                                    <Label dataUI={getDataUI()} ><strong>{'<'} 1800000</strong></Label>
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right">Grace Days</Label>
                                            </Col>
                                            <Col xs={8}>
                                                {isEditMode ? <Input dataUI={getDataUI()} /> :
                                                    <Label dataUI={getDataUI()} ></Label>
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right">Frequency</Label>
                                            </Col>
                                            <Col xs={8}>
                                                {isEditMode ?
                                                    <Select
                                                        dataUI={getDataUI()} 
                                                        options={[
                                                            { value: '1', label: 'Semi Annually' },
                                                            { value: '2', label: 'Option 2' },
                                                        ]}
                                                        value={select4}
                                                        onChange={(obj: any) => {
                                                            setSelect4(obj.value);
                                                        }}
                                                    /> :
                                                    <Label dataUI={getDataUI()} ><strong>Semi Annually</strong></Label>
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right">Recurring (Auto-Create)</Label>
                                            </Col>
                                            <Col xs={8}>
                                                <Checkbox dataUI={getDataUI()}  readOnly={!isEditMode} defaultChecked />
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right">Primary Officer</Label>
                                            </Col>
                                            <Col xs={8}>
                                                {isEditMode ? <div className="d-flex">
                                                    <Input dataUI={getDataUI()} className="width-full" />
                                                    <Tooltip title="Updated from core">
                                                        <Icon dataUI={getDataUI()} className="ml-s" icon="fal fa-sync" size="M" />
                                                    </Tooltip>
                                                </div> :
                                                    <Label dataUI={getDataUI()} ><strong>Robin Tuffs</strong></Label>
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right">Secondary Officer</Label>
                                            </Col>
                                            <Col xs={8}>
                                                {isEditMode ? <div className="d-flex">
                                                    <Input dataUI={getDataUI()} className="width-full" />
                                                    <Tooltip title="Updated from core">
                                                        <Icon dataUI={getDataUI()} className="ml-s" icon="fal fa-sync" size="M" />
                                                    </Tooltip>
                                                </div> :
                                                    <Label dataUI={getDataUI()} ></Label>
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right">Branch</Label>
                                            </Col>
                                            <Col xs={8}>
                                                {isEditMode ? <div className="d-flex">
                                                    <Input dataUI={getDataUI()} className="width-full" />
                                                    <Tooltip title="Updated from core">
                                                        <Icon dataUI={getDataUI()} className="ml-s" icon="fal fa-sync" size="M" />
                                                    </Tooltip>
                                                </div> :
                                                    <Label dataUI={getDataUI()} ></Label>
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right">Region</Label>
                                            </Col>
                                            <Col xs={8}>
                                                {isEditMode ?
                                                    <Select
                                                        dataUI={getDataUI()} 
                                                        options={[
                                                            { value: '1', label: 'Semi Annually' },
                                                            { value: '2', label: 'Option 2' },
                                                        ]}
                                                        value={select5}
                                                        onChange={(obj: any) => {
                                                            setSelect5(obj.value);
                                                        }}
                                                    /> :
                                                    <Label dataUI={getDataUI()} ></Label>
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right">Monitored by</Label>
                                            </Col>
                                            <Col xs={8}>
                                                {isEditMode ?
                                                    <Select
                                                        dataUI={getDataUI()} 
                                                        options={[
                                                            { value: '1', label: 'Jak Test' },
                                                            { value: '2', label: 'Option 2' },
                                                        ]}
                                                        value={select6}
                                                        onChange={(obj: any) => {
                                                            setSelect6(obj.value);
                                                        }}
                                                    /> :
                                                    <Label dataUI={getDataUI()} ><strong>Jak Test</strong></Label>
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right">Comments</Label>
                                            </Col>
                                            <Col xs={8}>
                                                {isEditMode ? <Textarea dataUI={getDataUI()}  name="textarea-comments" /> :
                                                    <Label dataUI={getDataUI()} ></Label>
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right">Created</Label>
                                            </Col>
                                            <Col xs={8}>
                                                <Label dataUI={getDataUI()} ><strong>2/24/2017 By Joro II</strong></Label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right">Last Updated</Label>
                                            </Col>
                                            <Col xs={8}>
                                                <Label dataUI={getDataUI()} ><strong>8/11/2021 ByJoro II</strong></Label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right">Last Status Change</Label>
                                            </Col>
                                            <Col xs={8}>
                                                <Label dataUI={getDataUI()} ></Label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={4}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right">FYE</Label>
                                            </Col>
                                            <Col xs={8}>
                                                <Label dataUI={getDataUI()} ><strong>12/31</strong></Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right">Subject To</Label>
                                            </Col>
                                            <Col xs={8}>
                                                <Checkbox dataUI={getDataUI()}  readOnly={!isEditMode} defaultChecked />
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="flex-1">
                                        <div className="section-container mb-m">
                                            <div className="d-flex align-items-center mb-m">
                                                <h2 className="mr-s">Current Period Info</h2>
                                                <Badge dataUI={getDataUI()}  type="danger" icon="fal fa-exclamation-triangle">Past Due</Badge>
                                            </div>
                                            <hr className="mb-m" />
                                            <Row className="mb-s">
                                                <Col xs={4}>
                                                    <Label dataUI={getDataUI()} className="justify-content-end text-right">Statement Date</Label>
                                                </Col>
                                                <Col xs={8}>
                                                    <Label dataUI={getDataUI()} ><strong>2/25/2021</strong></Label>
                                                </Col>
                                            </Row>
                                            <Row className="mb-s">
                                                <Col xs={4}>
                                                    <Label dataUI={getDataUI()} className="justify-content-end text-right">Value</Label>
                                                </Col>
                                                <Col xs={8}>
                                                    <Label dataUI={getDataUI()} ></Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={4}>
                                                    <Label dataUI={getDataUI()} className="justify-content-end text-right">Status</Label>
                                                </Col>
                                                <Col xs={8}>
                                                    <Label dataUI={getDataUI()} ><strong>Pending</strong></Label>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="section-container mb-m">
                                            <div className="d-flex align-items-center mb-m">
                                                <h2 className="mr-s">Last Measured Period Info</h2>
                                                <Badge dataUI={getDataUI()}  type="danger" noMaxWidth icon="far fa-times-circle">Non-Compliant</Badge>
                                            </div>
                                            <hr className="mb-m" />
                                            <Row className="mb-s">
                                                <Col xs={4}>
                                                    <Label dataUI={getDataUI()} className="justify-content-end text-right">Statement Date</Label>
                                                </Col>
                                                <Col xs={8}>
                                                    <Label dataUI={getDataUI()} ><strong>2/25/2021</strong></Label>
                                                </Col>
                                            </Row>
                                            <Row className="mb-s">
                                                <Col xs={4}>
                                                    <Label dataUI={getDataUI()} className="justify-content-end text-right">Value</Label>
                                                </Col>
                                                <Col xs={8}>
                                                    <Label dataUI={getDataUI()} ><strong>1,900,000.0000</strong></Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={4}>
                                                    <Label dataUI={getDataUI()} className="justify-content-end text-right">Status</Label>
                                                </Col>
                                                <Col xs={8}>
                                                    <Label dataUI={getDataUI()} ><strong>Measured</strong></Label>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="section-container">
                                            <h2 className="mb-m">Action Summary</h2>
                                            <hr className="mb-m" />
                                            <Row className="mb-s">
                                                <Col xs={4}>
                                                    <Label dataUI={getDataUI()} className="justify-content-end text-right">One-Time Waivers</Label>
                                                </Col>
                                                <Col xs={8}>
                                                    <Label dataUI={getDataUI()} ><strong>0</strong></Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={4}>
                                                    <Label dataUI={getDataUI()} className="justify-content-end text-right">Due Date Extentions</Label>
                                                </Col>
                                                <Col xs={8}>
                                                    <Label dataUI={getDataUI()} ><strong>0</strong></Label>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </ModalScrollableContainer>
                        </TabContentItem>
                        <TabContentItem dataUI={getDataUI()} >
                            <Toolbar dataUI={getDataUI()}  className="mb-m"
                                leftSide={
                                    <Button dataUI={getDataUI()}  icon="fal fa-plus">Add Measurement</Button>
                                }
                            />
                            <ModalScrollableContainer>
                                <SimpleTableContainer dataUI={getDataUI()} >
                                    <table>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '26px' }}></th>
                                                <th>Measurement</th>
                                                <th className="text-right">Meas. Date</th>
                                                <th className="text-right">Stmt. Date</th>
                                                <th className="text-right">Due Date</th>
                                                <th className="text-right">Value</th>
                                                <th className="text-right">Req. Value</th>
                                                <th>Status</th>
                                                <th style={{ width: '59px' }}>Past Due</th>
                                                <th style={{ width: '59px' }}>In Cmplnc</th>
                                                <th style={{ width: '59px' }}>Doc Link</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <Tooltip title="Edit">
                                                        <Button dataUI={getDataUI()}  btnType="icon" icon="fal fa-pen" onClick={() => setEditMeasurementModal(true)} />
                                                    </Tooltip>
                                                </td>
                                                <td>Future</td>
                                                <td className="text-right"></td>
                                                <td className="text-right">08/25/21</td>
                                                <td className="text-right">08/25/21</td>
                                                <td className="text-right"></td>
                                                <td className="text-right">{'<'} 1,800,000.0000</td>
                                                <td>Pending</td>
                                                <td className="text-center"></td>
                                                <td className="text-center"></td>
                                                <td className="text-center"></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Tooltip title="Edit">
                                                        <Button dataUI={getDataUI()}  btnType="icon" icon="fal fa-pen" onClick={() => setEditMeasurementModal(true)} />
                                                    </Tooltip>
                                                </td>
                                                <td>Current</td>
                                                <td className="text-right"></td>
                                                <td className="text-right">08/25/21</td>
                                                <td className="text-right">08/25/21</td>
                                                <td className="text-right"></td>
                                                <td className="text-right">{'<'} 1,800,000.0000</td>
                                                <td>Pending</td>
                                                <td className="text-center">
                                                    <Tooltip title="Past-Due">
                                                        <Icon dataUI={getDataUI()} className="text-danger" icon="fal fa-exclamation-triangle" size="M" />
                                                    </Tooltip>
                                                </td>
                                                <td className="text-center"></td>
                                                <td className="text-center">
                                                    <Tooltip title="Past-Due">
                                                        <Icon dataUI={getDataUI()} className="text-danger" icon="fal fa-exclamation-triangle" size="M" />
                                                    </Tooltip>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Tooltip title="Edit">
                                                        <Button dataUI={getDataUI()}  btnType="icon" icon="fal fa-pen" onClick={() => setEditMeasurementModal(true)} />
                                                    </Tooltip>
                                                </td>
                                                <td>Past</td>
                                                <td className="text-right"></td>
                                                <td className="text-right">08/25/20</td>
                                                <td className="text-right">08/25/20</td>
                                                <td className="text-right"></td>
                                                <td className="text-right">{'<'} 1,800,000.0000</td>
                                                <td>Pending</td>
                                                <td className="text-center">
                                                    <Tooltip title="Past-Due">
                                                        <Icon dataUI={getDataUI()} className="text-danger" icon="fal fa-exclamation-triangle" size="M" />
                                                    </Tooltip>
                                                </td>
                                                <td className="text-center"></td>
                                                <td className="text-center"></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Tooltip title="Edit">
                                                        <Button dataUI={getDataUI()}  btnType="icon" icon="fal fa-pen" onClick={() => setEditMeasurementModal(true)} />
                                                    </Tooltip>
                                                </td>
                                                <td>Past</td>
                                                <td className="text-right"></td>
                                                <td className="text-right">08/25/20</td>
                                                <td className="text-right">08/25/20</td>
                                                <td className="text-right"></td>
                                                <td className="text-right">{'<'} 1,800,000.0000</td>
                                                <td>Pending</td>
                                                <td className="text-center">
                                                    <Tooltip title="Past-Due">
                                                        <Icon dataUI={getDataUI()} className="text-danger" icon="fal fa-exclamation-triangle" size="M" />
                                                    </Tooltip>
                                                </td>
                                                <td className="text-center"></td>
                                                <td className="text-center">
                                                    <Tooltip title="Pending">
                                                        <Icon dataUI={getDataUI()} icon="fal fa-clock" size="M" />
                                                    </Tooltip>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Tooltip title="Edit">
                                                        <Button dataUI={getDataUI()}  btnType="icon" icon="fal fa-pen" onClick={() => setEditMeasurementModal(true)} />
                                                    </Tooltip>
                                                </td>
                                                <td>Past</td>
                                                <td className="text-right"></td>
                                                <td className="text-right">08/25/19</td>
                                                <td className="text-right">08/25/19</td>
                                                <td className="text-right"></td>
                                                <td className="text-right">{'<'} 1,800,000.0000</td>
                                                <td>Pending</td>
                                                <td className="text-center">
                                                    <Tooltip title="Past-Due">
                                                        <Icon dataUI={getDataUI()} className="text-danger" icon="fal fa-exclamation-triangle" size="M" />
                                                    </Tooltip>
                                                </td>
                                                <td className="text-center"></td>
                                                <td className="text-center"></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Tooltip title="Edit">
                                                        <Button dataUI={getDataUI()}  btnType="icon" icon="fal fa-pen" onClick={() => setEditMeasurementModal(true)} />
                                                    </Tooltip>
                                                </td>
                                                <td>Past</td>
                                                <td className="text-right">06/17/19</td>
                                                <td className="text-right">08/25/19</td>
                                                <td className="text-right">08/25/19</td>
                                                <td className="text-right">1,900,000.0000</td>
                                                <td className="text-right">{'<'} 1,800,000.0000</td>
                                                <td>Measured</td>
                                                <td className="text-center"></td>
                                                <td className="text-center">
                                                    <Tooltip title="Non-Compliant">
                                                        <Icon dataUI={getDataUI()} className="text-danger" icon="far fa-times-circle" size="M" />
                                                    </Tooltip>
                                                </td>
                                                <td className="text-center"></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Tooltip title="Edit">
                                                        <Button dataUI={getDataUI()}  btnType="icon" icon="fal fa-pen" onClick={() => setEditMeasurementModal(true)} />
                                                    </Tooltip>
                                                </td>
                                                <td>Past</td>
                                                <td className="text-right"></td>
                                                <td className="text-right">08/25/19</td>
                                                <td className="text-right">08/25/19</td>
                                                <td className="text-right"></td>
                                                <td className="text-right">{'<'} 1,800,000.0000</td>
                                                <td>Pending</td>
                                                <td className="text-center">
                                                    <Tooltip title="Past-Due">
                                                        <Icon dataUI={getDataUI()} className="text-danger" icon="fal fa-exclamation-triangle" size="M" />
                                                    </Tooltip>
                                                </td>
                                                <td className="text-center"></td>
                                                <td className="text-center"></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Tooltip title="Edit">
                                                        <Button dataUI={getDataUI()}  btnType="icon" icon="fal fa-pen" onClick={() => setEditMeasurementModal(true)} />
                                                    </Tooltip>
                                                </td>
                                                <td>Past</td>
                                                <td className="text-right"></td>
                                                <td className="text-right">08/25/19</td>
                                                <td className="text-right">08/25/19</td>
                                                <td className="text-right"></td>
                                                <td className="text-right">{'<'} 1,800,000.0000</td>
                                                <td>Pending</td>
                                                <td className="text-center">
                                                    <Tooltip title="Past-Due">
                                                        <Icon dataUI={getDataUI()} className="text-danger" icon="fal fa-exclamation-triangle" size="M" />
                                                    </Tooltip>
                                                </td>
                                                <td className="text-center">
                                                    <Tooltip title="Compliant">
                                                        <Icon dataUI={getDataUI()} className="text-success" icon="fal fa-check" size="M" />
                                                    </Tooltip>
                                                </td>
                                                <td className="text-center">
                                                    <Tooltip title="Received">
                                                        <Icon dataUI={getDataUI()} className="text-success" icon="fal fa-check-circle" size="M" />
                                                    </Tooltip>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Tooltip title="Edit">
                                                        <Button dataUI={getDataUI()}  btnType="icon" icon="fal fa-pen" onClick={() => setEditMeasurementModal(true)} />
                                                    </Tooltip>
                                                </td>
                                                <td>Past</td>
                                                <td className="text-right"></td>
                                                <td className="text-right">08/25/19</td>
                                                <td className="text-right">08/25/19</td>
                                                <td className="text-right"></td>
                                                <td className="text-right">{'<'} 1,800,000.0000</td>
                                                <td>Pending</td>
                                                <td className="text-center">
                                                    <Tooltip title="Past-Due">
                                                        <Icon dataUI={getDataUI()} className="text-danger" icon="fal fa-exclamation-triangle" size="M" />
                                                    </Tooltip>
                                                </td>
                                                <td className="text-center"></td>
                                                <td className="text-center"></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Tooltip title="Edit">
                                                        <Button dataUI={getDataUI()}  btnType="icon" icon="fal fa-pen" onClick={() => setEditMeasurementModal(true)} />
                                                    </Tooltip>
                                                </td>
                                                <td>Past</td>
                                                <td className="text-right"></td>
                                                <td className="text-right">08/25/19</td>
                                                <td className="text-right">08/25/19</td>
                                                <td className="text-right"></td>
                                                <td className="text-right">{'<'} 1,800,000.0000</td>
                                                <td>Pending</td>
                                                <td className="text-center">
                                                    <Tooltip title="Past-Due">
                                                        <Icon dataUI={getDataUI()} className="text-danger" icon="fal fa-exclamation-triangle" size="M" />
                                                    </Tooltip>
                                                </td>
                                                <td className="text-center"></td>
                                                <td className="text-center">
                                                    <Tooltip title="Caution">
                                                        <Icon dataUI={getDataUI()} className="text-warning" icon="fal fa-exclamation-circle" size="M" />
                                                    </Tooltip>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </SimpleTableContainer>
                            </ModalScrollableContainer>
                        </TabContentItem>
                        <TabContentItem dataUI={getDataUI()} >
                            <Toolbar dataUI={getDataUI()}  className="mb-m"
                                leftSide={
                                    <Button dataUI={getDataUI()}  icon="fal fa-plus">Аdd Note</Button>
                                }
                            />
                            <ModalScrollableContainer>
                                <SimpleTableContainer dataUI={getDataUI()} >
                                    <table>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '26px' }}></th>
                                                <th className="text-right">Date</th>
                                                <th>Type</th>
                                                <th>User</th>
                                                <th>Note</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <ConfirmationDialog
                                                        dataUI={getDataUI()} 
                                                        show={confirmationDialogShow2}
                                                        render={() => <>
                                                            <p className="text-center mb-m">Are you sure?</p>
                                                            <Toolbar dataUI={getDataUI()}  leftSide={<>
                                                                <Button dataUI={getDataUI()}  onClick={() => setConfirmationDialogShow2(false)} btnType="secondary" icon='fal fa-thumbs-up' className="mr-s" >Yes</Button>
                                                                <Button dataUI={getDataUI()}  onClick={() => setConfirmationDialogShow2(false)} btnType="primary" icon='fal fa-thumbs-down' className="mr-s" >No</Button></>
                                                            } />
                                                        </>}
                                                        onClose={() => setConfirmationDialogShow2(false)}
                                                    >
                                                        <div>
                                                            <Tooltip title="Delete">
                                                                <Button dataUI={getDataUI()}  btnType="icon" icon="fal fa-trash" onClick={() => setConfirmationDialogShow2(true)} />
                                                            </Tooltip>
                                                        </div>
                                                    </ConfirmationDialog>
                                                </td>
                                                <td className="text-right">8/11/2021</td>
                                                <td><a href="/" className="lv-link-styles">General</a></td>
                                                <td>Joro Georgiev</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <ConfirmationDialog
                                                        dataUI={getDataUI()} 
                                                        show={confirmationDialogShow3}
                                                        render={() => <>
                                                            <p className="text-center mb-m">Are you sure?</p>
                                                            <Toolbar dataUI={getDataUI()}  leftSide={<>
                                                                <Button dataUI={getDataUI()}  onClick={() => setConfirmationDialogShow3(false)} btnType="secondary" icon='fal fa-thumbs-up' className="mr-s" >Yes</Button>
                                                                <Button dataUI={getDataUI()}  onClick={() => setConfirmationDialogShow3(false)} btnType="primary" icon='fal fa-thumbs-down' className="mr-s" >No</Button></>
                                                            } />
                                                        </>}
                                                        onClose={() => setConfirmationDialogShow3(false)}
                                                    >
                                                        <div>
                                                            <Tooltip title="Delete">
                                                                <Button dataUI={getDataUI()}  btnType="icon" icon="fal fa-trash" onClick={() => setConfirmationDialogShow3(true)} />
                                                            </Tooltip>
                                                        </div>
                                                    </ConfirmationDialog>
                                                </td>
                                                <td className="text-right">8/11/2021</td>
                                                <td><a href="/" className="lv-link-styles">General</a></td>
                                                <td>Joro Georgiev</td>
                                                <td><a href="/" className="lv-link-styles">okok</a></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <ConfirmationDialog
                                                        dataUI={getDataUI()} 
                                                        show={confirmationDialogShow4}
                                                        render={() => <>
                                                            <p className="text-center mb-m">Are you sure?</p>
                                                            <Toolbar dataUI={getDataUI()}  leftSide={<>
                                                                <Button dataUI={getDataUI()}  onClick={() => setConfirmationDialogShow4(false)} btnType="secondary" icon='fal fa-thumbs-up' className="mr-s" >Yes</Button>
                                                                <Button dataUI={getDataUI()}  onClick={() => setConfirmationDialogShow4(false)} btnType="primary" icon='fal fa-thumbs-down' className="mr-s" >No</Button></>
                                                            } />
                                                        </>}
                                                        onClose={() => setConfirmationDialogShow4(false)}
                                                    >
                                                        <div>
                                                            <Tooltip title="Delete">
                                                                <Button dataUI={getDataUI()}  btnType="icon" icon="fal fa-trash" onClick={() => setConfirmationDialogShow4(true)} />
                                                            </Tooltip>
                                                        </div>
                                                    </ConfirmationDialog>
                                                </td>
                                                <td className="text-right">8/11/2021</td>
                                                <td><a href="/" className="lv-link-styles">General</a></td>
                                                <td>Joro Georgiev</td>
                                                <td><a href="/" className="lv-link-styles">Measurement Note</a></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <ConfirmationDialog
                                                        dataUI={getDataUI()} 
                                                        show={confirmationDialogShow5}
                                                        render={() => <>
                                                            <p className="text-center mb-m">Are you sure?</p>
                                                            <Toolbar dataUI={getDataUI()}  leftSide={<>
                                                                <Button dataUI={getDataUI()}  onClick={() => setConfirmationDialogShow5(false)} btnType="secondary" icon='fal fa-thumbs-up' className="mr-s" >Yes</Button>
                                                                <Button dataUI={getDataUI()}  onClick={() => setConfirmationDialogShow5(false)} btnType="primary" icon='fal fa-thumbs-down' className="mr-s" >No</Button></>
                                                            } />
                                                        </>}
                                                        onClose={() => setConfirmationDialogShow5(false)}
                                                    >
                                                        <div>
                                                            <Tooltip title="Delete">
                                                                <Button dataUI={getDataUI()}  btnType="icon" icon="fal fa-trash" onClick={() => setConfirmationDialogShow5(true)} />
                                                            </Tooltip>
                                                        </div>
                                                    </ConfirmationDialog>
                                                </td>
                                                <td className="text-right">8/11/2021</td>
                                                <td><a href="/" className="lv-link-styles">General</a></td>
                                                <td>Joro Georgiev</td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </SimpleTableContainer>
                            </ModalScrollableContainer>
                        </TabContentItem>
                        <TabContentItem dataUI={getDataUI()} >
                            <ModalScrollableContainer>
                                <SimpleTableContainer dataUI={getDataUI()} >
                                    <table>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '26px' }}></th>
                                                <th className="text-right">Date</th>
                                                <th>User</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <ConfirmationDialog
                                                        dataUI={getDataUI()} 
                                                        show={confirmationDialogShow6}
                                                        render={() => <>
                                                            <p className="text-center mb-m">Are you sure?</p>
                                                            <Toolbar dataUI={getDataUI()}  leftSide={<>
                                                                <Button dataUI={getDataUI()}  onClick={() => setConfirmationDialogShow6(false)} btnType="secondary" icon='fal fa-thumbs-up' className="mr-s" >Yes</Button>
                                                                <Button dataUI={getDataUI()}  onClick={() => setConfirmationDialogShow6(false)} btnType="primary" icon='fal fa-thumbs-down' className="mr-s" >No</Button></>
                                                            } />
                                                        </>}
                                                        onClose={() => setConfirmationDialogShow6(false)}
                                                    >
                                                        <div>
                                                            <Tooltip title="Delete">
                                                                <Button dataUI={getDataUI()}  btnType="icon" icon="fal fa-trash" onClick={() => setConfirmationDialogShow6(true)} />
                                                            </Tooltip>
                                                        </div>
                                                    </ConfirmationDialog>
                                                </td>
                                                <td className="text-right">8/11/2021</td>
                                                <td>Joro ||</td>
                                                <td>Phone</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <ConfirmationDialog
                                                        dataUI={getDataUI()} 
                                                        show={confirmationDialogShow7}
                                                        render={() => <>
                                                            <p className="text-center mb-m">Are you sure?</p>
                                                            <Toolbar dataUI={getDataUI()}  leftSide={<>
                                                                <Button dataUI={getDataUI()}  onClick={() => setConfirmationDialogShow7(false)} btnType="secondary" icon='fal fa-thumbs-up' className="mr-s" >Yes</Button>
                                                                <Button dataUI={getDataUI()}  onClick={() => setConfirmationDialogShow7(false)} btnType="primary" icon='fal fa-thumbs-down' className="mr-s" >No</Button></>
                                                            } />
                                                        </>}
                                                        onClose={() => setConfirmationDialogShow7(false)}
                                                    >
                                                        <div>
                                                            <Tooltip title="Delete">
                                                                <Button dataUI={getDataUI()}  btnType="icon" icon="fal fa-trash" onClick={() => setConfirmationDialogShow7(true)} />
                                                            </Tooltip>
                                                        </div>
                                                    </ConfirmationDialog>
                                                </td>
                                                <td className="text-right">8/11/2021</td>
                                                <td>Joro ||</td>
                                                <td><a href="/" className="lv-link-styles">_Keywords Covenant</a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </SimpleTableContainer>
                            </ModalScrollableContainer>
                        </TabContentItem>
                    </TabContent>
                </Tabs>
            </Modal>


            <Modal dataUI={getDataUI()} title="Covenant Measurement 53891" size="L" isVisible={editMeasurementModal} onClose={() => setEditMeasurementModal(false)}>
                <Toolbar dataUI={getDataUI()}  className="mb-m"
                    leftSide={
                        <>
                            <Button dataUI={getDataUI()}  className="mr-s" icon="fal fa-save">Save</Button>
                            <Button dataUI={getDataUI()}  className="mr-s" icon="fal fa-save" onClick={() => setEditMeasurementModal(false)}>Save and Close</Button>
                            <ConfirmationDialog
                                dataUI={getDataUI()} 
                                show={confirmationDialogShow8}
                                render={() => <>
                                    <p className="text-center mb-m">Are you sure?</p>
                                    <Toolbar dataUI={getDataUI()}  leftSide={<>
                                        <Button dataUI={getDataUI()}  onClick={() => { setConfirmationDialogShow8(false); setEditMeasurementModal(false); }} btnType="secondary" icon='fal fa-thumbs-up' className="mr-s" >Yes</Button>
                                        <Button dataUI={getDataUI()}  onClick={() => setConfirmationDialogShow8(false)} btnType="primary" icon='fal fa-thumbs-down' className="mr-s" >No</Button></>
                                    } />
                                </>}
                                onClose={() => setConfirmationDialogShow8(false)}
                            >
                                <Button dataUI={getDataUI()}  className="mr-s" icon="fal fa-trash" btnType="secondary" onClick={() => setConfirmationDialogShow8(true)}>Delete</Button>
                            </ConfirmationDialog>
                            <Button dataUI={getDataUI()}  icon="far fa-times-circle" btnType="secondary" onClick={() => setEditMeasurementModal(false)}>Cancel</Button>
                        </>
                    }
                />

                <Tabs dataUI={getDataUI()}  onTabIndexChange={(index: number) => setTabIndexMeasurement(index)} currentlyActiveTabIndex={tabIndexMeasurement}>
                    <TabList dataUI={getDataUI()} >
                        <TabItem dataUI={getDataUI()} >
                            <TabItemTitle dataUI={getDataUI()}>Details</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()} >
                            <TabItemTitle dataUI={getDataUI()}>Required Docs</TabItemTitle>
                        </TabItem>
                    </TabList>
                    <TabContent dataUI={getDataUI()} >
                        <TabContentItem dataUI={getDataUI()} >
                            <ModalScrollableContainer>
                                <Toolbar dataUI={getDataUI()} 
                                    className="mb-m"
                                    leftSide={
                                        <>
                                            <Label dataUI={getDataUI()} className="mr-s"><strong>Source:</strong></Label>
                                            <Radio dataUI={getDataUI()} className="mr-s" name="source-2" value="1" checked={sourceRadio === '1'} onChange={() => setSourceRadio('1')}>Loan Vantage Spread</Radio>
                                            <Radio dataUI={getDataUI()} className="mr-s" name="source-2" value="2" checked={sourceRadio === '2'} onChange={() => setSourceRadio('2')}>Manual</Radio>
                                            <Radio dataUI={getDataUI()} className="mr-s" name="source-2" value="3" checked={sourceRadio === '3'} onChange={() => setSourceRadio('3')}>Certificate</Radio>
                                            {sourceRadio === '3' && <Select
                                                dataUI={getDataUI()} 
                                                className="width-m"
                                                options={[
                                                    { value: '1', label: 'Option 1' },
                                                    { value: '2', label: 'Option 2' },
                                                ]}
                                                value="1"
                                            />}
                                        </>
                                    }
                                />
                                <div className="d-flex">
                                    <div className="flex-1 width-half mr-s">
                                        <div className="section-container mb-m">
                                            <h2 className="mb-m">Statement Date</h2>
                                            <hr className="mb-m" />
                                            <Row className="mb-s">
                                                <Col xs={6}>
                                                    <Label dataUI={getDataUI()} className="justify-content-end text-right">Statement Date</Label>
                                                </Col>
                                                <Col xs={6}>
                                                    <Input dataUI={getDataUI()} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={6}>
                                                    <Label dataUI={getDataUI()} className="justify-content-end text-right">Due Date</Label>
                                                </Col>
                                                <Col xs={6}>
                                                    <Input dataUI={getDataUI()} />
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="section-container mb-m">
                                            <h2 className="mb-m">Required Documents Data</h2>
                                            <hr className="mb-m" />
                                            <Row>
                                                <Col xs={6}>
                                                    <Label dataUI={getDataUI()} className="justify-content-end text-right">Received and Verified Date</Label>
                                                </Col>
                                                <Col xs={6}>
                                                    <Label dataUI={getDataUI()} ></Label>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="section-container">
                                            <h2 className="mb-m">Measured Data</h2>
                                            <hr className="mb-m" />
                                            <Row className="mb-s">
                                                <Col xs={6}>
                                                    <Label dataUI={getDataUI()} className="justify-content-end text-right">Calculated Measurement Due Date</Label>
                                                </Col>
                                                <Col xs={6}>
                                                    <Input dataUI={getDataUI()} />
                                                </Col>
                                            </Row>
                                            <Row className="mb-s">
                                                <Col xs={6}>
                                                    <Label dataUI={getDataUI()} className="justify-content-end text-right">Measurement Due Date</Label>
                                                </Col>
                                                <Col xs={6}>
                                                    <Input dataUI={getDataUI()} />
                                                </Col>
                                            </Row>
                                            <Row className="mb-s">
                                                <Col xs={6}>
                                                    <Label dataUI={getDataUI()} className="justify-content-end text-right">Measurement Date</Label>
                                                </Col>
                                                <Col xs={6}>
                                                    <Input dataUI={getDataUI()} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={6}>
                                                    <Label dataUI={getDataUI()} className="justify-content-end text-right">Measurement Status</Label>
                                                </Col>
                                                <Col xs={6}>
                                                    <Select
                                                        dataUI={getDataUI()} 
                                                        options={[
                                                            { value: '1', label: 'Option 1' },
                                                            { value: '2', label: 'Option 2' },
                                                        ]}
                                                        value={select7}
                                                        onChange={(obj: any) => {
                                                            setSelect7(obj.value);
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <div className="flex-1 width-half ml-s">
                                        <div className="section-container mb-m">
                                            <h2 className="mb-m">Value Data</h2>
                                            <hr className="mb-m" />
                                            <Row className="mb-s">
                                                <Col xs={3}>
                                                    <Label dataUI={getDataUI()} className="justify-content-end text-right">Required Value</Label>
                                                </Col>
                                                <Col xs={9}>
                                                    <Label dataUI={getDataUI()} ><strong>{'<'} 1800000</strong></Label>
                                                </Col>
                                            </Row>
                                            <Row className="mb-s">
                                                <Col xs={3}>
                                                    <Label dataUI={getDataUI()} className="justify-content-end text-right">Current Value</Label>
                                                </Col>
                                                <Col xs={9}>
                                                    <Input dataUI={getDataUI()} />
                                                </Col>
                                            </Row>
                                            <Row className="mb-s">
                                                <Col xs={3}>
                                                    <Label dataUI={getDataUI()} className="justify-content-end text-right">Notes</Label>
                                                </Col>
                                                <Col xs={9}>
                                                    <Textarea dataUI={getDataUI()}  name="notes" />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={3}>
                                                    <Label dataUI={getDataUI()} className="justify-content-end text-right">Last Status Change</Label>
                                                </Col>
                                                <Col xs={9}>
                                                    <Label dataUI={getDataUI()} ></Label>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="section-container">
                                            <h2 className="mb-m">Attachments</h2>
                                            <hr className="mb-m" />
                                            <FileUpload
                                                maxFileSizeMB={2}
                                                onChange={(files: any) => {
                                                    onAddFile(files,);
                                                }}
                                                isMultiple
                                                dataUI={getDataUI()} 
                                            >
                                                {renderFileRows()}
                                            </FileUpload>
                                        </div>
                                    </div>
                                </div>
                            </ModalScrollableContainer>
                        </TabContentItem>
                        <TabContentItem dataUI={getDataUI()} >
                            <ModalScrollableContainer>
                                <InfoBox dataUI={getDataUI()} className="mb-m">
                                    <p>Please select those Reporting Requirements that you wish to use to perform this Measurement.</p>
                                    <p><strong>Note:</strong> If the Document does not exist for the current Measurement, click "+" to add it.</p>
                                </InfoBox>
                                <SimpleTableContainer dataUI={getDataUI()} >
                                    <table>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '26px' }}></th>
                                                <th style={{ width: '26px' }}></th>
                                                <th>Doc Type</th>
                                                <th>Description</th>
                                                <th>Status</th>
                                                <th className="text-right">Statement Date</th>
                                                <th className="text-right">Received and Verified On</th>
                                                <th>Type</th>
                                                <th>Entities</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><Checkbox dataUI={getDataUI()}  /></td>
                                                <td>
                                                    <Tooltip title="Edit Document">
                                                        <Button dataUI={getDataUI()}  btnType="icon" icon="fal fa-pen" />
                                                    </Tooltip>
                                                </td>
                                                <td>123 Frequency Test Name</td>
                                                <td></td>
                                                <td>Open</td>
                                                <td className="text-right">08/25/21</td>
                                                <td className="text-right"></td>
                                                <td>Party</td>
                                                <td>ANDREW BYRNE</td>
                                            </tr>
                                            <tr>
                                                <td><Checkbox dataUI={getDataUI()}  /></td>
                                                <td>
                                                    <Tooltip title="Edit Document">
                                                        <Button dataUI={getDataUI()}  btnType="icon" icon="fal fa-pen" />
                                                    </Tooltip>
                                                </td>
                                                <td>AUG PREREQ DOC</td>
                                                <td>AUG PREREQ DOC</td>
                                                <td>Pending</td>
                                                <td className="text-right">08/25/21</td>
                                                <td className="text-right"></td>
                                                <td>Party</td>
                                                <td>COLLAT CALCS</td>
                                            </tr>
                                            <tr>
                                                <td><Checkbox dataUI={getDataUI()}  /></td>
                                                <td>
                                                    <Tooltip title="Document does not exist. Click to add it.">
                                                        <Button dataUI={getDataUI()}  btnType="icon" icon="fal fa-plus" />
                                                    </Tooltip>
                                                </td>
                                                <td>B Core NonAuto-Create Acc</td>
                                                <td>B Core NonAuto-Create Acc</td>
                                                <td></td>
                                                <td className="text-right"></td>
                                                <td className="text-right"></td>
                                                <td>Party</td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </SimpleTableContainer>
                            </ModalScrollableContainer>
                        </TabContentItem>
                    </TabContent>
                </Tabs>
            </Modal>
        </ReactAux>
    );
}

export default DemoCovenant;