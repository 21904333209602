import React, { useState } from 'react';
import { Button, Modal, Toolbar, Tabs, TabList, TabItem, TabItemTitle, TabContent, TabContentItem } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../../helpers/helpers';
import RoleAssignmentTab from './RoleAssignmentTab';
import UsersAssignmentTab from './UsersAssignmentTab';

type EditTeamsModalProps = {
    isVisible: boolean,
    onClose?(): void,
};

const EditTeamsModal = (props: EditTeamsModalProps) => {

    const { isVisible, onClose } = props;

    const [tabIndex, setTabindex] = useState(1);

    return (
        <Modal
            title="Edit ‘VSG Team’"
            isVisible={isVisible}
            size="L+"
            onClose={onClose}
            dataUI={getDataUI()}
        >
            <Toolbar className="mb-s" dataUI={getDataUI()} leftSide={(
                <>
                    <Button className="mr-s" icon="fal fa-save" dataUI={getDataUI()}>Save</Button>
                    <Button className="mr-s" btnType="secondary" icon="fal fa-copy" dataUI={getDataUI()}>Copy</Button>
                    <Button className="mr-s" btnType="secondary" icon="far fa-times-circle" dataUI={getDataUI()}>Cancel</Button>
                    <Button btnType="secondary" icon="fal fa-trash" dataUI={getDataUI()}>Delete</Button>
                </>
            )}>
            </Toolbar>

            <Tabs
                onTabIndexChange={(index: number) => setTabindex(index)}
                currentlyActiveTabIndex={tabIndex}
                dataUI={getDataUI()}
            >
                <TabList dataUI={getDataUI()}>
                    <TabItem dataUI={getDataUI()}>
                        <TabItemTitle dataUI={getDataUI()}>Details</TabItemTitle>
                    </TabItem>
                    <TabItem dataUI={getDataUI()}>
                        <TabItemTitle dataUI={getDataUI()}>Users Assignment</TabItemTitle>
                    </TabItem>
                    <TabItem dataUI={getDataUI()}>
                        <TabItemTitle dataUI={getDataUI()}>Role Assignment</TabItemTitle>
                    </TabItem>
                    <TabItem dataUI={getDataUI()}>
                        <TabItemTitle dataUI={getDataUI()}>Approvals</TabItemTitle>
                    </TabItem>
                </TabList>
                <TabContent dataUI={getDataUI()}>
                    <TabContentItem dataUI={getDataUI()}>
                        <h1>Details</h1>
                    </TabContentItem>
                    <TabContentItem dataUI={getDataUI()}>
                        <UsersAssignmentTab />
                    </TabContentItem>
                    <TabContentItem dataUI={getDataUI()}>
                        <RoleAssignmentTab />
                    </TabContentItem>
                    <TabContentItem dataUI={getDataUI()}>
                        <h1>Approvals</h1>
                    </TabContentItem>
                </TabContent>
            </Tabs>
        </Modal>
    );
};

export default EditTeamsModal;