import React from 'react';

import {
    MainContainerHeader, ReactAux, ActionContainer, ActionContainerHeader, ActionContainerCrumbs,
    ActionSettings, ActionSettingsForm, LinkWithIcon, Button, Select, ModalScrollableContainer,
    MainContainerScrollable, Col, Label, Row, Icon, ShowImage, Modal, Toolbar, FileUpload, ConfirmationDialog,
    Tooltip,
} from '@jkhy/vsg-loanvantage-design-system';

import {
    onAddFile, renderFileRows, UploadedFileRowProps,
} from '../helpers/fileUploadHelpers';
import { getDataUI } from '../helpers/helpers';

class DemoShowImage extends React.Component {
    private demoFilesUnmountedRows = React.createRef() as any;

    state = {
        actionContainerActiveFormIndex: -1,
        isFirstActionDisabled: false,
        isActionContainerLocked: false,
        isLockDisabled: false,
        selectValue1: '1',
        selectValue2: '1',
        modal1: false,
        modal2: false,
        file: null,
        image: null,
        show1: false,
        isDisabled: true,
        demoFiles: [] as UploadedFileRowProps[],
    }

    componentDidMount() {
        this.demoFilesUnmountedRows.current = {};
    }

    openActionContainerForm(formIndex: number) {
        this.setState({ actionContainerActiveFormIndex: formIndex });
    }

    toggleActiveActionSetting() {
        this.setState({ isFirstActionDisabled: !this.state.isFirstActionDisabled });
        this.openActionContainerForm(-1);
    }

    toggleActionContainerLocked() {
        this.setState({ isActionContainerLocked: !this.state.isActionContainerLocked });
    }

    toggleLockDisabled() {
        this.setState({ isLockDisabled: !this.state.isLockDisabled });
        this.openActionContainerForm(-1);
    }

    onDeleteImage() {
        this.demoFilesUnmountedRows.current = {};
        this.setState({
            image: null,
            show1: false,
            isDisabled: true,
            demoFiles: []
        });
    }

    render() {

        return (
            <ReactAux>
                <MainContainerHeader dataUI={getDataUI()}>
                    <ActionContainer dataUI={getDataUI()}>
                        <ActionContainerHeader dataUI={getDataUI()}>
                            <ActionContainerCrumbs dataUI={getDataUI()} crumbs={['Loan Request Refi TN Bldg (File 41962)']} />
                            <ActionSettings dataUI={getDataUI()} isActive={this.state.actionContainerActiveFormIndex === -1}>
                                <Tooltip title="Click to edit process definitions">
                                    <LinkWithIcon dataUI={getDataUI()} disabled={this.state.isFirstActionDisabled} icon="fal fa-cog">
                                        <a onClick={(e) => { e.preventDefault(); this.openActionContainerForm(1) }} href="/">Robin - Commercial</a>
                                    </LinkWithIcon>
                                </Tooltip>
                                <Tooltip title="All changes are allowed">
                                    <Button dataUI={getDataUI()} disabled={this.state.isLockDisabled} onClick={() => this.toggleActionContainerLocked()} btnType="icon" icon={this.state.isActionContainerLocked ? 'fal fa-lock' : 'fal fa-lock-open'}></Button>
                                </Tooltip>
                            </ActionSettings>
                            <ActionSettingsForm dataUI={getDataUI()} isActive={this.state.actionContainerActiveFormIndex === 1}>
                                <p>Process definitions:</p>
                                <Select
                                    dataUI={getDataUI()}
                                    className="width-m"
                                    value={this.state.selectValue1}
                                    onChange={(obj: any) => this.setState({ selectValue1: obj.value })}
                                    options={[{ value: '1', label: 'Robin - Commercial' }, { value: '2', label: 'test 2' }]}
                                />
                                <Button dataUI={getDataUI()} onClick={(e: any) => this.toggleLockDisabled()} icon="fal fa-save">Update</Button>
                                <Button dataUI={getDataUI()} onClick={(e: any) => this.openActionContainerForm(-1)} btnType="secondary" icon="fal fa-times">Cancel</Button>
                            </ActionSettingsForm>
                            <ActionSettingsForm dataUI={getDataUI()} isActive={this.state.actionContainerActiveFormIndex === 2}>
                                <p>Process definitions:</p>
                                <Select
                                    dataUI={getDataUI()}
                                    className="width-m"
                                    value={this.state.selectValue2}
                                    onChange={(obj: any) => this.setState({ selectValue2: obj.value })}
                                    options={[{ value: '1', label: 'Disable the previous action control' }, { value: '2', label: 'test 2' }]}
                                />
                                <Button dataUI={getDataUI()} icon="fal fa-save" onClick={() => this.toggleActiveActionSetting()}>Update</Button>
                                <Button dataUI={getDataUI()} onClick={(e: any) => this.openActionContainerForm(-1)} btnType="secondary" icon="fal fa-times">Cancel</Button>
                            </ActionSettingsForm>
                        </ActionContainerHeader>
                        <Button dataUI={getDataUI()} icon="fal fa-pen">Edit</Button>
                        <Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-copy">Copy</Button>
                        <Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-hand-paper">Move</Button>
                        <Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-sticky-note">Notes (0)</Button>
                        <Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-bullseye">Leads</Button>
                        <Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-user-tie">Agents</Button>
                    </ActionContainer>
                </MainContainerHeader>

                <MainContainerScrollable>
                    <Row className="mt-m">
                        <Col xs={4}>
                            <div className="d-flex justify-content-center mb-m">
                                <Label dataUI={getDataUI()} className="mr-s">Collateral Tracking  ID</Label>
                                <Label dataUI={getDataUI()} className="mr-s"><strong>347318</strong></Label>
                            </div>
                            <div className="d-flex justify-content-center mb-m">
                                <Label dataUI={getDataUI()} className="mr-s">Certificate Number</Label>
                                <Label dataUI={getDataUI()}> <strong>2007088239</strong></Label>
                            </div>
                            <div className="d-flex justify-content-center mb-m">
                                <Label dataUI={getDataUI()} className="mr-s">Flood Deter. Date</Label>
                                <Label dataUI={getDataUI()}> <strong>7/29/2020</strong></Label>
                            </div>
                            <div className="d-flex justify-content-center mb-m">
                                <Label dataUI={getDataUI()} className="mr-s">Flood Zone</Label>
                                <Label dataUI={getDataUI()}> <strong>X500*</strong></Label>
                            </div>
                            <Label dataUI={getDataUI()} className="mb-m justify-content-center">SFHA</Label>
                            <Label dataUI={getDataUI()} className="mb-m justify-content-center">Comments</Label>
                            <Label dataUI={getDataUI()} className="mb-m justify-content-center">Search Results</Label>
                        </Col>
                        <Col xs={4}>
                            <div className="d-flex justify-content-center mb-m">
                                <Label dataUI={getDataUI()} className="mr-s">NFIP Comm. Name</Label>
                                <Label dataUI={getDataUI()}> <strong>CAMDEN, CITY OF</strong></Label>
                            </div>
                            <div className="d-flex justify-content-center mb-m">
                                <Label dataUI={getDataUI()} className="mr-s">NFIP County</Label>
                                <Label dataUI={getDataUI()}> <strong>CAMDEN</strong></Label>
                            </div>
                            <div className="d-flex justify-content-center mb-m">
                                <Label dataUI={getDataUI()} className="mr-s">NFIP State</Label>
                                <Label dataUI={getDataUI()}> <strong>NJ</strong></Label>
                            </div>
                            <div className="d-flex justify-content-center mb-m">
                                <Label dataUI={getDataUI()} className="mr-s">NFIP Comm. #</Label>
                                <Label dataUI={getDataUI()}> <strong>347318</strong></Label>
                            </div>
                            <div className="d-flex justify-content-center mb-m">
                                <Label dataUI={getDataUI()} className="mr-s">No NFIP Map</Label>
                                <Label dataUI={getDataUI()}> <Icon dataUI={getDataUI()} icon="far fa-check" size="S" /></Label>
                            </div>
                            <div className="d-flex justify-content-center mb-m">
                                <Label dataUI={getDataUI()} className="mr-s">NFIP Map Number</Label>
                                <Label dataUI={getDataUI()}> <strong>34007C 0036F</strong></Label>
                            </div>
                            <div className="d-flex justify-content-center mb-m">
                                <Label dataUI={getDataUI()} className="mr-s">NFIP Map Panel Date</Label>
                                <Label dataUI={getDataUI()}> <strong>8/17/2016</strong></Label>
                            </div>
                            <Label dataUI={getDataUI()} className="mb-m justify-content-center">LOMA/LOMR Date</Label>
                        </Col>
                        <Col xs={4}>
                            <div className="d-flex justify-content-center mb-m">
                                <Label dataUI={getDataUI()} className="mr-s">Fed. Flood Ins. Is Ava.</Label>
                                <Label dataUI={getDataUI()}> <Icon dataUI={getDataUI()} icon="far fa-check" size="S" /></Label>
                            </div>
                            <Label dataUI={getDataUI()} className="mb-m justify-content-center">Fed. Flood Ins. Is Not Ava.</Label>
                            <Label dataUI={getDataUI()} className="mb-m justify-content-center">CBRA Or OPA</Label>
                            <Label dataUI={getDataUI()} className="mb-m justify-content-center">CBRA/OPA Date</Label>
                            <div className="d-flex justify-content-center mb-m">
                                <Label dataUI={getDataUI()} className="mr-s">Regular Program</Label>
                                <Label dataUI={getDataUI()}> <Icon dataUI={getDataUI()} icon="far fa-check" size="S" /></Label>
                            </div>
                            <div className="d-flex justify-content-center mb-m">
                                <ShowImage
                                    dataUI={getDataUI()}
                                    onClickAddBtn={() => this.setState({ modal1: true, isDisabled: true })}
                                    image={this.state.image ? { src: this.state.image || '' } : undefined}
                                    helperBtns={<>
                                        <Tooltip title="Zoom">
                                            <Button dataUI={getDataUI()} className="mb-s" btnType="icon" icon="fal fa-search-plus" onClick={() => this.setState({ modal2: true })} />
                                        </Tooltip>
                                        <ConfirmationDialog
                                            dataUI={getDataUI()}
                                            show={this.state.show1}
                                            render={() => <>
                                                <p className="text-center mb-m">Are you sure?</p>
                                                <Toolbar dataUI={getDataUI()} leftSide={<>
                                                    <Button dataUI={getDataUI()} onClick={() => this.onDeleteImage()} btnType="secondary" icon='fal fa-thumbs-up' className="mr-s" >Yes</Button>
                                                    <Button dataUI={getDataUI()} onClick={() => this.setState({ show1: false })} btnType="primary" icon='fal fa-thumbs-down' className="mr-s" >No</Button></>
                                                } />
                                            </>}
                                            onClose={() => this.setState({ show1: false })}
                                        >
                                            <div>
                                                <Tooltip title="Delete">
                                                    <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-trash" onClick={() => this.setState({ show1: true })} />
                                                </Tooltip>
                                            </div>
                                        </ConfirmationDialog>
                                    </>}
                                />
                            </div>
                            <Modal dataUI={getDataUI()} title="Add Image" isVisible={this.state.modal1} size="S" onClose={() => this.setState({ modal1: false })}>
                                <Toolbar
                                    dataUI={getDataUI()}
                                    className="mb-m"
                                    leftSide={
                                        <>
                                            <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-save" disabled={this.state.isDisabled} onClick={() => { this.setState({ modal1: false, image: URL.createObjectURL(this.state.demoFiles[0].uploadedFile.file as any), }); }}>Save</Button>
                                            <Button dataUI={getDataUI()} icon="far fa-times-circle" btnType="secondary" onClick={() => this.setState({ modal1: false })}>Cancel</Button>
                                        </>
                                    }
                                />
                                <FileUpload
                                    dataUI={getDataUI()}
                                    visualType="large"
                                    maxFileSizeMB={2}
                                    supportedFormats="image/*"
                                    onChange={(files: any) => {
                                        onAddFile(files, 'demoFiles', this);
                                    }}
                                    disabled={this.state.demoFiles.length === 1}
                                >
                                    {renderFileRows('demoFiles', this, () => this.setState({ isDisabled: false }), () => this.onDeleteImage())}
                                </FileUpload>
                            </Modal>

                            <Modal dataUI={getDataUI()} title="Sprinkles Holdings LLC Photo" isVisible={this.state.modal2} size="L" onClose={() => this.setState({ modal2: false })}>
                                <ModalScrollableContainer>
                                    <div className="text-center">
                                        <img className="img-fit" src={this.state.image || undefined} alt="View" />
                                    </div>
                                </ModalScrollableContainer>
                            </Modal>
                        </Col>
                    </Row>
                </MainContainerScrollable>
            </ReactAux>
        );
    }
}

export default DemoShowImage;