import React from 'react';

import {
    MainContainerHeader, ActionContainer, ActionContainerHeader, ActionContainerCrumbs,
    Button, Toolbar, Label, Checkbox, MainContainerScrollable, TabContent,
    TabContentItem, TabItem, TabItemTitle, TabList, Tabs, Dropzone, SimpleTableContainer, Tooltip,
    Icon, Modal, ModalScrollableContainer, FileUpload, InfoBox, Col, Input, Row, Select, Textarea, UploadedFileRow
} from '@jkhy/vsg-loanvantage-design-system';

import {
    onAddFile, renderFileRows, UploadedFileRowProps, checkFileOnServer, onDeleteFile,
    simulateUploading, updateUploadedFileRowProp, examplePDF,
} from '../helpers/fileUploadHelpers';
import { getDataUI } from '../helpers/helpers';


class DemoRequiredDocuments extends React.Component {
    private filesUnmountedRows = React.createRef() as any;
    private demoFilesThUnmountedRows = React.createRef() as any;
    private demoFilesSixUnmountedRows = React.createRef() as any;
    private demoFilesSevenUnmountedRows = React.createRef() as any;
    private demoFilesEightUnmountedRows = React.createRef() as any;

    state = {
        tabIndex: 0,
        showModal: false,
        files: [] as UploadedFileRowProps[],
        showSuccessInfo: true,
        isServerLockCheck: false,
        showModalEs: false,
        showModalEsInner: false,
        editMode: false,
        tabIndexEs: 4,
        showSuccessInfoEs: true,
        demoFilesTh: [] as UploadedFileRowProps[],
        demoFilesSix: [] as UploadedFileRowProps[],
        demoFilesSeven: [examplePDF] as UploadedFileRowProps[],
        demoFilesEight: [] as UploadedFileRowProps[],
        uploadingPropName: 'demoFilesTh',

    } as any


    componentDidMount() {
        this.filesUnmountedRows.current = {};
        this.demoFilesThUnmountedRows.current = {};
        this.demoFilesSixUnmountedRows.current = {};
        this.demoFilesSevenUnmountedRows.current = {};
        this.demoFilesEightUnmountedRows.current = {};
    }

    onDropFile = (files: any) => {
        if (files.length === 0) {
            return;
        }

        let isServerCheckFile = false

        if ((this.state.files.length + files.length) <= 1) {
            isServerCheckFile = checkFileOnServer(files);
            onAddFile(files, 'files', this);
        }

        this.setState({
            showModal: true,
            isServerLockCheck: isServerCheckFile
        })
    }


    toggleEditMode = () => {
        this.setState({
            editMode: !this.state.editMode
        })
    }

    resetStateTableDemo = () => {
        this.setState({
            showSuccessInfo: false,
            isServerLockCheck: false,
        });
    }

    renderFileRowsTable = (statePropName: any) => {
        const copyState = { ...this.state } as any;
        const files = copyState[statePropName];

        if (files.length === 0) {
            return null;
        }

        return files.map((fileData: any) => {
            const isError = fileData.uploadedFile.uploadError;
            const isUploading = fileData.uploadedFile.uploading;
            return (
                <UploadedFileRow
                    dataUI={getDataUI()}
                    key={fileData.key}
                    viewMode={!this.state.editMode}
                    uploadedFile={{
                        ...fileData.uploadedFile,
                        uploadError: isError,
                        ...(this.state.editMode && { linkSettings: { title: 'test', href: `${process.env.PUBLIC_URL}/img/anatomy-of-large-scale.pdf`, target: '_blank', download: false, className: 'text-primary' } })
                    }}
                    onProgress={(updateProgress: any) => {
                        if (isUploading) {
                            simulateUploading(updateProgress, fileData, statePropName, this, true)
                        }
                    }}
                    onSuccessUploaded={(progress: any, setUploadSuccessful: any) => {
                        if (progress === 100) {
                            setUploadSuccessful(true);
                            updateUploadedFileRowProp(statePropName, fileData, "uploading", false, this)
                        }
                    }}
                    onUploadDone={(uploadSuccessful: any, setUploadDone: any) => {
                        if (uploadSuccessful) {
                            setTimeout(() => {
                                setUploadDone(true);
                                updateUploadedFileRowProp(statePropName, fileData, "uploadDone", true, this);
                                if (this.state.showModalEsInner && statePropName === this.state.uploadingPropName) {
                                    this.resetStateTableDemo();
                                }

                            }, 3000);
                        }
                    }}
                    onDelete={() => {
                        onDeleteFile(fileData.key, statePropName, this);
                        this.resetStateTableDemo();
                    }}
                />
            );
        })
    }

    renderFileRowsModal = (statePropName: any) => {
        const copyState = { ...this.state } as any;
        const files = copyState[statePropName];

        if (files.length === 0) {
            return null;
        }

        return files.map((fileData: any) => {
            const isError = fileData.uploadedFile.uploadError;
            return (
                <UploadedFileRow
                    dataUI={getDataUI()}
                    key={fileData.key}
                    viewMode={fileData.viewMode}
                    uploadedFile={{
                        ...fileData.uploadedFile,
                        uploadError: isError,
                    }}
                    onSuccessUploaded={(progress: any, setUploadSuccessful: any) => {
                        if (progress === 100) {
                            setUploadSuccessful(true);
                            updateUploadedFileRowProp(statePropName, fileData, "uploading", false, this)
                        }
                    }}
                    onUploadDone={(uploadSuccessful: any, setUploadDone: any) => {
                        if (uploadSuccessful) {
                            setTimeout(() => {
                                setUploadDone(true);
                                updateUploadedFileRowProp(statePropName, fileData, "uploadDone", true, this);
                            }, 3000);
                        }
                    }}
                    onDelete={() => {
                        onDeleteFile(fileData.key, statePropName, this);
                        this.resetStateTableDemo();
                    }}
                />
            );
        })
    }


    render() {
        const { editMode } = this.state;
        return (
            <>
                <MainContainerHeader dataUI={getDataUI()}>
                    <ActionContainer dataUI={getDataUI()}>
                        <ActionContainerHeader dataUI={getDataUI()}>
                            <ActionContainerCrumbs dataUI={getDataUI()} crumbs={['Required Documents']} />
                        </ActionContainerHeader>
                    </ActionContainer>
                </MainContainerHeader>

                <Toolbar
                    dataUI={getDataUI()}
                    className="mt-m mb-s"
                    leftSide={
                        <>
                            <Label dataUI={getDataUI()} className="mr-s"><strong>Show:</strong></Label>
                            <Checkbox dataUI={getDataUI()} className="mr-xs" name="checkbox-1">Exceptions Only</Checkbox>
                            <Checkbox dataUI={getDataUI()} className="mr-xs" name="checkbox-1">Include Closed</Checkbox>
                            <Checkbox dataUI={getDataUI()} name="checkbox-1">Subject To</Checkbox>
                            <Button dataUI={getDataUI()} className="ml-m" btnType='secondary' icon='fal fa-columns'>Select Columns</Button>
                        </>
                    }
                />

                <Tabs
                    dataUI={getDataUI()}
                    className="mb-xl mt-m"
                    onTabIndexChange={(index: number) => { this.setState({ tabIndex: index }) }}
                    currentlyActiveTabIndex={this.state.tabIndex}
                >
                    <TabList dataUI={getDataUI()}>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Prerequisite Conditions</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Recurring-Documents</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Notes</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>E-sign</TabItemTitle>
                        </TabItem>
                    </TabList>
                    <TabContent dataUI={getDataUI()}>
                        <TabContentItem dataUI={getDataUI()}>
                            <MainContainerScrollable>
                                <Toolbar
                                    dataUI={getDataUI()}
                                    className="mb-m"
                                    leftSide={
                                        <>
                                            <Button dataUI={getDataUI()} className="mr-s" icon='fal fa-plus'>Add Required Document</Button>
                                            <Button dataUI={getDataUI()} btnType='secondary' icon='fal fa-sync'>Re-evaluate</Button>
                                        </>
                                    }
                                />

                                <SimpleTableContainer dataUI={getDataUI()} className="mb-xl">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '1px' }}>
                                                    Incl In <br />Report<br />
                                                    <Checkbox dataUI={getDataUI()} />
                                                </th>
                                                <th style={{ width: '1px' }}></th>
                                                <th style={{ width: '1px' }}>E-Sign Enabled</th>
                                                <th>Document</th>
                                                <th>Description</th>
                                                <th>Type</th>
                                                <th>Entities</th>
                                                <th>Correspondent</th>
                                                <th>Status</th>
                                                <th>Current</th>
                                            </tr>

                                        </thead>
                                        <tbody>
                                            {[...Array.from(Array(7).keys())].map((keys) => <Dropzone dataUI={getDataUI()} key={keys} isElement onChange={(files: any) => { this.onDropFile(files) }}>
                                                <tr data-uid="e99ce0cc-3f7a-4980-bb4d-c545ee4f1711" role="row">
                                                    <td><Checkbox dataUI={getDataUI()} /></td>
                                                    <td className='text-center'>
                                                        <Tooltip title="Edit">
                                                            <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-pen" onClick={() => this.setState({ showModalEs: true })} />
                                                        </Tooltip>
                                                    </td>
                                                    <td className='text-center'>
                                                        <Icon dataUI={getDataUI()} icon="fal fa-file-signature" size="M" />
                                                    </td>
                                                    <td>Recurring Insurance</td>
                                                    <td>Loan Request Created</td>
                                                    <td>Collateral</td>
                                                    <td>124 Business Owner</td>
                                                    <td>Business Owner</td>
                                                    <td>Pending-Portal</td>
                                                    <td className='text-center'>
                                                        <Icon dataUI={getDataUI()} className='text-success' icon="fal fa-check" size="M" />
                                                    </td>
                                                </tr>
                                            </Dropzone>)}
                                        </tbody>
                                    </table>
                                </SimpleTableContainer>
                                {/* Show uploading on drag and drop start*/}
                                <Modal
                                    dataUI={getDataUI()}
                                    title="Required Documents Details 3529232"
                                    disableCloseBtn={this.state.isServerLockCheck}
                                    isVisible={true}
                                    isHidden={!this.state.showModal}
                                    size="S"
                                    onClose={() => this.setState({
                                        showModal: false,
                                        showSuccessInfo: this.state.files.length === 0 ? true : false,
                                    })}
                                >
                                    <Toolbar
                                        dataUI={getDataUI()}
                                        className="mb-m"
                                        leftSide={
                                            <>
                                                <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-save" disabled={this.state.isServerLockCheck} onClick={() => { this.setState({ showModal: false }); }}>Save</Button>
                                                <Button dataUI={getDataUI()} icon="far fa-times-circle" disabled={this.state.isServerLockCheck} btnType="secondary" onClick={() => this.setState({ showModal: false })}>Cancel</Button>
                                            </>
                                        }
                                    />
                                    <ModalScrollableContainer>
                                        {this.state.showSuccessInfo && this.state.files[0] && !this.state.files[0].uploadedFile.uploadError &&
                                            <InfoBox dataUI={getDataUI()} className='mb-m' type={this.state.isServerLockCheck ? "warning" : "success"} icon={this.state.isServerLockCheck ? "fal fa-exclamation-triangle" : "fal fa-file-upload"} title={this.state.isServerLockCheck ? "System is uploading your file." : 'Your documents are uploading in the background.'}>
                                                {this.state.isServerLockCheck ? "Please wait while your file is currently uploading, so it can be checked. If you wish to stop the proccess, you can stop the uploading of the file." : "You can safely close this dialogue and the files will continue uploading in the background."}
                                            </InfoBox>}

                                        <FileUpload
                                            dataUI={getDataUI()}
                                            className='mb-m'
                                            visualType="large"
                                            onChange={(files: any) => {
                                                if (this.state.files.length >= 1) {
                                                    return;
                                                }
                                                onAddFile(files, 'files', this);
                                                this.setState({
                                                    showSuccessInfo: true,
                                                    isServerLockCheck: checkFileOnServer(files)
                                                })

                                            }}
                                            viewMode={this.state.files.length >= 1}
                                            {...this.state.files.length >= 1 && {
                                                description: {
                                                    visible: true,
                                                    value: "Wish to replace? First remove the uploaded file."
                                                }
                                            }}
                                        >
                                            {renderFileRows('files', this, () => this.setState({
                                                showSuccessInfo: false,
                                                isServerLockCheck: false
                                            }), () => this.setState({ showSuccessInfo: false, isServerLockCheck: false }))}
                                        </FileUpload>

                                        <Row>
                                            <Col xs="4">
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right mb-s">Statement Date</Label>
                                            </Col>
                                            <Col xs="8">
                                                <div className="d-flex mb-s">
                                                    <Input dataUI={getDataUI()} className="width-full" placeholder='DD/MM/YY' />
                                                    <Tooltip title="Date">
                                                        <Button dataUI={getDataUI()} className="ml-s" btnType="icon" icon='fal fa-calendar-alt' />
                                                    </Tooltip>
                                                    <span className='text-danger'>*</span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="4">
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right mb-s">Statement Date</Label>
                                            </Col>
                                            <Col xs="8">
                                                <div className="d-flex mb-s">
                                                    <Input dataUI={getDataUI()} className="width-full" placeholder='DD/MM/YY' />
                                                    <Tooltip title="Date">
                                                        <Button dataUI={getDataUI()} className="ml-s" btnType="icon" icon='fal fa-calendar-alt' />
                                                    </Tooltip>
                                                    <span className='text-danger'>*</span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="4">
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right mb-s">Status</Label>
                                            </Col>
                                            <Col xs="8">
                                                <Select
                                                    dataUI={getDataUI()}
                                                    className="mb-s"
                                                    options={[
                                                        { value: '1', label: 'Pending' },
                                                    ]}
                                                    value={'1'}
                                                    placeholder="Select..."
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="4">
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right mb-s">Description</Label>
                                            </Col>
                                            <Col xs="8">
                                                <Textarea dataUI={getDataUI()} />
                                            </Col>
                                        </Row>
                                    </ModalScrollableContainer>
                                </Modal>
                                {/* Show uploading on drag and drop end*/}

                                <Modal
                                    dataUI={getDataUI()}
                                    title='Edit Required Document'
                                    isVisible={true}
                                    isHidden={!this.state.showModalEs}
                                    size="L"
                                    onClose={() => {
                                        this.setState({
                                            showModalEs: false,
                                            editMode: false
                                        })
                                    }}
                                >

                                    <Toolbar
                                        dataUI={getDataUI()}
                                        className="mb-m"
                                        leftSide={
                                            editMode ? <>
                                                <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-save" onClick={this.toggleEditMode}>Save</Button>
                                                <Button dataUI={getDataUI()}
                                                    className="mr-s"
                                                    icon="fal fa-save"
                                                    onClick={() => {
                                                        this.setState({
                                                            editMode: false,
                                                            showModalEs: false
                                                        })
                                                    }}>
                                                    Save and Close
                                                </Button>
                                                <Button dataUI={getDataUI()} className="mr-s" icon="far fa-times-circle" btnType='secondary' onClick={this.toggleEditMode}>Cancel</Button>
                                                <Button dataUI={getDataUI()} icon="fal fa-trash" btnType='secondary'>Delete</Button>
                                            </> : <>
                                                <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-pen" onClick={this.toggleEditMode}>Edit</Button>
                                                <Button dataUI={getDataUI()} icon="far fa-times-circle" btnType='secondary'>Cancel</Button>
                                            </>

                                        }

                                        rightSide={
                                            !editMode ? <>
                                                <Button dataUI={getDataUI()} className='mr-s' icon="fal fa-envelope" btnType='secondary'>Email</Button>
                                                <Button dataUI={getDataUI()} className='mr-s' icon="fal fa-envelope-open" btnType='secondary'>Letter</Button>
                                                <Button dataUI={getDataUI()} className='mr-s' icon="fal fa-phone" btnType='secondary'>Call</Button>
                                            </> : <></>
                                        }
                                    />

                                    <Tabs
                                        dataUI={getDataUI()}
                                        onTabIndexChange={(index: number) => this.setState({ tabIndexEs: index })}
                                        currentlyActiveTabIndex={this.state.tabIndexEs}
                                    >
                                        <TabList dataUI={getDataUI()}>
                                            <TabItem dataUI={getDataUI()}>
                                                <TabItemTitle dataUI={getDataUI()}>Details</TabItemTitle>
                                            </TabItem>
                                            <TabItem dataUI={getDataUI()}>
                                                <TabItemTitle dataUI={getDataUI()}>Contact</TabItemTitle>
                                            </TabItem>
                                            <TabItem dataUI={getDataUI()}>
                                                <TabItemTitle dataUI={getDataUI()}>Notes</TabItemTitle>
                                            </TabItem>
                                            <TabItem dataUI={getDataUI()}>
                                                <TabItemTitle dataUI={getDataUI()}>Communication</TabItemTitle>
                                            </TabItem>
                                            <TabItem dataUI={getDataUI()}>
                                                <TabItemTitle dataUI={getDataUI()}>E-sign</TabItemTitle>
                                            </TabItem>
                                        </TabList>
                                        <TabContent dataUI={getDataUI()}>
                                            <TabContentItem dataUI={getDataUI()}>Details tab content</TabContentItem>
                                            <TabContentItem dataUI={getDataUI()}>Contact tab content</TabContentItem>
                                            <TabContentItem dataUI={getDataUI()}>Notes tab content</TabContentItem>
                                            <TabContentItem dataUI={getDataUI()}>Communication tab content</TabContentItem>
                                            <TabContentItem dataUI={getDataUI()}>
                                                <Toolbar
                                                    dataUI={getDataUI()}
                                                    className="mb-m"
                                                    leftSide={
                                                        <>
                                                            <Checkbox dataUI={getDataUI()} readOnly={!editMode} className="mr-m" name="enable-e-sign" > Enable E-signature </Checkbox>
                                                            <Label dataUI={getDataUI()} className="mr-s">Template</Label>
                                                            {editMode ?
                                                                <Select
                                                                    dataUI={getDataUI()}
                                                                    className='width-m'
                                                                    options={[
                                                                        { value: '1', label: 'LOC Business 2 Guarantors' },
                                                                    ]}
                                                                    value={'1'}
                                                                    placeholder="Select..."
                                                                />
                                                                :
                                                                <Label dataUI={getDataUI()}><strong>LOC Business 2 Guarantors</strong></Label>
                                                            }
                                                        </>
                                                    }
                                                />
                                                <ModalScrollableContainer>
                                                    <div className='section-container mb-m'>
                                                        <h2>Document Signers</h2>
                                                        <hr className='mt-m mb-m' />

                                                        <InfoBox dataUI={getDataUI()} className='mb-m'>
                                                            Edit each document signer role below until all are identified
                                                        </InfoBox>

                                                        <SimpleTableContainer dataUI={getDataUI()}>
                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        {editMode && <th style={{ width: '1px' }}></th>}
                                                                        <th>Name</th>
                                                                        <th>Signer Role</th>
                                                                        <th>E-mail</th>
                                                                    </tr>

                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        {editMode && <td>
                                                                            <Tooltip title="Edit">
                                                                                <Button dataUI={getDataUI()} btnType='icon' icon='fal fa-pen' />
                                                                            </Tooltip>
                                                                        </td>}
                                                                        <td>Personal Child</td>
                                                                        <td>Guarantor 2</td>
                                                                        <td>pch1@testing.dev</td>
                                                                    </tr>
                                                                    <tr>
                                                                        {editMode && <td>
                                                                            <Tooltip title="Edit">
                                                                                <Button dataUI={getDataUI()} btnType='icon' icon='fal fa-pen' />
                                                                            </Tooltip>
                                                                        </td>}
                                                                        <td>Another Child</td>
                                                                        <td>Guarantor 1</td>
                                                                        <td>pch1@testing.dev</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </SimpleTableContainer>
                                                    </div>

                                                    <div className='section-container mb-m'>
                                                        <h2>Required documents</h2>
                                                        <hr className='mt-m mb-m' />

                                                        <InfoBox dataUI={getDataUI()} className='mb-m'>
                                                            Upload each required document.
                                                        </InfoBox>

                                                        <SimpleTableContainer dataUI={getDataUI()}>
                                                            <table style={{ tableLayout: 'fixed' }}>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Document</th>
                                                                        <th style={{ width: '42px' }}>Type</th>
                                                                        <th>Uploaded file</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Business Loan Agreement</td>
                                                                        <td>pdf</td>
                                                                        <td>
                                                                            <FileUpload
                                                                                dataUI={getDataUI()}
                                                                                visualType="small"
                                                                                onChange={(files: any) => {
                                                                                    onAddFile(files, 'demoFilesTh', this);
                                                                                    this.setState({
                                                                                        showModalEsInner: true,
                                                                                        uploadingPropName: 'demoFilesTh',
                                                                                        showSuccessInfoEs: true,
                                                                                        isServerLockCheck: checkFileOnServer(files)
                                                                                    });
                                                                                }}
                                                                                viewMode={!editMode}
                                                                                supportedFormats='.pdf'
                                                                            >
                                                                                {this.renderFileRowsTable('demoFilesTh')}
                                                                            </FileUpload>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Commercial Guaranty Document</td>
                                                                        <td>pdf</td>
                                                                        <td>
                                                                            <FileUpload
                                                                                dataUI={getDataUI()}
                                                                                visualType="small"
                                                                                onChange={(files: any) => {
                                                                                    onAddFile(files, 'demoFilesSix', this);
                                                                                    this.setState({
                                                                                        showModalEsInner: true,
                                                                                        uploadingPropName: 'demoFilesSix',
                                                                                        showSuccessInfoEs: true,
                                                                                        isServerLockCheck: checkFileOnServer(files)
                                                                                    });
                                                                                }}
                                                                                viewMode={!editMode}
                                                                                supportedFormats='.pdf'
                                                                            >
                                                                                {this.renderFileRowsTable('demoFilesSix')}
                                                                            </FileUpload>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Promissory Note</td>
                                                                        <td>pdf</td>
                                                                        <td>
                                                                            <FileUpload
                                                                                dataUI={getDataUI()}
                                                                                visualType="small"
                                                                                onChange={(files: any) => {
                                                                                    onAddFile(files, 'demoFilesSeven', this);
                                                                                    this.setState({
                                                                                        showModalEsInner: true,
                                                                                        uploadingPropName: 'demoFilesSeven',
                                                                                        showSuccessInfoEs: true,
                                                                                        isServerLockCheck: checkFileOnServer(files)
                                                                                    });
                                                                                }}
                                                                                viewMode={!editMode}
                                                                                supportedFormats='.pdf'
                                                                            >
                                                                                {this.renderFileRowsTable('demoFilesSeven')}
                                                                            </FileUpload>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>W-9</td>
                                                                        <td>doc</td>
                                                                        <td>
                                                                            <FileUpload
                                                                                dataUI={getDataUI()}
                                                                                visualType="small"
                                                                                onChange={(files: any) => {
                                                                                    onAddFile(files, 'demoFilesEight', this);
                                                                                    this.setState({
                                                                                        showModalEsInner: true,
                                                                                        uploadingPropName: 'demoFilesEight',
                                                                                        showSuccessInfoEs: true,
                                                                                        isServerLockCheck: checkFileOnServer(files)
                                                                                    });
                                                                                }}
                                                                                viewMode={!editMode}
                                                                                supportedFormats='.doc'
                                                                            >
                                                                                {this.renderFileRowsTable('demoFilesEight')}
                                                                            </FileUpload>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </SimpleTableContainer>
                                                    </div>

                                                    <div className='section-container'>
                                                        <h2>Send for E-sign</h2>
                                                        <hr className='mt-m mb-m' />

                                                        <Toolbar
                                                            dataUI={getDataUI()}
                                                            className="mb-m"
                                                            leftSide={
                                                                <Button dataUI={getDataUI()} disabled={!editMode} icon="fal fa-share-square">Send</Button>
                                                            }
                                                        />

                                                        <Row className="mb-m">
                                                            <Col xs="3">
                                                                <Label dataUI={getDataUI()} className="d-flex justify-content-end">Subject</Label>
                                                            </Col>
                                                            <Col>
                                                                {editMode ?
                                                                    <Select
                                                                        dataUI={getDataUI()}
                                                                        className='width-l'
                                                                        options={[
                                                                            { value: '1', label: 'Closing Document' },
                                                                        ]}
                                                                        value={'1'}
                                                                        placeholder="Select..."
                                                                    />
                                                                    : <Label dataUI={getDataUI()} className="width-l"><strong>Closing Document</strong></Label>}
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs="3">
                                                                <Label dataUI={getDataUI()} className="d-flex justify-content-end ">Text</Label>
                                                            </Col>
                                                            <Col>
                                                                {editMode ? <Textarea dataUI={getDataUI()} className="width-l" value=' Application Number: Our financial institution uses DocuSign as our secure signing partner. If you have any questions, please contact your banker: Officer Name: Officer Cell Phone: Officer Email: Officer Work Phone: Officer title:' /> :
                                                                    <Label dataUI={getDataUI()} className="width-l">
                                                                        <strong>
                                                                            Application Number: Our financial institution uses DocuSign as our secure signing partner. If you have any questions, please contact your banker: Officer Name: Officer Cell Phone: Officer Email: Officer Work Phone: Officer title:
                                                                        </strong>
                                                                    </Label>
                                                                }

                                                            </Col>
                                                        </Row>

                                                    </div>
                                                </ModalScrollableContainer>
                                            </TabContentItem>
                                        </TabContent>
                                    </Tabs>
                                </Modal>


                                {/* Show uploading from table in modal upload start*/}
                                <Modal
                                    dataUI={getDataUI()}
                                    title="Required Documents Details 3529232"
                                    disableCloseBtn={this.state.isServerLockCheck}
                                    isVisible={true}
                                    isHidden={!this.state.showModalEsInner}
                                    size="S"
                                    onClose={() => this.setState({
                                        showModalEsInner: false,
                                        showSuccessInfoEs: this.state[this.state.uploadingPropName].length === 0 ? true : false,
                                    })}
                                >
                                    <Toolbar
                                        dataUI={getDataUI()}
                                        className="mb-m"
                                        leftSide={
                                            <>
                                                <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-save" disabled={this.state.isServerLockCheck} onClick={() => { this.setState({ showModalEsInner: false }); }}>Save</Button>
                                                <Button dataUI={getDataUI()} icon="far fa-times-circle" disabled={this.state.isServerLockCheck} btnType="secondary" onClick={() => this.setState({ showModalEsInner: false })}>Cancel</Button>
                                            </>
                                        }
                                    />
                                    <ModalScrollableContainer>
                                        {this.state.showSuccessInfoEs && this.state[this.state.uploadingPropName][0] && !this.state[this.state.uploadingPropName][0].uploadedFile.uploadError &&
                                            <InfoBox dataUI={getDataUI()} className='mb-m' type={this.state.isServerLockCheck ? "warning" : "success"} icon={this.state.isServerLockCheck ? "fal fa-exclamation-triangle" : "fal fa-file-upload"} title={this.state.isServerLockCheck ? "System is uploading your file." : 'Your documents are uploading in the background.'}>
                                                {this.state.isServerLockCheck ? "Please wait while your file is currently uploading, so it can be checked. If you wish to stop the proccess, you can stop the uploading of the file." : "You can safely close this dialogue and the files will continue uploading in the background."}
                                            </InfoBox>}

                                        <FileUpload
                                            dataUI={getDataUI()}
                                            visualType="large"
                                            onChange={(files: any) => {
                                                if (this.state[this.state.uploadingPropName].length >= 1) {
                                                    return;
                                                }
                                                onAddFile(files, this.state.uploadingPropName, this);
                                                this.setState({
                                                    showSuccessInfoEs: true,
                                                    isServerLockCheck: checkFileOnServer(files)
                                                })

                                            }}
                                            viewMode={this.state[this.state.uploadingPropName].length >= 1}
                                            {...this.state[this.state.uploadingPropName].length >= 1 && {
                                                description: {
                                                    visible: true,
                                                    value: "Wish to replace? First remove the uploaded file."
                                                }
                                            }}
                                        >
                                            {this.renderFileRowsModal(this.state.uploadingPropName)}
                                        </FileUpload>
                                    </ModalScrollableContainer>
                                </Modal>
                                {/* Show uploading from table in modal upload end*/}
                            </MainContainerScrollable>
                        </TabContentItem>
                        <TabContentItem dataUI={getDataUI()}>Recurring-Documents tab content</TabContentItem>
                        <TabContentItem dataUI={getDataUI()}>Notes tab content</TabContentItem>
                        <TabContentItem dataUI={getDataUI()}>E-sign tab content</TabContentItem>
                    </TabContent>
                </Tabs>
            </>
        );
    }
}

export default DemoRequiredDocuments;