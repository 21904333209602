import React, { useState, useRef, useCallback } from "react";
import {
    ReactAux, MainContainerHeader, ActionContainer,
    Button, ActionContainerHeader, ActionContainerCrumbs, MainContainerScrollable, Toolbar, Input,
    Label,
    Radio,
    Multiselect,
    LinkWithIcon,
    ActionSettings,
    GridContainer,
    Grid,
    GridColumn,
    Icon,
    Modal,
    ModalScrollableContainer,
    TabContent,
    TabContentItem,
    TabItem,
    TabItemTitle,
    TabList,
    Tabs,
    Col,
    Row,
    Editor,
    EditorContainer,
    EditorTools,
    Select,
    Checkbox,
    SimpleTableContainer,
    Textarea,
    EditorRightPanel,
    Collapse,
    classNameHelper,
    Stack
} from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from "../helpers/helpers";

import emailTemplates from '../components/KendoTable/emailTemplates.json'

const multiselectOptions = [
    { value: '1', label: 'Option 1', isDisabled: true },
    { value: '2', label: 'Option 2', secondCode: 'code-123' },
    { value: '3', label: 'Option 3', isSomething: false },
    { value: '4', label: 'Option 4' }
];

interface PageState {
    skip: number,
    take: number
}

const initialDataState: PageState = { skip: 0, take: 50 };

const initialList = [
    { id: '1', name: 'Accountant', expanded: false, items: ['Addr1', 'Cell Phone'] },
    { id: '2', name: 'Activity', expanded: false, items: ['BusPhone',] },
    { id: '3', name: 'Appraiser', expanded: false, items: ['Addr1', 'Cell Phone', 'City', 'Code', 'Company', 'E-mail', 'LicExpireDt', 'Name'] },
    { id: '4', name: 'Attorney', expanded: false, items: ['Addr1', 'Cell Phone'] },
    { id: '5', name: 'Broker', expanded: false, items: ['Addr1', 'Cell Phone'] },
    { id: '6', name: 'Collateral', expanded: false, items: ['Addr1', 'Cell Phone'] },
];

const DemoMergeEmail = () => {
    const [value1, setValue1] = useState([{ ...multiselectOptions[0] }]);
    const [page, setPage] = useState<PageState>(initialDataState);
    const [showModal, setShowModal] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [tempStyle, setTempStyle] = useState([true, false]);
    const [list, setList] = useState(initialList);
    const [searchTerm, setSearchTerm] = useState('');
    const [showInsertMedia, setShowInsertMedia] = useState(false);

    const {
        Bold, Italic, Underline, Strikethrough, Subscript, Superscript,
        ForeColor, BackColor,
        AlignLeft, AlignCenter, AlignRight, AlignJustify,
        Indent, OrderedList, UnorderedList,
        FontSize, FontName, FormatBlock,
        InsertImage, ViewHtml, Link,
    } = EditorTools;

    const editorRef = useRef<any>();
    const [value, setValue] = React.useState("<p>Editor default value...</p>");
    const [editorDisabled, setEditorDisabled] = useState(false);

    const customToolRendering = useCallback((Tool: any, colorPicker = false) => {
        return function (props: any) {
            return (
                <Tool
                    {...props}
                    disabled={editorDisabled}
                    {...(colorPicker && editorDisabled) && { colorPickerProps: { disabled: editorDisabled } }}
                />
            );
        };
    }, [editorDisabled]);

    const onAddEditorContent = (content: string) => {
        const view = editorRef?.current?.view;
        const state = view.state;
        const tr = state.tr;
        const currentContent = state.schema.text(content);
        tr.replaceSelectionWith(currentContent, true);

        view.dispatch(tr);
    }

    const onEditorValueChange = (e: any) => {
        setValue(e.html);
    }

    const InsertMedia = () => {
        return <Button disabled={editorDisabled} onClick={() => setShowInsertMedia(true)} icon="fal fa-images" btnType='secondary' dataUI={getDataUI()}>Insert Media Library Image</Button>
    }


    const pageChange = (event: any) => {
        setPage(event.page);
    }

    const expandAll = () => {
        const updatedList = list.map(item => ({ ...item, expanded: true }));
        setList(updatedList);
    };

    const collapseAll = () => {
        const updatedList = list.map(item => ({ ...item, expanded: false }));
        setList(updatedList);
    };

    const isExpandedAll = () => {
        return list.every(item => item.expanded);
    };

    const toggleItem = (index: number) => {
        const updatedList = [...list];
        updatedList[index].expanded = !updatedList[index].expanded;
        setList(updatedList);
    };

    const filterList = (newSearchTerm: string) => {
        if (newSearchTerm === '') {
            const updatedList = initialList.map(initialItem => {
                const listItem = list.find(item => item.id === initialItem.id);

                if (listItem) {
                    return {
                        ...initialItem,
                        expanded: true,
                    };
                }

                return initialItem;
            });

            setList(updatedList);
        } else {
            const updatedList = initialList.map(item => ({
                ...item,
                expanded: item.items.some(subItem =>
                    subItem.toLowerCase().includes(newSearchTerm.toLowerCase())
                ),
            }))
                .filter(item => item.expanded);


            setList(updatedList);
        }
    };

    return (
        <ReactAux>
            <MainContainerHeader dataUI={getDataUI()}>
                <ActionContainer dataUI={getDataUI()}>
                    <ActionContainerHeader dataUI={getDataUI()}>
                        <ActionContainerCrumbs dataUI={getDataUI()} crumbs={[<a href="/">Home</a>, 'System Settings', 'Merge E-mail Templates']} />
                        <ActionSettings dataUI={getDataUI()} isActive>
                            <LinkWithIcon dataUI={getDataUI()} icon="fal fa-info-circle" iconPlace="left"><a href="/">About Merge E-mail Templates</a></LinkWithIcon>
                        </ActionSettings>
                    </ActionContainerHeader>
                </ActionContainer>
            </MainContainerHeader>

            <Toolbar
                dataUI={getDataUI()}
                className="mt-m mb-m"
                leftSide={<>
                    <Button dataUI={getDataUI()} btnType="primary" icon='fal fa-plus' className="mr-m" >Add E-mail Template</Button>
                    <Input dataUI={getDataUI()} icon="fal fa-search" placeholder="Search" className="width-m mr-m" />
                    <Label dataUI={getDataUI()} className="mr-s text-bold">Show:</Label>
                    <Radio dataUI={getDataUI()} className="mr-s" name="radio-1">Acive</Radio>
                    <Radio dataUI={getDataUI()} className="mr-s" name="radio-1">Inacive</Radio>
                    <Radio dataUI={getDataUI()} className="mr-m" name="radio-1">All</Radio>
                    <Label dataUI={getDataUI()} className="mr-s text-bold">Type:</Label>
                    <Multiselect
                        className="width-l"
                        options={multiselectOptions}
                        value={value1}
                        onChange={(obj: any) => {
                            setValue1(obj);
                        }}
                        dataUI={getDataUI()}
                    />
                </>}
            />

            <MainContainerScrollable>
                <GridContainer dataUI={getDataUI()} style={{ maxHeight: '100%' }} className="d-flex">
                    <Grid
                        style={{ maxHeight: '100%' }}
                        data={emailTemplates.slice(page.skip, page.take + page.skip)}
                        skip={page.skip}
                        take={page.take}
                        total={emailTemplates.length}
                        pageable={true}
                        onPageChange={pageChange}
                    >
                        <GridColumn
                            width={42}
                            cell={() => {
                                return (
                                    <td className="text-center">
                                        <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-pen" onClick={() => setShowModal(true)} />
                                    </td>
                                )
                            }}
                        />
                        <GridColumn field="Name" />
                        <GridColumn field="Type" />
                        <GridColumn field="TemplateStyle" title="Template Style" />
                        <GridColumn field="MergeDocument" title="Merge Document" />
                        <GridColumn
                            width={110}
                            field="ShowForMerge"
                            title="Show For Merge"
                            cell={() => {
                                return (
                                    <td className="text-center">
                                        <Icon dataUI={getDataUI()} icon="far fa-check text-muted" size="M" />
                                    </td>
                                )
                            }}
                        />
                        <GridColumn
                            width={56}
                            field="Active"
                            cell={() => {
                                return (
                                    <td className="text-center">
                                        <Icon dataUI={getDataUI()} icon="fas fa-check-circle text-success" size="M" />
                                    </td>
                                )
                            }}
                        />
                    </Grid>
                </GridContainer>
            </MainContainerScrollable>

            <Modal dataUI={getDataUI()} title="Edit ‘Tax Return Due E-mail’" isVisible={showModal} size="XL" onClose={() => { setShowModal(false) }}>
                <Toolbar
                    dataUI={getDataUI()}
                    className="mt-m mb-m"
                    leftSide={<>
                        <Button dataUI={getDataUI()} btnType="primary" icon='fal fa-save' className="mr-s">Save</Button>
                        <Button dataUI={getDataUI()} btnType="secondary" icon='fal fa-copy' className="mr-s">Copy </Button>
                        <Button dataUI={getDataUI()} btnType="secondary" icon='far fa-times-circle' className="mr-s">Cancel</Button>
                        <Button dataUI={getDataUI()} btnType="secondary" icon='fal fa-trash' className="mr-s">Delete</Button>

                    </>}
                />

                <Tabs dataUI={getDataUI()} onTabIndexChange={(index: number) => setTabIndex(index)} currentlyActiveTabIndex={tabIndex}>
                    <TabList dataUI={getDataUI()}>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Details</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Notifications</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Required Documents</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Usage</TabItemTitle>
                        </TabItem>
                    </TabList>
                    <TabContent dataUI={getDataUI()}>
                        <TabContentItem dataUI={getDataUI()}>
                            <ModalScrollableContainer>
                                <Row>
                                    <Col xs="5">
                                        <Row className="mb-s">
                                            <Col xs="3">
                                                <Label dataUI={getDataUI()} className="d-flex justify-content-end">Name</Label>
                                            </Col>
                                            <Col>
                                                <Input dataUI={getDataUI()} />
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs="3">
                                                <Label dataUI={getDataUI()} className="d-flex justify-content-end">Subject</Label>
                                            </Col>
                                            <Col>
                                                <Input dataUI={getDataUI()} />
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs="3">
                                                <Label dataUI={getDataUI()} className="d-flex justify-content-end">Type</Label>
                                            </Col>
                                            <Col>
                                                <Select
                                                    dataUI={getDataUI()}
                                                    options={[
                                                        { value: '1', label: 'Recurring Docs' },
                                                    ]}
                                                    value={'1'}
                                                    placeholder="Select..."
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="3">
                                                <Label dataUI={getDataUI()} className="d-flex justify-content-end ">Active</Label>
                                            </Col>
                                            <Col>
                                                <Checkbox dataUI={getDataUI()} defaultChecked />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="3">
                                                <Label dataUI={getDataUI()} className="d-flex justify-content-end ">Prevent Editing</Label>
                                            </Col>
                                            <Col>
                                                <Checkbox dataUI={getDataUI()} defaultChecked />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="3">
                                                <Label dataUI={getDataUI()} className="d-flex justify-content-end ">Show for E-mail Merge</Label>
                                            </Col>
                                            <Col>
                                                <Checkbox dataUI={getDataUI()} defaultChecked />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="3">
                                                <Label dataUI={getDataUI()} className="d-flex justify-content-end ">Group Ticklers</Label>
                                            </Col>
                                            <Col>
                                                <div className="d-flex">
                                                    <Checkbox dataUI={getDataUI()} defaultChecked />
                                                    <Label dataUI={getDataUI()} className="text-muted ml-s">With some Correspondent into a Single Document</Label>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs="7">
                                        <div className="section-container">
                                            <div className="d-flex">
                                                <Label dataUI={getDataUI()} className="mr-s text-bold">Template Style:</Label>
                                                <Radio dataUI={getDataUI()} className="mr-s" name="radio-2" checked={tempStyle[0]} onChange={() => { setTempStyle([true, false]); setEditorDisabled(false) }}>HTML</Radio>
                                                <Radio dataUI={getDataUI()} className="mr-s" name="radio-2" checked={tempStyle[1]} onChange={() => { setTempStyle([false, true]); setEditorDisabled(true) }}>Microsoft Word</Radio>
                                                {tempStyle[1] &&
                                                    <Select
                                                        dataUI={getDataUI()}
                                                        className="width-m"
                                                        options={[
                                                            { value: '1', label: 'Recurring Docs' },
                                                        ]}
                                                        value={'0'}
                                                        placeholder="Select..."
                                                    />
                                                }
                                            </div>
                                            <hr className="mt-m mb-m" />
                                            <EditorContainer dataUI={getDataUI()}>
                                                <Editor
                                                    className={classNameHelper(editorDisabled && "lv-disabled-editor")}
                                                    ref={editorRef}
                                                    value={value}
                                                    onChange={onEditorValueChange}
                                                    tools={[
                                                        [customToolRendering(Bold), customToolRendering(Italic), customToolRendering(Underline), customToolRendering(Strikethrough),
                                                        () => <hr className="lv-vertical border-color-3 ml-s mr-s" />,
                                                        customToolRendering(AlignLeft), customToolRendering(AlignCenter), customToolRendering(AlignRight), customToolRendering(AlignJustify),
                                                        () => <hr className="lv-vertical border-color-3 ml-s mr-s" />,
                                                        customToolRendering(OrderedList), customToolRendering(UnorderedList), customToolRendering(Indent),
                                                        () => <hr className="lv-vertical border-color-3 ml-s mr-s" />,
                                                        customToolRendering(InsertImage), customToolRendering(Link),
                                                        () => <hr className="lv-vertical border-color-3 ml-s mr-s" />,
                                                        customToolRendering(ViewHtml),
                                                        () => <hr className="lv-vertical border-color-3 ml-s mr-s" />,
                                                        customToolRendering(Subscript), customToolRendering(Superscript),
                                                        () => <hr className="lv-vertical border-color-3 ml-s mr-s" />,
                                                        customToolRendering(FormatBlock), customToolRendering(FontName), customToolRendering(FontSize),
                                                        () => <hr className="lv-vertical border-color-3 ml-s mr-s" />,
                                                        customToolRendering(ForeColor, true), customToolRendering(BackColor, true),
                                                        InsertMedia
                                                        ]
                                                    ]}
                                                    contentStyle={{ height: 300 }}
                                                />
                                                <EditorRightPanel dataUI={getDataUI()}>
                                                    <Input disabled={editorDisabled} dataUI={getDataUI()} className="mb-s" placeholder="Search" icon="fal fa-search" value={searchTerm} clearAction onClear={() => setSearchTerm('')}
                                                        onChange={(e) => {
                                                            setSearchTerm(prevSearchTerm => {
                                                                const newSearchTerm = e.target.value;
                                                                filterList(newSearchTerm);
                                                                return newSearchTerm;
                                                            });
                                                        }} />
                                                    {list.length > 1 &&
                                                        <LinkWithIcon disabled={editorDisabled} dataUI={getDataUI()} className="mb-s" icon={classNameHelper(isExpandedAll() ? 'fal fa-compress-alt' : 'fal fa-expand-alt', !editorDisabled && 'text-primary')} iconPlace="left">
                                                            <a href="/" onClick={(e) => {
                                                                e.preventDefault();

                                                                if (isExpandedAll()) {
                                                                    collapseAll();
                                                                    return;
                                                                }

                                                                expandAll();
                                                            }}>
                                                                {isExpandedAll() ? 'Collapse all' : 'Expand all'}
                                                            </a>
                                                        </LinkWithIcon>
                                                    }
                                                    <MainContainerScrollable>
                                                        {list.length <= 0 && <p className="lv-small text-muted text-center mt-s">No Results Found for ‘{searchTerm}’.</p>}
                                                        {list.map((item, index) => (
                                                            <Collapse
                                                                key={index}
                                                                title={item.name}
                                                                isLightType
                                                                expanded={item.expanded}
                                                                onClick={() => toggleItem(index)}
                                                                dataUI={getDataUI()}
                                                                disabled={editorDisabled}
                                                            >
                                                                <Stack gap="S">
                                                                    {item.items
                                                                        .filter(subItem =>
                                                                            subItem.toLowerCase().includes(searchTerm.toLowerCase())
                                                                        )
                                                                        .map((filteredItem, subIndex) => (
                                                                            <p key={subIndex} onClick={() => onAddEditorContent(filteredItem)}>
                                                                                <span className="lv-link-styles">
                                                                                    {filteredItem.split(new RegExp(`(${searchTerm})`, 'i')).map((part, i) => (
                                                                                        i % 2 === 0 ? part : <strong key={i}>{part}</strong>
                                                                                    ))}
                                                                                </span>
                                                                            </p>
                                                                        ))
                                                                    }
                                                                </Stack>
                                                            </Collapse>
                                                        ))}
                                                    </MainContainerScrollable>
                                                </EditorRightPanel>
                                            </EditorContainer>

                                            <Modal
                                                dataUI={getDataUI()}
                                                title="Insert media modal"
                                                isVisible={showInsertMedia}
                                                size="L"
                                                onClose={() => setShowInsertMedia(false)}
                                            >
                                                <div style={{ height: '200px', background: 'var(--color-select)' }} />
                                            </Modal>
                                        </div>
                                    </Col>
                                </Row>
                            </ModalScrollableContainer>
                        </TabContentItem>
                        <TabContentItem dataUI={getDataUI()}>
                            <ModalScrollableContainer>
                                <Row>
                                    <Col xs="6">
                                        <Row>
                                            <Col xs="6">
                                                <SimpleTableContainer dataUI={getDataUI()}>
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>To</th>
                                                                <th>Cc</th>
                                                                <th>Bcc</th>
                                                            </tr>
                                                            <tr>
                                                                <th className="text-primary" colSpan={4}>External Recipients</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Accountant</td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Appraiser</td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Attorney</td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Broker</td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Inspector</td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Insurance Agent</td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                            </tr>
                                                            <tr>
                                                                <td className="border-text-color">Party/Corespondent</td>
                                                                <td className="border-text-color"><Checkbox dataUI={getDataUI()} /></td>
                                                                <td className="border-text-color"><Checkbox dataUI={getDataUI()} /></td>
                                                                <td className="border-text-color"><Checkbox dataUI={getDataUI()} /></td>
                                                            </tr>
                                                            <tr>
                                                                <th className="text-primary" colSpan={4}>External Recipients</th>
                                                            </tr>
                                                            <tr>
                                                                <td>All Loan Assistants</td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Credit Analyst</td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Loan Assistant</td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Primary Loan Assistant</td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Primary Officer</td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Portfolio Managet</td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Processor</td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Secondary Offiver</td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                                <td><Checkbox dataUI={getDataUI()} /></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </SimpleTableContainer>
                                            </Col>
                                            <Col xs="6">
                                                <Label dataUI={getDataUI()} className="mb-s"><strong>To</strong></Label>
                                                <Textarea dataUI={getDataUI()} className="mb-m" rows={10} />
                                                <Label dataUI={getDataUI()} className="mb-s"><strong>Cc</strong></Label>
                                                <Textarea dataUI={getDataUI()} className="mb-m" rows={10} />
                                                <Label dataUI={getDataUI()} className="mb-s"><strong>Bcc</strong></Label>
                                                <Textarea dataUI={getDataUI()} className="mb-m" rows={10} />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </ModalScrollableContainer>
                        </TabContentItem>
                        <TabContentItem dataUI={getDataUI()}>
                            <h3 className="mb-m">Required Documents</h3>
                            <h4 className="mb-m">Lorem</h4>
                        </TabContentItem>
                        <TabContentItem dataUI={getDataUI()}>
                            <ModalScrollableContainer>
                                <Row>
                                    <Col xs="6">
                                        <SimpleTableContainer dataUI={getDataUI()}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th>Area </th>
                                                        <th>Name</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td><a href="/" className="lv-link-styles">Prerequisite Requirements</a></td>
                                                        <td>2020 Business Tax Return</td>
                                                    </tr>
                                                    <tr>
                                                        <td><a href="/" className="lv-link-styles">Reporting Requirements</a></td>
                                                        <td>Business Tax Return</td>
                                                    </tr>
                                                    <tr>
                                                        <td><a href="/" className="lv-link-styles">Reporting Requirements</a></td>
                                                        <td>Personal Tax Return</td>
                                                    </tr>
                                                    <tr>
                                                        <td><a href="/" className="lv-link-styles">Covenant Settings</a></td>
                                                        <td>DSCR {'>'} 125</td>
                                                    </tr>
                                                    <tr>
                                                        <td><a href="/" className="lv-link-styles">Activity Definitions</a></td>
                                                        <td>Due Diligence</td>
                                                    </tr>
                                                    <tr>
                                                        <td><a href="/" className="lv-link-styles">Authentication</a></td>
                                                        <td>Field Name</td>
                                                    </tr>
                                                    <tr>
                                                        <td><a href="/" className="lv-link-styles">Online Application</a></td>
                                                        <td>Field Name</td>
                                                    </tr>
                                                    <tr>
                                                        <td><a href="/" className="lv-link-styles">Automated Loan Review</a></td>
                                                        <td>Name of Loan Review Template</td>
                                                    </tr>
                                                    <tr>
                                                        <td><a href="/" className="lv-link-styles">Approval Matrix</a></td>
                                                        <td>Name of Approval Matrix</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </SimpleTableContainer>
                                    </Col>
                                </Row>
                            </ModalScrollableContainer>
                        </TabContentItem>
                    </TabContent>
                </Tabs>
            </Modal>
        </ReactAux>
    )
}

export default DemoMergeEmail