import React from 'react';

import {
    MainContainerHeader, ReactAux, ActionContainer, ActionContainerHeader, ActionContainerCrumbs,
    Button, MainContainerScrollable, Checkbox, Collapse, TabContent, TabContentItem, TabItem,
    TabItemTitle, TabList, Tabs, Col, Input, Label, Row, Select, NumericInput, Radio, Card,
    CardSection, Modal, Toolbar, DraggableItem, DraggableItemActions, DraggableItemTitle,
    DragNDropContext, DynamicScrollableContainer, DragNDropSection, DragNDropSectionHeader,
    Droparea, VerticalTabContent, VerticalTabContentItem, VerticalTabItem, VerticalTabList,

    VerticalTabs, DragNDropUtils, Badge, SimpleTableContainer, LinkWithIcon, ModalScrollableContainer,
    DragNDropSectionScrollable, Tooltip,

} from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../helpers/helpers';


// TODO This structure is only for demo/test. A real structure must be devised.
const addressForm =
    <Row>
        <Col xs="3" className="align-self-center">
            <p className="text-right mb-s">Street Number</p>
        </Col>
        <Col xs="3">
            <Input dataUI={getDataUI()} className="mb-s" type="text" />
        </Col>
        <Col xs="3" className="align-self-center">
            <p className="text-right mb-s">Zip Code</p>
        </Col>
        <Col xs="3">
            <Input dataUI={getDataUI()} className="mb-s" type="text" />
        </Col>
        <Col xs="3" className="align-self-center">
            <p className="text-right mb-s">Address Line 1</p>
        </Col>
        <Col xs="3">
            <Input dataUI={getDataUI()} className="mb-s" type="text" />
        </Col>
        <Col xs="3" className="align-self-center">
            <p className="text-right mb-s">City</p>
        </Col>
        <Col xs="3">
            <Input dataUI={getDataUI()} className="mb-s" type="text" />
        </Col>
        <Col xs="3" className="align-self-center">
            <p className="text-right mb-s">Address Line 2</p>
        </Col>
        <Col xs="3">
            <Input dataUI={getDataUI()} className="mb-s" type="text" />
        </Col>
        <Col xs="3" className="align-self-center">
            <p className="text-right mb-s">State</p>
        </Col>
        <Col xs="3">
            <Input dataUI={getDataUI()} className="mb-s" type="text" />
        </Col>
        <Col xs="3" className="align-self-center">
            <p className="text-right mb-s">Street Suffix</p>
        </Col>
        <Col xs="3">
            <Input dataUI={getDataUI()} className="mb-s" type="text" />
        </Col>
        <Col xs="3" className="align-self-center">
            <p className="text-right mb-s">County</p>
        </Col>
        <Col xs="3">
            <Input dataUI={getDataUI()} className="mb-s" type="text" />
        </Col>
    </Row>;

const defaultGeneralAvailable = [
    { title: 'Analysis', id: 'item-1' }, { title: 'Branch', id: 'item-2' }, { title: 'CD Completed', id: 'item-3' }, { title: 'CD Required', id: 'item-4' },
    { title: 'CBA Completed', id: 'item-5' }, { title: 'CBA Required', id: 'item-6' }, { title: 'HDMA Reportable Closing Percent', id: 'item-7' }, { title: 'Competition', id: 'item-8' },
    { title: 'Create Date', id: 'item-9' }, { title: 'Days in Decisioning', id: 'item-10' }, { title: 'Days in Last Review', id: 'item-11' }, { title: 'General item 1', id: 'item-12' },
    { title: 'General item 2', id: 'item-13' }, { title: 'General item 3', id: 'item-14' }, { title: 'General item 4', id: 'item-15' }, { title: 'General item 5', id: 'item-16' }, { title: 'General item 6', id: 'item-17' }
];

const defaultCollateralAvailable = [
    { title: 'Collateral item 1', id: 'item-18' }, { title: 'Collateral item 2', id: 'item-19' }, { title: 'Collateral item 3', id: 'item-20' },
    { title: 'Collateral item 4', id: 'item-21' }, { title: 'Collateral item 5', id: 'item-12' }
];

class DemoSBA extends React.Component {

    private static readonly AVAILABLE_GENERAL_ITEMS = 'availableGeneralItems';
    private static readonly AVAILABLE_COLLATERAL_ITEMS = 'availableCollateralItems';
    private static readonly ASSIGNED_BUSINESS_FINANCIALS = 'assignedBusinessFinancials';
    private static readonly ASSIGNED_BORROWERS_CERTIFICATION = 'assignedBorrowersCertification';
    private static readonly ASSIGNED_SIGNED = 'assignedSigned';
    private static readonly ASSIGNED_LOADN_AGREEMENT = 'assignedLoanAgreement';

    state = {
        currentlyActiveTab: 0,
        currentlyActiveTabModal: 0,
        collapseDemo1: true,
        collapseDemo2: false,
        collapseDemo3: false,
        collapseDemo4: false,
        collapseDemo5: true,
        collapseDemo6: true,
        collapseDemo7: false,
        collapseDemo8: false,
        lightCollapseDemo1: false,
        lightCollapseDemo2: true,
        lightCollapseDemo3: false,
        lightCollapseDemo4: false,
        modal1: false,
        modal2: false,
        modal3: false,
        modal4: false,
        currentTabIndex: 0,
        availableGeneralItems: defaultGeneralAvailable.map(e => { return { ...e, type: DemoSBA.AVAILABLE_GENERAL_ITEMS } }),
        availableCollateralItems: defaultCollateralAvailable.map(e => { return { ...e, type: DemoSBA.AVAILABLE_COLLATERAL_ITEMS } }),
        assignedBusinessFinancials: [],
        assignedBorrowersCertification: [],
        assignedSigned: [],
        assignedLoanAgreement: [],
        dragStartEvent: null,
        dragUpdateEvent: null,
        availableGeneralSearch: '',
        availableCollateralSearch: '',
    }

    componentDidMount() {
        const chatButton = document.querySelector('.lv-chat-container .lv-toggle-btn') as HTMLElement;
        if (chatButton) chatButton.click();
    }

    updateTabIndex(newIndex: number) {
        if (this.state.currentTabIndex !== newIndex) {
            this.setState({ currentTabIndex: newIndex })
        }
    }

    getList(listId: string) {
        switch (listId) {
            case DemoSBA.AVAILABLE_GENERAL_ITEMS:
                return this.state.availableGeneralItems;
            case DemoSBA.AVAILABLE_COLLATERAL_ITEMS:
                return this.state.availableCollateralItems;
            case DemoSBA.ASSIGNED_BUSINESS_FINANCIALS:
                return this.state.assignedBusinessFinancials;
            case DemoSBA.ASSIGNED_BORROWERS_CERTIFICATION:
                return this.state.assignedBorrowersCertification;
            case DemoSBA.ASSIGNED_SIGNED:
                return this.state.assignedSigned;
            case DemoSBA.ASSIGNED_LOADN_AGREEMENT:
                return this.state.assignedLoanAgreement;
            default:
                return null;
        }
    }

    onDragEnd(result: any) {
        const { source, destination } = result;
        if (!result.destination) {
            this.setState({
                dragStartEvent: null,
                dragUpdateEvent: null,
            });
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const items = DragNDropUtils.reorderList(
                this.getList(source.droppableId) || [],
                source.index,
                destination.index
            );

            this.setState({ [source.droppableId]: items });
        } else {
            const result: any = DragNDropUtils.moveBetweenLists(
                this.getList(source.droppableId) || [],
                this.getList(destination.droppableId) || [],
                source.index,
                destination.index
            );

            this.setState({ [source.droppableId]: result[0], [destination.droppableId]: result[1], });
        }

        this.setState({
            dragStartEvent: null,
            dragUpdateEvent: null
        });
    }

    handleDragStart(event: any) {
        this.setState({
            dragStartEvent: event,
            dragUpdateEvent: null,
        });
    }

    handleDragUpdate(event: any) {
        this.setState({
            dragStartEvent: null,
            dragUpdateEvent: event,
        });
    }

    returnItemToAvailable(currentType: string, prevType: string, index: number) {
        const currentList = [...this.getList(currentType) || []];
        const prevList = [...this.getList(prevType) || []];

        const [removedItem] = currentList.splice(index, 1);
        prevList.push(removedItem);

        this.setState({ [currentType]: currentList, [prevType]: prevList });
    }

    render() {

        return (
            <ReactAux>
                <MainContainerHeader dataUI={getDataUI()}>
                    <ActionContainer dataUI={getDataUI()}>
                        <ActionContainerHeader dataUI={getDataUI()}>
                            <ActionContainerCrumbs crumbs={['SBA']} dataUI={getDataUI()} />
                        </ActionContainerHeader>
                        <Button dataUI={getDataUI()} icon="fal fa-save">Save</Button>
                        <Button dataUI={getDataUI()} btnType="secondary" icon="far fa-times-circle">Cancel</Button>

                        <Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-history" onClick={() => this.setState({ modal3: true })}>History</Button>

                    </ActionContainer>
                </MainContainerHeader>

                <Tabs dataUI={getDataUI()} className="mt-m" currentlyActiveTabIndex={this.state.currentlyActiveTab} onTabIndexChange={i => this.setState({ currentlyActiveTab: i })}>
                    <TabList dataUI={getDataUI()}>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Application</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Attachments</TabItemTitle>
                        </TabItem>
                    </TabList>
                    <TabContent dataUI={getDataUI()}>
                        <TabContentItem dataUI={getDataUI()}>
                            <MainContainerScrollable>
                                <Collapse
                                    dataUI={getDataUI()}
                                    title="Address"
                                    expanded={this.state.collapseDemo1}
                                    onClick={() => this.setState({ collapseDemo1: !this.state.collapseDemo1 })}
                                >
                                    <Row>
                                        <Col xs={3} className="text-right">
                                            <Label dataUI={getDataUI()} className="mb-s justify-content-end">SBA Application Status</Label>
                                            <Label dataUI={getDataUI()} className="mb-s justify-content-end">SBA Application Number</Label>
                                            <Label dataUI={getDataUI()} className="mb-s justify-content-end">SBA Loan Number</Label>
                                            <Label dataUI={getDataUI()} className="mb-s justify-content-end">Substatus</Label>
                                            <Label dataUI={getDataUI()} className="mb-s justify-content-end">Underwriting Status</Label>
                                            <Label dataUI={getDataUI()} className="mb-s justify-content-end">SBA Purpose</Label>
                                            <Label dataUI={getDataUI()} className="mb-s justify-content-end">SBA Proccessing Method</Label>
                                            <Label dataUI={getDataUI()} className="mb-s justify-content-end">Elligibility Requirements Met</Label>
                                            <Label dataUI={getDataUI()} className="mb-s justify-content-end">Is a standby Creditor Agreement</Label>
                                            <Label dataUI={getDataUI()} className="mb-s justify-content-end">Net Debenture Amount</Label>
                                            <Label dataUI={getDataUI()} className="mb-s justify-content-end">Closing Cost Amount</Label>
                                            <Label dataUI={getDataUI()} className="mb-s justify-content-end">SBA Loan Proceed Type</Label>
                                            <Label dataUI={getDataUI()} className="mb-s justify-content-end">SBA Other Proceed Type</Label>
                                            <Label dataUI={getDataUI()} className="mb-s justify-content-end">Underwriting Status</Label>
                                        </Col>
                                        <Col xs={6}>
                                            <Label dataUI={getDataUI()} className="mb-s"><strong>Status</strong></Label>
                                            <Label dataUI={getDataUI()} className="mb-s"><strong>453454</strong></Label>
                                            <Label dataUI={getDataUI()} className="mb-s"><strong>7867678</strong></Label>

                                            <Tooltip title="Add">
                                                <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-plus" className="mb-s" onClick={() => this.setState({ modal1: true })} />
                                            </Tooltip>
                                            <div className="mb-s d-flex">
                                                <Label dataUI={getDataUI()}><strong>Label Status</strong></Label>
                                                <Tooltip title="Edit">
                                                    <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-pen" className="ml-s" onClick={() => this.setState({ modal2: true })} />
                                                </Tooltip>
                                            </div>
                                            <Select
                                                dataUI={getDataUI()}
                                                className="mb-s width-m"
                                                value="1"
                                                options={[
                                                    { value: '1', label: ' ' },
                                                    { value: '2', label: 'Option 2' }
                                                ]}
                                            />
                                            <Select
                                                dataUI={getDataUI()}
                                                className="mb-s width-m"
                                                value="1"
                                                options={[
                                                    { value: '1', label: ' ' },
                                                    { value: '2', label: 'Option 2' }
                                                ]}
                                            />
                                            <Checkbox dataUI={getDataUI()} className="d-block mb-s" />
                                            <Checkbox dataUI={getDataUI()} className="d-block mb-s" />
                                            <NumericInput dataUI={getDataUI()} className="mb-s width-m" type="currency" />
                                            <NumericInput dataUI={getDataUI()} className="mb-s width-m" type="currency" />
                                            <Select
                                                dataUI={getDataUI()}
                                                className="mb-s width-m"
                                                value="1"
                                                options={[
                                                    { value: '1', label: ' ' },
                                                    { value: '2', label: 'Option 2' }
                                                ]}
                                            />
                                            <Input dataUI={getDataUI()} className="mb-s width-m" />
                                            <Input dataUI={getDataUI()} className="mb-s width-m" />
                                        </Col>
                                    </Row>
                                </Collapse>

                                <Collapse
                                    dataUI={getDataUI()}
                                    title="Collateral"
                                    expanded={this.state.collapseDemo2}
                                    onClick={() => this.setState({ collapseDemo2: !this.state.collapseDemo2 })}
                                >
                                    <p>Collateral Content...</p>
                                </Collapse>
                                <Collapse
                                    dataUI={getDataUI()}
                                    title="Injection"
                                    expanded={this.state.collapseDemo3}
                                    onClick={() => this.setState({ collapseDemo3: !this.state.collapseDemo3 })}
                                >
                                    <p>Injection Content...</p>
                                </Collapse>
                                <Collapse
                                    dataUI={getDataUI()}
                                    title="Previous Government Fianncing"
                                    expanded={this.state.collapseDemo4}
                                    onClick={() => this.setState({ collapseDemo4: !this.state.collapseDemo4 })}
                                >
                                    <p>Previous Government Fianncing Content...</p>
                                </Collapse>
                                <Collapse
                                    dataUI={getDataUI()}
                                    title="Loan Packager"
                                    expanded={this.state.collapseDemo5}
                                    onClick={() => this.setState({ collapseDemo5: !this.state.collapseDemo5 })}
                                >
                                    <Row>
                                        <Col xs={3} className="text-right">
                                            <Label dataUI={getDataUI()} className="mb-s justify-content-end">Loan Packager</Label>
                                            <Label dataUI={getDataUI()} className="mb-s justify-content-end">Will fees be paid to the Loan</Label>
                                        </Col>
                                        <Col xs={6}>
                                            <div className="mb-s d-flex">
                                                <Select
                                                    dataUI={getDataUI()}
                                                    className="width-m"
                                                    value="1"
                                                    options={[
                                                        { value: '1', label: ' ' },
                                                        { value: '2', label: 'Option 2' }
                                                    ]}
                                                />
                                                <Tooltip title="Add">
                                                    <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-plus" className="ml-s" />
                                                </Tooltip>
                                            </div>
                                            <div className="mb-s d-flex">
                                                <Radio dataUI={getDataUI()} className="mr-s" name="radio-8">Yes</Radio>
                                                <Radio dataUI={getDataUI()} name="radio-8">No</Radio>
                                            </div>
                                        </Col>
                                    </Row>
                                </Collapse>
                                <Collapse
                                    dataUI={getDataUI()}
                                    title="Primary Adresses"
                                    expanded={this.state.collapseDemo6}
                                    onClick={() => this.setState({ collapseDemo6: !this.state.collapseDemo6 })}
                                >
                                    <Collapse
                                        dataUI={getDataUI()}
                                        isLightType
                                        elementRenderRight={() => <><Checkbox dataUI={getDataUI()} defaultChecked>Borrower</Checkbox><Checkbox dataUI={getDataUI()} className="ml-s">Guarantor</Checkbox><Checkbox dataUI={getDataUI()} className="ml-s">Principal</Checkbox><Checkbox dataUI={getDataUI()} className="ml-s">Federal Employee</Checkbox></>}
                                        title="Jane Cupcake"
                                        expanded={this.state.lightCollapseDemo1}
                                        onClick={() => this.setState({ lightCollapseDemo1: !this.state.lightCollapseDemo1 })}
                                    >
                                        <p>Content...</p>
                                    </Collapse>
                                    <Collapse
                                        dataUI={getDataUI()}
                                        isLightType
                                        elementRenderRight={() => <><Checkbox dataUI={getDataUI()} defaultChecked>Borrower</Checkbox><Checkbox dataUI={getDataUI()} className="ml-s">Guarantor</Checkbox><Checkbox dataUI={getDataUI()} className="ml-s">Principal</Checkbox><Checkbox dataUI={getDataUI()} className="ml-s">Federal Employee</Checkbox></>}
                                        title="Ricky Garcia"
                                        expanded={this.state.lightCollapseDemo2}
                                        onClick={() => this.setState({ lightCollapseDemo2: !this.state.lightCollapseDemo2 })}
                                    >
                                        <Card dataUI={getDataUI()} fullWidth>
                                            <CardSection dataUI={getDataUI()} title="Current Address">{addressForm}</CardSection>
                                            <CardSection dataUI={getDataUI()} title="Mailing Address">{addressForm}</CardSection>
                                        </Card>
                                    </Collapse>
                                    <Collapse
                                        dataUI={getDataUI()}
                                        isLightType
                                        elementRenderRight={() => <><Checkbox dataUI={getDataUI()} defaultChecked>Borrower</Checkbox><Checkbox dataUI={getDataUI()} className="ml-s">Guarantor</Checkbox><Checkbox dataUI={getDataUI()} className="ml-s">Principal</Checkbox><Checkbox dataUI={getDataUI()} className="ml-s">Federal Employee</Checkbox></>}
                                        title="Larry Appleton"
                                        expanded={this.state.lightCollapseDemo3}
                                        onClick={() => this.setState({ lightCollapseDemo3: !this.state.lightCollapseDemo3 })}
                                    >
                                        <p>Content...</p>
                                    </Collapse>
                                    <Collapse
                                        dataUI={getDataUI()}
                                        isLightType
                                        elementRenderRight={() => <><Checkbox dataUI={getDataUI()} defaultChecked>Borrower</Checkbox><Checkbox dataUI={getDataUI()} className="ml-s">Guarantor</Checkbox><Checkbox dataUI={getDataUI()} className="ml-s">Principal</Checkbox><Checkbox dataUI={getDataUI()} className="ml-s">Federal Employee</Checkbox></>}
                                        title="Luis Cupcake"
                                        expanded={this.state.lightCollapseDemo4}
                                        onClick={() => this.setState({ lightCollapseDemo4: !this.state.lightCollapseDemo4 })}
                                    >
                                        <p>Content...</p>
                                    </Collapse>
                                </Collapse>
                                <Collapse
                                    dataUI={getDataUI()}
                                    title="SBA District Office"
                                    expanded={this.state.collapseDemo7}
                                    onClick={() => this.setState({ collapseDemo7: !this.state.collapseDemo7 })}
                                >
                                    <p>SBA District Office Content...</p>
                                </Collapse>
                                <Collapse
                                    dataUI={getDataUI()}
                                    title="Authorization Details"
                                    expanded={this.state.collapseDemo8}
                                    onClick={() => this.setState({ collapseDemo8: !this.state.collapseDemo8 })}
                                >
                                    <p>Authorization Details Content...</p>
                                </Collapse>
                            </MainContainerScrollable>
                        </TabContentItem>

                        <TabContentItem dataUI={getDataUI()}>
                            <MainContainerScrollable>
                                <DragNDropContext onDragStart={this.handleDragStart.bind(this)} onDragUpdate={this.handleDragUpdate.bind(this)} onDragEnd={this.onDragEnd.bind(this)}>
                                    <Row>
                                        <Col xs={7}>
                                            <DynamicScrollableContainer dataUI={getDataUI()}>
                                                <VerticalTabs
                                                    dataUI={getDataUI()}
                                                    currentlyActiveTabIndex={this.state.currentTabIndex}
                                                    onTabIndexChange={index => this.updateTabIndex(index)}
                                                >
                                                    <VerticalTabList dataUI={getDataUI()}>
                                                        <VerticalTabItem dataUI={getDataUI()}>General</VerticalTabItem>
                                                        <VerticalTabItem dataUI={getDataUI()}>Collateral IUDF</VerticalTabItem>
                                                        <VerticalTabItem dataUI={getDataUI()}>Complience</VerticalTabItem>
                                                        <VerticalTabItem dataUI={getDataUI()}>Criticized Asset Data</VerticalTabItem>
                                                        <VerticalTabItem dataUI={getDataUI()}>Facility</VerticalTabItem>
                                                        <VerticalTabItem dataUI={getDataUI()}>Facility Funds</VerticalTabItem>
                                                    </VerticalTabList>
                                                    <VerticalTabContent dataUI={getDataUI()}>
                                                        <VerticalTabContentItem dataUI={getDataUI()}>
                                                            <DragNDropSection dataUI={getDataUI()}>
                                                                <DragNDropSectionHeader dataUI={getDataUI()}>

                                                                    <Input dataUI={getDataUI()}
                                                                        name="input-icon"
                                                                        icon="fal fa-search"
                                                                        value={this.state.availableGeneralSearch}
                                                                        onChange={(ev) => this.setState({ availableGeneralSearch: ev.target.value })}
                                                                    />
                                                                </DragNDropSectionHeader>
                                                                <Droparea
                                                                    dataUI={getDataUI()}
                                                                    dropareaID={DemoSBA.AVAILABLE_GENERAL_ITEMS}
                                                                    allowedItems={[DemoSBA.AVAILABLE_GENERAL_ITEMS]}

                                                                    dragStartEvent={this.state.dragStartEvent}
                                                                    dragUpdateEvent={this.state.dragUpdateEvent}
                                                                >

                                                                    {this.state.availableGeneralItems.map((item, index) => {

                                                                        const itemTitleToLower = item.title.toLowerCase();
                                                                        const searchStr = this.state.availableGeneralSearch;

                                                                        return (
                                                                            !searchStr || (searchStr && itemTitleToLower.includes(searchStr.toLowerCase())) ?

                                                                                <DraggableItem dataUI={getDataUI()} key={item.title} index={index} draggableID={item.id}>
                                                                                    <DraggableItemActions dataUI={getDataUI()}>
                                                                                        <DraggableItemTitle dataUI={getDataUI()}>{item.title}</DraggableItemTitle>
                                                                                    </DraggableItemActions>
                                                                                    <DraggableItemActions dataUI={getDataUI()}>

                                                                                        {item.title.toLowerCase().includes('completed') && <Select dataUI={getDataUI()} className="width-s" placeholder="Select" options={[{ label: 'Test 1', value: 'test 1' }, { label: 'Test 2', value: 'test 2' }]} value="" />}

                                                                                    </DraggableItemActions>
                                                                                </DraggableItem>
                                                                                :
                                                                                null

                                                                        )
                                                                    })}

                                                                </Droparea>

                                                            </DragNDropSection>

                                                        </VerticalTabContentItem>
                                                        <VerticalTabContentItem dataUI={getDataUI()}>
                                                            <DragNDropSection dataUI={getDataUI()}>
                                                                <DragNDropSectionHeader dataUI={getDataUI()}>

                                                                    <Input dataUI={getDataUI()}
                                                                        name="input-icon"
                                                                        icon="fal fa-search"
                                                                        value={this.state.availableCollateralSearch}
                                                                        onChange={(ev) => this.setState({ availableCollateralSearch: ev.target.value })}
                                                                    />
                                                                </DragNDropSectionHeader>
                                                                <Droparea
                                                                    dataUI={getDataUI()}
                                                                    dropareaID={DemoSBA.AVAILABLE_COLLATERAL_ITEMS}
                                                                    allowedItems={[DemoSBA.AVAILABLE_COLLATERAL_ITEMS]}

                                                                    dragStartEvent={this.state.dragStartEvent}
                                                                    dragUpdateEvent={this.state.dragUpdateEvent}
                                                                >
                                                                    {this.state.availableCollateralItems.map((item, index) => {

                                                                        const itemTitleToLower = item.title.toLowerCase();
                                                                        const searchStr = this.state.availableCollateralSearch;

                                                                        return (
                                                                            !searchStr || (searchStr && itemTitleToLower.includes(searchStr.toLowerCase())) ?

                                                                                <DraggableItem dataUI={getDataUI()} key={item.title} index={index} draggableID={item.id}>
                                                                                    <DraggableItemActions dataUI={getDataUI()}>
                                                                                        <DraggableItemTitle dataUI={getDataUI()}>{item.title}</DraggableItemTitle>
                                                                                    </DraggableItemActions>

                                                                                </DraggableItem>
                                                                                :
                                                                                null

                                                                        )
                                                                    })}
                                                                </Droparea>
                                                            </DragNDropSection>
                                                        </VerticalTabContentItem>
                                                        <VerticalTabContentItem dataUI={getDataUI()}>Complience content</VerticalTabContentItem>
                                                        <VerticalTabContentItem dataUI={getDataUI()}>Criticized Asset Data content</VerticalTabContentItem>
                                                        <VerticalTabContentItem dataUI={getDataUI()}>Facility content</VerticalTabContentItem>
                                                        <VerticalTabContentItem dataUI={getDataUI()}>Facility Funds content</VerticalTabContentItem>
                                                    </VerticalTabContent>
                                                </VerticalTabs>
                                            </DynamicScrollableContainer>
                                        </Col>
                                        <Col xs={5}>

                                            <DynamicScrollableContainer dataUI={getDataUI()}>
                                                <DragNDropSection dataUI={getDataUI()} hasMultipleDropareas>
                                                    <DragNDropSectionHeader dataUI={getDataUI()}>
                                                        <Button dataUI={getDataUI()} className="mb-l" btnType="secondary" icon="fal fa-file-export">Upload</Button>
                                                    </DragNDropSectionHeader>
                                                    <DragNDropSectionScrollable dataUI={getDataUI()}>
                                                        <h2>Required Docs (3)</h2>
                                                        <Droparea
                                                            dataUI={getDataUI()}
                                                            title="Business Financials"
                                                            stopScrollable
                                                            dropareaID={DemoSBA.ASSIGNED_BUSINESS_FINANCIALS}
                                                            allowedItems={[DemoSBA.ASSIGNED_BUSINESS_FINANCIALS, DemoSBA.AVAILABLE_GENERAL_ITEMS, DemoSBA.AVAILABLE_COLLATERAL_ITEMS]}
                                                            callToActionText="Drop items here."
                                                            dragStartEvent={this.state.dragStartEvent}
                                                            dragUpdateEvent={this.state.dragUpdateEvent}
                                                        >
                                                            {this.state.assignedBusinessFinancials.map((item: any, index) => {
                                                                return (
                                                                    <DraggableItem dataUI={getDataUI()} key={item.title} index={index} draggableID={item.id}>
                                                                        <DraggableItemActions dataUI={getDataUI()}>
                                                                            <DraggableItemTitle dataUI={getDataUI()}>{item.title}</DraggableItemTitle>
                                                                        </DraggableItemActions>
                                                                        <DraggableItemActions dataUI={getDataUI()}>
                                                                            {item.title.toLowerCase().includes('completed') && <Badge dataUI={getDataUI()} type="info">Selected doc type</Badge>}
                                                                            <Tooltip title="Remove">
                                                                                <Button dataUI={getDataUI()} onClick={() => this.returnItemToAvailable(DemoSBA.ASSIGNED_BUSINESS_FINANCIALS, item.type, index)} btnType="icon" icon="fal fa-times" />
                                                                            </Tooltip>
                                                                        </DraggableItemActions>
                                                                    </DraggableItem>
                                                                )
                                                            })}
                                                        </Droparea>
                                                        <Droparea
                                                            dataUI={getDataUI()}
                                                            title="Borrower’s Certification"
                                                            stopScrollable
                                                            dropareaID={DemoSBA.ASSIGNED_BORROWERS_CERTIFICATION}
                                                            allowedItems={[DemoSBA.ASSIGNED_BORROWERS_CERTIFICATION, DemoSBA.AVAILABLE_GENERAL_ITEMS, DemoSBA.AVAILABLE_COLLATERAL_ITEMS]}
                                                            callToActionText="Drop items here."
                                                            dragStartEvent={this.state.dragStartEvent}
                                                            dragUpdateEvent={this.state.dragUpdateEvent}
                                                        >
                                                            {this.state.assignedBorrowersCertification.map((item: any, index) => {
                                                                return (
                                                                    <DraggableItem dataUI={getDataUI()} key={item.title} index={index} draggableID={item.id}>
                                                                        <DraggableItemActions dataUI={getDataUI()}>
                                                                            <DraggableItemTitle dataUI={getDataUI()}>{item.title}</DraggableItemTitle>
                                                                        </DraggableItemActions>
                                                                        <DraggableItemActions dataUI={getDataUI()}>
                                                                            {item.title.toLowerCase().includes('completed') && <Badge dataUI={getDataUI()} type="info">Selected doc type</Badge>}
                                                                            <Tooltip title="Remove">
                                                                                <Button dataUI={getDataUI()} onClick={() => this.returnItemToAvailable(DemoSBA.ASSIGNED_BORROWERS_CERTIFICATION, item.type, index)} btnType="icon" icon="fal fa-times" />
                                                                            </Tooltip>
                                                                        </DraggableItemActions>
                                                                    </DraggableItem>
                                                                )
                                                            })}
                                                        </Droparea>
                                                        <Droparea
                                                            dataUI={getDataUI()}
                                                            className="mb-l"
                                                            title="Signed SBA 912"
                                                            stopScrollable
                                                            dropareaID={DemoSBA.ASSIGNED_SIGNED}
                                                            allowedItems={[DemoSBA.ASSIGNED_SIGNED, DemoSBA.AVAILABLE_GENERAL_ITEMS, DemoSBA.AVAILABLE_COLLATERAL_ITEMS]}
                                                            callToActionText="Drop items here."
                                                            dragStartEvent={this.state.dragStartEvent}
                                                            dragUpdateEvent={this.state.dragUpdateEvent}
                                                        >
                                                            {this.state.assignedSigned.map((item: any, index) => {
                                                                return (

                                                                    <DraggableItem dataUI={getDataUI()} key={item.title} index={index} draggableID={item.id}>
                                                                        <DraggableItemActions dataUI={getDataUI()}>
                                                                            <DraggableItemTitle dataUI={getDataUI()}>{item.title}</DraggableItemTitle>
                                                                        </DraggableItemActions>
                                                                        <DraggableItemActions dataUI={getDataUI()}>
                                                                            {item.title.toLowerCase().includes('completed') && <Badge dataUI={getDataUI()} type="info">Selected doc type</Badge>}
                                                                            <Tooltip title="Remove">
                                                                                <Button dataUI={getDataUI()} onClick={() => this.returnItemToAvailable(DemoSBA.ASSIGNED_SIGNED, item.type, index)} btnType="icon" icon="fal fa-times" />
                                                                            </Tooltip>
                                                                        </DraggableItemActions>
                                                                    </DraggableItem>
                                                                )
                                                            })}
                                                        </Droparea>
                                                        <h2>Other attachments (1)</h2>
                                                        <Droparea
                                                            dataUI={getDataUI()}
                                                            title="Loan Agreement"
                                                            stopScrollable
                                                            dropareaID={DemoSBA.ASSIGNED_LOADN_AGREEMENT}
                                                            allowedItems={[DemoSBA.ASSIGNED_LOADN_AGREEMENT, DemoSBA.AVAILABLE_GENERAL_ITEMS, DemoSBA.AVAILABLE_COLLATERAL_ITEMS]}
                                                            callToActionText="Drop items here."
                                                            dragStartEvent={this.state.dragStartEvent}
                                                            dragUpdateEvent={this.state.dragUpdateEvent}
                                                        >
                                                            {this.state.assignedLoanAgreement.map((item: any, index) => {
                                                                return (
                                                                    <DraggableItem dataUI={getDataUI()} key={item.title} index={index} draggableID={item.id}>
                                                                        <DraggableItemActions dataUI={getDataUI()}>
                                                                            <DraggableItemTitle dataUI={getDataUI()}>{item.title}</DraggableItemTitle>
                                                                        </DraggableItemActions>
                                                                        <DraggableItemActions dataUI={getDataUI()}>
                                                                            {item.title.toLowerCase().includes('completed') && <Badge dataUI={getDataUI()} type="info">Selected doc type</Badge>}
                                                                            <Tooltip title="Remove">
                                                                                <Button dataUI={getDataUI()} onClick={() => this.returnItemToAvailable(DemoSBA.ASSIGNED_LOADN_AGREEMENT, item.type, index)} btnType="icon" icon="fal fa-times" />
                                                                            </Tooltip>
                                                                        </DraggableItemActions>
                                                                    </DraggableItem>
                                                                )
                                                            })}
                                                        </Droparea>
                                                    </DragNDropSectionScrollable>

                                                </DragNDropSection>
                                            </DynamicScrollableContainer>
                                        </Col>
                                    </Row>
                                </DragNDropContext>


                            </MainContainerScrollable>
                        </TabContentItem>
                    </TabContent>
                </Tabs>

                <Modal dataUI={getDataUI()} title="Add SBA Substatus" isVisible={this.state.modal1} size="S" onClose={() => this.setState({ modal1: false })}>
                    <Toolbar
                        dataUI={getDataUI()}
                        leftSide={
                            <>
                                <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-share-square" onClick={() => this.setState({ modal1: false })}>Submit</Button>
                                <Button dataUI={getDataUI()} icon="far fa-times-circle" btnType="secondary" onClick={() => this.setState({ modal1: false })}>Cancel</Button>
                            </>
                        }
                    />
                    <div className="d-flex mt-l">
                        <Label dataUI={getDataUI()}>Substatus</Label>
                        <Select
                            dataUI={getDataUI()}
                            className="ml-s width-full"
                            value="1"
                            options={[
                                { value: '1', label: ' ' },
                                { value: '2', label: 'Option 2' }
                            ]}
                        />
                    </div>
                </Modal>
                <Modal dataUI={getDataUI()} title="Add Underwriting Status" isVisible={this.state.modal2} size="S" onClose={() => this.setState({ modal2: false })}>
                    <Toolbar
                        dataUI={getDataUI()}
                        leftSide={
                            <>
                                <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-share-square" onClick={() => this.setState({ modal2: false })}>Submit</Button>
                                <Button dataUI={getDataUI()} icon="far fa-times-circle" btnType="secondary" onClick={() => this.setState({ modal2: false })}>Cancel</Button>
                            </>
                        }
                    />
                    <div className="d-flex mt-l">
                        <Label dataUI={getDataUI()} className="white-space-nowrap">Underwriting Status</Label>
                        <Select
                            dataUI={getDataUI()}
                            className="ml-s width-full"
                            value="1"
                            options={[
                                { value: '1', label: ' ' },
                                { value: '2', label: 'Option 2' }
                            ]}
                        />
                    </div>
                </Modal>
                <Modal dataUI={getDataUI()} title="History" isVisible={this.state.modal3} size="L" onClose={() => this.setState({ modal3: false })}>
                    <Tabs dataUI={getDataUI()} currentlyActiveTabIndex={this.state.currentlyActiveTabModal} onTabIndexChange={i => this.setState({ currentlyActiveTabModal: i })}>
                        <TabList dataUI={getDataUI()}>
                            <TabItem dataUI={getDataUI()}>
                                <TabItemTitle dataUI={getDataUI()}>Application</TabItemTitle>
                            </TabItem>
                            <TabItem dataUI={getDataUI()}>
                                <TabItemTitle dataUI={getDataUI()}>Attachments</TabItemTitle>
                            </TabItem>
                        </TabList>
                        <TabContent dataUI={getDataUI()}>
                            <TabContentItem dataUI={getDataUI()}>
                                <ModalScrollableContainer>
                                    <SimpleTableContainer dataUI={getDataUI()}>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th style={{ width: '80px' }}>Date/Time</th>
                                                    <th>User</th>
                                                    <th>Call</th>
                                                    <th style={{ width: '100px' }} className="text-center">Status</th>
                                                    <th>Message</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <Tooltip title="7/12/2021 10:14 AM"><span>7/12/2021</span></Tooltip>
                                                    </td>
                                                    <td>S. Swidler</td>
                                                    <td>Retrieve Authorization Document</td>
                                                    <td className="text-center"><Badge dataUI={getDataUI()} fullWidth type="success" icon="fal fa-check">Success</Badge></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Tooltip title="7/11/2021 9:15 AM"><span>7/11/2021</span></Tooltip>
                                                    </td>
                                                    <td>Samantha Swidler</td>
                                                    <td>Application Submission</td>
                                                    <td className="text-center"><Badge dataUI={getDataUI()} fullWidth type="success" icon="fal fa-check">Success</Badge></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <Tooltip title="7/10/2021 11:15 AM"><span>7/10/2021</span></Tooltip>
                                                    </td>
                                                    <td>Swidler</td>
                                                    <td>Application Submission</td>
                                                    <td>
                                                        <Badge dataUI={getDataUI()} className="mb-xs" fullWidth type="danger" icon="far fa-times-circle">Error</Badge>
                                                    </td>
                                                    <td>
                                                        <LinkWithIcon dataUI={getDataUI()} icon="fal fa-clipboard-list" iconPlace="left"><span onClick={() => this.setState({ modal4: true })}>5 Warnings and 0 Errors</span></LinkWithIcon>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </SimpleTableContainer>
                                </ModalScrollableContainer>
                            </TabContentItem>
                            <TabContentItem dataUI={getDataUI()}>
                                <SimpleTableContainer dataUI={getDataUI()}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>Upload</th>
                                                <th>Attachments</th>
                                                <th style={{ width: '120px' }} className="text-center">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>6/9/2020</td>
                                                <td>SBA</td>
                                                <td>Presentation Reports Appraisal Sprinkles Holdings</td>
                                                <td className="text-center"><Badge dataUI={getDataUI()} fullWidth type="info" icon="fal fa-clock">Pending</Badge></td>
                                            </tr>
                                            <tr>
                                                <td>6/9/2020</td>
                                                <td>SBA</td>
                                                <td>Presentation Report</td>
                                                <td className="text-center"><Badge dataUI={getDataUI()} fullWidth type="warning" icon="fal fa-exclamation-triangle">In progress</Badge></td>
                                            </tr>
                                            <tr>
                                                <td>6/9/2020</td>
                                                <td>SBA</td>
                                                <td>Appraisal</td>
                                                <td className="text-center"><Badge dataUI={getDataUI()} fullWidth type="success" icon="fal fa-check">Success</Badge></td>
                                            </tr>
                                            <tr>
                                                <td>5/9/2020</td>
                                                <td>SBA</td>
                                                <td>Suzy Cupcake Holdings Tax Return</td>
                                                <td className="text-center"><Badge dataUI={getDataUI()} fullWidth type="info" icon="fal fa-clock">Pending</Badge></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </SimpleTableContainer>
                            </TabContentItem>
                        </TabContent>
                    </Tabs>
                </Modal>

                <Modal dataUI={getDataUI()} title="Warnings and Errors" isVisible={this.state.modal4} size="L" onClose={() => this.setState({ modal4: false })}>
                    <ModalScrollableContainer>
                        <h2 className="mb-s">Errors</h2>
                        <p className="mb-xs">1. Loan Documents Missing 2 T1 - SBA Form 1919 - Borrower's Information (700)</p>
                        <p className="mb-xs">2. Loan Documents Missing T1 - SBA Form 1920 - Lender's Application (702)</p>
                        <p className="mb-xs">3. Loan Documents Missing T2- Lender's Credit Memo (725)</p>
                        <p className="mb-xs">4. Loan Documents Missing 2 T4 - SBA Form 413 - Personal Financial Statements</p>
                        <p className="mb-l">5. Loan Documents Missing T5 - Business Valuation (800)</p>
                        <h2 className="mb-s">Warnings</h2>
                        <p className="mb-xs">1. Loan Documents Missing 2 T1 - SBA Form 1919 - Borrower's Information (700)</p>
                        <p className="mb-xs">2. Loan Documents Missing T1 - SBA Form 1920 - Lender's Application (702)</p>
                        <p className="mb-xs">3. Loan Documents Missing T2- Lender's Credit Memo (725)</p>
                        <p className="mb-xs">4. Loan Documents Missing 2 T4 - SBA Form 413 - Personal Financial Statements</p>
                        <p>5. Loan Documents Missing T5 - Business Valuation (800)</p>
                    </ModalScrollableContainer>
                </Modal>

            </ReactAux>
        );
    }
}

export default DemoSBA;