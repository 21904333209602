import React, { useState } from 'react';

import {
    MainContainerHeader, ReactAux, ActionContainer, ActionContainerHeader, ActionContainerCrumbs,
    Button, Modal, SimpleTableContainer, MainContainerScrollable, ModalScrollableContainer,
    Toolbar, TabContent, TabContentItem, TabItem, TabItemTitle, TabList, Tabs, Label, Col, Row,
    Radio, Select, Input, ConfirmationDialog, Textarea, Tooltip,
} from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../helpers/helpers';

const DemoPolicyException = () => {

    const [addPolicyException, setAddPolicyException] = useState(false);
    const [editPolicyException, setEditPolicyException] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [tabIndexEdit, setTabIndexEdit] = useState(0);

    const [confirmationDialogShow1, setConfirmationDialogShow1] = useState(false);
    const [confirmationDialogShow2, setConfirmationDialogShow2] = useState(false);
    const [confirmationDialogShow3, setConfirmationDialogShow3] = useState(false);
    const [confirmationDialogShow4, setConfirmationDialogShow4] = useState(false);
    const [confirmationDialogShow5, setConfirmationDialogShow5] = useState(false);
    const [select1, setSelect1] = useState('1');
    const [select2, setSelect2] = useState('1');
    const [select3, setSelect3] = useState('1');
    const [select4, setSelect4] = useState('1');

    return (
        <ReactAux>
            <MainContainerHeader dataUI={getDataUI()}>
                <ActionContainer dataUI={getDataUI()} >
                    <ActionContainerHeader dataUI={getDataUI()}>
                        <ActionContainerCrumbs dataUI={getDataUI()} crumbs={['Policy Exception']} />
                    </ActionContainerHeader>
                </ActionContainer>
            </MainContainerHeader>

            <MainContainerScrollable>
                <Button dataUI={getDataUI()} className="mt-l mr-m" icon="fal fa-plus" onClick={() => setAddPolicyException(true)}>Add Policy Exception</Button>
                <Button dataUI={getDataUI()} className="mt-l" icon="fal fa-pen" onClick={() => setEditPolicyException(true)}>Edit Policy Exception</Button>
            </MainContainerScrollable>

            <Modal dataUI={getDataUI()} title="Add Policy Exception" size="M" isVisible={addPolicyException} onClose={() => setAddPolicyException(false)}>
                <Toolbar dataUI={getDataUI()} className="mb-m"
                    leftSide={
                        <>
                            <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-save" onClick={() => setAddPolicyException(false)}>Save</Button>
                            <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-save" onClick={() => setAddPolicyException(false)}>Save and Close</Button>
                            <Button dataUI={getDataUI()} icon="far fa-times-circle" btnType="secondary" onClick={() => setAddPolicyException(false)}>Cancel</Button>
                        </>
                    }
                />

                <Tabs dataUI={getDataUI()} onTabIndexChange={(index: number) => setTabIndex(index)} currentlyActiveTabIndex={tabIndex}>
                    <TabList dataUI={getDataUI()}>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Details</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Notes</TabItemTitle>
                        </TabItem>
                    </TabList>
                    <TabContent dataUI={getDataUI()}>
                        <TabContentItem dataUI={getDataUI()}>
                            <ModalScrollableContainer>
                                <Row className="mb-s">
                                    <Col xs={3}>
                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Exception</Label>
                                    </Col>
                                    <Col xs={6}>
                                        <Select
                                            dataUI={getDataUI()}
                                            options={[
                                                { value: '1', label: 'Option 1' },
                                                { value: '2', label: 'Option 2' },
                                            ]}
                                            value={select1}
                                            onChange={(obj: any) => {
                                                setSelect1(obj.value);
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs={3}>
                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Type</Label>
                                    </Col>
                                    <Col xs={6}>
                                        <Radio dataUI={getDataUI()} className="mr-s" name="policy-type" defaultChecked>Permanent</Radio>
                                        <Radio dataUI={getDataUI()} name="policy-type">Temporary</Radio>
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs={3}>
                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Required for</Label>
                                    </Col>
                                    <Col xs={6}>
                                        <Tooltip title="Add">
                                            <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-plus" />
                                        </Tooltip>
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs={3}>
                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Party</Label>
                                    </Col>
                                    <Col xs={6}>
                                        <Label dataUI={getDataUI()} className="justify-content-end text-right"></Label>
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs={3}>
                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Risk Rating</Label>
                                    </Col>
                                    <Col xs={6}>
                                        <Select
                                            dataUI={getDataUI()}
                                            options={[
                                                { value: '1', label: 'Option 1' },
                                                { value: '2', label: 'Option 2' },
                                            ]}
                                            value={select2}
                                            onChange={(obj: any) => {
                                                setSelect2(obj.value);
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs={3}>
                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Approved by</Label>
                                    </Col>
                                    <Col xs={6}>
                                        <Select
                                            dataUI={getDataUI()}
                                            options={[
                                                { value: '1', label: 'Option 1' },
                                                { value: '2', label: 'Option 2' },
                                            ]}
                                            value={select3}
                                            onChange={(obj: any) => {
                                                setSelect3(obj.value);
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs={3}>
                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Approved Date</Label>
                                    </Col>
                                    <Col xs={6}>
                                        <Input dataUI={getDataUI()} />
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs={3}>
                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Reason</Label>
                                    </Col>
                                    <Col xs={6}>
                                        <Textarea dataUI={getDataUI()} name="textarea-reason" />
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs={3}>
                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Exceeds Policy by</Label>
                                    </Col>
                                    <Col xs={6}>
                                        <Textarea dataUI={getDataUI()} name="textarea-exceeds" />
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs={3}>
                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Mitigation</Label>
                                    </Col>
                                    <Col xs={6}>
                                        <Textarea dataUI={getDataUI()} name="textarea-mitigation" />
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs={3}>
                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Status</Label>
                                    </Col>
                                    <Col xs={6}>
                                        <Select
                                            dataUI={getDataUI()}
                                            options={[
                                                { value: '1', label: 'Option 1' },
                                                { value: '2', label: 'Option 2' },
                                            ]}
                                            value={select4}
                                            onChange={(obj: any) => {
                                                setSelect4(obj.value);
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs={3}>
                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Open Date</Label>
                                    </Col>
                                    <Col xs={6}>
                                        <Input dataUI={getDataUI()} />
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs={3}>
                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Closed Date</Label>
                                    </Col>
                                    <Col xs={6}>
                                        <Input dataUI={getDataUI()} />
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs={3}>
                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Primary Officer</Label>
                                    </Col>
                                    <Col xs={6}>
                                        <Input dataUI={getDataUI()} />
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs={3}>
                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Secondary Officer</Label>
                                    </Col>
                                    <Col xs={6}>
                                        <Input dataUI={getDataUI()} />
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs={3}>
                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Branch</Label>
                                    </Col>
                                    <Col xs={6}>
                                        <Input dataUI={getDataUI()} />
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs={3}>
                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Last Status Change</Label>
                                    </Col>
                                    <Col xs={6}>
                                        <Label dataUI={getDataUI()} className="justify-content-end text-right"></Label>
                                    </Col>
                                </Row>
                            </ModalScrollableContainer>
                        </TabContentItem>
                        <TabContentItem dataUI={getDataUI()}>
                            <Toolbar dataUI={getDataUI()} className="mb-m"
                                leftSide={
                                    <Button dataUI={getDataUI()} icon="fal fa-plus">Add note</Button>
                                }
                            />
                            <ModalScrollableContainer>
                                <SimpleTableContainer dataUI={getDataUI()}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '26px' }}></th>
                                                <th style={{ width: '80px' }} className="text-right">Date</th>
                                                <th>User</th>
                                                <th>Note</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <ConfirmationDialog
                                                        dataUI={getDataUI()}
                                                        show={confirmationDialogShow2}
                                                        render={() => <>
                                                            <p className="text-center mb-m">Are you sure?</p>
                                                            <Toolbar dataUI={getDataUI()} leftSide={<>
                                                                <Button dataUI={getDataUI()} onClick={() => setConfirmationDialogShow2(false)} btnType="secondary" icon='fal fa-thumbs-up' className="mr-s" >Yes</Button>
                                                                <Button dataUI={getDataUI()} onClick={() => setConfirmationDialogShow2(false)} btnType="primary" icon='fal fa-thumbs-down' className="mr-s" >No</Button></>
                                                            } />
                                                        </>}
                                                        onClose={() => setConfirmationDialogShow2(false)}
                                                    >
                                                        <div>
                                                            <Tooltip title="Delete">
                                                                <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-trash" onClick={() => setConfirmationDialogShow2(true)} />
                                                            </Tooltip>
                                                        </div>
                                                    </ConfirmationDialog>
                                                </td>
                                                <td className="text-right">08/25/21</td>
                                                <td>Joro Georgiev</td>
                                                <td><a href="/" className="lv-link-styles">Important note regarding the policy exception.</a></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <ConfirmationDialog
                                                        dataUI={getDataUI()}
                                                        show={confirmationDialogShow3}
                                                        render={() => <>
                                                            <p className="text-center mb-m">Are you sure?</p>
                                                            <Toolbar dataUI={getDataUI()} leftSide={<>
                                                                <Button dataUI={getDataUI()} onClick={() => setConfirmationDialogShow3(false)} btnType="secondary" icon='fal fa-thumbs-up' className="mr-s" >Yes</Button>
                                                                <Button dataUI={getDataUI()} onClick={() => setConfirmationDialogShow3(false)} btnType="primary" icon='fal fa-thumbs-down' className="mr-s" >No</Button></>
                                                            } />
                                                        </>}
                                                        onClose={() => setConfirmationDialogShow3(false)}
                                                    >
                                                        <div>
                                                            <Tooltip title="Delete">
                                                                <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-trash" onClick={() => setConfirmationDialogShow3(true)} />
                                                            </Tooltip>
                                                        </div>
                                                    </ConfirmationDialog>
                                                </td>
                                                <td className="text-right">08/25/21</td>
                                                <td>Joro Georgiev</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <ConfirmationDialog
                                                        dataUI={getDataUI()}
                                                        show={confirmationDialogShow4}
                                                        render={() => <>
                                                            <p className="text-center mb-m">Are you sure?</p>
                                                            <Toolbar dataUI={getDataUI()} leftSide={<>
                                                                <Button dataUI={getDataUI()} onClick={() => setConfirmationDialogShow4(false)} btnType="secondary" icon='fal fa-thumbs-up' className="mr-s" >Yes</Button>
                                                                <Button dataUI={getDataUI()} onClick={() => setConfirmationDialogShow4(false)} btnType="primary" icon='fal fa-thumbs-down' className="mr-s" >No</Button></>
                                                            } />
                                                        </>}
                                                        onClose={() => setConfirmationDialogShow4(false)}
                                                    >
                                                        <div>
                                                            <Tooltip title="Delete">
                                                                <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-trash" onClick={() => setConfirmationDialogShow4(true)} />
                                                            </Tooltip>
                                                        </div>
                                                    </ConfirmationDialog>
                                                </td>
                                                <td className="text-right">08/25/21</td>
                                                <td>Joro Georgiev</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <ConfirmationDialog
                                                        dataUI={getDataUI()}
                                                        show={confirmationDialogShow5}
                                                        render={() => <>
                                                            <p className="text-center mb-m">Are you sure?</p>
                                                            <Toolbar dataUI={getDataUI()} leftSide={<>
                                                                <Button dataUI={getDataUI()} onClick={() => setConfirmationDialogShow5(false)} btnType="secondary" icon='fal fa-thumbs-up' className="mr-s" >Yes</Button>
                                                                <Button dataUI={getDataUI()} onClick={() => setConfirmationDialogShow5(false)} btnType="primary" icon='fal fa-thumbs-down' className="mr-s" >No</Button></>
                                                            } />
                                                        </>}
                                                        onClose={() => setConfirmationDialogShow5(false)}
                                                    >
                                                        <div>
                                                            <Tooltip title="Delete">
                                                                <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-trash" onClick={() => setConfirmationDialogShow5(true)} />
                                                            </Tooltip>
                                                        </div>
                                                    </ConfirmationDialog>
                                                </td>
                                                <td className="text-right">08/25/21</td>
                                                <td>Joro Georgiev</td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </SimpleTableContainer>
                            </ModalScrollableContainer>
                        </TabContentItem>
                    </TabContent>
                </Tabs>
            </Modal>

            <Modal dataUI={getDataUI()} title="Policy Exception Detail 2882" size="M" isVisible={editPolicyException} onClose={() => setEditPolicyException(false)}>
                <Toolbar dataUI={getDataUI()} className="mb-m"
                    leftSide={
                        <>
                            {isEditMode ? <>
                                <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-save" onClick={() => setIsEditMode(false)}>Save</Button>
                                <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-save" onClick={() => { setEditPolicyException(false); setIsEditMode(false); }}>Save and Close</Button>
                                <ConfirmationDialog
                                    dataUI={getDataUI()}
                                    show={confirmationDialogShow1}
                                    render={() => <>
                                        <p className="text-center mb-m">Are you sure?</p>
                                        <Toolbar dataUI={getDataUI()} leftSide={<>
                                            <Button dataUI={getDataUI()} onClick={() => setConfirmationDialogShow1(false)} btnType="secondary" icon='fal fa-thumbs-up' className="mr-s" >Yes</Button>
                                            <Button dataUI={getDataUI()} onClick={() => setConfirmationDialogShow1(false)} btnType="primary" icon='fal fa-thumbs-down' className="mr-s" >No</Button></>
                                        } />
                                    </>}
                                    onClose={() => setConfirmationDialogShow1(false)}
                                >
                                    <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-trash" btnType="secondary" onClick={() => setConfirmationDialogShow1(true)}>Delete</Button>
                                </ConfirmationDialog>
                                <Button dataUI={getDataUI()} icon="far fa-times-circle" btnType="secondary" onClick={() => setIsEditMode(false)}>Cancel</Button>
                            </>
                                :
                                <>
                                    <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-pen" onClick={() => setIsEditMode(true)}>Edit</Button>
                                    <Button dataUI={getDataUI()} icon="far fa-times-circle" btnType="secondary" onClick={() => setEditPolicyException(false)}>Close</Button>
                                </>}
                        </>
                    }
                />

                <Tabs dataUI={getDataUI()} onTabIndexChange={(index: number) => setTabIndexEdit(index)} currentlyActiveTabIndex={tabIndexEdit}>
                    <TabList dataUI={getDataUI()}>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Details</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Notes</TabItemTitle>
                        </TabItem>
                    </TabList>
                    <TabContent dataUI={getDataUI()}>
                        <TabContentItem dataUI={getDataUI()}>
                            <ModalScrollableContainer>
                                <Row className="mb-s">
                                    <Col xs={3}>
                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Exception</Label>
                                    </Col>
                                    <Col xs={6}>
                                        {isEditMode ? <Select
                                            dataUI={getDataUI()}
                                            options={[
                                                { value: '1', label: 'Option 1' },
                                                { value: '2', label: 'Option 2' },
                                            ]}
                                            value={select1}
                                            onChange={(obj: any) => {
                                                setSelect1(obj.value);
                                            }}
                                        /> : <Label dataUI={getDataUI()}><strong>Collateral County</strong></Label>}
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs={3}>
                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Type</Label>
                                    </Col>
                                    <Col xs={6}>
                                        <Radio dataUI={getDataUI()} className="mr-s" name="policy-type" defaultChecked readOnly={!isEditMode}>Permanent</Radio>
                                        <Radio dataUI={getDataUI()} name="policy-type" readOnly={!isEditMode}>Temporary</Radio>
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs={3}>
                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Required for</Label>
                                    </Col>
                                    <Col xs={6}>
                                        <Label dataUI={getDataUI()}><strong>Ruth Simpson - LR Pending</strong></Label>
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs={3}>
                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Party</Label>
                                    </Col>
                                    <Col xs={6}>
                                        <Label dataUI={getDataUI()}><strong>Ruth Simpson</strong></Label>
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs={3}>
                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Risk Rating</Label>
                                    </Col>
                                    <Col xs={6}>
                                        {isEditMode ? <Select
                                            dataUI={getDataUI()}
                                            options={[
                                                { value: '1', label: 'Option 1' },
                                                { value: '2', label: 'Option 2' },
                                            ]}
                                            value={select2}
                                            onChange={(obj: any) => {
                                                setSelect2(obj.value);
                                            }}
                                        /> : <Label dataUI={getDataUI()}><strong></strong></Label>}
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs={3}>
                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Approved by</Label>
                                    </Col>
                                    <Col xs={6}>
                                        {isEditMode ? <Select
                                            dataUI={getDataUI()}
                                            options={[
                                                { value: '1', label: 'Option 1' },
                                                { value: '2', label: 'Option 2' },
                                            ]}
                                            value={select3}
                                            onChange={(obj: any) => {
                                                setSelect3(obj.value);
                                            }}
                                        /> : <Label dataUI={getDataUI()}><strong></strong></Label>}
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs={3}>
                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Approved Date</Label>
                                    </Col>
                                    <Col xs={6}>
                                        {isEditMode ? <Input dataUI={getDataUI()} /> : <Label dataUI={getDataUI()}><strong></strong></Label>}
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs={3}>
                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Reason</Label>
                                    </Col>
                                    <Col xs={6}>
                                        {isEditMode ? <Textarea dataUI={getDataUI()} name="textarea-reason" /> : <Label dataUI={getDataUI()}><strong></strong></Label>}
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs={3}>
                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Exceeds Policy by</Label>
                                    </Col>
                                    <Col xs={6}>
                                        {isEditMode ? <Textarea dataUI={getDataUI()} name="textarea-exceeds" /> : <Label dataUI={getDataUI()}><strong></strong></Label>}
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs={3}>
                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Mitigation</Label>
                                    </Col>
                                    <Col xs={6}>
                                        {isEditMode ? <Textarea dataUI={getDataUI()} name="textarea-mitigation" /> : <Label dataUI={getDataUI()}><strong></strong></Label>}
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs={3}>
                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Status</Label>
                                    </Col>
                                    <Col xs={6}>
                                        {isEditMode ? <Select
                                            dataUI={getDataUI()}
                                            options={[
                                                { value: '1', label: 'Option 1' },
                                                { value: '2', label: 'Option 2' },
                                            ]}
                                            value={select4}
                                            onChange={(obj: any) => {
                                                setSelect4(obj.value);
                                            }}
                                        /> : <Label dataUI={getDataUI()}><strong>Closed</strong></Label>}
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs={3}>
                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Open Date</Label>
                                    </Col>
                                    <Col xs={6}>
                                        {isEditMode ? <Input dataUI={getDataUI()} /> : <Label dataUI={getDataUI()}><strong>9/3/2021</strong></Label>}
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs={3}>
                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Closed Date</Label>
                                    </Col>
                                    <Col xs={6}>
                                        {isEditMode ? <Input dataUI={getDataUI()} /> : <Label dataUI={getDataUI()}><strong></strong></Label>}
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs={3}>
                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Primary Officer</Label>
                                    </Col>
                                    <Col xs={6}>
                                        {isEditMode ? <Input dataUI={getDataUI()} /> : <Label dataUI={getDataUI()}><strong>Alonzo Mourning</strong></Label>}
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs={3}>
                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Secondary Officer</Label>
                                    </Col>
                                    <Col xs={6}>
                                        {isEditMode ? <Input dataUI={getDataUI()} /> : <Label dataUI={getDataUI()}><strong></strong></Label>}
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs={3}>
                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Branch</Label>
                                    </Col>
                                    <Col xs={6}>
                                        {isEditMode ? <Input dataUI={getDataUI()} /> : <Label dataUI={getDataUI()}><strong></strong></Label>}
                                    </Col>
                                </Row>
                                <Row className="mb-s">
                                    <Col xs={3}>
                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Last Status Change</Label>
                                    </Col>
                                    <Col xs={6}>
                                        <Label dataUI={getDataUI()} className="justify-content-end text-right"></Label>
                                    </Col>
                                </Row>
                            </ModalScrollableContainer>
                        </TabContentItem>
                        <TabContentItem dataUI={getDataUI()}>
                            {isEditMode && <Toolbar dataUI={getDataUI()} className="mb-m"
                                leftSide={
                                    <Button dataUI={getDataUI()} icon="fal fa-plus">Add note</Button>
                                }
                            />}
                            <ModalScrollableContainer>
                                <SimpleTableContainer dataUI={getDataUI()}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '26px' }}></th>
                                                <th style={{ width: '80px' }} className="text-right">Date</th>
                                                <th>User</th>
                                                <th>Note</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <ConfirmationDialog
                                                        dataUI={getDataUI()}
                                                        show={confirmationDialogShow2}
                                                        render={() => <>
                                                            <p className="text-center mb-m">Are you sure?</p>
                                                            <Toolbar dataUI={getDataUI()} leftSide={<>
                                                                <Button dataUI={getDataUI()} onClick={() => setConfirmationDialogShow2(false)} btnType="secondary" icon='fal fa-thumbs-up' className="mr-s" >Yes</Button>
                                                                <Button dataUI={getDataUI()} onClick={() => setConfirmationDialogShow2(false)} btnType="primary" icon='fal fa-thumbs-down' className="mr-s" >No</Button></>
                                                            } />
                                                        </>}
                                                        onClose={() => setConfirmationDialogShow2(false)}
                                                    >
                                                        <div>
                                                            <Tooltip title="Delete">
                                                                <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-trash" onClick={() => setConfirmationDialogShow2(true)} />
                                                            </Tooltip>
                                                        </div>
                                                    </ConfirmationDialog>
                                                </td>
                                                <td className="text-right">08/25/21</td>
                                                <td>Joro Georgiev</td>
                                                <td>
                                                    {isEditMode ? <a href="/" className="lv-link-styles">Important note regarding the policy exception.</a>
                                                        : 'Important note regarding the policy exception.'}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <ConfirmationDialog
                                                        dataUI={getDataUI()}
                                                        show={confirmationDialogShow3}
                                                        render={() => <>
                                                            <p className="text-center mb-m">Are you sure?</p>
                                                            <Toolbar dataUI={getDataUI()} leftSide={<>
                                                                <Button dataUI={getDataUI()} onClick={() => setConfirmationDialogShow3(false)} btnType="secondary" icon='fal fa-thumbs-up' className="mr-s" >Yes</Button>
                                                                <Button dataUI={getDataUI()} onClick={() => setConfirmationDialogShow3(false)} btnType="primary" icon='fal fa-thumbs-down' className="mr-s" >No</Button></>
                                                            } />
                                                        </>}
                                                        onClose={() => setConfirmationDialogShow3(false)}
                                                    >
                                                        <div>
                                                            <Tooltip title="Delete">
                                                                <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-trash" onClick={() => setConfirmationDialogShow3(true)} />
                                                            </Tooltip>
                                                        </div>
                                                    </ConfirmationDialog>
                                                </td>
                                                <td className="text-right">08/25/21</td>
                                                <td>Joro Georgiev</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <ConfirmationDialog
                                                        dataUI={getDataUI()}
                                                        show={confirmationDialogShow4}
                                                        render={() => <>
                                                            <p className="text-center mb-m">Are you sure?</p>
                                                            <Toolbar dataUI={getDataUI()} leftSide={<>
                                                                <Button dataUI={getDataUI()} onClick={() => setConfirmationDialogShow4(false)} btnType="secondary" icon='fal fa-thumbs-up' className="mr-s" >Yes</Button>
                                                                <Button dataUI={getDataUI()} onClick={() => setConfirmationDialogShow4(false)} btnType="primary" icon='fal fa-thumbs-down' className="mr-s" >No</Button></>
                                                            } />
                                                        </>}
                                                        onClose={() => setConfirmationDialogShow4(false)}
                                                    >
                                                        <div>
                                                            <Tooltip title="Delete">
                                                                <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-trash" onClick={() => setConfirmationDialogShow4(true)} />
                                                            </Tooltip>
                                                        </div>
                                                    </ConfirmationDialog>
                                                </td>
                                                <td className="text-right">08/25/21</td>
                                                <td>Joro Georgiev</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <ConfirmationDialog
                                                        dataUI={getDataUI()}
                                                        show={confirmationDialogShow5}
                                                        render={() => <>
                                                            <p className="text-center mb-m">Are you sure?</p>
                                                            <Toolbar dataUI={getDataUI()} leftSide={<>
                                                                <Button dataUI={getDataUI()} onClick={() => setConfirmationDialogShow5(false)} btnType="secondary" icon='fal fa-thumbs-up' className="mr-s" >Yes</Button>
                                                                <Button dataUI={getDataUI()} onClick={() => setConfirmationDialogShow5(false)} btnType="primary" icon='fal fa-thumbs-down' className="mr-s" >No</Button></>
                                                            } />
                                                        </>}
                                                        onClose={() => setConfirmationDialogShow5(false)}
                                                    >
                                                        <div>
                                                            <Tooltip title="Delete">
                                                                <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-trash" onClick={() => setConfirmationDialogShow5(true)} />
                                                            </Tooltip>
                                                        </div>
                                                    </ConfirmationDialog>
                                                </td>
                                                <td className="text-right">08/25/21</td>
                                                <td>Joro Georgiev</td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </SimpleTableContainer>
                            </ModalScrollableContainer>
                        </TabContentItem>
                    </TabContent>
                </Tabs>
            </Modal>
        </ReactAux>
    );
}

export default DemoPolicyException;