import React from 'react';
import Highlight from 'react-highlight';
import { Stack, Badge } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const StackPage: React.FC = () => {
    return (
        <div>
            <h1 className="mb-s">Stack</h1>
            <p className="mb-l">
                The Stack component manages layout of immediate children along the vertical or horizontal axis with spacing between each child
            </p>

            <h3 className="mb-s">Direction</h3>
            <p className="mb-l">
                There are two types of direction : 'row' or 'column'. Default is 'row'.
            </p>

            <div className="mb-l">
                <p className="mb-s">
                    Exampe row
                </p>
                <Stack gap='S' className='mb-l'>
                    <Badge fullWidth type="info" dataUI={getDataUI()}>Basic</Badge>
                    <Badge fullWidth type="info" dataUI={getDataUI()}>Basic</Badge>
                    <Badge fullWidth type="info" dataUI={getDataUI()}>Basic</Badge>
                </Stack>

                <p className="mb-s">
                    Example column
                </p>
                <Stack gap='S' direction='column'>
                    <Badge fullWidth type="info" dataUI={getDataUI()}>Basic</Badge>
                    <Badge fullWidth type="info" dataUI={getDataUI()}>Basic</Badge>
                    <Badge fullWidth type="info" dataUI={getDataUI()}>Basic</Badge>
                </Stack>
            </div>

            <h3 className="mb-s">Gap</h3>
            <p className="mb-l">
                Gap options are : '0',  'XS', 'S', 'M','L', 'XL', 'XXL'.
            </p>

            <div className="mb-l">
                <Stack gap='XS' direction='column' className='mb-m'>
                    <Badge fullWidth type="info" dataUI={getDataUI()}>Basic</Badge>
                    <Badge fullWidth type="info" dataUI={getDataUI()}>Basic</Badge>
                    <Badge fullWidth type="info" dataUI={getDataUI()}>Basic</Badge>
                </Stack>
                <Stack gap='S' direction='column' className='mb-m'>
                    <Badge fullWidth type="info" dataUI={getDataUI()}>Basic</Badge>
                    <Badge fullWidth type="info" dataUI={getDataUI()}>Basic</Badge>
                    <Badge fullWidth type="info" dataUI={getDataUI()}>Basic</Badge>
                </Stack>
                <Stack gap='M' direction='column' className='mb-m'>
                    <Badge fullWidth type="info" dataUI={getDataUI()}>Basic</Badge>
                    <Badge fullWidth type="info" dataUI={getDataUI()}>Basic</Badge>
                    <Badge fullWidth type="info" dataUI={getDataUI()}>Basic</Badge>
                </Stack>
            </div>


            <Highlight className="React">
                {
                    `
import React, { useState } from 'react';

import { Stack, Badge } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const Example = () => {
    return (
        <Stack gap='S'>
            <Badge fullWidth type="info" dataUI={getDataUI()}>Basic</Badge>
            <Badge fullWidth type="info" dataUI={getDataUI()}>Basic</Badge>
            <Badge fullWidth type="info" dataUI={getDataUI()}>Basic</Badge>
        </Stack>

        <Stack gap='S' direction='column'>
            <Badge fullWidth type="info" dataUI={getDataUI()}>Basic</Badge>
            <Badge fullWidth type="info" dataUI={getDataUI()}>Basic</Badge>
            <Badge fullWidth type="info" dataUI={getDataUI()}>Basic</Badge>
        </Stack>
    );
}

export default Example;
        `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>direction</td>
                        <td>"row" | "column"</td>
                        <td>"row"</td>
                        <td>Sets component direction.</td>
                    </tr>
                    <tr>
                        <td>gap</td>
                        <td>"0" | "XS" | "S" | "M" |"L" | "XL" | "XXL"</td>
                        <td>"0"</td>
                        <td>Defines the gap between immediate children.</td>
                    </tr>
                    <tr>
                        <td>all native div attributes</td>
                        <td className="text-center">-</td>
                        <td className="text-center">-</td>
                        <td>You can use all native button attributes like: "class", "style", etc.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
};

export default StackPage;
