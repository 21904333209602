import React from 'react';
import Highlight from 'react-highlight';
import { Toast, Button, ToastContainer } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const notifyInfo = () => Toast.info({ content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.', title: 'Info title' });
const notifySuccess = () => Toast.success({ content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.', title: 'Success title' });
const notifyWarning = () => Toast.warning({ content: 'Warning message here!', title: 'Warning title' });
const notifyDanger = () => Toast.danger({ content: 'Danger message here!', title: 'Danger title' });
const notifySuccessTime = () => Toast.success({ content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.', options: { autoClose: false } });

const ToastPage: React.FC = () => {
    return (
        <div>
            <h1 className="mb-s">Toast</h1>
            <p className="mb-l">Toast are often used to show messages on the screen.
                <br /> Toast component use "react-toastify" library.
                Check demo <a href="https://fkhadra.github.io/react-toastify/" target="_blank" rel="noopener noreferrer">here</a> and documentation <a href="https://github.com/fkhadra/react-toastify#readme" target="_blank" rel="noopener noreferrer">here</a>.
                <br /> Remember to render the <b>ToastContainer</b> once in application tree.
                <br /> There are 4 types: "info", "success", "warning", "danger".
            </p>

            <ToastContainer dataUI={getDataUI()} />

            <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-check" onClick={notifyInfo}>Info toast</Button>
            <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-check" onClick={notifySuccess}>Success toast</Button>
            <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-check" onClick={notifyWarning}>Warning toast</Button>
            <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-check" onClick={notifyDanger}>Danger toast</Button>
            <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-check" onClick={notifySuccessTime}>Success toast with custom props</Button>

            <Highlight className="React">
                {
                    `
import React from 'react';
import { Toast, ToastContainer, Button } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

// Methods example
const notifyInfo = () => Toast.info({ content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.', title: 'Info title' });
const notifySuccess = () => Toast.success({ content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.', title: 'Success title' });
const notifyWarning = () => Toast.warning({ content: 'Warning message here!', title: 'Warning title' });
const notifyDanger = () => Toast.danger({ content: 'Danger message here!', title: 'Danger title' });
const notifySuccessTime = () => Toast.success({ content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.', options: { autoClose: false } });

const Example: React.FC = (props) => {
    return (
        <div>
            // Initialize ToastContainer. Call it once. At the root of app is the best place.
            <ToastContainer dataUI={getDataUI()} />

            <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-check" onClick={notifyInfo}>Info toast</Button>
            <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-check" onClick={notifySuccess}>Success toast</Button>
            <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-check" onClick={notifyWarning}>Warning toast</Button>
            <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-check" onClick={notifyDanger}>Danger toast</Button>
            <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-check" onClick={notifySuccessTime}>Success toast with custom props</Button>
        </div>
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">API</h2>

            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={3}>Methods</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Props</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>info</td>
                        <td>object with: "content", "title", "options"</td>
                        <td>Display a info toast.</td>
                    </tr>
                    <tr>
                        <td>success</td>
                        <td>object with: "content", "title", "options"</td>
                        <td>Display a success toast.</td>
                    </tr>
                    <tr>
                        <td>warning</td>
                        <td>object with: "content", "title", "options"</td>
                        <td>Display a warning toast.</td>
                    </tr>
                    <tr>
                        <td>danger</td>
                        <td>object with: "content", "title", "options"</td>
                        <td>Display a danger toast.</td>
                    </tr>
                </tbody>
            </table>

            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Methods Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Parameter</strong>
                        </td>
                        <td>
                            <strong>Type</strong>
                        </td>
                        <td>
                            <strong>Required</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>content</td>
                        <td>string or React Element</td>
                        <td>Yes</td>
                        <td>Element that will be displayed.</td>
                    </tr>
                    <tr>
                        <td>title</td>
                        <td>string</td>
                        <td>No</td>
                        <td>Display the title.</td>
                    </tr>
                    <tr>
                        <td>options</td>
                        <td>object</td>
                        <td>No</td>
                        <td><a href="https://github.com/fkhadra/react-toastify#api" target="_blank" rel="noopener noreferrer">Options list.</a></td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default ToastPage;
