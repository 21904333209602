import React from 'react';
import { Link } from 'react-router-dom';
import Highlight from 'react-highlight';
import { Badge } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';


const BadgePage: React.FC = () => {
    return (
        <div>
            <h1 className="mb-s">Badge</h1>
            <p className="mb-s">Badges are used to display short text content with icon in nine status

                types: "info", "success", "warning", "danger", "poor", "fair", "good", "very-good" and "excellent"</p>
            <p className="mb-s">By default, Badge <strong>width is limited to 100px</strong> and if the text content
                is bigger, it will be cut with ellipsis and a tooltip will be shown. Optionally, Badge elements can be
                configured to expand to full width.</p>

            <p className="mb-l">When the Badge is used in <Link to="/docs/drag-n-drop">DraggableItem</Link> width is limited to 200px.</p>

            <div className="mb-m">
                <Badge type="info" icon="fal fa-file-upload" className="mr-s" dataUI={getDataUI()}>Basic</Badge>
                <Badge type="success" icon="fal fa-check" className="mr-s" dataUI={getDataUI()}>Success</Badge>
                <Badge type="warning" icon="fal fa-exclamation-triangle" className="mr-s" dataUI={getDataUI()}>Warning</Badge>
                <Badge type="danger" icon="far fa-times-circle" dataUI={getDataUI()}>Danger</Badge>
            </div>
            <div className="mb-m">
                <Badge type="poor" className="mr-s" dataUI={getDataUI()}>Poor</Badge>
                <Badge type="fair" className="mr-s" dataUI={getDataUI()}>Fair</Badge>
                <Badge type="good" className="mr-s" dataUI={getDataUI()}>Good</Badge>
                <Badge type="very-good" className="mr-s" dataUI={getDataUI()}>Very Good</Badge>
                <Badge type="excellent" dataUI={getDataUI()}>Excellent</Badge>
            </div>
            <div className="mb-m">
                <Badge type="info" icon="fal fa-info-circle" fullWidth dataUI={getDataUI()}>Full width badge</Badge>
            </div>
            <div className="mb-m">
                <Badge type="danger" noMaxWidth icon="far fa-times-circle" dataUI={getDataUI()}>No max width badge</Badge>
            </div>
            <div className="mb-m">
                <Badge type="info" icon="fal fa-clock" className="mr-s" dataUI={getDataUI()}>Pending</Badge>
                <Badge type="info" icon="fal fa-spinner" className="mr-s" dataUI={getDataUI()}>Processing</Badge>
                <Badge type="danger" icon="far fa-times-circle" className="mr-s" dataUI={getDataUI()}>Failed</Badge>
                <Badge type="danger" icon="far fa-times-circle" className="mr-s" dataUI={getDataUI()}>Canceled</Badge>
                <Badge type="warning" icon="fal fa-exclamation-triangle" dataUI={getDataUI()}>Needs Attention</Badge>
                <br />
                <br />
                <Badge type="success" icon="fal fa-check" className="mr-s" dataUI={getDataUI()}>Recognised successful</Badge>
                <Badge type="info" icon="fal fa-lock" className="mr-s" dataUI={getDataUI()}>File Protected</Badge>
                <Badge type="success" icon="fal fa-badge-check" className="mr-s" dataUI={getDataUI()}>Recognised</Badge>
                <Badge type="info" icon="fal fa-file-search" className="mr-s" dataUI={getDataUI()}>Sent to Review</Badge>
                <Badge type="success" icon="custom-icon-file-check-s" dataUI={getDataUI()}>Review Complete</Badge>
            </div>

            <Highlight className="React">
                {
                    `
import React from 'react';
import { Badge } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const Example: React.FC = (props) => {
    return (
      <div>
        <Badge type="info" icon="fal fa-file-upload" className="mr-s" dataUI={getDataUI()}>Basic</Badge>
        <Badge type="success" icon="fal fa-check" className="mr-s" dataUI={getDataUI()}>Success</Badge>
        <Badge type="warning" icon="fal fa-exclamation-triangle" className="mr-s" dataUI={getDataUI()}>Warning</Badge>
        <Badge type="danger" icon="far fa-times-circle" dataUI={getDataUI()}>Danger</Badge>

        <Badge type="poor" className="mr-s" dataUI={getDataUI()}>Poor</Badge>
        <Badge type="fair" className="mr-s" dataUI={getDataUI()}>Fair</Badge>
        <Badge type="good" className="mr-s" dataUI={getDataUI()}>Good</Badge>
        <Badge type="very-good" className="mr-s" dataUI={getDataUI()}>Very Good</Badge>
        <Badge type="excellent" dataUI={getDataUI()}>Excellent</Badge>
    
        <Badge type="info" icon="fal fa-info-circle" fullWidth dataUI={getDataUI()}>Full width badge</Badge>
    
        <Badge type="danger" noMaxWidth icon="far fa-times-circle" dataUI={getDataUI()}>No max width badge</Badge>

        <Badge type="info" icon="fal fa-clock" className="mr-s" dataUI={getDataUI()}>Pending</Badge>
        <Badge type="info" icon="fal fa-spinner" className="mr-s" dataUI={getDataUI()}>Processing</Badge>
        <Badge type="danger" icon="far fa-times-circle" className="mr-s" dataUI={getDataUI()}>Failed</Badge>
        <Badge type="danger" icon="far fa-times-circle" className="mr-s" dataUI={getDataUI()}>Canceled</Badge>
        <Badge type="warning" icon="fal fa-exclamation-triangle" dataUI={getDataUI()}>Needs Attention</Badge>
        
        <Badge type="success" icon="fal fa-check" className="mr-s" dataUI={getDataUI()}>Recognised successful</Badge>
        <Badge type="info" icon="fal fa-lock" className="mr-s" dataUI={getDataUI()}>File Protected</Badge>
        <Badge type="success" icon="fal fa-badge-check" className="mr-s" dataUI={getDataUI()}>Recognised</Badge>
        <Badge type="info" icon="fal fa-file-search" className="mr-s" dataUI={getDataUI()}>Sent to Review</Badge>
        <Badge type="success" icon="custom-icon-file-check-s" dataUI={getDataUI()}>Review Complete</Badge>

      </div>
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>type</td>
                        <td>"info", "success", "warning", "danger", "poor", "fair", "good", "very-good", "excellent"</td>
                        <td className="text-center">-</td>
                        <td>Sets the status type of the Badge. Should be passed explicitly.</td>
                    </tr>
                    <tr>
                        <td>icon</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets the icon of the Badge. Should be passed explicitly.</td>
                    </tr>
                    <tr>
                        <td>fullWidth</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Expands the Badge element to the width of its parent element.</td>
                    </tr>
                    <tr>
                        <td>noMaxWidth</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Removes the Badge max width and take content width.</td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default BadgePage;
