import React, { useState } from 'react';

import {
    MainContainerHeader, ActionContainer, ActionContainerHeader, ActionContainerCrumbs,
    ActionSettings, LinkWithIcon, ResizableSections, Radio, Toolbar, Label, Select, Button,
    Input, GridContainer, Grid, GridColumn, MainContainerScrollable, Tabs, TabList, TabItem,
    TabItemTitle, TabContent, TabContentItem, Row, Col, Tooltip, Textarea, InfoBox,
    DragNDropContext, DynamicScrollableContainer, DragNDropSection, DragNDropSectionHeader, Droparea,
    DraggableItem, DraggableItemActions, DraggableItemTitle, DragNDropUtils,
} from '@jkhy/vsg-loanvantage-design-system';

import products from './KendoTable/products.json';
import { getDataUI } from '../helpers/helpers';

const defaultGeneralAvailable = [
    { title: 'Analysis', id: 'item-1' }, { title: 'Branch', id: 'item-2' }, { title: 'CD Completed', id: 'item-3' }, { title: 'CD Required', id: 'item-4' },
    { title: 'CBA Completed', id: 'item-5' }, { title: 'CBA Required', id: 'item-6' }, { title: 'HDMA Reportable Closing Percent', id: 'item-7' }, { title: 'Competition', id: 'item-8' },
    { title: 'Create Date', id: 'item-9' }, { title: 'Days in Decisioning', id: 'item-10' }, { title: 'Days in Last Review', id: 'item-11' }, { title: 'General item 1', id: 'item-12' },
    { title: 'General item 2', id: 'item-13' }, { title: 'General item 3', id: 'item-14' }, { title: 'General item 4', id: 'item-15' }, { title: 'General item 5', id: 'item-16' }, { title: 'General item 6', id: 'item-17' }
];

const DemoResizableSections = () => {

    const [tabIndex, setTabIndex] = useState(0);
    const [select1, setSelect1] = useState('1');
    const [select2, setSelect2] = useState('1');
    const [select3, setSelect3] = useState('1');
    const [select4, setSelect4] = useState('1');

    const AVAILABLE_SECTION_NAME = 'available';
    const ASSIGNED_SECTION_NAME = 'assigned';

    const [availableItems, setAvailableItems] = useState<{ title: string, id: string, type: string }[]>(defaultGeneralAvailable.map(e => { return { ...e, type: AVAILABLE_SECTION_NAME } }))
    const [assignedItems, setAssignedItems] = useState<{ title: string, id: string, type: string }[]>([])
    const [dragStartEvent, setDragStartEvent] = useState<Event | null>(null);
    const [dragUpdateEvent, setDragUpdateEvent] = useState<Event | null>(null);
    const [availableSearch, setAvailableSearch] = useState<string>('');
    const [assignedSearch, setAssignedSearch] = useState<string>('');

    function getList(listId: string) {
        if (listId === AVAILABLE_SECTION_NAME) {
            return availableItems;
        } else if (listId === ASSIGNED_SECTION_NAME) {
            return assignedItems;
        }
        return null;
    }

    function handleDragStart(event: any) {
        setDragStartEvent(event);
        setDragUpdateEvent(null);
    }

    function handleDragUpdate(event: any) {
        setDragStartEvent(null);
        setDragUpdateEvent(event);
    }

    function onDragEnd(result: any) {
        const { source, destination } = result;
        if (!result.destination) {
            setDragStartEvent(null);
            setDragUpdateEvent(null);
            return;
        }

        if (source.droppableId === destination.droppableId) {
            if (source.droppableId === AVAILABLE_SECTION_NAME) {
                const items = DragNDropUtils.reorderList(
                    availableItems,
                    source.index,
                    destination.index
                );

                setAvailableItems(items);
            } else if (source.droppableId === ASSIGNED_SECTION_NAME) {
                const items = DragNDropUtils.reorderList(
                    assignedItems,
                    source.index,
                    destination.index
                );

                setAssignedItems(items);
            }
        } else {
            const result: any = DragNDropUtils.moveBetweenLists(
                getList(source.droppableId) || [],
                getList(destination.droppableId) || [],
                source.index,
                destination.index
            );

            if (source.droppableId === AVAILABLE_SECTION_NAME) {
                setAvailableItems(result[0]);
                setAssignedItems(result[1]);
            } else if (source.droppableId === ASSIGNED_SECTION_NAME) {
                setAssignedItems(result[0]);
                setAvailableItems(result[1]);
            }
        }

        setDragStartEvent(null);
        setDragUpdateEvent(null);
    }

    function setSearchValue(sectionName: string, value: string) {
        switch (sectionName) {
            case AVAILABLE_SECTION_NAME:
                setAvailableSearch(value);
                break;
            case ASSIGNED_SECTION_NAME:
                setAssignedSearch(value);
                break;
            default:
                return;
        }
    }

    function returnItemToAvailable(index: number) {
        const assignedClone = Array.from(assignedItems);
        const availableClone = Array.from(availableItems);
        const [removed] = assignedClone.splice(index, 1);
        availableClone.push(removed);

        setAvailableItems(availableClone);
        setAssignedItems(assignedClone);
    }

    function addAllToAssigned() {
        let assignedClone = Array.from(assignedItems);
        let availableClone = Array.from(availableItems);
        assignedClone = [...assignedClone, ...availableClone];
        availableClone = [];

        setAvailableItems(availableClone);
        setAssignedItems(assignedClone);
    }

    function removeAllAssigned() {
        let availableClone = Array.from(availableItems);
        let assignedClone = Array.from(assignedItems);
        for (const item of assignedClone) {
            availableClone.push(item);
        }

        setAvailableItems(availableClone);
        setAssignedItems([]);
    }

    function moveAvailableItemToAssigned(index: number) {
        const assignedClone = Array.from(assignedItems);
        const availableClone = Array.from(availableItems);
        const [removed] = availableClone.splice(index, 1);
        assignedClone.push(removed);

        setAvailableItems(availableClone);
        setAssignedItems(assignedClone);
    }

    return (
        <>
            <MainContainerHeader dataUI={getDataUI()}>
                <ActionContainer dataUI={getDataUI()}>
                    <ActionContainerHeader dataUI={getDataUI()}>
                        <ActionContainerCrumbs dataUI={getDataUI()} crumbs={[<a href="/">Home</a>, <a href="/">Page Section</a>, 'Page Name']} />
                        <ActionSettings dataUI={getDataUI()}>
                            <LinkWithIcon dataUI={getDataUI()} icon="fal fa-info-circle">
                                <a href="/">About Page</a>
                            </LinkWithIcon>
                        </ActionSettings>
                    </ActionContainerHeader>
                </ActionContainer>
            </MainContainerHeader>

            <ResizableSections
                className="mt-s"
                dataUI={getDataUI()}
                leftDefaultPercent={40}
                minPercentLeft={22}
                maxPercentLeft={77}
                // isResizable={false}
                // isCollapsable={false}
                onChange={(value: any) => {
                    console.log(value, ' ~~~onChange out');
                }}
                leftSection={
                    <>
                        <Toolbar
                            dataUI={getDataUI()}
                            className="mb-s"
                            leftSide={(
                                <>
                                    <div className="d-flex flex-wrap mb-s width-full">
                                        <Button dataUI={getDataUI()} className="mr-s mb-s" btnType="secondary" icon="fal fa-plus">
                                            Add Wizard
                                        </Button>
                                        <Input dataUI={getDataUI()} className="mb-s" placeholder="Search" name="input-icon" icon='fal fa-search' />
                                    </div>
                                    <div className="d-flex flex-wrap">
                                        <Label dataUI={getDataUI()} className="mr-s"><strong>Show:</strong></Label>
                                        <Radio dataUI={getDataUI()} className="mr-s" name="radio-1">Active</Radio>
                                        <Radio dataUI={getDataUI()} className="mr-s" name="radio-1">Inactive</Radio>
                                        <Radio dataUI={getDataUI()} className="mr-s" name="radio-1">All</Radio>
                                        <Label dataUI={getDataUI()} className='mr-s'><strong>Type</strong></Label>
                                        <Select
                                            dataUI={getDataUI()}
                                            className="width-s"
                                            value='1'
                                            options={[{ value: "1", label: "Commetcial" }, { value: "2", label: "Other" }]}
                                        />
                                    </div>
                                </>
                            )}
                        />

                        <MainContainerScrollable>
                            <GridContainer dataUI={getDataUI()} style={{ height: '100%', maxHeight: '100%' }}>
                                <Grid
                                    style={{ height: '100%', maxHeight: '100%' }}
                                    data={products}
                                >
                                    <GridColumn field="ProductName" width="120px" title="Product Name" />
                                    <GridColumn field="QuantityPerUnit" width="120px" />
                                    <GridColumn field="QuantityPerUnit" width="120px" />
                                    <GridColumn field="UnitPrice" width="120px" title="Unit Price" />
                                </Grid>
                            </GridContainer>
                        </MainContainerScrollable>
                    </>
                }
                rightSection={
                    <>
                        <Toolbar
                            dataUI={getDataUI()}
                            className="mb-s"
                            leftSide={(
                                <>
                                    <h2 className="mr-s">New Wizard Loan</h2>
                                    <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-save">
                                        Save
                                    </Button>
                                    <Button dataUI={getDataUI()} btnType="secondary" icon="far fa-times-circle">
                                        Cancel
                                    </Button>
                                </>
                            )}
                        />

                        <Tabs dataUI={getDataUI()} onTabIndexChange={(index: number) => setTabIndex(index)} currentlyActiveTabIndex={tabIndex}>
                            <TabList dataUI={getDataUI()}>
                                <TabItem dataUI={getDataUI()}>
                                    <TabItemTitle dataUI={getDataUI()}>Setup</TabItemTitle>
                                </TabItem>
                                <TabItem dataUI={getDataUI()}>
                                    <TabItemTitle dataUI={getDataUI()}>Party Information</TabItemTitle>
                                </TabItem>
                                <TabItem dataUI={getDataUI()}>
                                    <TabItemTitle dataUI={getDataUI()}>Loan Request</TabItemTitle>
                                </TabItem>
                                <TabItem dataUI={getDataUI()}>
                                    <TabItemTitle dataUI={getDataUI()}>Complience</TabItemTitle>
                                </TabItem>
                                <TabItem dataUI={getDataUI()}>
                                    <TabItemTitle dataUI={getDataUI()}>Collateral</TabItemTitle>
                                </TabItem>
                                <TabItem dataUI={getDataUI()}>
                                    <TabItemTitle dataUI={getDataUI()}>Role Assignment</TabItemTitle>
                                </TabItem>
                            </TabList>
                            <TabContent dataUI={getDataUI()}>
                                <TabContentItem dataUI={getDataUI()}>
                                    <MainContainerScrollable>
                                        <Row className="mb-s">
                                            <Col xs={3}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right">Exception</Label>
                                            </Col>
                                            <Col xs={6}>
                                                <Select
                                                    dataUI={getDataUI()}
                                                    options={[
                                                        { value: '1', label: 'Option 1' },
                                                        { value: '2', label: 'Option 2' },
                                                    ]}
                                                    value={select1}
                                                    onChange={(obj: any) => {
                                                        setSelect1(obj.value);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={3}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right">Type</Label>
                                            </Col>
                                            <Col xs={6}>
                                                <Radio dataUI={getDataUI()} className="mr-s" name="policy-type" defaultChecked>Permanent</Radio>
                                                <Radio dataUI={getDataUI()} name="policy-type">Temporary</Radio>
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={3}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right">Required for</Label>
                                            </Col>
                                            <Col xs={6}>
                                                <Tooltip title="Add">
                                                    <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-plus" />
                                                </Tooltip>
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={3}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right">Party</Label>
                                            </Col>
                                            <Col xs={6}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right"></Label>
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={3}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right">Risk Rating</Label>
                                            </Col>
                                            <Col xs={6}>
                                                <Select
                                                    dataUI={getDataUI()}
                                                    options={[
                                                        { value: '1', label: 'Option 1' },
                                                        { value: '2', label: 'Option 2' },
                                                    ]}
                                                    value={select2}
                                                    onChange={(obj: any) => {
                                                        setSelect2(obj.value);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={3}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right">Approved by</Label>
                                            </Col>
                                            <Col xs={6}>
                                                <Select
                                                    dataUI={getDataUI()}
                                                    options={[
                                                        { value: '1', label: 'Option 1' },
                                                        { value: '2', label: 'Option 2' },
                                                    ]}
                                                    value={select3}
                                                    onChange={(obj: any) => {
                                                        setSelect3(obj.value);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={3}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right">Approved Date</Label>
                                            </Col>
                                            <Col xs={6}>
                                                <Input dataUI={getDataUI()} />
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={3}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right">Reason</Label>
                                            </Col>
                                            <Col xs={6}>
                                                <Textarea dataUI={getDataUI()} name="textarea-reason" />
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={3}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right">Exceeds Policy by</Label>
                                            </Col>
                                            <Col xs={6}>
                                                <Textarea dataUI={getDataUI()} name="textarea-exceeds" />
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={3}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right">Mitigation</Label>
                                            </Col>
                                            <Col xs={6}>
                                                <Textarea dataUI={getDataUI()} name="textarea-mitigation" />
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={3}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right">Status</Label>
                                            </Col>
                                            <Col xs={6}>
                                                <Select
                                                    dataUI={getDataUI()}
                                                    options={[
                                                        { value: '1', label: 'Option 1' },
                                                        { value: '2', label: 'Option 2' },
                                                    ]}
                                                    value={select4}
                                                    onChange={(obj: any) => {
                                                        setSelect4(obj.value);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={3}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right">Open Date</Label>
                                            </Col>
                                            <Col xs={6}>
                                                <Input dataUI={getDataUI()} />
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={3}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right">Closed Date</Label>
                                            </Col>
                                            <Col xs={6}>
                                                <Input dataUI={getDataUI()} />
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={3}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right">Primary Officer</Label>
                                            </Col>
                                            <Col xs={6}>
                                                <Input dataUI={getDataUI()} />
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={3}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right">Secondary Officer</Label>
                                            </Col>
                                            <Col xs={6}>
                                                <Input dataUI={getDataUI()} />
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={3}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right">Branch</Label>
                                            </Col>
                                            <Col xs={6}>
                                                <Input dataUI={getDataUI()} />
                                            </Col>
                                        </Row>
                                        <Row className="mb-s">
                                            <Col xs={3}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right">Last Status Change</Label>
                                            </Col>
                                            <Col xs={6}>
                                                <Label dataUI={getDataUI()} className="justify-content-end text-right"></Label>
                                            </Col>
                                        </Row>
                                    </MainContainerScrollable>
                                </TabContentItem>
                                <TabContentItem dataUI={getDataUI()}>
                                    <h1>Party Information</h1>
                                </TabContentItem>
                                <TabContentItem dataUI={getDataUI()}>
                                    <h1>Loan Request</h1>
                                </TabContentItem>
                                <TabContentItem dataUI={getDataUI()}>
                                    <h1>Complience</h1>
                                </TabContentItem>
                                <TabContentItem dataUI={getDataUI()}>
                                    <h1>Collateral</h1>
                                </TabContentItem>
                                <TabContentItem dataUI={getDataUI()}>
                                    <InfoBox dataUI={getDataUI()} className="mb-m">
                                        Assign the appropriate roles where this wizard should be assigned.
                                    </InfoBox>

                                    <DragNDropContext onDragStart={handleDragStart} onDragUpdate={handleDragUpdate} onDragEnd={onDragEnd}>
                                        <Row>
                                            <Col xs={6}>
                                                <DynamicScrollableContainer dataUI={getDataUI()}>
                                                    <DragNDropSection dataUI={getDataUI()}>
                                                        <DragNDropSectionHeader dataUI={getDataUI()}>
                                                            <div className="d-flex justify-content-between align-items-center mb-m">
                                                                <h2>Available ({availableItems.length})</h2>
                                                                <Button dataUI={getDataUI()} onClick={() => addAllToAssigned()} btnType="secondary" icon="fal fa-file-import">Add all</Button>
                                                            </div>
                                                            <Input
                                                                dataUI={getDataUI()}
                                                                name="input-icon"
                                                                value={availableSearch}
                                                                onChange={(ev: any) => setSearchValue(AVAILABLE_SECTION_NAME, ev.target.value)}
                                                                icon='fal fa-search'
                                                            />
                                                        </DragNDropSectionHeader>
                                                        <Droparea dataUI={getDataUI()} dropareaID={AVAILABLE_SECTION_NAME} allowedItems={[AVAILABLE_SECTION_NAME, ASSIGNED_SECTION_NAME]}
                                                            dragStartEvent={dragStartEvent}
                                                            dragUpdateEvent={dragUpdateEvent}
                                                        >

                                                            {availableItems.map((item, index) => {
                                                                return (
                                                                    !availableSearch || (availableSearch && item.title.includes(availableSearch)) ?
                                                                        <DraggableItem dataUI={getDataUI()} key={item.title} index={index} draggableID={item.id}>
                                                                            <DraggableItemActions dataUI={getDataUI()}>
                                                                                <DraggableItemTitle dataUI={getDataUI()}>{item.title}</DraggableItemTitle>
                                                                            </DraggableItemActions>
                                                                            <DraggableItemActions dataUI={getDataUI()}>
                                                                                <Tooltip title="Move">
                                                                                    <Button dataUI={getDataUI()} onClick={() => moveAvailableItemToAssigned(index)} btnType="icon" icon="fal fa-arrow-right" />
                                                                                </Tooltip>
                                                                            </DraggableItemActions>
                                                                        </DraggableItem>
                                                                        :
                                                                        ''
                                                                )
                                                            })}
                                                        </Droparea>
                                                    </DragNDropSection>
                                                </DynamicScrollableContainer>
                                            </Col>

                                            <Col xs={6}>
                                                <DynamicScrollableContainer dataUI={getDataUI()}>
                                                    <DragNDropSection dataUI={getDataUI()}>
                                                        <DragNDropSectionHeader dataUI={getDataUI()}>
                                                            <div className="d-flex justify-content-between align-items-center mb-m">
                                                                <h2>Assigned ({assignedItems.length})</h2>
                                                                <Button dataUI={getDataUI()} onClick={() => removeAllAssigned()} btnType="secondary" icon="fal fa-file-import">Remove all</Button>
                                                            </div>
                                                            <Input
                                                                dataUI={getDataUI()}
                                                                name="input-icon"
                                                                value={assignedSearch}
                                                                onChange={(ev: any) => setSearchValue(ASSIGNED_SECTION_NAME, ev.target.value)}
                                                                icon='fal fa-search'
                                                            />
                                                        </DragNDropSectionHeader>
                                                        <Droparea
                                                            dataUI={getDataUI()}
                                                            dropareaID={ASSIGNED_SECTION_NAME}
                                                            allowedItems={[ASSIGNED_SECTION_NAME, AVAILABLE_SECTION_NAME]}
                                                            callToActionText="Drop your files here."
                                                            dragStartEvent={dragStartEvent}
                                                            dragUpdateEvent={dragUpdateEvent}
                                                        >
                                                            {assignedItems.map((item, index) => {
                                                                return (
                                                                    !assignedSearch || (assignedSearch && item.title.includes(assignedSearch)) ?
                                                                        <DraggableItem dataUI={getDataUI()} key={item.title} index={index} draggableID={item.id}>
                                                                            <DraggableItemActions dataUI={getDataUI()}>
                                                                                <DraggableItemTitle dataUI={getDataUI()}>{item.title}</DraggableItemTitle>
                                                                            </DraggableItemActions>
                                                                            <DraggableItemActions dataUI={getDataUI()}>
                                                                                <Tooltip title="Remove">
                                                                                    <Button dataUI={getDataUI()} onClick={() => returnItemToAvailable(index)} btnType="icon" icon="fal fa-times" />
                                                                                </Tooltip>
                                                                            </DraggableItemActions>
                                                                        </DraggableItem>
                                                                        :
                                                                        ''
                                                                )
                                                            })}
                                                        </Droparea>
                                                    </DragNDropSection>
                                                </DynamicScrollableContainer>
                                            </Col>
                                        </Row>
                                    </DragNDropContext>
                                </TabContentItem>
                            </TabContent>
                        </Tabs>
                    </>
                }
                bottomSection={
                    <div>
                        <h2>Lender Notification</h2>
                        <hr className="mt-m mb-m" />
                        <Toolbar
                            dataUI={getDataUI()}
                            className="mb-s"
                            leftSide={(
                                <>
                                    <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-save">
                                        Save
                                    </Button>
                                </>
                            )}
                        />
                    </div>
                }
            />
        </>
    )
}

export default DemoResizableSections;