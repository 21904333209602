import React from 'react';

import {
    MainContainerHeader, ActionContainer, ActionContainerHeader, ActionContainerCrumbs,
    Button, Toolbar, MainContainerScrollable, SimpleTableContainer,
    Modal,
    ModalScrollableContainer,
    FileUpload,
    InfoBox,
    UploadedFileRow,
    Checkbox,
    Select
} from '@jkhy/vsg-loanvantage-design-system';

import { onAddFile, UploadedFileRowProps, checkFileOnServer, onDeleteFile, simulateUploading, updateUploadedFileRowProp } from '../helpers/fileUploadHelpers';
import { getDataUI } from '../helpers/helpers';


class DemoPageFileUploadTable extends React.Component {
    private filesUnmountedRows = React.createRef() as any;
    private filesRowTwoUnmountedRows = React.createRef() as any;
    private filesRowThreeUnmountedRows = React.createRef() as any;
    private filesRowFourUnmountedRows = React.createRef() as any;

    state = {
        showMainModal: false,
        showModal: false,
        files: [] as UploadedFileRowProps[],
        filesRowTwo: [] as UploadedFileRowProps[],
        filesRowThree: [] as UploadedFileRowProps[],
        filesRowFour: [] as UploadedFileRowProps[],

        showSuccessInfo: true,
        isServerLockCheck: false,
        uploadingPropName: 'files'
    } as any

    componentDidMount() {
        this.filesUnmountedRows.current = {};
        this.filesRowTwoUnmountedRows.current = {};
        this.filesRowThreeUnmountedRows.current = {};
        this.filesRowFourUnmountedRows.current = {};
    }

    resetOnDeleteTableDemo = () => {
        this.setState({
            showSuccessInfo: false,
            isServerLockCheck: false,
        });
    }

    renderFileRowsTable = (statePropName: any) => {
        const copyState = { ...this.state } as any;
        const files = copyState[statePropName];

        if (files.length === 0) {
            return null;
        }

        return files.map((fileData: any) => {
            const isError = fileData.uploadedFile.uploadError;
            const isUploading = fileData.uploadedFile.uploading;
            return (
                <UploadedFileRow
                    dataUI={getDataUI()}
                    key={fileData.key}
                    viewMode={fileData.viewMode}
                    uploadedFile={{
                        ...fileData.uploadedFile,
                        uploadError: isError,
                    }}
                    onProgress={(updateProgress: any) => {
                        if (isUploading) {
                            simulateUploading(updateProgress, fileData, statePropName, this, true)
                        }
                    }}
                    onSuccessUploaded={(progress: any, setUploadSuccessful: any) => {
                        if (progress === 100) {
                            setUploadSuccessful(true);
                            updateUploadedFileRowProp(statePropName, fileData, "uploading", false, this)
                        }
                    }}
                    onUploadDone={(uploadSuccessful: any, setUploadDone: any) => {
                        if (uploadSuccessful) {
                            setTimeout(() => {
                                setUploadDone(true);
                                updateUploadedFileRowProp(statePropName, fileData, "uploadDone", true, this);
                                if (this.state.showModal && statePropName === this.state.uploadingPropName) {
                                    this.resetOnDeleteTableDemo();
                                }

                            }, 3000);
                        }
                    }}
                    onDelete={() => {
                        onDeleteFile(fileData.key, statePropName, this);
                        this.resetOnDeleteTableDemo();
                    }}
                />
            );
        })
    }


    renderFileRowsModal = (statePropName: any) => {
        const copyState = { ...this.state } as any;
        const files = copyState[statePropName];

        if (files.length === 0) {
            return null;
        }

        return files.map((fileData: any) => {
            const isError = fileData.uploadedFile.uploadError;
            return (
                <UploadedFileRow
                    dataUI={getDataUI()}
                    key={fileData.key}
                    viewMode={fileData.viewMode}
                    uploadedFile={{
                        ...fileData.uploadedFile,
                        uploadError: isError,
                    }}
                    onSuccessUploaded={(progress: any, setUploadSuccessful: any) => {
                        if (progress === 100) {
                            setUploadSuccessful(true);
                            updateUploadedFileRowProp(statePropName, fileData, "uploading", false, this)
                        }
                    }}
                    onUploadDone={(uploadSuccessful: any, setUploadDone: any) => {
                        if (uploadSuccessful) {
                            setTimeout(() => {
                                setUploadDone(true);
                                updateUploadedFileRowProp(statePropName, fileData, "uploadDone", true, this);
                            }, 3000);
                        }
                    }}
                    onDelete={() => {
                        onDeleteFile(fileData.key, statePropName, this);
                        this.resetOnDeleteTableDemo();
                    }}
                />
            );
        })
    }

    render() {
        return (
            <>
                <MainContainerHeader dataUI={getDataUI()}>
                    <ActionContainer dataUI={getDataUI()}>
                        <ActionContainerHeader dataUI={getDataUI()}>
                            <ActionContainerCrumbs dataUI={getDataUI()} crumbs={['Documents Upload']} />
                        </ActionContainerHeader>
                    </ActionContainer>
                </MainContainerHeader>

                <MainContainerScrollable>
                    <Toolbar
                        dataUI={getDataUI()}
                        className="mb-m mt-m"
                        leftSide={
                            <>
                                <Button dataUI={getDataUI()} onClick={() => this.setState({ showMainModal: true })} className="mr-s" icon='fal fa-plus'>Add Documents</Button>
                            </>
                        }
                    />


                    <Modal
                        dataUI={getDataUI()}
                        title='Upload 4506T'
                        isVisible={true}
                        onClose={() => this.setState({
                            showMainModal: false,
                        })}
                        isHidden={!this.state.showMainModal}
                        size="L"
                    >
                        <Toolbar
                            dataUI={getDataUI()}
                            className="mb-m"
                            leftSide={
                                <>
                                    <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-save" onClick={() => { this.setState({ showModal: false }); }}>Save</Button>
                                    <Button dataUI={getDataUI()} icon="far fa-times-circle" btnType="secondary" onClick={() => this.setState({ showModal: false })}>Cancel</Button>
                                </>
                            }
                        />
                        <ModalScrollableContainer>

                            <SimpleTableContainer dataUI={getDataUI()} className='mb-xl'>
                                <table style={{ tableLayout: 'fixed' }}>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '42px' }}></th>
                                            <th>Party</th>
                                            <th>Product Requested</th>
                                            <th style={{ width: '50%' }}>Upload Signed</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><Checkbox dataUI={getDataUI()} /></td>
                                            <td>Long’s Drugs of Bakersville</td>
                                            <td>
                                                <Select dataUI={getDataUI()}
                                                    className="mb-s"
                                                    options={[
                                                        { value: '1', label: 'Pending' },
                                                    ]}
                                                    value={''}
                                                    placeholder="Select..."
                                                />
                                            </td>
                                            <td>
                                                <FileUpload
                                                    dataUI={getDataUI()}
                                                    visualType="small"
                                                    onChange={(files: any) => {
                                                        onAddFile(files, 'files', this);
                                                        this.setState({
                                                            showModal: true,
                                                            uploadingPropName: 'files',
                                                            showSuccessInfo: true,
                                                            isServerLockCheck: checkFileOnServer(files)
                                                        });
                                                    }}
                                                >
                                                    {this.renderFileRowsTable('files')}
                                                </FileUpload>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><Checkbox dataUI={getDataUI()} /></td>
                                            <td>Another Child</td>
                                            <td>
                                                <Select dataUI={getDataUI()}
                                                    className="mb-s"
                                                    options={[
                                                        { value: '1', label: 'Pending' },
                                                    ]}
                                                    value={''}
                                                    placeholder="Select..."
                                                />
                                            </td>
                                            <td>
                                                <FileUpload
                                                    dataUI={getDataUI()}
                                                    visualType="small"
                                                    onChange={(files: any) => {
                                                        onAddFile(files, 'filesRowTwo', this);
                                                        this.setState({
                                                            showModal: true,
                                                            uploadingPropName: 'filesRowTwo',
                                                            showSuccessInfo: true,
                                                            isServerLockCheck: checkFileOnServer(files)
                                                        });
                                                    }}
                                                >
                                                    {this.renderFileRowsTable('filesRowTwo')}
                                                </FileUpload>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><Checkbox dataUI={getDataUI()} /></td>
                                            <td>Long’s Drugs of Bakersville</td>
                                            <td>
                                                <Select dataUI={getDataUI()}
                                                    className="mb-s"
                                                    options={[
                                                        { value: '1', label: 'Pending' },
                                                    ]}
                                                    value={''}
                                                    placeholder="Select..."
                                                />
                                            </td>
                                            <td>
                                                <FileUpload
                                                    dataUI={getDataUI()}
                                                    visualType="small"
                                                    onChange={(files: any) => {
                                                        onAddFile(files, 'filesRowThree', this);
                                                        this.setState({
                                                            showModal: true,
                                                            uploadingPropName: 'filesRowThree',
                                                            showSuccessInfo: true,
                                                            isServerLockCheck: checkFileOnServer(files)
                                                        });
                                                    }}
                                                >
                                                    {this.renderFileRowsTable('filesRowThree')}
                                                </FileUpload>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><Checkbox dataUI={getDataUI()} /></td>
                                            <td>Another Child</td>
                                            <td>
                                                <Select dataUI={getDataUI()}
                                                    className="mb-s"
                                                    options={[
                                                        { value: '1', label: 'Pending' },
                                                    ]}
                                                    value={''}
                                                    placeholder="Select..."
                                                />
                                            </td>
                                            <td>
                                                <FileUpload
                                                    dataUI={getDataUI()}
                                                    visualType="small"
                                                    onChange={(files: any) => {
                                                        onAddFile(files, 'filesRowFour', this);
                                                        this.setState({
                                                            showModal: true,
                                                            uploadingPropName: 'filesRowFour',
                                                            showSuccessInfo: true,
                                                            isServerLockCheck: checkFileOnServer(files)
                                                        });
                                                    }}
                                                >
                                                    {this.renderFileRowsTable('filesRowFour')}
                                                </FileUpload>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </SimpleTableContainer>
                        </ModalScrollableContainer>

                    </Modal>


                    <Modal
                        dataUI={getDataUI()}
                        title="Required Documents Details 3529232"
                        disableCloseBtn={this.state.isServerLockCheck}
                        isVisible={true}
                        isHidden={!this.state.showModal}
                        size="S"
                        onClose={() => this.setState({
                            showModal: false,
                            showSuccessInfo: this.state[this.state.uploadingPropName].length === 0 ? true : false,
                        })}
                    >
                        <Toolbar
                            dataUI={getDataUI()}
                            className="mb-m"
                            leftSide={
                                <>
                                    <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-save" disabled={this.state.isServerLockCheck} onClick={() => { this.setState({ showModal: false }); }}>Save</Button>
                                    <Button dataUI={getDataUI()} icon="far fa-times-circle" disabled={this.state.isServerLockCheck} btnType="secondary" onClick={() => this.setState({ showModal: false })}>Cancel</Button>
                                </>
                            }
                        />
                        <ModalScrollableContainer>
                            {this.state.showSuccessInfo && this.state[this.state.uploadingPropName][0] && !this.state[this.state.uploadingPropName][0].uploadedFile.uploadError &&
                                <InfoBox dataUI={getDataUI()} className='mb-m' type={this.state.isServerLockCheck ? "warning" : "success"} icon={this.state.isServerLockCheck ? "fal fa-exclamation-triangle" : "fal fa-file-upload"} title={this.state.isServerLockCheck ? "System is uploading your file." : 'Your documents are uploading in the background.'}>
                                    {this.state.isServerLockCheck ? "Please wait while your file is currently uploading, so it can be checked. If you wish to stop the proccess, you can stop the uploading of the file." : "You can safely close this dialogue and the files will continue uploading in the background."}
                                </InfoBox>}

                            <FileUpload
                                dataUI={getDataUI()}
                                visualType="large"
                                onChange={(files: any) => {
                                    if (this.state[this.state.uploadingPropName].length >= 1) {
                                        return;
                                    }
                                    onAddFile(files, this.state.uploadingPropName, this);
                                    this.setState({
                                        showSuccessInfo: true,
                                        isServerLockCheck: checkFileOnServer(files)
                                    })

                                }}
                                viewMode={this.state[this.state.uploadingPropName].length >= 1}
                                {...this.state[this.state.uploadingPropName].length >= 1 && {
                                    description: {
                                        visible: true,
                                        value: "Wish to replace? First remove the uploaded file."
                                    }
                                }}
                            >
                                {this.renderFileRowsModal(this.state.uploadingPropName)}
                            </FileUpload>
                        </ModalScrollableContainer>
                    </Modal>
                </MainContainerScrollable>

            </>
        );
    }
}

export default DemoPageFileUploadTable;