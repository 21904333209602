import React, { useState } from 'react';
import { Button, Modal, Toolbar, Tabs, TabList, TabItem, TabItemTitle, TabContent, TabContentItem } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../../helpers/helpers';
import DetailsTab from './DetailsTab';
import TeamsTab from './TeamsTab';

type EditRolesModalProps = {
    isVisible: boolean,
    onClose?(): void,
};

const EditRolesModal = (props: EditRolesModalProps) => {

    const { isVisible, onClose } = props;

    const [tabIndex, setTabindex] = useState(0);

    return (
        <Modal
            title="Edit ‘3.0 Admin Testers’"
            isVisible={isVisible}
            size="L+"
            onClose={onClose}
            dataUI={getDataUI()}
        >
            <Toolbar className="mb-s" dataUI={getDataUI()} leftSide={(
                <>
                    <Button className="mr-s" icon="fal fa-save" dataUI={getDataUI()}>Save</Button>
                    <Button className="mr-s" btnType="secondary" icon="fal fa-copy" dataUI={getDataUI()}>Copy</Button>
                    <Button className="mr-s" btnType="secondary" icon="far fa-times-circle" dataUI={getDataUI()}>Cancel</Button>
                    <Button btnType="secondary" icon="fal fa-trash" dataUI={getDataUI()}>Delete</Button>
                </>
            )}>
            </Toolbar>

            <Tabs
                onTabIndexChange={(index: number) => setTabindex(index)}
                currentlyActiveTabIndex={tabIndex}
                dataUI={getDataUI()}
            >
                <TabList dataUI={getDataUI()}>
                    <TabItem dataUI={getDataUI()}>
                        <TabItemTitle dataUI={getDataUI()}>Details</TabItemTitle>
                    </TabItem>
                    <TabItem dataUI={getDataUI()}>
                        <TabItemTitle dataUI={getDataUI()}>Teams</TabItemTitle>
                    </TabItem>
                </TabList>
                <TabContent dataUI={getDataUI()}>
                    <TabContentItem dataUI={getDataUI()}>
                        <DetailsTab />
                    </TabContentItem>
                    <TabContentItem dataUI={getDataUI()}>
                        <TeamsTab />
                    </TabContentItem>
                </TabContent>
            </Tabs>
        </Modal>
    );
};

export default EditRolesModal;