/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import Highlight from 'react-highlight';
import { ScheduleButton, Container, Label, ButtonGroup, Button } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const defaultScheduleButtons = [
    { text: 'Sat', active: false, disabled: false },
    { text: 'Mon', active: false, disabled: false },
    { text: 'Tue', active: false, disabled: false },
    { text: 'Wed', active: false, disabled: false },
    { text: 'Thu', active: false, disabled: false },
    { text: 'Fri', active: false, disabled: false },
    { text: 'Sun', active: false, disabled: false }
]

type ScheduleButtonsList = typeof defaultScheduleButtons;

const buttonGroupButtons = [
    { text: 'Daily' },
    { text: 'Weekly' },
    { text: 'Monthly' }
]

const ScheduleButtonPage: React.FC = () => {
    const [scheduleButtons, setScheduleButtons] = useState(defaultScheduleButtons);
    const [scheduleButtonsSE, setScheduleButtonsSE] = useState(defaultScheduleButtons);
    const [activeBtn, setActiveBtn] = useState(1)

    const onClick = (list: ScheduleButtonsList, callback: (list: ScheduleButtonsList) => void, index: number) => {
        const updatedscheduleButtons = list.map((btn, i) => {
            if (index === i) {
                return { ...btn, active: !btn.active }
            }
            return btn
        })
        callback(updatedscheduleButtons);
    };

    return (
        <div>
            <h1 className="mb-s">Schedule Button</h1>
            <p className="mb-l">The Schedule Button component extends the native Button element.</p>

            <div className="mb-m">
                <Container gap='S' dataUI={getDataUI()}>
                    {
                        scheduleButtons.map((btn, index) => {
                            return (
                                <ScheduleButton
                                    key={btn.text}
                                    dataUI={getDataUI()}
                                    active={btn.active}
                                    disabled={btn.disabled}
                                    onClick={() => {
                                        onClick(scheduleButtons, setScheduleButtons, index);
                                    }}
                                >
                                    {btn.text}
                                </ScheduleButton>
                            )
                        })
                    }
                </Container>
            </div>


            <h3 className="mb-s">Example schedule buttons in nested containers</h3>
            <p className='mb-l'>You can check container documentation here</p>
            <div className='mb-m'>
                <Container padding='M' gap='M' background='color-2' flexColumn dataUI={getDataUI()}>
                    <Container gap='M' dataUI={getDataUI()}>
                        <Label dataUI={getDataUI()} className='text-bold'>Occurence:</Label>
                        <ButtonGroup dataUI={getDataUI()}>
                            {buttonGroupButtons.map((btn, index) => {
                                return (
                                    <Button
                                        btnType={activeBtn === index ? 'primary' : 'secondary'}
                                        onClick={() => setActiveBtn(index)}
                                        dataUI={getDataUI()}
                                    >
                                        {btn.text}
                                    </Button>
                                )
                            })}
                        </ButtonGroup>
                    </Container>

                    <hr className='border-color-3' />

                    <Container gap='S' padding='M' radius='small' background='color-1' isShadow dataUI={getDataUI()}>
                        <Label dataUI={getDataUI()} className='text-bold'>On</Label>
                        {
                            scheduleButtonsSE.map((btn, index) => {
                                return (
                                    <ScheduleButton
                                        key={btn.text}
                                        dataUI={getDataUI()}
                                        active={btn.active}
                                        disabled={btn.disabled}
                                        onClick={() => {
                                            onClick(scheduleButtonsSE, setScheduleButtonsSE, index);
                                        }}
                                    >
                                        {btn.text}
                                    </ScheduleButton>
                                )
                            })
                        }
                    </Container>
                </Container>
            </div>

            <h3 className="mb-s">Disabled Button</h3>
            <div className="d-flex mb-m">
                <ScheduleButton
                    dataUI={getDataUI()}
                    disabled
                >
                    Sun
                </ScheduleButton>
            </div>


            <Highlight className="React">
                {
                    `
import React from 'react';
import React, { useState } from 'react';
import { ScheduleButton, Container, Label, ButtonGroup, Button } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const defaultScheduleButtons = [
    { text: 'Sat', active: false, disabled: false },
    { text: 'Mon', active: false, disabled: false },
    { text: 'Tue', active: false, disabled: false },
    { text: 'Wed', active: false, disabled: false },
    { text: 'Thu', active: false, disabled: false },
    { text: 'Fri', active: false, disabled: false },
    { text: 'Sun', active: false, disabled: false }
]

type ScheduleButtonsList = typeof defaultScheduleButtons;

const buttonGroupButtons = [
    { text: 'Daily' },
    { text: 'Weekly' },
    { text: 'Monthly' }
]

const Example: React.FC = (props) => {
    const [scheduleButtons, setScheduleButtons] = useState(defaultScheduleButtons);
    const [scheduleButtonsSE, setScheduleButtonsSE] = useState(defaultScheduleButtons);
    const [activeBtn, setActiveBtn] = useState(1)

    const onClick = (list: ScheduleButtonsList, callback: (list: ScheduleButtonsList) => void, index: number) => {
        const updatedscheduleButtons = list.map((btn, i) => {
            if (index === i) {
                return { ...btn, active: !btn.active }
            }
            return btn
        })
        callback(updatedscheduleButtons);
    };
    return (
            // Default
             <Container gap='S' dataUI={getDataUI()}>
                    {
                        scheduleButtons.map((btn, index) => {
                            return (
                                <ScheduleButton
                                    key={btn.text}
                                    dataUI={getDataUI()}
                                    active={btn.active}
                                    disabled={btn.disabled}
                                    onClick={() => {
                                        onClick(scheduleButtons, setScheduleButtons, index);
                                    }}
                                >
                                    {btn.text}
                                </ScheduleButton>
                            )
                        })
                    }
             </Container>
             
            // Example schedule buttons in nested containers
            <Container padding='M' gap='M' background='color-2' flexColumn dataUI={getDataUI()}>
                <Container gap='M' dataUI={getDataUI()}>
                    <Label dataUI={getDataUI()} className='text-bold'>Occurence:</Label>
                    <ButtonGroup dataUI={getDataUI()}>
                        {buttonGroupButtons.map((btn, index) => {
                            return (
                                <Button
                                    btnType={activeBtn === index ? 'primary' : 'secondary'}
                                    onClick={() => setActiveBtn(index)}
                                    dataUI={getDataUI()}
                                >
                                    {btn.text}
                                </Button>
                            )
                        })}
                    </ButtonGroup>
                </Container>

                <hr className='border-color-3' />

                <Container gap='S' padding='M' radius='small' background='color-1' isShadow dataUI={getDataUI()}>
                    <Label dataUI={getDataUI()} className='text-bold'>On</Label>
                    {
                        scheduleButtonsSE.map((btn, index) => {
                            return (
                                <ScheduleButton
                                    key={btn.text}
                                    dataUI={getDataUI()}
                                    active={btn.active}
                                    disabled={btn.disabled}
                                    onClick={() => {
                                        onClick(scheduleButtonsSE, setScheduleButtonsSE, index);
                                    }}
                                >
                                    {btn.text}
                                </ScheduleButton>
                            )
                        })
                    }
                </Container>
            </Container>
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>active</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Set component active state.</td>
                    </tr>
                    <tr>
                        <td>all native button attributes</td>
                        <td className="text-center">-</td>
                        <td className="text-center">-</td>
                        <td>You can use all native button attributes like: "type", "disabled", "name", etc.</td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default ScheduleButtonPage;
