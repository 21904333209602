import React, { useRef, useState } from 'react';
import Highlight from 'react-highlight';
import { Input, ValidationMessage } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';


const InputPage: React.FC = () => {
    const inputRef = useRef<HTMLInputElement>();
    const inputRefSE = useRef<HTMLInputElement>();
    const inputRefTR = useRef<HTMLInputElement>();

    const [value, setValue] = useState("Input controlled with icon and events: onChange, onKeyDown, onBlur");

    const searchValue = (ref: any) => {
        const value = ref?.current?.value.trim();
        console.log("Keyup event value ref:", value);
    }

    const onClear = () => {
        console.log('Clear event')
    }

    const onBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        console.log('Blur event value:', e.target.value);
    }

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            searchValue(inputRefTR);
        }
    }

    return (
        <div>
            <h1 className="mb-s">Input</h1>
            <p className="mb-l">The Input component extends the native input element.</p>

            <div className="mb-m">
                <Input dataUI={getDataUI()} className="mb-s" />
                <Input dataUI={getDataUI()} placeholder={'Input Placeholder'} className="mb-s" />
                <Input dataUI={getDataUI()} value="Input Disabled" name="input" disabled className="mb-s" onChange={() => { }} />
                <Input dataUI={getDataUI()} name="input-icon" className="mb-s" onChange={() => { }} icon='fal fa-file-alt' />
            </div>

            <h3 className="mb-s">Input with dark border.</h3>
            <div className="mb-m">
                <Input darkBorder dataUI={getDataUI()} />
            </div>

            <h3 className="mb-s">Input invalid.</h3>
            <div className="mb-m">
                <Input invalid dataUI={getDataUI()} />
                <ValidationMessage type='error' message='Invalid message' dataUI={getDataUI()} />
            </div>


            <div className="mb-xl">
                <h3 className="mb-s">Input with clear action.</h3>
                <Input
                    dataUI={getDataUI()}
                    className="mb-s"
                    clearAction
                    placeholder='Input uncontrolled with no ref and events'
                    onClear={onClear}
                />

                <Input
                    ref={inputRef}
                    dataUI={getDataUI()}
                    className="mb-s"
                    placeholder='Input uncontrolled with set ref and events: onKeyDown, onBlur'
                    clearAction
                    onKeyDown={onKeyDown}
                    onBlur={onBlur}
                    onClear={onClear}
                />

                <Input
                    ref={inputRefSE}
                    dataUI={getDataUI()}
                    className="mb-s"
                    icon='fal fa-search'
                    placeholder='Input uncontrolled with icon, ref and events: onChange, onKeyDown, onBlur'
                    clearAction
                    onChange={(e) => {
                        const currentValue = e.target.value;
                        console.log('Change event value:', currentValue);
                        console.log('Change event value ref:', inputRefSE.current?.value);
                    }}
                    onKeyDown={onKeyDown}
                    onBlur={onBlur}
                    onClear={onClear}
                />

                <Input
                    dataUI={getDataUI()}
                    className="mb-s"
                    icon='fal fa-search'
                    placeholder='Input controlled with icon and events: onChange, onKeyDown, onBlur'
                    clearAction
                    value={value}
                    onChange={(e) => {
                        const currentValue = e.target.value;
                        console.log('Change Event value:', currentValue);
                        setValue(currentValue);
                    }}
                    onKeyDown={onKeyDown}
                    onBlur={onBlur}
                    onClear={onClear}
                />

                <Input
                    dataUI={getDataUI()}
                    clearAction
                    disabled
                    placeholder='Input with clear action disabled'
                    onClear={onClear}
                />
            </div>
            <Highlight className="React">
                {
                    `
import React from 'react';
import { Input, ValidationMessage } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const Example: React.FC = (props) => {
    const inputRef = useRef<HTMLInputElement>();

    const [value, setValue] = useState("Input controlled with icon and events: onChange, onKeyDown, onBlur");

    const searchValue = (ref: any) => {
        const value = ref?.current?.value.trim();
        console.log("Keyup event value ref:", value);
    }

    const onClear = () => {
        console.log('Clear event')
    }

    const onBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        console.log('Blur event value:', e.target.value);
    }

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            searchValue(inputRefTR);
        }
    }
    return (
      <div>
            <Input dataUI={getDataUI()} name="input" type="text" onChange={() => {} }/>
            <Input dataUI={getDataUI()} name="inputPlaceholeder" type="text" placeholder={'Input Placeholder'}  onChange={() => {} }/>
            <Input dataUI={getDataUI()} name="inputDisabled" type="text" value="Input Disabled" disabled onChange={() => {} } />
            <Input dataUI={getDataUI()} name="input-icon" className="mb-s" onChange={() => { }} icon='fal fa-file-alt' />

            //Input with dark border
            <Input darkBorder />

            // Input invalid
            <Input 
             invalid
             // other way to set invalid
            // className='invalid'
             dataUI={getDataUI()}
            />
            <ValidationMessage type='error' message='Invalid message' dataUI={getDataUI()}/>

            // Input with clear action
            <Input
                dataUI={getDataUI()}
                placeholder='Input uncontrolled'
                ref={inputRef}
                clearAction
                onKeyDown={onKeyDown}
                onBlur={onBlur}
                onClear={onClear}
            />

            <Input
                dataUI={getDataUI()}
                placeholder='Input controlled'
                clearAction
                value={value}
                onChange={(e) => {
                    const currentValue = e.target.value;
                    console.log('Change Event value:', currentValue);
                    setValue(currentValue);
                }}
                onKeyDown={onKeyDown}
                onBlur={onBlur}
                onClear={onClear}
             />
      </div>
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>icon</td>
                        <td className='text-center'>string</td>
                        <td className='text-center'>-</td>
                        <td>Set icon</td>
                    </tr>
                    <tr>
                        <td>invalid</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>Sets component invalid styles. You can set component invalid styles and by adding class 'invalid' </td>
                    </tr>
                    <tr>
                        <td>darkBorder</td>
                        <td className='text-center'>boolean</td>
                        <td className='text-center'>-</td>
                        <td>Sets component border color</td>
                    </tr>
                    <tr>
                        <td>clearAction</td>
                        <td className='text-center'>boolean</td>
                        <td className='text-center'>-</td>
                        <td>Sets clear action</td>
                    </tr>
                    <tr>
                        <td>onClear</td>
                        <td className='text-center text-no-break'>{`() => void`}</td>
                        <td className='text-center'>-</td>
                        <td>Callback when clear action is clicked</td>
                    </tr>
                    <tr>
                        <td>all native input attributes</td>
                        <td className='text-center'>-</td>
                        <td className='text-center'>-</td>
                        <td>You can use all native input attributes like: "type", "disabled", "name"...</td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default InputPage;
