import React from 'react';

import {
    MainContainerHeader, ReactAux, ActionContainer, ActionContainerHeader,
    ActionContainerCrumbs, Button, MainContainerScrollable, Modal, ModalScrollableContainer, Toolbar, TabContent, TabContentItem, TabItem, TabItemTitle, TabList, Tabs,
} from '@jkhy/vsg-loanvantage-design-system';

import KendoTable from './KendoTable/KendoTable';
import { getDataUI } from '../helpers/helpers';


class DemoScroll7 extends React.Component {

    state = {
        modal: false,
        modalTabIndex: 0,
        pageTabIndex: 0,
    }

    render() {

        return (
            <ReactAux>
                <MainContainerHeader dataUI={getDataUI()} className="mb-m">
                    <ActionContainer dataUI={getDataUI()}>
                        <ActionContainerHeader dataUI={getDataUI()}>
                            <ActionContainerCrumbs dataUI={getDataUI()} crumbs={[<a href="/">Loan Collateral</a>, '36465: Loan Request']} />
                        </ActionContainerHeader>
                        <Button dataUI={getDataUI()} icon="fal fa-pen" onClick={() => this.setState({ modal: true })}>
                            Open Modal with KendoTalbe
                        </Button>
                    </ActionContainer>
                </MainContainerHeader>

                <Tabs dataUI={getDataUI()} onTabIndexChange={(index: number) => this.setState({ pageTabIndex: index })} currentlyActiveTabIndex={this.state.pageTabIndex}>
                    <TabList dataUI={getDataUI()}>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>KendoTable</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Notes</TabItemTitle>
                        </TabItem>
                    </TabList>
                    <TabContent dataUI={getDataUI()}>
                        <TabContentItem dataUI={getDataUI()}>
                            <MainContainerScrollable>
                                <KendoTable />
                            </MainContainerScrollable>
                        </TabContentItem>
                        <TabContentItem dataUI={getDataUI()}>
                            <Toolbar dataUI={getDataUI()} className="mb-m"
                                leftSide={
                                    <Button dataUI={getDataUI()} icon="fal fa-plus">Add note</Button>
                                }
                            />
                            <MainContainerScrollable>
                                <h1>Notes tab</h1>
                            </MainContainerScrollable>
                        </TabContentItem>
                    </TabContent>
                </Tabs>

                <Modal
                    title="Modal with KendoTable"
                    size="M"
                    isVisible={this.state.modal}
                    onClose={() => this.setState({ modal: false })}
                    dataUI={getDataUI()}
                >
                    <Toolbar dataUI={getDataUI()} className="mb-m"
                        leftSide={
                            <>
                                <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-save">Save</Button>
                                <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-save">Save and Close</Button>
                                <Button dataUI={getDataUI()} icon="far fa-times-circle" btnType="secondary">Cancel</Button>
                            </>
                        }
                    />
                    <Tabs dataUI={getDataUI()} onTabIndexChange={(index: number) => this.setState({ modalTabIndex: index })} currentlyActiveTabIndex={this.state.modalTabIndex}>
                        <TabList dataUI={getDataUI()}>
                            <TabItem dataUI={getDataUI()}>
                                <TabItemTitle dataUI={getDataUI()}>KendoTable</TabItemTitle>
                            </TabItem>
                            <TabItem dataUI={getDataUI()}>
                                <TabItemTitle dataUI={getDataUI()}>Notes</TabItemTitle>
                            </TabItem>
                        </TabList>
                        <TabContent dataUI={getDataUI()}>
                            <TabContentItem dataUI={getDataUI()}>
                                <ModalScrollableContainer>
                                    <KendoTable />
                                </ModalScrollableContainer>
                            </TabContentItem>
                            <TabContentItem dataUI={getDataUI()}>
                                <Toolbar dataUI={getDataUI()} className="mb-m"
                                    leftSide={
                                        <Button dataUI={getDataUI()} icon="fal fa-plus">Add note</Button>
                                    }
                                />
                                <ModalScrollableContainer>
                                    <h1>Notes tab</h1>
                                </ModalScrollableContainer>
                            </TabContentItem>
                        </TabContent>
                    </Tabs>
                </Modal>
            </ReactAux>
        );
    }
}

export default DemoScroll7;