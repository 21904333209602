
import React, { useState } from "react";
import {
    ReactAux, MainContainerHeader, ActionContainer,
    Button, ActionContainerHeader, ActionContainerCrumbs, Tabs, TabList, TabItem,
    TabItemTitle, TabContent, TabContentItem, MainContainerScrollable, Toolbar, Input,
    Label, Radio, InfoBox, LinkWithIcon, SimpleTableContainer, Checkbox, Badge, Row, Col,
    Icon
} from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from "../helpers/helpers";

const DemoTicklerRecord = (props: any) => {
    const NUMBER_TABLE_ROWS = 17;

    const [tabIndex, setTabIndex] = useState(0);
    const [radiosChecked, setRadiosChecked] = useState({
        'account-radio': [{ value: 0, checked: true }, { value: 1, checked: false }, { value: 2, checked: false }],
        'type-radio': [{ value: 0, checked: true }, { value: 1, checked: false }]
    } as any);
    const [toolBtnDisabled, setToolBtnDisabled] = useState(true);
    const [checkboxSelected, setCheckboxSelected] = useState(new Array(NUMBER_TABLE_ROWS).fill(false));
    const [toolBtnDisabledSecond, setToolBtnDisabledSecond] = useState(true);
    const [checkboxSelectedSe, setCheckboxSelectedSe] = useState(new Array(NUMBER_TABLE_ROWS).fill(false));
    const [checkAll, setCheckAll] = useState(false);
    const [checkAllSe, setCheckAllSe] = useState(false);

    const toogleBtnDisabledState = (isSelectedCheckbox: boolean) => {
        const isButtonDisabled = tabIndex === 0 ? toolBtnDisabled : toolBtnDisabledSecond
        const stateSetter = tabIndex === 0 ? setToolBtnDisabled : setToolBtnDisabledSecond
        if (isButtonDisabled && !isSelectedCheckbox) {
            stateSetter(false);
        }

        if (isSelectedCheckbox && !isButtonDisabled) {
            stateSetter(true);
        }
    }

    const onCheck = (index: any, value: boolean) => {
        const copyCheckboxSelect = tabIndex === 0 ? [...checkboxSelected] : [...checkboxSelectedSe];
        const setStateCheckbox = tabIndex === 0 ? setCheckboxSelected : setCheckboxSelectedSe;
        copyCheckboxSelect[index] = value;
        const isSelectedCheckbox = copyCheckboxSelect.findIndex((el) => el === true);

        toogleBtnDisabledState(isSelectedCheckbox === - 1);
        setStateCheckbox(copyCheckboxSelect);
    }

    const onCheckAll = (value: any) => {
        const checkboxesValue = tabIndex === 0 ? checkboxSelected : checkboxSelectedSe;
        const checkBoxesSetter = tabIndex === 0 ? setCheckboxSelected : setCheckboxSelectedSe;
        const setCheckAllState = tabIndex === 0 ? setCheckAll : setCheckAllSe;

        const mappedCheckboxes = checkboxesValue.map(el => value);
        const isSelectedCheckbox = mappedCheckboxes.findIndex((el) => el === true);

        toogleBtnDisabledState(isSelectedCheckbox === - 1);
        checkBoxesSetter(mappedCheckboxes);
        setCheckAllState(value);
    }

    const onRadioChange = (name: any, index: any, value: boolean) => {
        const copyRadiosState = { ...radiosChecked };
        copyRadiosState[name].forEach((checkbox: any) => {
            checkbox.checked = false
        });
        copyRadiosState[name][index] = {
            ...copyRadiosState[name][index],
            checked: value
        }

        setRadiosChecked({
            ...radiosChecked,
            [name]: copyRadiosState[name]
        });
    }

    return (
        <ReactAux>
            <MainContainerHeader dataUI={getDataUI()}>
                <ActionContainer dataUI={getDataUI()}>
                    <ActionContainerHeader dataUI={getDataUI()}>
                        <ActionContainerCrumbs dataUI={getDataUI()} crumbs={['Covenant and Required Documents Maintenance']} />
                    </ActionContainerHeader>
                </ActionContainer>
            </MainContainerHeader>

            <Tabs dataUI={getDataUI()} className="mt-m" currentlyActiveTabIndex={tabIndex} onTabIndexChange={i => setTabIndex(i)}>
                <TabList dataUI={getDataUI()}>
                    <TabItem dataUI={getDataUI()}>
                        <TabItemTitle dataUI={getDataUI()}>Account Linkages</TabItemTitle>
                    </TabItem>
                    <TabItem dataUI={getDataUI()}>
                        <TabItemTitle dataUI={getDataUI()}>Orphaned Records</TabItemTitle>
                    </TabItem>
                    <TabItem dataUI={getDataUI()}>
                        <TabItemTitle dataUI={getDataUI()}>History</TabItemTitle>
                    </TabItem>
                </TabList>
                <TabContent dataUI={getDataUI()}>
                    <TabContentItem dataUI={getDataUI()}>
                        <MainContainerScrollable>
                            <Toolbar
                                dataUI={getDataUI()}
                                className="mb-s"
                                leftSide={
                                    <ReactAux>
                                        <Input dataUI={getDataUI()} icon='fal fa-search' />
                                        <Label dataUI={getDataUI()} className="ml-m mr-s"><strong>Accounts:</strong></Label>
                                        <div>
                                            <Radio dataUI={getDataUI()} name="account-radio" className="mr-xs" checked={radiosChecked['account-radio'][0].checked} onChange={(e) => { onRadioChange('account-radio', 0, e.target.checked) }} value={radiosChecked['account-radio'][0].value}>All</Radio>
                                            <Radio dataUI={getDataUI()} name="account-radio" className="mr-xs" checked={radiosChecked['account-radio'][1].checked} onChange={(e) => { onRadioChange('account-radio', 1, e.target.checked) }} value={radiosChecked['account-radio'][1].value}>Paid Off</Radio>
                                            <Radio dataUI={getDataUI()} name="account-radio" checked={radiosChecked['account-radio'][2].checked} onChange={(e) => { onRadioChange('account-radio', 2, e.target.checked) }} value={radiosChecked['account-radio'][2].value}>Closed</Radio>
                                        </div>
                                        <Button dataUI={getDataUI()} disabled={toolBtnDisabled} btnType="secondary" icon='fal fa-trash' className="ml-m">Remove Linkages</Button>
                                    </ReactAux>
                                }
                                rightSide={
                                    <LinkWithIcon dataUI={getDataUI()} icon="fal fa-info-circle" iconPlace="left">
                                        <a href="/">Info</a>
                                    </LinkWithIcon>
                                }

                            />

                            <InfoBox  dataUI={getDataUI()} className="mb-s">
                                The following Covenants and Required Documents are linked to Accounts that have been Paid-Off or Closed.
                            </InfoBox>

                            <SimpleTableContainer dataUI={getDataUI()}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th><Checkbox dataUI={getDataUI()} checked={checkAll} onChange={(e) => { onCheckAll(e.target.checked); }} /></th>
                                            <th>Account</th>
                                            <th>Account Status</th>
                                            <th>Tickler ID</th>
                                            <th>Covenant/Required Doc</th>
                                            <th>Tickler Comments</th>
                                            <th>Type</th>
                                            <th>Entities</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.from(Array(NUMBER_TABLE_ROWS).keys()).map((el, i) => {
                                            const index = i;
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <Checkbox dataUI={getDataUI()}
                                                            checked={checkboxSelected[index]}
                                                            onChange={(e) => onCheck(index, e.target.checked)}
                                                        />
                                                    </td>
                                                    <td>887469100</td>
                                                    <td>Paid-off</td>
                                                    <td>2928842</td>
                                                    <td><a className="lv-link-styles" href="/" title="link">01 SHAZ Annual AutoCreateDoc Test</a></td>
                                                    <td>887469100</td>
                                                    <td>Required Document</td>
                                                    <td>BARRETT'S BOW HUNTING LLC - CR 887469100</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </SimpleTableContainer>
                        </MainContainerScrollable>
                    </TabContentItem>

                    <TabContentItem dataUI={getDataUI()}>
                        <MainContainerScrollable>
                            <Toolbar
                                dataUI={getDataUI()}
                                className="mb-s"
                                leftSide={
                                    <ReactAux>
                                        <Input dataUI={getDataUI()} icon='fal fa-search' />
                                        <Label dataUI={getDataUI()} className="ml-m mr-s"><strong>Type:</strong></Label>
                                        <div>
                                            <Radio dataUI={getDataUI()} name="type-radio" className="mr-xs" checked={radiosChecked['type-radio'][0].checked} onChange={(e) => { onRadioChange('type-radio', 0, e.target.checked) }} value={radiosChecked['type-radio'][0].value}>Active</Radio>
                                            <Radio dataUI={getDataUI()} name="type-radio" className="mr-xs" checked={radiosChecked['type-radio'][1].checked} onChange={(e) => { onRadioChange('type-radio', 1, e.target.checked) }} value={radiosChecked['type-radio'][1].value}>Excluded</Radio>
                                        </div>
                                        {
                                            radiosChecked['type-radio'][0].checked ? (
                                                <ReactAux>
                                                    <Button dataUI={getDataUI()} disabled={toolBtnDisabledSecond} btnType="secondary" icon='far fa-times-circle' className="ml-m mr-s">Close</Button>
                                                    <Button dataUI={getDataUI()} disabled={toolBtnDisabledSecond} btnType="secondary" icon='fal fa-minus-circle'>Exclude</Button>
                                                </ReactAux>
                                            ) : <Button dataUI={getDataUI()} disabled={toolBtnDisabledSecond} btnType="secondary" icon='fal fa-check' className="ml-m mr-s">Make active</Button>
                                        }
                                    </ReactAux>
                                }
                                rightSide={
                                    <LinkWithIcon dataUI={getDataUI()} icon="fal fa-info-circle" iconPlace="left">
                                        <a href="/">Info</a>
                                    </LinkWithIcon>
                                }

                            />

                            <InfoBox  dataUI={getDataUI()} className="mb-s">
                                The following Covenants and Required Documents are linked to Accounts that have been Paid-Off or Closed.
                            </InfoBox>


                            <SimpleTableContainer dataUI={getDataUI()}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th><Checkbox dataUI={getDataUI()} checked={checkAllSe} onChange={(e) => { onCheckAll(e.target.checked); }} /></th>
                                            <th>Tickler ID</th>
                                            <th>Covenant/Required Doc</th>
                                            <th>Tickler Comments</th>
                                            <th>Type</th>
                                            <th>Orphaned Records</th>
                                            <th style={{ width: 1 }}>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.from(Array(NUMBER_TABLE_ROWS).keys()).map((el, i) => {
                                            const index = i;
                                            const danderBadges = [0, 6, 11];
                                            const succesBadges = [2, 8, 14];
                                            const textName = [0, 1, 10, 13, 15]
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <Checkbox dataUI={getDataUI()}
                                                            checked={checkboxSelectedSe[index]}
                                                            onChange={(e) => onCheck(index, e.target.checked)}
                                                        />
                                                    </td>
                                                    <td>2928842</td>
                                                    <td><a className="lv-link-styles" href="/" title="link">01 SHAZ Annual AutoCreateDoc Test</a></td>
                                                    <td>887469100</td>
                                                    <td>Required Document</td>
                                                    <td> {textName.includes(index) ? "Billy Bob Thorton" : "GMM Business 1065 - LR Pending"}</td>
                                                    <td>
                                                        {danderBadges.includes(index) && <Badge dataUI={getDataUI()} type="danger" fullWidth icon="fal fa-exclamation-triangle">Past Due</Badge>}
                                                        {succesBadges.includes(index) && <Badge dataUI={getDataUI()} type="success" fullWidth icon="fal fa-check">Credit Approved</Badge>}
                                                        {!succesBadges.includes(index) && !danderBadges.includes(index) && <Badge dataUI={getDataUI()} type="info" icon="fal fa-info-circle" fullWidth>Open</Badge>}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </SimpleTableContainer>
                        </MainContainerScrollable>
                    </TabContentItem>

                    <TabContentItem dataUI={getDataUI()}>
                        <MainContainerScrollable>
                            <Toolbar
                                dataUI={getDataUI()}
                                className="mb-s"
                                leftSide={
                                    <ReactAux>
                                        <Button dataUI={getDataUI()} btnType="secondary" icon='fal fa-file-pdf' className="mr-s">Print PDF</Button>
                                        <Button dataUI={getDataUI()} btnType="secondary" icon='fal fa-file-excel'>Print Excel</Button>
                                    </ReactAux>
                                }
                                rightSide={
                                    <LinkWithIcon dataUI={getDataUI()} icon="fal fa-info-circle" iconPlace="left">
                                        <a href="/">Info</a>
                                    </LinkWithIcon>
                                }

                            />
                            <InfoBox  dataUI={getDataUI()} className="mb-s">
                                Select dates to generate ypur report.
                            </InfoBox>

                            <Row>
                                <Col xs={2}>
                                    <Label dataUI={getDataUI()} className="d-flex justify-content-end mr-s mb-s">Date From</Label>
                                </Col>
                                <Col xs={3}>
                                    <div className="d-flex mb-s">
                                        <Input dataUI={getDataUI()} placeholder="DD/MM/YY" />
                                        <Icon  dataUI={getDataUI()} icon="fal fa-calendar-alt" size="M" className='text-primary ml-s' />
                                        <span className="text-danger">*</span>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={2}>
                                    <Label dataUI={getDataUI()} className="d-flex justify-content-end mr-s">Date to</Label>
                                </Col>
                                <Col xs={3}>
                                    <div className="d-flex mb-s">
                                        <Input dataUI={getDataUI()} placeholder="DD/MM/YY" />
                                        <Icon  dataUI={getDataUI()} icon="fal fa-calendar-alt" size="M" className='text-primary ml-s' />
                                        <span className="text-danger">*</span>
                                    </div>
                                </Col>
                            </Row>

                        </MainContainerScrollable>
                    </TabContentItem>
                </TabContent>
            </Tabs>
        </ReactAux>
    )
}

export default DemoTicklerRecord