import React from 'react';

import './docContainer.scss';

const DocContainer = (props: any) => {
    return <div className="doc-container">
        {props.children}
    </div>;
}

export default DocContainer;