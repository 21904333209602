import React from 'react';
import Highlight from 'react-highlight';

import { TreeView } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const treeViewItems = [
    {
        title: 'Samantha',
        icon: 'fal fa-user',
        children: [
            {
                title: 'Permission 1',
                icon: 'fal fa-shield-alt',
            },
            {
                title: 'Permission 2',
                icon: 'fal fa-shield-alt',
            }
        ],
    },
    {
        title: 'Jean',
        icon: 'fal fa-user',
    },
    {
        title: 'Vasko',
        icon: 'fal fa-user',
    },
    {
        title: 'Yana',
        icon: 'fal fa-user',
        children: [
            {
                title: 'Permission 3',
                icon: 'fal fa-shield-alt',
                children: [
                    {
                        title: 'Next 5',
                    },
                ],
            },
            {
                title: 'Permission 4',
                icon: 'fal fa-shield-alt',
            }
        ],
    }
];

const TooltipPage: React.FC = () => {

    return (
        <div>
            <h1 className="mb-s">TreeView</h1>

            <p className="mb-l">A tree view widget presents a hierarchical list.</p>

            <div className="mb-xl">
                <div className="background-color-2 p-m">
                    <TreeView items={treeViewItems} dataUI={getDataUI()} />
                </div>
            </div>

            <Highlight className="React">
                {
                    `
import React 'react';
import { TreeView } from '@jkhy/vsg-loanvantage-design-system';

const treeViewItems = [
    {
        title: 'Samantha',
        icon: 'fal fa-user',
        children: [
            {
                title: 'Permission 1',
                icon: 'fal fa-shield',
            },
            {
                title: 'Permission 2',
                icon: 'fal fa-shield',
            }
        ],
    },
    {
        title: 'Jean',
        icon: 'fal fa-user',
    },
    {
        title: 'Vasko',
        icon: 'fal fa-user',
    },
    {
        title: 'Yana',
        icon: 'fal fa-user',
        children: [
            {
                title: 'Permission 3',
                icon: 'fal fa-shield',
                children: [
                    {
                        title: 'Next 5',
                    },
                ],
            },
            {
                title: 'Permission 4',
                icon: 'fal fa-shield',
            }
        ],
    }
];

class Example extends React.Component {

    render() {
        return (
            <div className="background-color-2 p-m">
                <TreeView items={treeViewItems} dataUI={getDataUI()} />
            </div>
        );
    }
}

export default Example;
        `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>items</td>
                        <td>
                            {'TreeViewItem[]'}
                            <br />
                            <br />
                            {'TreeViewItem = {'}
                            <br />
                            <div className="pl-s">
                                title?: string,
                                <br />
                                titleElement?: JSX.Element | string,
                                <br />
                                icon?: string,
                                <br />
                                children?: TreeViewItem[],
                            </div>
                            {'}'}
                        </td>
                        <td className="text-center">-</td>
                        <td>
                            Array of objects to build the TreeView with.
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
};

export default TooltipPage;
