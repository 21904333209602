import React, { useState } from 'react';
import Highlight from 'react-highlight';
import { Slider } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';


const SelectPage: React.FC = () => {
    const [value1, setValue1] = useState(10);
    const [value2, setValue2] = useState(1);
    const [value3, setValue3] = useState(50);
    const [value4, setValue4] = useState(1);
    const [value5, setValue5] = useState(0);

    return (
        <div>
            <h1 className="mb-s">Slider</h1>
            <p className="mb-l">Sliders allow users to make selections from a range of values.</p>

            <div className="mb-m" style={{ maxWidth: '350px' }}>
                <Slider
                    dataUI={getDataUI()}
                    className="mb-m"
                    showArrows={false}
                    value={value1}
                    onChange={(percent) => {
                        setValue1(percent);
                        console.log(percent, ' ~~~OUT');
                    }}
                />

                <Slider
                    dataUI={getDataUI()}
                    value={value2}
                    step={0.1}
                    min={0.9}
                    max={1.4}
                    lineMarkers={true}
                    onChange={(percent) => {
                        setValue2(percent);
                        console.log(percent, ' ~~~OUT');
                    }}
                />
            </div>

            <h3 className="mb-s">Step</h3>
            <p className="mb-l">You can change the default step increment.</p>

            <div className="mb-m" style={{ maxWidth: '350px' }}>
                <Slider
                    dataUI={getDataUI()}
                    value={value5}
                    step={5}
                    min={0}
                    max={50}
                    lineMarkers={true}
                    onChange={(percent) => {
                        setValue5(percent);
                        console.log(percent, ' ~~~OUT');
                    }}
                />
            </div>

            <h3 className="mb-s">Line Markers</h3>
            <p className="mb-l">Incremented sliders can be adjusted to a specific value by referencing its value indicator. You can generate a mark for each step with lineMarkers=true.</p>

            <div style={{ maxWidth: '350px' }}>
                <Slider
                    dataUI={getDataUI()}
                    className="mb-m"
                    value={value3}
                    min={0}
                    max={100}
                    step={10}
                    lineMarkers={[
                        { value: 0, showLabel: true },
                        { value: 10, showLabel: false },
                        { value: 20, showLabel: false },
                        { value: 30, showLabel: false },
                        { value: 40, showLabel: false },
                        { value: 50, showLabel: true },
                        { value: 60, showLabel: false },
                        { value: 70, showLabel: false },
                        { value: 80, showLabel: false },
                        { value: 90, showLabel: false },
                        { value: 100, showLabel: true }
                    ]}
                    onChange={(percent) => {
                        setValue3(percent);
                        console.log(percent, ' ~~~OUT');
                    }}
                />

                <Slider
                    dataUI={getDataUI()}
                    value={value4}
                    min={1}
                    max={4}
                    step={1}
                    lineMarkers={[
                        { value: 1, displayValue: 'S' },
                        { value: 2, displayValue: 'M' },
                        { value: 3, displayValue: 'L' },
                        { value: 4, displayValue: 'XL' }
                    ]}
                    onChange={(percent) => {
                        setValue4(percent);
                        console.log(percent, ' ~~~OUT');
                    }}
                />
            </div>


            <Highlight className="React">
                {
                    `
import React, { useState } from 'react';
import { Slider } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const Example: React.FC = (props) => {
    const [value1, setValue1] = useState(10);
    const [value2, setValue2] = useState(1);
    const [value3, setValue3] = useState(50);
    const [value4, setValue4] = useState(1);
    const [value5, setValue5] = useState(0);

    return (
    <div>
        <div className="mb-m" style={{ maxWidth: '350px' }}>
            <Slider
                dataUI={getDataUI()}
                className="mb-m"
                showArrows={false}
                value={value1}
                onChange={(percent) => {
                    setValue1(percent);
                    console.log(percent, ' ~~~OUT');
                }}
            />

            <Slider
                dataUI={getDataUI()}
                value={value2}
                step={0.1}
                min={0.9}
                max={1.4}
                lineMarkers={true}
                onChange={(percent) => {
                    setValue2(percent);
                    console.log(percent, ' ~~~OUT');
                }}
            />
        </div>

        // Step
        <div className="mb-m" style={{ maxWidth: '350px' }}>
            <Slider
                dataUI={getDataUI()}
                value={value5}
                step={5}
                min={0}
                max={50}
                lineMarkers={true}
                onChange={(percent) => {
                    setValue5(percent);
                    console.log(percent, ' ~~~OUT');
                }}
            />
        </div>

        // Line Markers
        <div style={{ maxWidth: '350px' }}>
            <Slider
                dataUI={getDataUI()}
                className="mb-m"
                value={value3}
                min={0}
                max={100}
                step={10}
                lineMarkers={[
                    { value: 0, showLabel: true },
                    { value: 10, showLabel: false },
                    { value: 20, showLabel: false },
                    { value: 30, showLabel: false },
                    { value: 40, showLabel: false },
                    { value: 50, showLabel: true },
                    { value: 60, showLabel: false },
                    { value: 70, showLabel: false },
                    { value: 80, showLabel: false },
                    { value: 90, showLabel: false },
                    { value: 100, showLabel: true }
                ]}
                onChange={(percent) => {
                    setValue3(percent);
                    console.log(percent, ' ~~~OUT');
                }}
            />

            <Slider
                dataUI={getDataUI()}
                value={value4}
                min={1}
                max={4}
                step={1}
                lineMarkers={[
                    { value: 1, displayValue: 'S' },
                    { value: 2, displayValue: 'M' },
                    { value: 3, displayValue: 'L' },
                    { value: 4, displayValue: 'XL' }
                ]}
                onChange={(percent) => {
                    setValue4(percent);
                    console.log(percent, ' ~~~OUT');
                }}
            />
        </div>
    </div>
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>value</td>
                        <td>number</td>
                        <td className="text-center">-</td>
                        <td>The value of the slider.</td>
                    </tr>
                    <tr>
                        <td>min</td>
                        <td>number</td>
                        <td>0</td>
                        <td>The minimum allowed value of the slider. Should not be equal to max.</td>
                    </tr>
                    <tr>
                        <td>max</td>
                        <td>number</td>
                        <td>100</td>
                        <td>The maximum allowed value of the slider. Should not be equal to min.</td>
                    </tr>
                    <tr>
                        <td>step</td>
                        <td>number</td>
                        <td>1</td>
                        <td>The granularity with which the slider can step through values.</td>
                    </tr>
                    <tr>
                        <td>lineMarkers</td>
                        <td>{'boolean | { value: number; showLabel?: boolean; displayValue?: string }[]'}</td>
                        <td>false</td>
                        <td>
                            Line marks indicate predetermined values to which the user can move the slider. If true the
                            marks are spaced according the value of the step prop.
                        </td>
                    </tr>
                    <tr>
                        <td>showArrows</td>
                        <td>boolean</td>
                        <td>true</td>
                        <td>If true, arrows for increase and decrease will be displayed.</td>
                    </tr>
                    <tr>
                        <td>onChange</td>
                        <td>{'(percent: number) => void'}</td>
                        <td className="text-center">-</td>
                        <td>Returns current selected percent.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default SelectPage;
