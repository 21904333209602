import React, { useState, useMemo } from 'react';
import Highlight from 'react-highlight';

import { ColorPicker } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';


const ColorPickerPage: React.FC = () => {

    const [value, setValue] = useState('#06185F');

    const highlightElement = useMemo(() =>
        <Highlight className="React">
            {
                `
import React, { useState } from 'react';

import { ColorPicker } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const Example: React.FC = () => {

    const [value, setValue] = useState('#06185F');

    return (
        <div>
            <ColorPicker
                dataUI={getDataUI()}
                value={value}
                onChange={(e) => {
                    console.log(e.target.value, ' ~~~onChange value');
                    setValue(e.target.value);
                }}
            />
        </div>
    );
}

export default Example;
        `}
        </Highlight>
        , []);

    return (
        <div>
            <h1 className="mb-s">ColorPicker</h1>

            <p className="mb-l">The ColorPicker component extends the native input element with type 'color'.</p>

            <ColorPicker
                dataUI={getDataUI()}
                value={value}
                onChange={(e: any) => {
                    console.log(e.target.value, ' ~~~onChange value');
                    setValue(e.target.value);
                }}
            />

            {highlightElement}

            <h2 className="mb-m mt-l">API</h2>

            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>all native input attributes</td>
                        <td className='text-center'>-</td>
                        <td className='text-center'>-</td>
                        <td>You can use all native input attributes like: "value", "disabled", "onChange"...</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
};

export default ColorPickerPage;
