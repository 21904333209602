
import React, { useCallback, useRef, useState } from "react";
import { useLocation } from 'react-router-dom';
import {
    ReactAux, MainContainerHeader, ActionContainer,
    Button, ActionContainerHeader, ActionContainerCrumbs, Toolbar,
    Label, Radio,
    ActionSettings,
    LinkWithIcon,
    Tooltip,
    Checkbox,
    MainContainerScrollable,
    TabContent,
    TabContentItem,
    TabItem,
    TabItemTitle,
    TabList,
    Tabs,
    VerticalTabContent,
    VerticalTabContentItem,
    VerticalTabItem,
    VerticalTabList,
    VerticalTabs,
    Editor,
    EditorContainer,
    EditorTools,
    classNameHelper,
    Col,
    Input,
    Row,
    Textarea,
    Collapse,
    InfoBox,
    SimpleTableContainer,
    DraggableItem,
    DraggableItemActions,
    DraggableItemTitle,
    DragNDropContext,
    DragNDropSection,
    DragNDropSectionHeader,
    Droparea,
    DragNDropUtils,
    Icon
} from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from "../helpers/helpers";

const defaultGeneralAvailable = [
    { title: 'Analysis', id: 'item-1' }, { title: 'Branch', id: 'item-2' }, { title: 'CD Completed', id: 'item-3' }, { title: 'CD Required', id: 'item-4' },
    { title: 'CBA Completed', id: 'item-5' }, { title: 'CBA Required', id: 'item-6' }, { title: 'HDMA Reportable Closing Percent', id: 'item-7' }, { title: 'Competition', id: 'item-8' },
    { title: 'Create Date', id: 'item-9' }, { title: 'Days in Decisioning', id: 'item-10' }, { title: 'Days in Last Review', id: 'item-11' }, { title: 'General item 1', id: 'item-12' },
    { title: 'General item 2', id: 'item-13' }, { title: 'General item 3', id: 'item-14' }, { title: 'General item 4', id: 'item-15' }, { title: 'General item 5', id: 'item-16' }, { title: 'General item 6', id: 'item-17' }
];

const tableData = [
    { field: '', change: '' },
    { field: 'Bramch Question', change: 'Do you conduct business with a specific branch location?' },
    { field: 'Officer Question', change: 'Do you conduct business with an existing loan officer?' },
    { field: '(Zip Code) Location', change: '' },
    { field: '(State) Location', change: 'Which State?' },
    { field: '(County) Location', change: '' },
    { field: 'Esign Consent Question', change: '' },
]

const DemoApplicationModels = () => {
    const location = useLocation();

    const hasQueryParam = (param: any) => {
        const searchParams = new URLSearchParams(location.search);
        return searchParams.has(param);
    };

    const [currentlyActiveTab, setCurrentlyActiveTab] = useState(3);
    const [verticalTabIndex, setVerticalTabIndex] = useState(hasQueryParam('information') ? 1 : 0);
    const [isExpanded, setIsExpanded] = useState(true);
    const [isExpanded2, setIsExpanded2] = useState(true);
    const [declarationsDisabled, setDeclarationsDisabled] = useState(false);
    const [declarations2Disabled, setDeclarations2Disabled] = useState(true);
    const [hiddenCheckboxes, setHiddenCheckboxes] = useState(Array(7).fill(false));
    const [requiredCheckboxes, setRequiredCheckboxes] = useState(Array(7).fill(false));
    const [boldCheckboxes, setBoldCheckboxes] = useState(Array(7).fill(false));
    const [hoverHelpCheckboxes, setHoverHelpCheckboxes] = useState(Array(7).fill(false));

    const toggleAll = (checkboxes: any[]) => {
        const newValue = !checkboxes[0];
        return checkboxes.map((_, index) => newValue);
    }

    const areAllChecked = (checkboxes: any[]) => {
        return checkboxes.every((checked, index) => index === 0 ? true : checked);
    };

    const toggleCheckbox = (checkboxes: any[], index: number) => {
        checkboxes[index] = !checkboxes[index];

        if (areAllChecked(checkboxes)) {
            checkboxes[0] = true;
        } else {
            checkboxes[0] = false;
        }

        return [...checkboxes];
    }

    // Editor
    const editorRef = useRef<any>();
    const [value, setValue] = React.useState("<p>Editor default value...</p>");
    const [editorDisabled, setEditorDisabled] = useState(false);

    const {
        Bold, Italic, Underline, Strikethrough, Subscript, Superscript,
        ForeColor, BackColor,
        AlignLeft, AlignCenter, AlignRight, AlignJustify,
        Indent, OrderedList, UnorderedList,
        FontSize, FontName, FormatBlock,
        InsertImage, ViewHtml,
        Link,
    } = EditorTools;

    const customToolRendering = useCallback((Tool: any, colorPicker = false) => {
        return function (props: any) {
            return (
                <Tool
                    {...props}
                    disabled={editorDisabled}
                    // Set disabled for color picker and text color
                    {...(colorPicker && editorDisabled) && { colorPickerProps: { disabled: editorDisabled } }}
                />
            );
        };
    }, [editorDisabled]);

    const onEditorValueChange = (e: any) => {
        setValue(e.html);
    }
    // Editor end

    // DND 
    const AVAILABLE_SECTION_NAME = 'available';
    const ASSIGNED_SECTION_NAME = 'assigned';

    const [availableItems, setAvailableItems] = useState<{ title: string, id: string, type: string }[]>(defaultGeneralAvailable.map(e => { return { ...e, type: AVAILABLE_SECTION_NAME } }))
    const [assignedItems, setAssignedItems] = useState<{ title: string, id: string, type: string }[]>([])
    const [dragStartEvent, setDragStartEvent] = useState<Event | null>(null);
    const [dragUpdateEvent, setDragUpdateEvent] = useState<Event | null>(null);
    const [availableSearch, setAvailableSearch] = useState<string>('');
    const [assignedSearch, setAssignedSearch] = useState<string>('');

    function getList(listId: string) {
        if (listId === AVAILABLE_SECTION_NAME) {
            return availableItems;
        } else if (listId === ASSIGNED_SECTION_NAME) {
            return assignedItems;
        }
        return null;
    }

    function handleDragStart(event: any) {
        setDragStartEvent(event);
        setDragUpdateEvent(null);
    }

    function handleDragUpdate(event: any) {
        setDragStartEvent(null);
        setDragUpdateEvent(event);
    }

    function onDragEnd(result: any) {
        const { source, destination } = result;
        if (!result.destination) {
            setDragStartEvent(null);
            setDragUpdateEvent(null);
            return;
        }

        if (source.droppableId === destination.droppableId) {
            if (source.droppableId === AVAILABLE_SECTION_NAME) {
                const items = DragNDropUtils.reorderList(
                    availableItems,
                    source.index,
                    destination.index
                );

                setAvailableItems(items);
            } else if (source.droppableId === ASSIGNED_SECTION_NAME) {
                const items = DragNDropUtils.reorderList(
                    assignedItems,
                    source.index,
                    destination.index
                );

                setAssignedItems(items);
            }
        } else {
            const result: any = DragNDropUtils.moveBetweenLists(
                getList(source.droppableId) || [],
                getList(destination.droppableId) || [],
                source.index,
                destination.index
            );

            if (source.droppableId === AVAILABLE_SECTION_NAME) {
                setAvailableItems(result[0]);
                setAssignedItems(result[1]);
            } else if (source.droppableId === ASSIGNED_SECTION_NAME) {
                setAssignedItems(result[0]);
                setAvailableItems(result[1]);
            }
        }

        setDragStartEvent(null);
        setDragUpdateEvent(null);
    }

    function setSearchValue(sectionName: string, value: string) {
        switch (sectionName) {
            case AVAILABLE_SECTION_NAME:
                setAvailableSearch(value);
                break;
            case ASSIGNED_SECTION_NAME:
                setAssignedSearch(value);
                break;
            default:
                return;
        }
    }

    function returnItemToAvailable(index: number) {
        const assignedClone = Array.from(assignedItems);
        const availableClone = Array.from(availableItems);
        const [removed] = assignedClone.splice(index, 1);
        availableClone.push(removed);

        setAvailableItems(availableClone);
        setAssignedItems(assignedClone);
    }

    function addAllToAssigned() {
        let assignedClone = Array.from(assignedItems);
        let availableClone = Array.from(availableItems);
        assignedClone = [...assignedClone, ...availableClone];
        availableClone = [];

        setAvailableItems(availableClone);
        setAssignedItems(assignedClone);
    }

    function removeAllAssigned() {
        let availableClone = Array.from(availableItems);
        let assignedClone = Array.from(assignedItems);
        for (const item of assignedClone) {
            availableClone.push(item);
        }

        setAvailableItems(availableClone);
        setAssignedItems([]);
    }

    function moveAvailableItemToAssigned(index: number) {
        const assignedClone = Array.from(assignedItems);
        const availableClone = Array.from(availableItems);
        const [removed] = availableClone.splice(index, 1);
        assignedClone.push(removed);

        setAvailableItems(availableClone);
        setAssignedItems(assignedClone);
    }
    // DND end

    return (
        <ReactAux>
            <MainContainerHeader dataUI={getDataUI()}>
                <ActionContainer dataUI={getDataUI()}>
                    <ActionContainerHeader dataUI={getDataUI()}>
                        <ActionContainerCrumbs dataUI={getDataUI()} crumbs={['Admin', 'Online', 'Online Application Models', 'Robin’s Do Not Edit']} />
                        <ActionSettings dataUI={getDataUI()} isActive>
                            <Tooltip title="Learn more">
                                <LinkWithIcon dataUI={getDataUI()} icon="fal fa-info-circle">
                                    <a onClick={(e) => { e.preventDefault(); }} href="/">About Online Application Models</a>
                                </LinkWithIcon>
                            </Tooltip>
                        </ActionSettings>
                    </ActionContainerHeader>
                </ActionContainer>
            </MainContainerHeader>
            <Toolbar
                dataUI={getDataUI()}
                className="mt-s mb-m"
                leftSide={
                    <>
                        <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-save" disabled>Save</Button>
                        <Button dataUI={getDataUI()} className="mr-s" icon="far fa-times-circle" btnType="secondary">Cancel</Button>
                        <Button dataUI={getDataUI()} className="mr-m" icon="fal fa-copy" btnType="secondary">Copy</Button>
                        <Label className="text-bold mr-s" dataUI={getDataUI()}>Customer Type:</Label>
                        <Radio className="mr-s" name="radio-1" readOnly defaultChecked dataUI={getDataUI()}>Commercial</Radio>
                        <Radio className="mr-m" name="radio-1" readOnly dataUI={getDataUI()}>Consumer</Radio>
                        <Label className="text-bold mr-s" dataUI={getDataUI()}>Active:</Label>
                        <Checkbox dataUI={getDataUI()} />
                    </>
                }
            />

            <Tabs dataUI={getDataUI()} className="mt-m" currentlyActiveTabIndex={currentlyActiveTab} onTabIndexChange={i => setCurrentlyActiveTab(i)}>
                <TabList dataUI={getDataUI()}>
                    <TabItem dataUI={getDataUI()}>
                        <TabItemTitle dataUI={getDataUI()}>Branding</TabItemTitle>
                    </TabItem>
                    <TabItem dataUI={getDataUI()}>
                        <TabItemTitle dataUI={getDataUI()}>Application & Facility</TabItemTitle>
                    </TabItem>
                    <TabItem dataUI={getDataUI()}>
                        <TabItemTitle dataUI={getDataUI()}>Emails</TabItemTitle>
                    </TabItem>
                    <TabItem dataUI={getDataUI()}>
                        <TabItemTitle dataUI={getDataUI()}>Applicant Pages</TabItemTitle>
                    </TabItem>
                    <TabItem dataUI={getDataUI()}>
                        <TabItemTitle dataUI={getDataUI()}>Co-Applicant Pages</TabItemTitle>
                    </TabItem>
                    <TabItem dataUI={getDataUI()}>
                        <TabItemTitle dataUI={getDataUI()}>Results Pages</TabItemTitle>
                    </TabItem>
                    <TabItem dataUI={getDataUI()}>
                        <TabItemTitle dataUI={getDataUI()}>FAQ</TabItemTitle>
                    </TabItem>
                </TabList>
                <TabContent dataUI={getDataUI()} >
                    <TabContentItem dataUI={getDataUI()}>Branding</TabContentItem>
                    <TabContentItem dataUI={getDataUI()}>Application & Facility</TabContentItem>
                    <TabContentItem dataUI={getDataUI()}>Emails</TabContentItem>
                    <TabContentItem dataUI={getDataUI()}>

                        <MainContainerScrollable>
                            <div className="d-flex height-full">
                                <VerticalTabs dataUI={getDataUI()} type="outlined" currentlyActiveTabIndex={verticalTabIndex} onTabIndexChange={index => setVerticalTabIndex(index)}>
                                    <VerticalTabList dataUI={getDataUI()}>
                                        <VerticalTabItem dataUI={getDataUI()}>Welcome</VerticalTabItem>
                                        <VerticalTabItem dataUI={getDataUI()}>Information</VerticalTabItem>
                                        <VerticalTabItem dataUI={getDataUI()}>Request</VerticalTabItem>
                                        <VerticalTabItem dataUI={getDataUI()}>Auto Details</VerticalTabItem>
                                        <VerticalTabItem dataUI={getDataUI()}>Personal Party Match</VerticalTabItem>
                                        <VerticalTabItem dataUI={getDataUI()}>Personal Information</VerticalTabItem>
                                        <VerticalTabItem dataUI={getDataUI()}>Address</VerticalTabItem>
                                        <VerticalTabItem dataUI={getDataUI()}>Income & Expenses</VerticalTabItem>
                                        <VerticalTabItem dataUI={getDataUI()}>Business</VerticalTabItem>
                                        <VerticalTabItem dataUI={getDataUI()}>Business Address</VerticalTabItem>
                                        <VerticalTabItem dataUI={getDataUI()}>Business Other Information</VerticalTabItem>
                                        <VerticalTabItem dataUI={getDataUI()}>Business References</VerticalTabItem>
                                        <VerticalTabItem dataUI={getDataUI()}>Business Obligations</VerticalTabItem>
                                        <VerticalTabItem dataUI={getDataUI()}>Business Deposits</VerticalTabItem>
                                        <VerticalTabItem dataUI={getDataUI()}>Guarantor</VerticalTabItem>
                                        <VerticalTabItem dataUI={getDataUI()}>Collateral</VerticalTabItem>
                                        <VerticalTabItem dataUI={getDataUI()}>Declarations</VerticalTabItem>
                                        <VerticalTabItem dataUI={getDataUI()}>Disclosures</VerticalTabItem>
                                        <VerticalTabItem dataUI={getDataUI()}>Terms and Conditions</VerticalTabItem>
                                        <VerticalTabItem dataUI={getDataUI()}>Required Documents</VerticalTabItem>
                                    </VerticalTabList>
                                    <VerticalTabContent dataUI={getDataUI()}>
                                        <VerticalTabContentItem dataUI={getDataUI()}>
                                            <div className="p-m">
                                                <Row className="mb-s">
                                                    <Col xs={3}>
                                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Display this page</Label>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Radio dataUI={getDataUI()} name="radio-2" className="mr-s" checked={!editorDisabled} onChange={() => setEditorDisabled(false)}>Always</Radio>
                                                        <Radio dataUI={getDataUI()} name="radio-2" checked={editorDisabled} onChange={() => setEditorDisabled(true)}>Never</Radio>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-s">
                                                    <Col xs={3}>
                                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Tab Label</Label>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Input dataUI={getDataUI()} className="width-l" disabled={editorDisabled} />
                                                    </Col>
                                                </Row>
                                                <Row className="mb-s">
                                                    <Col xs={3}>
                                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Page Header</Label>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Input dataUI={getDataUI()} className="width-l" disabled={editorDisabled} />
                                                    </Col>
                                                </Row>
                                                <Row className="mb-s">
                                                    <Col xs={3}>
                                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Content</Label>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <EditorContainer dataUI={getDataUI()}>
                                                            <Editor
                                                                ref={editorRef}
                                                                value={value}
                                                                onChange={onEditorValueChange}
                                                                className={classNameHelper(editorDisabled && "lv-disabled-editor")}
                                                                tools={[
                                                                    [customToolRendering(Bold), customToolRendering(Italic), customToolRendering(Underline), customToolRendering(Strikethrough),
                                                                    () => <hr className="lv-vertical border-color-3 ml-s mr-s" />,
                                                                    customToolRendering(AlignLeft), customToolRendering(AlignCenter), customToolRendering(AlignRight), customToolRendering(AlignJustify),
                                                                    () => <hr className="lv-vertical border-color-3 ml-s mr-s" />,
                                                                    customToolRendering(OrderedList), customToolRendering(UnorderedList), customToolRendering(Indent),
                                                                    () => <hr className="lv-vertical border-color-3 ml-s mr-s" />,
                                                                    customToolRendering(InsertImage), customToolRendering(Link),
                                                                    () => <hr className="lv-vertical border-color-3 ml-s mr-s" />,
                                                                    customToolRendering(ViewHtml),
                                                                    () => <hr className="lv-vertical border-color-3 ml-s mr-s" />,
                                                                    customToolRendering(Subscript), customToolRendering(Superscript),
                                                                    () => <hr className="lv-vertical border-color-3 ml-s mr-s" />,
                                                                    customToolRendering(FormatBlock), customToolRendering(FontName), customToolRendering(FontSize),
                                                                    () => <hr className="lv-vertical border-color-3 ml-s mr-s" />,
                                                                    customToolRendering(ForeColor, true), customToolRendering(BackColor, true),
                                                                    ]
                                                                ]}
                                                                contentStyle={{ height: 300, color: 'red' }}

                                                            />
                                                        </EditorContainer>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-s">
                                                    <Col xs={3}>
                                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Content</Label>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <EditorContainer dataUI={getDataUI()}>
                                                            <Editor
                                                                ref={editorRef}
                                                                value={value}
                                                                onChange={onEditorValueChange}
                                                                className={classNameHelper(editorDisabled && "lv-disabled-editor")}
                                                                tools={[
                                                                    [customToolRendering(Bold), customToolRendering(Italic), customToolRendering(Underline), customToolRendering(Strikethrough),
                                                                    () => <hr className="lv-vertical border-color-3 ml-s mr-s" />,
                                                                    customToolRendering(AlignLeft), customToolRendering(AlignCenter), customToolRendering(AlignRight), customToolRendering(AlignJustify),
                                                                    () => <hr className="lv-vertical border-color-3 ml-s mr-s" />,
                                                                    customToolRendering(OrderedList), customToolRendering(UnorderedList), customToolRendering(Indent),
                                                                    () => <hr className="lv-vertical border-color-3 ml-s mr-s" />,
                                                                    customToolRendering(InsertImage), customToolRendering(Link),
                                                                    () => <hr className="lv-vertical border-color-3 ml-s mr-s" />,
                                                                    customToolRendering(ViewHtml),
                                                                    () => <hr className="lv-vertical border-color-3 ml-s mr-s" />,
                                                                    customToolRendering(Subscript), customToolRendering(Superscript),
                                                                    () => <hr className="lv-vertical border-color-3 ml-s mr-s" />,
                                                                    customToolRendering(FormatBlock), customToolRendering(FontName), customToolRendering(FontSize),
                                                                    () => <hr className="lv-vertical border-color-3 ml-s mr-s" />,
                                                                    customToolRendering(ForeColor, true), customToolRendering(BackColor, true),
                                                                    ]
                                                                ]}
                                                                contentStyle={{ height: 300, color: 'red' }}

                                                            />
                                                        </EditorContainer>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </VerticalTabContentItem>
                                        <VerticalTabContentItem dataUI={getDataUI()}>
                                            <div className="p-m">
                                                <Row className="mb-s">
                                                    <Col xs={4}>
                                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Display this page</Label>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Radio dataUI={getDataUI()} name="radio-2" className="mr-s" checked={!editorDisabled} onChange={() => setEditorDisabled(false)}>Always</Radio>
                                                        <Radio dataUI={getDataUI()} name="radio-2" checked={editorDisabled} onChange={() => setEditorDisabled(true)}>Never</Radio>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-s">
                                                    <Col xs={4}>
                                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Tab Label</Label>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Input dataUI={getDataUI()} className="width-l" disabled={editorDisabled} />
                                                    </Col>
                                                </Row>
                                                <Row className="mb-s">
                                                    <Col xs={4}>
                                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Page Header</Label>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Input dataUI={getDataUI()} className="width-l" disabled={editorDisabled} />
                                                    </Col>
                                                </Row>
                                                <Row className="mb-m">
                                                    <Col xs={4}>
                                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Header Text</Label>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Textarea dataUI={getDataUI()} className="width-l" disabled={editorDisabled} />
                                                    </Col>
                                                </Row>

                                                <Collapse
                                                    className="mb-s"
                                                    isLightType
                                                    elementRenderRight={() => <div className="d-flex">
                                                        <Label dataUI={getDataUI()} className="mr-s">Display this section:</Label>
                                                        <Radio dataUI={getDataUI()} name="radio-3" className="mr-s" checked={!declarationsDisabled} onChange={() => setDeclarationsDisabled(false)}>Always</Radio>
                                                        <Radio dataUI={getDataUI()} name="radio-3" checked={declarationsDisabled} onChange={() => setDeclarationsDisabled(true)}>Never</Radio>
                                                    </div>}
                                                    title="Declarations"
                                                    expanded={isExpanded}
                                                    onClick={() => setIsExpanded(!isExpanded)}
                                                    dataUI={getDataUI()}
                                                >
                                                    <Toolbar dataUI={getDataUI()} className="mb-m" leftSide={
                                                        <>
                                                            <Button dataUI={getDataUI()} className="mr-m" icon="fal fa-plus" disabled={declarationsDisabled}>Add New Declaration Question</Button>
                                                            <InfoBox dataUI={getDataUI()} className={declarationsDisabled ? 'text-muted' : ''}>
                                                                Assign custom declaration questions to be answered by the primary applicant only.
                                                            </InfoBox>
                                                        </>
                                                    } />


                                                    <DragNDropContext onDragStart={handleDragStart} onDragUpdate={handleDragUpdate} onDragEnd={onDragEnd}>
                                                        <Row className="mb-m" style={{ height: 320, flexWrap: 'unset' }}>
                                                            <Col xs={6}>
                                                                <DragNDropSection dataUI={getDataUI()}>
                                                                    <DragNDropSectionHeader dataUI={getDataUI()}>
                                                                        <div className="d-flex justify-content-between align-items-center mb-m">
                                                                            <h2 className={declarationsDisabled ? 'text-muted' : ''}>Available ({availableItems.length})</h2>
                                                                            <Button dataUI={getDataUI()} onClick={() => addAllToAssigned()} btnType="secondary" icon="fal fa-file-import" disabled={declarationsDisabled}>Add all</Button>
                                                                        </div>
                                                                        <Input
                                                                            dataUI={getDataUI()}
                                                                            name="input-icon"
                                                                            value={availableSearch}
                                                                            onChange={(ev: any) => setSearchValue(AVAILABLE_SECTION_NAME, ev.target.value)}
                                                                            icon='fal fa-search'
                                                                            disabled={declarationsDisabled}
                                                                        />
                                                                    </DragNDropSectionHeader>
                                                                    <Droparea dataUI={getDataUI()} dropareaID={AVAILABLE_SECTION_NAME} allowedItems={[AVAILABLE_SECTION_NAME, ASSIGNED_SECTION_NAME]}
                                                                        dragStartEvent={dragStartEvent}
                                                                        dragUpdateEvent={dragUpdateEvent}
                                                                    >

                                                                        {availableItems.map((item, index) => {
                                                                            return (
                                                                                !availableSearch || (availableSearch && item.title.includes(availableSearch)) ?
                                                                                    <DraggableItem dataUI={getDataUI()} key={item.title} index={index} draggableID={item.id} isDragDisabled={declarationsDisabled}>
                                                                                        <DraggableItemActions dataUI={getDataUI()}>
                                                                                            <Tooltip title={declarationsDisabled ? '' : 'If you entered yes to any of these questions, please provide details.'}>
                                                                                                <Icon dataUI={getDataUI()} size="M" icon="fal fa-info-circle" className={declarationsDisabled ? 'text-muted' : ''} />
                                                                                            </Tooltip>
                                                                                            <DraggableItemTitle dataUI={getDataUI()} className={declarationsDisabled ? 'text-muted' : ''}>{item.title}</DraggableItemTitle>
                                                                                        </DraggableItemActions>
                                                                                        <DraggableItemActions dataUI={getDataUI()}>
                                                                                            <Tooltip title={declarationsDisabled ? '' : 'Edit'}>
                                                                                                <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-pen" disabled={declarationsDisabled} />
                                                                                            </Tooltip>
                                                                                            <Tooltip title={declarationsDisabled ? '' : 'Move to assigned'}>
                                                                                                <Button dataUI={getDataUI()} onClick={() => moveAvailableItemToAssigned(index)} btnType="icon" icon="fal fa-arrow-right" disabled={declarationsDisabled} />
                                                                                            </Tooltip>
                                                                                        </DraggableItemActions>
                                                                                    </DraggableItem>
                                                                                    :
                                                                                    ''
                                                                            )
                                                                        })}
                                                                    </Droparea>
                                                                </DragNDropSection>
                                                            </Col>

                                                            <Col xs={6}>
                                                                <DragNDropSection dataUI={getDataUI()}>
                                                                    <DragNDropSectionHeader dataUI={getDataUI()}>
                                                                        <div className="d-flex justify-content-between align-items-center mb-m">
                                                                            <h2 className={declarationsDisabled ? 'text-muted' : ''}>Assigned ({assignedItems.length})</h2>
                                                                            <Button dataUI={getDataUI()} onClick={() => removeAllAssigned()} btnType="secondary" icon="fal fa-file-import" disabled={declarationsDisabled}>Remove all</Button>
                                                                        </div>
                                                                        <Input
                                                                            dataUI={getDataUI()}
                                                                            name="input-icon"
                                                                            value={assignedSearch}
                                                                            onChange={(ev: any) => setSearchValue(ASSIGNED_SECTION_NAME, ev.target.value)}
                                                                            icon='fal fa-search'
                                                                            disabled={declarationsDisabled}
                                                                        />
                                                                    </DragNDropSectionHeader>
                                                                    <Droparea
                                                                        dataUI={getDataUI()}
                                                                        dropareaID={ASSIGNED_SECTION_NAME}
                                                                        allowedItems={[ASSIGNED_SECTION_NAME, AVAILABLE_SECTION_NAME]}
                                                                        callToActionText="Drop your files here."
                                                                        dragStartEvent={dragStartEvent}
                                                                        dragUpdateEvent={dragUpdateEvent}
                                                                    >
                                                                        {assignedItems.map((item, index) => {
                                                                            return (
                                                                                !assignedSearch || (assignedSearch && item.title.includes(assignedSearch)) ?
                                                                                    <DraggableItem dataUI={getDataUI()} key={item.title} index={index} draggableID={item.id} isDragDisabled={declarationsDisabled}>
                                                                                        <DraggableItemActions dataUI={getDataUI()}>
                                                                                            <Tooltip title={declarationsDisabled ? '' : 'If you entered yes to any of these questions, please provide details.'}>
                                                                                                <Icon dataUI={getDataUI()} size="M" icon="fal fa-info-circle" className={declarationsDisabled ? 'text-muted' : ''} />
                                                                                            </Tooltip>
                                                                                            <DraggableItemTitle dataUI={getDataUI()} className={declarationsDisabled ? 'text-muted' : ''}>{item.title}</DraggableItemTitle>
                                                                                        </DraggableItemActions>
                                                                                        <DraggableItemActions dataUI={getDataUI()}>
                                                                                            <Tooltip title={declarationsDisabled ? '' : 'Edit'}>
                                                                                                <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-pen" disabled={declarationsDisabled} />
                                                                                            </Tooltip>
                                                                                            <Tooltip title={declarationsDisabled ? '' : 'Remove from assigned'}>
                                                                                                <Button dataUI={getDataUI()} onClick={() => returnItemToAvailable(index)} btnType="icon" icon="fal fa-times" disabled={declarationsDisabled} />
                                                                                            </Tooltip>
                                                                                        </DraggableItemActions>
                                                                                    </DraggableItem>
                                                                                    :
                                                                                    ''
                                                                            )
                                                                        })}
                                                                    </Droparea>
                                                                </DragNDropSection>
                                                            </Col>
                                                        </Row>
                                                    </DragNDropContext>


                                                    <SimpleTableContainer dataUI={getDataUI()}>
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th>Field</th>
                                                                    <th>Change Field Label To</th>
                                                                    <th className="text-center" style={{ width: 1 }}>
                                                                        <div className="mb-xs">Hidden</div>
                                                                        <Checkbox dataUI={getDataUI()} checked={areAllChecked(hiddenCheckboxes)} onChange={() => {
                                                                            setHiddenCheckboxes(toggleAll(hiddenCheckboxes))
                                                                        }} />
                                                                    </th>
                                                                    <th className="text-center" style={{ width: 1 }}>
                                                                        <div className="mb-xs">Required</div>
                                                                        <Checkbox dataUI={getDataUI()} checked={areAllChecked(requiredCheckboxes)} onChange={() => {
                                                                            setRequiredCheckboxes(toggleAll(requiredCheckboxes))
                                                                        }} />
                                                                    </th>
                                                                    <th className="text-center" style={{ width: 1 }}>
                                                                        <div className="mb-xs">Bold</div>
                                                                        <Checkbox dataUI={getDataUI()} checked={areAllChecked(boldCheckboxes)} onChange={() => {
                                                                            setBoldCheckboxes(toggleAll(boldCheckboxes))
                                                                        }} />
                                                                    </th>
                                                                    <th className="text-center white-space-nowrap" style={{ width: 1 }}>
                                                                        <div className="mb-xs">Hover Help</div>
                                                                        <Checkbox dataUI={getDataUI()} checked={areAllChecked(hoverHelpCheckboxes)} onChange={() => {
                                                                            setHoverHelpCheckboxes(toggleAll(hoverHelpCheckboxes))
                                                                        }} />
                                                                    </th>
                                                                    <th className="text-center white-space-nowrap" style={{ width: 1, verticalAlign: 'top' }}>
                                                                        <div className="mb-xs">
                                                                            Hover Text
                                                                        </div>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {tableData.map((item, index) => (
                                                                    <ReactAux key={index}>
                                                                        {item.field === '' ? null :
                                                                            <tr>
                                                                                <td>{item.field}</td>
                                                                                <td>{item.change}</td>
                                                                                <td className="text-center">
                                                                                    <Checkbox dataUI={getDataUI()} checked={hiddenCheckboxes[index]} onChange={() => {
                                                                                        setHiddenCheckboxes(toggleCheckbox(hiddenCheckboxes, index))
                                                                                    }} />
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <Checkbox dataUI={getDataUI()} checked={requiredCheckboxes[index]} onChange={() => {
                                                                                        setRequiredCheckboxes(toggleCheckbox(requiredCheckboxes, index))
                                                                                    }} />
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <Checkbox dataUI={getDataUI()} checked={boldCheckboxes[index]} onChange={() => {
                                                                                        setBoldCheckboxes(toggleCheckbox(boldCheckboxes, index))
                                                                                    }} />
                                                                                </td>
                                                                                <td className="text-center">
                                                                                    <Checkbox dataUI={getDataUI()} checked={hoverHelpCheckboxes[index]} onChange={() => {
                                                                                        setHoverHelpCheckboxes(toggleCheckbox(hoverHelpCheckboxes, index))
                                                                                    }} />
                                                                                </td>
                                                                                <td className="text-center"><Button btnType="icon" icon="fal fa-pen" dataUI={getDataUI()} /></td>
                                                                            </tr>
                                                                        }
                                                                    </ReactAux>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </SimpleTableContainer>
                                                </Collapse>

                                                <Collapse
                                                    className="mb-m"
                                                    isLightType
                                                    elementRenderRight={() => <div className="d-flex">
                                                        <Label dataUI={getDataUI()} className="mr-s">Display this section:</Label>
                                                        <Radio dataUI={getDataUI()} name="radio-4" className="mr-s" checked={!declarations2Disabled} onChange={() => setDeclarations2Disabled(false)}>Always</Radio>
                                                        <Radio dataUI={getDataUI()} name="radio-4" checked={declarations2Disabled} onChange={() => setDeclarations2Disabled(true)}>Never</Radio>
                                                    </div>}
                                                    title="Declarations"
                                                    expanded={isExpanded2}
                                                    onClick={() => setIsExpanded2(!isExpanded2)}
                                                    dataUI={getDataUI()}
                                                >

                                                    <Row className="mb-m">
                                                        <Col xs={4}>
                                                            <Label dataUI={getDataUI()} className="justify-content-end text-right">Section Header</Label>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <Input dataUI={getDataUI()} className="width-l" disabled={declarations2Disabled} />
                                                        </Col>
                                                    </Row>

                                                    <Toolbar dataUI={getDataUI()} className="mb-m" leftSide={
                                                        <>
                                                            <Button dataUI={getDataUI()} className="mr-m" icon="fal fa-plus" disabled={declarations2Disabled}>Add New Declaration Question</Button>
                                                            <InfoBox dataUI={getDataUI()} className={declarations2Disabled ? 'text-muted' : ''}>
                                                                Assign custom declaration questions to be answered by the primary applicant only.
                                                            </InfoBox>
                                                        </>
                                                    } />


                                                    <DragNDropContext onDragStart={handleDragStart} onDragUpdate={handleDragUpdate} onDragEnd={onDragEnd}>
                                                        <Row style={{ height: 320, flexWrap: 'unset' }}>
                                                            <Col xs={6}>
                                                                <DragNDropSection dataUI={getDataUI()}>
                                                                    <DragNDropSectionHeader dataUI={getDataUI()}>
                                                                        <div className="d-flex justify-content-between align-items-center mb-m">
                                                                            <h2 className={declarations2Disabled ? 'text-muted' : ''}>Available ({availableItems.length})</h2>
                                                                            <Button dataUI={getDataUI()} onClick={() => addAllToAssigned()} btnType="secondary" icon="fal fa-file-import" disabled={declarations2Disabled}>Add all</Button>
                                                                        </div>
                                                                        <Input
                                                                            dataUI={getDataUI()}
                                                                            name="input-icon"
                                                                            value={availableSearch}
                                                                            onChange={(ev: any) => setSearchValue(AVAILABLE_SECTION_NAME, ev.target.value)}
                                                                            icon='fal fa-search'
                                                                            disabled={declarations2Disabled}
                                                                        />
                                                                    </DragNDropSectionHeader>
                                                                    <Droparea dataUI={getDataUI()} dropareaID={AVAILABLE_SECTION_NAME} allowedItems={[AVAILABLE_SECTION_NAME, ASSIGNED_SECTION_NAME]}
                                                                        dragStartEvent={dragStartEvent}
                                                                        dragUpdateEvent={dragUpdateEvent}
                                                                    >

                                                                        {availableItems.map((item, index) => {
                                                                            return (
                                                                                !availableSearch || (availableSearch && item.title.includes(availableSearch)) ?
                                                                                    <DraggableItem dataUI={getDataUI()} key={item.title} index={index} draggableID={item.id} isDragDisabled={declarations2Disabled}>
                                                                                        <DraggableItemActions dataUI={getDataUI()}>
                                                                                            <Tooltip title={declarations2Disabled ? '' : 'If you entered yes to any of these questions, please provide details.'}>
                                                                                                <Icon dataUI={getDataUI()} size="M" icon="fal fa-info-circle" className={declarations2Disabled ? 'text-muted' : ''} />
                                                                                            </Tooltip>
                                                                                            <DraggableItemTitle dataUI={getDataUI()} className={declarations2Disabled ? 'text-muted' : ''}>{item.title}</DraggableItemTitle>
                                                                                        </DraggableItemActions>
                                                                                        <DraggableItemActions dataUI={getDataUI()}>
                                                                                            <Tooltip title={declarations2Disabled ? '' : 'Edit'}>
                                                                                                <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-pen" disabled={declarations2Disabled} />
                                                                                            </Tooltip>
                                                                                            <Tooltip title={declarations2Disabled ? '' : 'Move to assigned'}>
                                                                                                <Button dataUI={getDataUI()} onClick={() => moveAvailableItemToAssigned(index)} btnType="icon" icon="fal fa-arrow-right" disabled={declarations2Disabled} />
                                                                                            </Tooltip>
                                                                                        </DraggableItemActions>
                                                                                    </DraggableItem>
                                                                                    :
                                                                                    ''
                                                                            )
                                                                        })}
                                                                    </Droparea>
                                                                </DragNDropSection>
                                                            </Col>

                                                            <Col xs={6}>
                                                                <DragNDropSection dataUI={getDataUI()}>
                                                                    <DragNDropSectionHeader dataUI={getDataUI()}>
                                                                        <div className="d-flex justify-content-between align-items-center mb-m">
                                                                            <h2 className={declarations2Disabled ? 'text-muted' : ''}>Assigned ({assignedItems.length})</h2>
                                                                            <Button dataUI={getDataUI()} onClick={() => removeAllAssigned()} btnType="secondary" icon="fal fa-file-import" disabled={declarations2Disabled}>Remove all</Button>
                                                                        </div>
                                                                        <Input
                                                                            dataUI={getDataUI()}
                                                                            name="input-icon"
                                                                            value={assignedSearch}
                                                                            onChange={(ev: any) => setSearchValue(ASSIGNED_SECTION_NAME, ev.target.value)}
                                                                            icon='fal fa-search'
                                                                            disabled={declarations2Disabled}
                                                                        />
                                                                    </DragNDropSectionHeader>
                                                                    <Droparea
                                                                        dataUI={getDataUI()}
                                                                        dropareaID={ASSIGNED_SECTION_NAME}
                                                                        allowedItems={[ASSIGNED_SECTION_NAME, AVAILABLE_SECTION_NAME]}
                                                                        callToActionText="Drop your files here."
                                                                        dragStartEvent={dragStartEvent}
                                                                        dragUpdateEvent={dragUpdateEvent}
                                                                    >
                                                                        {assignedItems.map((item, index) => {
                                                                            return (
                                                                                !assignedSearch || (assignedSearch && item.title.includes(assignedSearch)) ?
                                                                                    <DraggableItem dataUI={getDataUI()} key={item.title} index={index} draggableID={item.id} isDragDisabled={declarations2Disabled}>
                                                                                        <DraggableItemActions dataUI={getDataUI()}>
                                                                                            <Tooltip title={declarations2Disabled ? '' : 'If you entered yes to any of these questions, please provide details.'}>
                                                                                                <Icon dataUI={getDataUI()} size="M" icon="fal fa-info-circle" className={declarations2Disabled ? 'text-muted' : ''} />
                                                                                            </Tooltip>
                                                                                            <DraggableItemTitle dataUI={getDataUI()} className={declarations2Disabled ? 'text-muted' : ''}>{item.title}</DraggableItemTitle>
                                                                                        </DraggableItemActions>
                                                                                        <DraggableItemActions dataUI={getDataUI()}>
                                                                                            <Tooltip title={declarations2Disabled ? '' : 'Edit'}>
                                                                                                <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-pen" disabled={declarations2Disabled} />
                                                                                            </Tooltip>
                                                                                            <Tooltip title={declarations2Disabled ? '' : 'Remove from assigned'}>
                                                                                                <Button dataUI={getDataUI()} onClick={() => returnItemToAvailable(index)} btnType="icon" icon="fal fa-times" disabled={declarations2Disabled} />
                                                                                            </Tooltip>
                                                                                        </DraggableItemActions>
                                                                                    </DraggableItem>
                                                                                    :
                                                                                    ''
                                                                            )
                                                                        })}
                                                                    </Droparea>
                                                                </DragNDropSection>
                                                            </Col>
                                                        </Row>
                                                    </DragNDropContext>
                                                </Collapse>

                                                <Row className="mb-s">
                                                    <Col xs={4}>
                                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Footer Text</Label>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Textarea dataUI={getDataUI()} className="width-l" />
                                                    </Col>
                                                </Row>
                                                <Row className="mb-s">
                                                    <Col xs={4}>
                                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Stop Application</Label>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Radio dataUI={getDataUI()} name="radio-6" className="mr-s" onChange={() => { }}>Never</Radio>
                                                        <Radio dataUI={getDataUI()} name="radio-6" defaultChecked onChange={() => { }}>Conditional</Radio>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-m">
                                                    <Col xs={4}>
                                                        <Label dataUI={getDataUI()} className="justify-content-end text-right">Stop Application Message</Label>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Textarea dataUI={getDataUI()} className="width-l" />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </VerticalTabContentItem>
                                        <VerticalTabContentItem dataUI={getDataUI()}>Request</VerticalTabContentItem>
                                        <VerticalTabContentItem dataUI={getDataUI()}>Auto Details</VerticalTabContentItem>
                                        <VerticalTabContentItem dataUI={getDataUI()}>Personal Party Match</VerticalTabContentItem>
                                        <VerticalTabContentItem dataUI={getDataUI()}>Personal Information</VerticalTabContentItem>
                                        <VerticalTabContentItem dataUI={getDataUI()}>Address</VerticalTabContentItem>
                                        <VerticalTabContentItem dataUI={getDataUI()}>Income & Expenses</VerticalTabContentItem>
                                        <VerticalTabContentItem dataUI={getDataUI()}>Business</VerticalTabContentItem>
                                        <VerticalTabContentItem dataUI={getDataUI()}>Business Address</VerticalTabContentItem>
                                        <VerticalTabContentItem dataUI={getDataUI()}>Business Other Information</VerticalTabContentItem>
                                        <VerticalTabContentItem dataUI={getDataUI()}>Business References</VerticalTabContentItem>
                                        <VerticalTabContentItem dataUI={getDataUI()}>Business Obligations</VerticalTabContentItem>
                                        <VerticalTabContentItem dataUI={getDataUI()}>Business Deposits</VerticalTabContentItem>
                                        <VerticalTabContentItem dataUI={getDataUI()}>Guarantor</VerticalTabContentItem>
                                        <VerticalTabContentItem dataUI={getDataUI()}>Collateral</VerticalTabContentItem>
                                        <VerticalTabContentItem dataUI={getDataUI()}>Declarations</VerticalTabContentItem>
                                        <VerticalTabContentItem dataUI={getDataUI()}>Disclosures</VerticalTabContentItem>
                                        <VerticalTabContentItem dataUI={getDataUI()}>Terms and Conditions</VerticalTabContentItem>
                                        <VerticalTabContentItem dataUI={getDataUI()}>Required Documents</VerticalTabContentItem>
                                    </VerticalTabContent>
                                </VerticalTabs>
                            </div>
                        </MainContainerScrollable>

                    </TabContentItem>
                    <TabContentItem dataUI={getDataUI()}>Co-Applicant Pages</TabContentItem>
                    <TabContentItem dataUI={getDataUI()}>Results Pages</TabContentItem>
                    <TabContentItem dataUI={getDataUI()}>FAQ</TabContentItem>
                </TabContent>
            </Tabs>
        </ReactAux>
    )
}

export default DemoApplicationModels