import React from 'react';
import Highlight from 'react-highlight';

import { Collapse, PartyNavigationItem, Checkbox, } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';


class CollapsePage extends React.Component {

    state = {
        collapseDemo1: false,
        collapseDemo2: true,
        collapseDemo3: false,
        collapseDemo4: false,
        collapseDemo5: false,
        collapseDemo6: false,
        collapseDemo7: false,
        collapseDisabled: false,
    }

    render() {
        return (
            <div>
                <h1 className="mb-s">Collapse</h1>

                <p className="mb-l">The Collapse component is used to show and hide content. <br />
                    For Party Navigation content use PartyNavigationItem component.</p>

                <Collapse
                    title="Item 1"
                    expanded={this.state.collapseDemo1}
                    onClick={() => this.setState({ collapseDemo1: !this.state.collapseDemo1 })}
                    rightIcon="far fa-user"
                    rightIconTooltip="Description in Tooltip"
                    dataUI="collapse-1"
                    disabled
                >
                    <PartyNavigationItem render={() => <a href="/">Item 2.1</a>} dataUI="partyNavigationItem1" />
                    <PartyNavigationItem render={() => <a href="/" onClick={(e) => { e.preventDefault(); alert('Item 2.2 is clicked!') }}>Item 2.2</a>} dataUI={getDataUI()}/>
                    <PartyNavigationItem render={() => <span onClick={() => alert('Item 2.3 is clicked!')}>Item 2.3</span>} dataUI={getDataUI()}/>
                </Collapse>

                <Collapse
                    title="Item 2"
                    expanded={this.state.collapseDemo2}
                    onClick={() => this.setState({ collapseDemo2: !this.state.collapseDemo2 })}
                    dataUI={getDataUI()}
                >
                    <p>Content...</p>
                </Collapse>

                <Collapse
                    title="Item 3"
                    expanded={this.state.collapseDemo3}
                    onClick={() => this.setState({ collapseDemo3: !this.state.collapseDemo3 })}
                    dataUI={getDataUI()}
                >
                    <Collapse
                        title="Item 3.1"
                        expanded={this.state.collapseDemo4}
                        onClick={() => this.setState({ collapseDemo4: !this.state.collapseDemo4 })}
                        dataUI={getDataUI()}
                    >
                        <p>Content...</p>
                    </Collapse>
                </Collapse>

                <Collapse
                    elementRender={() => <Checkbox dataUI={getDataUI()}/>}
                    title="Item 4"
                    expanded={this.state.collapseDemo5}
                    onClick={() => this.setState({ collapseDemo5: !this.state.collapseDemo5 })}
                    dataUI={getDataUI()}
                >
                    <p>Content...</p>
                </Collapse>

                <Collapse
                    elementRender={() => <Checkbox disabled dataUI={getDataUI()}/>}
                    title="Item 5 Disabled"
                    expanded={this.state.collapseDisabled}
                    onClick={() => this.setState({ collapseDisabled: !this.state.collapseDisabled })}
                    dataUI={getDataUI()}
                    disabled
                >
                    <p>Content...</p>
                </Collapse>

                <h3 className="mb-m mt-l">Collapse Light</h3>
                <Collapse
                    isLightType
                    elementRenderRight={() => <><Checkbox dataUI={getDataUI()}>Borrower</Checkbox><Checkbox className="ml-s" dataUI={getDataUI()}>Guarantor</Checkbox></>}
                    title="Collapse Light"
                    expanded={this.state.collapseDemo6}
                    onClick={() => this.setState({ collapseDemo6: !this.state.collapseDemo6 })}
                    dataUI={getDataUI()}
                    
                >
                    <p>Content...</p>
                </Collapse>

                <Collapse
                    isLightType
                    elementRenderRight={() => <><Checkbox disabled dataUI={getDataUI()}>Borrower</Checkbox><Checkbox disabled className="ml-s" dataUI={getDataUI()}>Guarantor</Checkbox></>}
                    title="Collapse Light Disabled"
                    expanded={this.state.collapseDisabled}
                    onClick={() => this.setState({ collapseDisabled: !this.state.collapseDisabled })}
                    dataUI={getDataUI()}
                    disabled
                >
                    <p>Content...</p>
                </Collapse>

                <h3 className="mb-m mt-l">Collapse Small Left Padding</h3>
                <Collapse
                    smallLeftPadding
                    title="Collapse With Small Left Padding"
                    expanded={this.state.collapseDemo7}
                    onClick={() => this.setState({ collapseDemo7: !this.state.collapseDemo7 })}
                    dataUI={getDataUI()}
                >
                    <p>Content...</p>
                </Collapse>



                <Highlight className="React">
                    {
                        `
import React from 'react';
import { Collapse, PartyNavigationItem } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

class Example extends React.Component {

    state = {
        collapseDemo1: false,
        collapseDemo2: true,
        collapseDemo3: false,
        collapseDemo4: false,
        collapseDemo5: false,
        collapseDemo6: false,
    }

    render() {
        return (
            <div>
                <Collapse
                    title="Item 1"
                    expanded={this.state.collapseDemo1}
                    onClick={() => this.setState({ collapseDemo1: !this.state.collapseDemo1 })}
                    rightIcon="far fa-user"
                    rightIconTooltip="Description in Tooltip"
                    dataUI="collapse-1"
                >
                    <PartyNavigationItem render={() => <a href="/">Item 2.1</a>} dataUI="partyNavigationItem1" />
                    <PartyNavigationItem render={
                        () => <a href="/" onClick={(e) => { e.preventDefault(); alert('Item 2.2 is clicked!') }} dataUI={getDataUI()}>Item 2.2</a>
                    } />
                    <PartyNavigationItem render={() => <span onClick={() => alert('Item 2.3 is clicked!')} dataUI={getDataUI()}>Item 2.3</span>} />
                </Collapse>

                <Collapse
                    title="Item 2"
                    expanded={this.state.collapseDemo2}
                    onClick={() => this.setState({ collapseDemo2: !this.state.collapseDemo2 })}
                    dataUI={getDataUI()}
                >
                    <p>Content...</p>
                </Collapse>

                <Collapse
                    title="Item 3"
                    expanded={this.state.collapseDemo3}
                    onClick={() => this.setState({ collapseDemo3: !this.state.collapseDemo3 })}
                    dataUI={getDataUI()}
                >
                    <Collapse
                        title="Item 3.1"
                        expanded={this.state.collapseDemo4}
                        onClick={() => this.setState({ collapseDemo4: !this.state.collapseDemo4 })}
                        dataUI={getDataUI()}
                    >
                        <p>Content...</p>
                    </Collapse>
                </Collapse>

                <Collapse
                    elementRender={() => <Checkbox dataUI={getDataUI()}/>}
                    title="Item 4"
                    expanded={this.state.collapseDemo5}
                    onClick={() => this.setState({ collapseDemo5: !this.state.collapseDemo5 })}
                    dataUI={getDataUI()}
                >
                    <p>Content...</p>
                </Collapse>

                <Collapse
                    elementRender={() => <Checkbox disabled dataUI={getDataUI()}/>}
                    title="Item 5 Disabled"
                    expanded={this.state.collapseDisabled}
                    onClick={() => this.setState({ collapseDisabled: !this.state.collapseDisabled })}
                    dataUI={getDataUI()}
                    disabled
                 >
                    <p>Content...</p>
                 </Collapse>

                <Collapse
                    isLightType
                    elementRenderRight={() => <><Checkbox>Borrower</Checkbox><Checkbox className="ml-s">Guarantor</Checkbox></>}
                    title="Collapse Light"
                    expanded={this.state.collapseDemo6}
                    onClick={() => this.setState({ collapseDemo6: !this.state.collapseDemo6 })}
                    dataUI={getDataUI()}
                >
                    <p>Content...</p>
                </Collapse>

                <Collapse
                    isLightType
                    elementRenderRight={() => <><Checkbox disabled dataUI={getDataUI()}>Borrower</Checkbox><Checkbox disabled className="ml-s" dataUI={getDataUI()}>Guarantor</Checkbox></>}
                    title="Collapse Light Disabled"
                    expanded={this.state.collapseDisabled}
                    onClick={() => this.setState({ collapseDisabled: !this.state.collapseDisabled })}
                    dataUI={getDataUI()}
                    disabled
                >
                    <p>Content...</p>
                </Collapse>

                <Collapse
                    smallLeftPadding
                    title="Collapse With Small Left Padding"
                    expanded={this.state.collapseDemo7}
                    onClick={() => this.setState({ collapseDemo7: !this.state.collapseDemo7 })}
                    dataUI={getDataUI()}
                >
                    <p>Content...</p>
                </Collapse>
            </div>
        );
    }
}

export default Example;
        `}
                </Highlight>

                <h2 className="mb-m">API</h2>
                <table className="doc-table mb-l">
                    <thead>
                        <tr>
                            <th colSpan={4}>Props</th>
                        </tr>
                        <tr>
                            <td>
                                <strong>Name</strong>
                            </td>
                            <td>
                                <strong>Options</strong>
                            </td>
                            <td>
                                <strong>Default</strong>
                            </td>
                            <td>
                                <strong>Description</strong>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>elementRender</td>
                            <td>function</td>
                            <td className="text-center">-</td>
                            <td>Render custom element before title.</td>
                        </tr>
                        <tr>
                            <td>title</td>
                            <td>string</td>
                            <td className="text-center">-</td>
                            <td>Sets the title.</td>
                        </tr>
                        <tr>
                            <td>expanded</td>
                            <td>boolean</td>
                            <td>false</td>
                            <td>Shows the expanded content.</td>
                        </tr>
                        <tr>
                            <td>onClick</td>
                            <td>void</td>
                            <td className="text-center">-</td>
                            <td>Callback when the component is clicked.</td>
                        </tr>
                        <tr>
                            <td>rightIcon</td>
                            <td>string</td>
                            <td className="text-center">-</td>
                            <td>Sets the icon for right side.</td>
                        </tr>
                        <tr>
                            <td>rightIconTooltip</td>
                            <td>string</td>
                            <td className="text-center">-</td>
                            <td>Sets Tooltip with the given text to the icon on the right side.</td>
                        </tr>
                        <tr>
                            <td>elementRenderRight</td>
                            <td>function</td>
                            <td className="text-center">-</td>
                            <td>Render custom element in right side.</td>
                        </tr>
                        <tr>
                            <td>isLightType</td>
                            <td>boolean</td>
                            <td>false</td>
                            <td>Sets style for light collapse.</td>
                        </tr>
                        <tr>
                            <td>smallLeftPadding</td>
                            <td>boolean</td>
                            <td>false</td>
                            <td>If true, reduces the left padding.</td>
                        </tr>
                        <tr>
                            <td>disabled</td>
                            <td>boolean</td>
                            <td>-</td>
                            <td>Set disabled state.</td>
                        </tr>
                    </tbody>
                </table>

            </div>
        )
    }
}

export default CollapsePage;
