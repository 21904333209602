import React from 'react';
import { Link } from 'react-router-dom';

import {
    Button, Header, HeaderButtonIcon, DropdownMenu, MenuItem, HeaderSearch, SearchForm,
    SearchInput, SearchMenu, Select, SearchFilters, Radio, SearchResults, SearchResultsList,
    SearchResultsItem, SearchDetails, Icon, SearchDetailsList, SearchDetailsItem, SearchResultsFooter,
    HeaderMenu, FacilityMenuItem, FacilityList, FacilityItem, CollateralItem, FacilityFooter,
    HeaderRightSection, HeaderInfo, ThemeChanger, themeChangerHelpers, Modal, Toolbar, Tabs, TabList, TabItem, TabItemTitle,
    TabContent, TabContentItem, SimpleTableContainer, Badge, Checkbox, UploadedFileRow, Label,
    LinkWithIcon, ModalScrollableContainer, Tooltip, NotificationMenuItem, NotificationMenuHeader,
    ConfirmationDialog, NotificationMenuContent, NotificationMenuScrollPart,
    ButtonGroup, Notification,
    ReactAux,
} from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../helpers/helpers';
import DemoMessageDealerModal from './DemoMessageDealerModal';

const searchSelectOptions = [{ value: "1", label: "Default" }, { value: "2", label: "File" }, { value: "3", label: "Party Starts With" }, { value: "4", label: "Party Contains" }, { value: "5", label: "Property Address" }];
const noNotificationsToShow = false;

type DemoHeaderProps = {
    onThemeChange?(theme: any): void
};

class DemoHeader extends React.Component<DemoHeaderProps> {
    private inputFileRef: any;

    state = {
        isOpenIndex: [
            false, false, false, false, false, false, false, false, false, false, false, false
        ],
        hasSearchResults: false,
        searchInputValue: '',
        searchSelectValue: searchSelectOptions[0].value,
        searchRadioValue: '',
        headerSearchActive: false,
        theme: undefined,
        isHidden: true,
        isHidden2: true,
        isHidden3: true,
        spreadListModalVisible: false,
        spreadListTabIndex: 0,
        mySettingsTabIndex: 0,
        uploadsModalVisible: false,
        modalSettings: false,
        image: null,
        showConfirmation: false,
        activeBtn: 1,
        notifications: [
            { title: 'Drive Time Used Cars', description: 'Thank you!', dateTime: '1 m', isUnread: true },
            { title: 'Dan Brown Chevrolet Inc.', description: 'Down Base Rate by 5 bp', dateTime: '3 m', isUnread: true },
            { title: 'Another Dealer', description: 'Thank you! This is also an example of how a long message would display.', dateTime: '6 m', isUnread: true },
            { title: 'Drive Time Used Cars', description: 'Thank you!', dateTime: '1 h', isUnread: true },
            { title: 'Dealer with a very very very long name example', description: 'Thank you!', dateTime: '5:17 pm', isUnread: true },
            { title: 'Dan Brown Chevrolet Inc.', description: 'Down Base Rate by 5 bp', dateTime: '3:36 pm', isUnread: true },
            { title: 'Another Dealer', description: 'Thank you!', dateTime: '8:02 am', isUnread: true },
            { title: 'Drive Time Used Cars', description: 'Thank you!', dateTime: '7:12 am', isUnread: true },
            { title: 'Drive Time Used Cars', description: 'Thank you!', dateTime: '7:52 am', isUnread: true },
            { title: 'Drive Time Used Cars', description: 'Thank you!', dateTime: '8:22 am', isUnread: false },
            { title: 'Drive Time Used Cars', description: 'Thank you!', dateTime: '8:47 am', isUnread: false },
        ],
        isVisibleDealerModal: false,
    }

    componentDidMount() {
        this.setState({ theme: 'navy' });
    }

    handleOnChangeImage = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            const targetFile = e.target.files[0];

            this.setState({ image: URL.createObjectURL(targetFile), });
        }
    }

    onImageUploadClick = () => {
        if (this.inputFileRef) this.inputFileRef.click();
    };

    onToggleDropDown = (index: any) => {
        const { isOpenIndex } = this.state;
        if (isOpenIndex[index]) {
            isOpenIndex[index] = false;
        } else {
            isOpenIndex.forEach((el, i) => {
                if (i === index) {
                    isOpenIndex[i] = true;
                } else {
                    isOpenIndex[i] = false;
                }
            });
        }

        this.setState({
            isOpenIndex: isOpenIndex,
        })
    }

    onSearchInputChange = (event: any) => {
        const newVal = event.target.value;
        this.setState({ searchInputValue: newVal });
    }

    onSearchSelectChange = (obj: any) => {
        const newVal = obj.value;
        this.setState({ searchSelectValue: newVal });
    }

    onSearchRadioChange = (event: any) => {
        const newVal = event.target.value;
        this.setState({ searchRadioValue: newVal });
    }

    onSearchSubmit = () => {
        const searchValue = this.state.searchInputValue;
        if (searchValue.trim().length > 0) {
            this.setState({ hasSearchResults: true })
        } else {
            this.setState({ hasSearchResults: false })
        }
    }

    activateHeaderSearch() {
        this.setState({ headerSearchActive: true });
    }

    deactivateHeaderSearch() {
        this.setState({ headerSearchActive: false });
    }

    setTheme = (theme: any) => {
        this.setState({ theme: theme.name });
        const { onThemeChange } = this.props;
        if (onThemeChange) onThemeChange(theme);
    }

    setSpreadListModalVisibility(showModal: boolean) {
        if (this.state.spreadListModalVisible !== showModal) {
            this.setState({ spreadListModalVisible: showModal });
        }
    }

    setSpreadListTabIndex(newIndex: number) {
        if (this.state.spreadListTabIndex !== newIndex) {
            this.setState({ spreadListTabIndex: newIndex });
        }
    }

    setMySettingsTabIndex(newIndex: number) {
        if (this.state.mySettingsTabIndex !== newIndex) {
            this.setState({ mySettingsTabIndex: newIndex });
        }
    }

    setUploadsModalVisibility(showModal: boolean) {
        if (this.state.uploadsModalVisible !== showModal) {
            this.setState({ uploadsModalVisible: showModal });
        }
    }

    render() {
        const { theme, isHidden, isHidden2, isHidden3 } = this.state;

        // These files are programatically created only for the sake of the demo.
        const examplePDF = new File(['example pdf'], 'file.pdf', { type: 'application/pdf' })
        const exampleJPG = new File(['example jpg image'], 'picture02.jpg', { type: 'image/jpeg' })
        const exampleDoc = new File([''], 'file.doc', { type: 'application/msword' })

        return (
            <>
                <Header>
                    <Link to="/home">
                        <HeaderButtonIcon dataUI={getDataUI()} icon="fal fa-home" />
                    </Link>
                    <DropdownMenu
                        dataUI={getDataUI()}
                        onToggleDropdownMenu={() => this.onToggleDropDown(9)}
                        isOpen={this.state.isOpenIndex[9]}
                        icon='fal fa-bars'
                    >
                        <MenuItem dataUI={getDataUI()} title={'Hello, VSG'} avatar={{ src: 'https://www.w3schools.com/howto/img_avatar2.png', alt: 'avatarTest' }} noHoverEffect />
                        <MenuItem dataUI={getDataUI()} title={'My Settings'} icon={{ name: 'fal fa-cog' }} onClick={() => this.setState({ modalSettings: true })} />
                        <MenuItem dataUI={getDataUI()} title={'Sign Out'} icon={{ name: 'fal fa-sign-out-alt' }} />
                        <MenuItem dataUI={getDataUI()} title={'Set Up'} />
                        <MenuItem dataUI={getDataUI()} title={'Approval Log'} />
                        <MenuItem dataUI={getDataUI()} title={'Dashboard'} />
                        <MenuItem dataUI={getDataUI()} title={'FPC'} />
                        <MenuItem dataUI={getDataUI()} title={'Change Theme'} icon={{ name: 'fal fa-palette', color: 'text-primary' }}>
                            {themeChangerHelpers.THEMES.map((t, i) => {
                                return <MenuItem dataUI={getDataUI()}
                                    key={i}
                                    className={`lv-theme-menu-${t.base}`}
                                    title={t.title}
                                    icon={{ name: `fas fa-${theme === t.name ? 'check-circle' : 'circle'}`, color: `lv-theme-${t.name}-primary` }}
                                    onClick={() => this.setTheme(t)}
                                />
                            })}
                        </MenuItem>
                    </DropdownMenu>
                    <HeaderButtonIcon dataUI={getDataUI()} icon="fal fa-cogs" />
                    <HeaderSearch
                        dataUI={getDataUI()}
                        isActive={this.state.headerSearchActive}
                        onActivate={() => this.activateHeaderSearch()}
                        onDeactivate={() => this.deactivateHeaderSearch()}
                    >
                        <SearchForm dataUI={getDataUI()}>
                            <SearchInput
                                dataUI={getDataUI()}
                                placeholder="Search"
                                clearAction
                                value={this.state.searchInputValue}
                                onChange={e => this.onSearchInputChange(e)}
                                onSearchSubmit={() => this.onSearchSubmit()}
                                onClear={() => console.log('Clear event')}
                            />
                            <SearchMenu dataUI={getDataUI()}>
                                <Select dataUI={getDataUI()} value={this.state.searchSelectValue} onChange={(obj: any) => this.onSearchSelectChange(obj)} options={searchSelectOptions} noMenuPortal />
                                <Button dataUI={getDataUI()} onClick={() => this.onSearchSubmit()} icon="fal fa-search">Search</Button>
                                <SearchFilters dataUI={getDataUI()} title="Search in:">
                                    <Radio dataUI={getDataUI()} className="mr-s" name="customer-type" type="radio" checked={this.state.searchRadioValue === "Customers"} onChange={e => this.onSearchRadioChange(e)} value="Customers">Customers</Radio>
                                    <Radio dataUI={getDataUI()} className="mr-s" name="customer-type" type="radio" checked={this.state.searchRadioValue === "Prospects"} onChange={e => this.onSearchRadioChange(e)} value="Prospects">Prospects</Radio>
                                    <Radio dataUI={getDataUI()} className="mr-s" name="customer-type" type="radio" checked={this.state.searchRadioValue === "Leads"} onChange={e => this.onSearchRadioChange(e)} value="Leads">Leads</Radio>
                                    <Radio dataUI={getDataUI()} className="mr-s" name="customer-type" type="radio" checked={this.state.searchRadioValue === "Tenants"} onChange={e => this.onSearchRadioChange(e)} value="Tenants">Tenants</Radio>
                                    <Radio dataUI={getDataUI()} name="customer-type" type="radio" checked={this.state.searchRadioValue === "All"} onChange={e => this.onSearchRadioChange(e)} value="All">All</Radio>
                                </SearchFilters>
                            </SearchMenu>
                        </SearchForm>
                        {
                            this.state.hasSearchResults &&
                            <SearchResults dataUI={getDataUI()} title={'65 results found for "Joro"'}>
                                <SearchResultsList dataUI={getDataUI()}>
                                    <SearchResultsItem dataUI={getDataUI()}>
                                        <SearchDetails dataUI={getDataUI()} title="test" icon={<Icon dataUI={getDataUI()} size="S" icon="fal fa-user"></Icon>} onClick={() => console.log('click')} titleAdditionalInfo="business" >
                                            Spread model: GMM, GMM
                                        </SearchDetails>
                                        <SearchDetailsList>
                                            <SearchDetailsItem dataUI={getDataUI()} title="(12240) File Name" onClick={() => console.log('click')} officer="Officer" icon={<Icon dataUI={getDataUI()} size="S" icon="fal fa-people-arrows"></Icon>} />
                                            <SearchDetailsItem dataUI={getDataUI()} officer="Officer" render={<a href="./components">(12240) File Name</a>} icon={<Icon dataUI={getDataUI()} size="S" icon="fal fa-people-arrows"></Icon>} />
                                        </SearchDetailsList>
                                    </SearchResultsItem>

                                    <SearchResultsItem dataUI={getDataUI()}>
                                        <SearchDetails dataUI={getDataUI()} title="test" icon={<Icon dataUI={getDataUI()} size="S" icon="fal fa-user"></Icon>} onClick={() => console.log('click')} titleAdditionalInfo="business" >
                                            <p>test 1</p>
                                            <p>test 2</p>
                                            <p>test 3</p>
                                            Spread model: GMM, GMM
                                        </SearchDetails>
                                        <SearchDetailsList>
                                            <SearchDetailsItem dataUI={getDataUI()} title="(12240) File Name" onClick={() => console.log('click')} officer="Officer" />
                                        </SearchDetailsList>
                                    </SearchResultsItem>

                                    <SearchResultsItem dataUI={getDataUI()}>
                                        <SearchDetails dataUI={getDataUI()} render={<><a href="./components" onClick={() => console.log('click')}>Party</a>, some info</>} icon={<Icon dataUI={getDataUI()} size="S" icon="fal fa-user"></Icon>}>
                                            Spread model: GMM, GMM
                                        </SearchDetails>
                                        <SearchDetailsList>
                                            <SearchDetailsItem dataUI={getDataUI()} title="(12240) File Name" icon={<Icon dataUI={getDataUI()} size="S" icon="fal fa-people-arrows"></Icon>} onClick={() => console.log('click')} officer="Officer" />
                                            <SearchDetailsItem dataUI={getDataUI()} officer="Officer" icon={<Icon dataUI={getDataUI()} size="S" icon="fal fa-people-arrows"></Icon>} render={<a href="./components">(12240) File Name</a>} />
                                            <SearchDetailsItem dataUI={getDataUI()} title="Party name" icon={<Icon dataUI={getDataUI()} size="S" icon="fal fa-people-arrows"></Icon>} onClick={() => console.log('click')} />
                                        </SearchDetailsList>
                                    </SearchResultsItem>

                                    <SearchResultsItem dataUI={getDataUI()}>
                                        <SearchDetails dataUI={getDataUI()} title="test" icon={<Icon dataUI={getDataUI()} size="S" icon="fal fa-user"></Icon>} onClick={() => console.log('click')} titleAdditionalInfo="business" >
                                            Spread model: GMM, GMM
                                        </SearchDetails>
                                        <SearchDetailsList>
                                            <SearchDetailsItem dataUI={getDataUI()} title="(12240) File Name" onClick={() => console.log('click')} officer="Officer" icon={<Icon dataUI={getDataUI()} size="S" icon="fal fa-people-arrows"></Icon>} />
                                            <SearchDetailsItem dataUI={getDataUI()} officer="Officer" render={<a href="./components">(12240) File Name</a>} icon={<Icon dataUI={getDataUI()} size="S" icon="fal fa-people-arrows"></Icon>} />
                                        </SearchDetailsList>
                                    </SearchResultsItem>

                                    <SearchResultsItem dataUI={getDataUI()}>
                                        <SearchDetails dataUI={getDataUI()} title="test" icon={<Icon dataUI={getDataUI()} size="S" icon="fal fa-user"></Icon>} onClick={() => console.log('click')} titleAdditionalInfo="business" >
                                            <p>test 1</p>
                                            <p>test 2</p>
                                            <p>test 3</p>
                                            Spread model: GMM, GMM
                                        </SearchDetails>
                                        <SearchDetailsList>
                                            <SearchDetailsItem dataUI={getDataUI()} title="(12240) File Name" onClick={() => console.log('click')} officer="Officer" />
                                        </SearchDetailsList>
                                    </SearchResultsItem>

                                    <SearchResultsItem dataUI={getDataUI()}>
                                        <SearchDetails dataUI={getDataUI()} render={<><a href="./components" onClick={() => console.log('click')}>Party</a>, some info</>} icon={<Icon dataUI={getDataUI()} size="S" icon="fal fa-user"></Icon>}>
                                            Spread model: GMM, GMM
                                        </SearchDetails>
                                        <SearchDetailsList >
                                            <SearchDetailsItem dataUI={getDataUI()} title="(12240) File Name" icon={<Icon dataUI={getDataUI()} size="S" icon="fal fa-people-arrows"></Icon>} onClick={() => console.log('click')} officer="Officer" />
                                            <SearchDetailsItem dataUI={getDataUI()} officer="Officer" icon={<Icon dataUI={getDataUI()} size="S" icon="fal fa-people-arrows"></Icon>} render={<a href="./components">(12240) File Name</a>} />
                                            <SearchDetailsItem dataUI={getDataUI()} title="Party name" icon={<Icon dataUI={getDataUI()} size="S" icon="fal fa-people-arrows"></Icon>} onClick={() => console.log('click')} />
                                        </SearchDetailsList>
                                    </SearchResultsItem>

                                </SearchResultsList>
                                <SearchResultsFooter dataUI={getDataUI()}>
                                    Didn't find the party you are searching for?
                                    <Button dataUI={getDataUI()} className="ml-s" icon="fal fa-plus" btnType="secondary">Add party</Button>
                                </SearchResultsFooter>
                            </SearchResults>
                        }
                    </HeaderSearch>

                    <HeaderMenu dataUI={getDataUI()}>
                        <DropdownMenu dataUI={getDataUI()} title="Facility" onToggleDropdownMenu={() => this.onToggleDropDown(10)} isOpen={this.state.isOpenIndex[10]} >
                            <FacilityMenuItem dataUI={getDataUI()}>
                                <FacilityList dataUI={getDataUI()}>
                                    <FacilityItem
                                        dataUI={getDataUI()}
                                        price="9,000"
                                        loan={{ element: <a href="/">7777-2-L1111</a>, isActive: true }}
                                        collateralSummary={{ element: <a href="/">Collateral summary</a> }}
                                    >
                                        <CollateralItem dataUI={getDataUI()} render={() => <a href="/">White house</a>} />
                                        <CollateralItem dataUI={getDataUI()} render={() => <a href="/">White house</a>} />
                                        <CollateralItem dataUI={getDataUI()} render={() => <a href="/">White house</a>} />
                                    </FacilityItem>
                                    <FacilityItem
                                        price="8,500,000"
                                        dataUI={getDataUI()}
                                        loan={{ element: <a href="/">26CApprovalRequest</a> }}
                                        collateralSummary={{ element: <a href="/">Collateral summary</a> }}
                                    />
                                    <FacilityItem
                                        price="21,266,000"
                                        dataUI={getDataUI()}
                                        loan={{ element: <a href="/">7777-6 - 26CApprovalRequest</a> }}
                                        collateralSummary={{ element: <a href="/">Collateral summary</a> }}
                                    >
                                        <CollateralItem dataUI={getDataUI()} render={() => <a href="/">L111 Collat for CAR reports</a>} />
                                        <CollateralItem dataUI={getDataUI()} render={() => <a href="/">Titled -Motor Vehicles-Personal</a>} />
                                        <CollateralItem dataUI={getDataUI()} render={() => <a href="/">CAP Rate Test</a>} />
                                        <CollateralItem dataUI={getDataUI()} render={() => <a href="/">Test</a>} />
                                        <CollateralItem dataUI={getDataUI()} render={() => <a href="/">Titled -Motor Vehicles-Personal</a>} />
                                        <CollateralItem dataUI={getDataUI()} render={() => <a href="/">CAP Rate Test</a>} />
                                    </FacilityItem>
                                </FacilityList>
                                <FacilityFooter dataUI={getDataUI()}>
                                    <Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-plus">Add Facility</Button>
                                </FacilityFooter>
                            </FacilityMenuItem>
                        </DropdownMenu>
                        <DropdownMenu
                            dataUI={getDataUI()}
                            title={'Collateral'}
                            onToggleDropdownMenu={() => this.onToggleDropDown(0)}
                            isOpen={this.state.isOpenIndex[0]}
                        >
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} icon={{ name: 'fal fa-cog' }} isActive={true} />
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} icon={{ name: 'fal fa-sign-out-alt', color: 'text-primary' }} />
                            <MenuItem dataUI={getDataUI()} title={'Dropdown Level 1'} >
                                <MenuItem dataUI={getDataUI()} title={'Dropdown Level 2'}>
                                    {
                                        Array(20).fill('').map((el, i) => <MenuItem dataUI={getDataUI()} key={i} title={'Level 3'} render={() => <span>Render Span</span>} />)
                                    }
                                    <MenuItem dataUI={getDataUI()} title={'Level 3'} render={() => <span>LAST</span>} />
                                </MenuItem>
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} separator={true} />
                                {
                                    Array(20).fill('').map((el, i) => <MenuItem dataUI={getDataUI()} key={i} title={'Level 3'} render={() => <span>Render Span</span>} />)
                                }

                                <MenuItem dataUI={getDataUI()} title={'Dropdown Level 2'}>
                                    {
                                        Array(20).fill('').map((el, i) => <MenuItem dataUI={getDataUI()} key={i} title={'Level 3'} render={() => <span>Render Span</span>} />)
                                    }
                                    <MenuItem dataUI={getDataUI()} title={'Level 3'} render={() => <span>LAST</span>} />
                                </MenuItem>
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} onClick={(e: any) => { console.log(e.target) }} />
                                <MenuItem dataUI={getDataUI()} title={'Level 3'} render={() => <span>LAST</span>} />
                            </MenuItem>
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} icon={{ name: 'fal fa-palette', color: 'text-primary' }} render={() => <a href='/'>Render Link</a>} />
                            <MenuItem dataUI={getDataUI()} title={'Dropdown Level 1'} >
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} separator={true} />
                                <MenuItem dataUI={getDataUI()} title={'Dropdown Level 2'}>
                                    <MenuItem dataUI={getDataUI()} title={'Level 3'} />
                                    <MenuItem dataUI={getDataUI()} title={'Level 3'} />
                                    <MenuItem dataUI={getDataUI()} title={'Level 3'} render={() => <span>Render Span</span>} />
                                </MenuItem>
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} onClick={(e: any) => { console.log(e.target) }} />
                            </MenuItem>
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} icon={{ name: 'fal fa-palette', color: 'text-primary' }} render={() => <a href='/'>Render Link</a>} />
                            <MenuItem dataUI={getDataUI()} title={'Dropdown Level 1'} >
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} separator={true} />
                                <MenuItem dataUI={getDataUI()} title={'Dropdown Level 2'}>
                                    <MenuItem dataUI={getDataUI()} title={'Level 3'} />
                                    <MenuItem dataUI={getDataUI()} title={'Level 3'} />
                                    <MenuItem dataUI={getDataUI()} title={'Level 3'} render={() => <span>Render Span</span>} />
                                </MenuItem>
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} onClick={(e: any) => { console.log(e.target) }} />
                            </MenuItem>
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} icon={{ name: 'fal fa-palette', color: 'text-primary' }} render={() => <a href='/'>Render Link</a>} />
                            <MenuItem dataUI={getDataUI()} title={'Dropdown Level 1'} >
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} separator={true} />
                                <MenuItem dataUI={getDataUI()} title={'Dropdown Level 2'}>
                                    <MenuItem dataUI={getDataUI()} title={'Level 3'} />
                                    <MenuItem dataUI={getDataUI()} title={'Level 3'} />
                                    <MenuItem dataUI={getDataUI()} title={'Level 3'} render={() => <span>Render Span</span>} />
                                </MenuItem>
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} onClick={(e: any) => { console.log(e.target) }} />
                            </MenuItem>
                            <MenuItem dataUI={getDataUI()} title={'Dropdown Level 1'} >
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} separator={true} />
                                <MenuItem dataUI={getDataUI()} title={'Dropdown Level 2'}>
                                    {
                                        Array(20).fill('').map((el, i) => <MenuItem dataUI={getDataUI()} key={i} title={'Level 2'} render={() => <span>Render Span</span>} />)
                                    }
                                    <MenuItem dataUI={getDataUI()} title={'Level 3'} render={() => <span>LAST</span>} />
                                </MenuItem>
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} onClick={(e: any) => { console.log(e.target) }} />
                            </MenuItem>
                            <MenuItem dataUI={getDataUI()} title={'Dropdown Level 2'}>
                                {
                                    Array(9).fill('').map((el, i) => <MenuItem dataUI={getDataUI()} key={i} title={'Level 2'} render={() => <span>Render Span</span>} />)
                                }
                                <MenuItem dataUI={getDataUI()} title={'Dropdown Level 3'}>
                                    {
                                        Array(20).fill('').map((el, i) => <MenuItem dataUI={getDataUI()} key={i} title={'Level 3'} render={() => <span>Render Span</span>} />)
                                    }
                                    <MenuItem dataUI={getDataUI()} title={'Level 3'} render={() => <span>LAST 3</span>} />
                                </MenuItem>
                                <MenuItem dataUI={getDataUI()} title={'Level 3'} render={() => <span>LAST</span>} />
                            </MenuItem>
                            {
                                Array(5).fill('').map((el, i) => <MenuItem dataUI={getDataUI()} key={i} title={'Level 1'} render={() => <span>Render Span</span>} />)
                            }
                            <MenuItem dataUI={getDataUI()} title={'Dropdown Level 2'}>
                                {
                                    Array(4).fill('').map((el, i) => <MenuItem dataUI={getDataUI()} key={i} title={'Level 2'} render={() => <span>Render Span</span>} />)
                                }
                                <MenuItem dataUI={getDataUI()} title={'Level 3'} render={() => <span>LAST 3</span>} />
                            </MenuItem>
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} icon={{ name: 'fal fa-palette', color: 'text-primary' }} render={() => <a href='/'>LAST LEVEL 1</a>} />
                        </DropdownMenu>
                        <DropdownMenu
                            dataUI={getDataUI()}
                            title={'Risk'}
                            onToggleDropdownMenu={() => this.onToggleDropDown(1)}
                            isOpen={this.state.isOpenIndex[1]}
                        >
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} />
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} >
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} />
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} />
                            </MenuItem>
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} />
                        </DropdownMenu>
                        <DropdownMenu
                            title={'Tracking'}
                            onToggleDropdownMenu={() => this.onToggleDropDown(2)}
                            isOpen={this.state.isOpenIndex[2]}
                            dataUI={getDataUI()}
                        >
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} />
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} >
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} />
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} />
                            </MenuItem>
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} />
                        </DropdownMenu>
                        <DropdownMenu
                            dataUI={getDataUI()}
                            title={'Documents'}
                            onToggleDropdownMenu={() => this.onToggleDropDown(3)}
                            isOpen={this.state.isOpenIndex[3]}
                        >
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} />
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} >
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} />
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} />
                            </MenuItem>
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} />
                        </DropdownMenu>
                        <DropdownMenu
                            title={'Decision'}
                            onToggleDropdownMenu={() => this.onToggleDropDown(4)}
                            isOpen={this.state.isOpenIndex[4]}
                            dataUI={getDataUI()}
                        >
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} />
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} >
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} />
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} />
                            </MenuItem>
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} />
                        </DropdownMenu>
                        <DropdownMenu
                            title={'Presentation'}
                            onToggleDropdownMenu={() => this.onToggleDropDown(5)}
                            isOpen={this.state.isOpenIndex[5]}
                            dataUI={getDataUI()}
                        >
                            <MenuItem dataUI={getDataUI()}
                                onMouseLeave={() => this.setState({ isHidden: true })}
                                render={() => <div className={`lv-custom-presentation-menu-item${!isHidden ? ' lv-custom-show-btn' : ''}`}>
                                    <a className="lv-link-styles" href="./">Test Blank Narrative</a>
                                    <Tooltip title="Some text">
                                        <Button dataUI={getDataUI()} onClick={() => this.setState({ isHidden: !isHidden })} btnType="icon" icon="far fa-ellipsis-v" />
                                    </Tooltip>
                                </div>}>
                                <MenuItem dataUI={getDataUI()} isHidden={isHidden} title={'Download plain'} />
                                <MenuItem dataUI={getDataUI()} isHidden={isHidden} title={'Reset to template'} />
                            </MenuItem>
                            <MenuItem dataUI={getDataUI()}
                                onMouseLeave={() => this.setState({ isHidden2: true })}
                                render={() => <div className={`lv-custom-presentation-menu-item${!isHidden2 ? ' lv-custom-show-btn' : ''}`}>
                                    <a className="lv-link-styles" href="./">E-mail from Dave</a>
                                    <Tooltip title="Some text">
                                        <Button dataUI={getDataUI()} onClick={() => this.setState({ isHidden2: !isHidden2 })} btnType="icon" icon="far fa-ellipsis-v" />
                                    </Tooltip>
                                </div>}>
                                <MenuItem dataUI={getDataUI()} isHidden={isHidden2} title={'Download plain'} />
                                <MenuItem dataUI={getDataUI()} isHidden={isHidden2} title={'Reset to template'} />
                            </MenuItem>
                            <MenuItem dataUI={getDataUI()}
                                onMouseLeave={() => this.setState({ isHidden3: true })}
                                render={() => <div className={`lv-custom-presentation-menu-item${!isHidden3 ? ' lv-custom-show-btn' : ''}`}>
                                    <a className="lv-link-styles" href="./">RSB Word Narrative</a>
                                    <Tooltip title="Some text">
                                        <Button dataUI={getDataUI()} onClick={() => this.setState({ isHidden3: !isHidden3 })} btnType="icon" icon="far fa-ellipsis-v" />
                                    </Tooltip>
                                </div>}>
                                <MenuItem dataUI={getDataUI()} isHidden={isHidden3} title={'Download plain'} />
                                <MenuItem dataUI={getDataUI()} isHidden={isHidden3} title={'Reset to template'} />
                            </MenuItem>
                        </DropdownMenu>
                        <DropdownMenu
                            title={'Approval'}
                            onToggleDropdownMenu={() => this.onToggleDropDown(6)}
                            isOpen={this.state.isOpenIndex[6]}
                            dataUI={getDataUI()}
                        >
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} />
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} >
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} />
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} />
                            </MenuItem>
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} />
                        </DropdownMenu>
                        <DropdownMenu
                            title={'Links'}
                            onToggleDropdownMenu={() => this.onToggleDropDown(7)}
                            isOpen={this.state.isOpenIndex[7]}
                            dataUI={getDataUI()}
                        >
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} />
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} >
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} />
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} />
                            </MenuItem>
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} />
                        </DropdownMenu>
                        <DropdownMenu
                            title={'Reports'}
                            onToggleDropdownMenu={() => this.onToggleDropDown(8)}
                            isOpen={this.state.isOpenIndex[8]}
                            dataUI={getDataUI()}
                        >
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} />
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} >
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} />
                                <MenuItem dataUI={getDataUI()} title={'Level 2'} />
                            </MenuItem>
                            <MenuItem dataUI={getDataUI()} title={'Level 1'} />
                        </DropdownMenu>
                        <DropdownMenu
                            dataUI={getDataUI()}
                            render={() => <span>Link</span>}
                        />
                    </HeaderMenu>

                    <HeaderRightSection dataUI={getDataUI()}>
                        <DropdownMenu
                            icon="fal fa-bell"
                            iconTooltip="Notifications"
                            notification={this.state.notifications.filter(n => n.isUnread).length > 0 ? { title: `${this.state.notifications.filter(n => n.isUnread).length}`, type: 'danger' } : undefined}
                            onToggleDropdownMenu={() => this.onToggleDropDown(11)}
                            isOpen={this.state.isOpenIndex[11]}
                            isLeftSide
                            dataUI={getDataUI()}
                        >
                            <NotificationMenuItem dataUI={getDataUI()}>
                                <NotificationMenuHeader dataUI={getDataUI()}>
                                    <p className="h1-styles">Notifications</p>
                                    <ConfirmationDialog
                                        show={this.state.showConfirmation}
                                        render={() => <>
                                            <p className="mb-m">Are you sure you want to mark all notifications as read?</p>
                                            <Toolbar
                                                dataUI={getDataUI()}
                                                leftSide={<>
                                                    <Button
                                                        onClick={() => {
                                                            const updatedNotifications = this.state.notifications.map(notification => ({
                                                                ...notification,
                                                                isUnread: false,
                                                            }));

                                                            this.setState({ notifications: updatedNotifications, showConfirmation: false })
                                                        }}
                                                        btnType="primary"
                                                        icon='fal fa-check'
                                                        className="mr-s"
                                                        dataUI={getDataUI()}
                                                    >
                                                        Yes, mark all
                                                    </Button>
                                                    <Button onClick={() => this.setState({ showConfirmation: false })} btnType="secondary" icon='far fa-times-circle' className="mr-s" dataUI={getDataUI()}>
                                                        Cancel
                                                    </Button>
                                                </>}
                                            />
                                        </>}
                                        onClose={() => this.setState({ showConfirmation: false })}
                                        dataUI={getDataUI()}
                                    >
                                        <LinkWithIcon dataUI={getDataUI()} icon="fal fa-check" iconPlace="left" disabled={noNotificationsToShow}>
                                            <a href="/" onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({ showConfirmation: true })
                                            }}
                                            >
                                                Mark all as read
                                            </a>
                                        </LinkWithIcon>
                                    </ConfirmationDialog>
                                </NotificationMenuHeader>
                                <NotificationMenuContent dataUI={getDataUI()}>
                                    <ButtonGroup dataUI={getDataUI()}>
                                        <Button btnType={this.state.activeBtn === 1 ? 'primary' : 'secondary'} onClick={() => this.setState({ activeBtn: 1 })} dataUI={getDataUI()}>All</Button>
                                        <Button btnType={this.state.activeBtn === 2 ? 'primary' : 'secondary'} onClick={() => this.setState({ activeBtn: 2 })} dataUI={getDataUI()}>Unread</Button>
                                    </ButtonGroup>
                                    {/* No notifications to show. */}
                                    {noNotificationsToShow &&
                                        <>
                                            <div className="text-center mt-m mb-s">
                                                <Icon dataUI={getDataUI()} icon="fal fa-thumbs-up text-muted" size="L" />
                                            </div>
                                            <p className="text-muted text-center">No notifications to show.</p>
                                        </>
                                    }
                                </NotificationMenuContent>
                                {!noNotificationsToShow &&
                                    <NotificationMenuScrollPart dataUI={getDataUI()}>
                                        {this.state.notifications.map((notification, index) => (
                                            <ReactAux key={index}>
                                                {index === 0 && <p className="text-muted mb-xs">Today</p>}
                                                {index === 4 && <p className="text-muted mt-m mb-xs">09/08/2022</p>}
                                                <Notification
                                                    type="notification-enter"
                                                    fileNumber="6554-1"
                                                    title={notification.title}
                                                    description={notification.description}
                                                    dateTime={notification.dateTime}
                                                    isUnread={notification.isUnread}
                                                    messageDealerDisabled={index === 6}
                                                    markAsReadDisabled={index === 6}
                                                    onClickFileNumber={() => { console.log(' fileNumber Click ') }}
                                                    onClickMessageDealer={() => {
                                                        console.log(' MessageDealer Click ');
                                                        this.setState({ isVisibleDealerModal: true });
                                                        // this.onToggleDropDown(11);
                                                    }}
                                                    onClickMarkAsRead={() => {
                                                        const updatedNotifications = [...this.state.notifications];

                                                        updatedNotifications[index] = {
                                                            ...updatedNotifications[index],
                                                            isUnread: false
                                                        };

                                                        this.setState({
                                                            notifications: updatedNotifications
                                                        })
                                                    }}
                                                    dataUI={getDataUI()}
                                                />
                                            </ReactAux>
                                        ))}
                                    </NotificationMenuScrollPart>
                                }
                            </NotificationMenuItem>
                        </DropdownMenu>
                        <HeaderButtonIcon dataUI={getDataUI()} icon="fal fa-file-upload" notification={{ title: "3", type: 'danger' }} onClick={() => this.setUploadsModalVisibility(true)} />
                        <HeaderButtonIcon dataUI={getDataUI()} icon="fal fa-file-spreadsheet" notification={{ title: '', type: 'danger' }} onClick={() => this.setSpreadListModalVisibility(true)} />
                        <HeaderButtonIcon dataUI={getDataUI()} icon="fal fa-stamp" notification={{ title: "6", type: 'danger' }} />
                        <HeaderInfo dataUI={getDataUI()} sync="03-01" user="IBS\Stoil" version="3.19.25" />
                    </HeaderRightSection>
                </Header>
                <ThemeChanger theme={theme} />
                <Modal
                    dataUI={getDataUI()}
                    isVisible={this.state.spreadListModalVisible}
                    title="Spread List"
                    onClose={() => this.setSpreadListModalVisibility(false)}>
                    <Toolbar
                        dataUI={getDataUI()}
                        leftSide={
                            <>
                                <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-check-double" type="button">Check in Selected</Button>
                                <Button dataUI={getDataUI()} btnType="secondary" type="button">Cancel</Button>
                            </>
                        } />
                    <Tabs
                        dataUI={getDataUI()}
                        onTabIndexChange={(index: number) => this.setSpreadListTabIndex(index)}
                        currentlyActiveTabIndex={this.state.spreadListTabIndex}
                    >
                        <TabList dataUI={getDataUI()}>
                            <TabItem dataUI={getDataUI()}>
                                <TabItemTitle dataUI={getDataUI()}>Spread Status list</TabItemTitle>
                            </TabItem>
                            <TabItem dataUI={getDataUI()}>
                                <TabItemTitle dataUI={getDataUI()}>Spread Checkout list</TabItemTitle>
                            </TabItem>
                        </TabList>
                        <TabContent dataUI={getDataUI()}>
                            <TabContentItem dataUI={getDataUI()}>
                                <SimpleTableContainer dataUI={getDataUI()}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Party</th>
                                                <th>Period</th>
                                                <th style={{ width: '200px' }}>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Anthony Testcase</td>
                                                <td>6/9/2020</td>
                                                <td><Badge dataUI={getDataUI()} type="warning" icon="fal fa-exclamation-triangle" fullWidth>Needs Attention</Badge></td>
                                            </tr>
                                            <tr>
                                                <td>Cupcakeds on Wheels</td>
                                                <td>6/8/2018</td>
                                                <td><Badge dataUI={getDataUI()} type="info" icon="fal fa-file-search" fullWidth>Sent to Review</Badge></td>
                                            </tr>
                                            <tr>
                                                <td>David Test</td>
                                                <td>6/9/2020</td>
                                                <td><Badge dataUI={getDataUI()} type="success" icon="fal fa-check" fullWidth>Recognised Successful</Badge></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </SimpleTableContainer>
                            </TabContentItem>

                            <TabContentItem dataUI={getDataUI()}>
                                <p className="mb-m">The following parties have spreads that you have open in edit mode and thus may not be edited by others. Select the customers you wish to Check In:</p>
                                <SimpleTableContainer dataUI={getDataUI()}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '46px' }}><Checkbox dataUI={getDataUI()} checked onChange={() => { }} /></th>
                                                <th>Description</th>
                                                <th>Last Checked</th>
                                            </tr>

                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><Checkbox dataUI={getDataUI()} checked onChange={() => { }} /></td>
                                                <td>Anthony Testcase</td>
                                                <td>6/9/2020 10:36:45 AM</td>
                                            </tr>
                                            <tr>
                                                <td><Checkbox dataUI={getDataUI()} checked onChange={() => { }} /></td>
                                                <td>Cupcakeds on Wheels</td>
                                                <td>3/9/2020 11:36:45 AM</td>
                                            </tr>
                                            <tr>
                                                <td><Checkbox dataUI={getDataUI()} checked onChange={() => { }} /></td>
                                                <td>David Test</td>
                                                <td>5/9/2020 1:03:16 PM</td>
                                            </tr>
                                            <tr>
                                                <td><Checkbox dataUI={getDataUI()} checked onChange={() => { }} /></td>
                                                <td>Anthony Testcase</td>
                                                <td>6/9/2020 10:36:45 AM</td>
                                            </tr>
                                            <tr>
                                                <td><Checkbox dataUI={getDataUI()} checked onChange={() => { }} /></td>
                                                <td>Cupcakeds on Wheels</td>
                                                <td>3/9/2020 11:36:45 AM</td>
                                            </tr>
                                            <tr>
                                                <td><Checkbox dataUI={getDataUI()} checked onChange={() => { }} /></td>
                                                <td>David Test</td>
                                                <td>5/9/2020 1:03:16 PM</td>
                                            </tr>
                                            <tr>
                                                <td><Checkbox dataUI={getDataUI()} checked onChange={() => { }} /></td>
                                                <td>Sprinkles Holdings LLC</td>
                                                <td>6/9/2020 9:17:45 AM</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </SimpleTableContainer>
                            </TabContentItem>
                        </TabContent>
                    </Tabs>
                </Modal>

                <Modal dataUI={getDataUI()} isVisible={this.state.uploadsModalVisible} title="Upload Document" onClose={() => this.setUploadsModalVisibility(false)}>
                    <SimpleTableContainer dataUI={getDataUI()}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Context</th>
                                    <th>Document and Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Required Doc</td>
                                    <td><UploadedFileRow dataUI={getDataUI()} uploadedFile={{ file: examplePDF, uploadSuccessful: true }} /></td>
                                </tr>
                                <tr>
                                    <td>Site Visit</td>
                                    <td><UploadedFileRow dataUI={getDataUI()} uploadedFile={{ file: exampleJPG, progress: 90 }} /></td>
                                </tr>
                                <tr>
                                    <td>Attachments</td>
                                    <td><UploadedFileRow dataUI={getDataUI()} uploadedFile={{ file: exampleDoc, uploadError: true }} /></td>
                                </tr>
                            </tbody>
                        </table>
                    </SimpleTableContainer>
                </Modal>

                <Modal dataUI={getDataUI()} isVisible={this.state.modalSettings} title="My Settings" onClose={() => this.setState({ modalSettings: false })}>
                    <Toolbar
                        dataUI={getDataUI()}
                        className="mb-m"
                        leftSide={
                            <>
                                <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-save">Save</Button>
                                <Button dataUI={getDataUI()} icon="far fa-times-circle" btnType="secondary">Cancel</Button>
                            </>
                        }
                        rightSide={
                            <>
                                <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-key" btnType="secondary">Set Pin</Button>
                                <Button dataUI={getDataUI()} icon="fal fa-lock-alt" btnType="secondary">Change Password</Button>
                            </>
                        }
                    />
                    <Tabs
                        dataUI={getDataUI()}
                        onTabIndexChange={(index: number) => this.setMySettingsTabIndex(index)}
                        currentlyActiveTabIndex={this.state.mySettingsTabIndex}
                    >
                        <TabList dataUI={getDataUI()}>
                            <TabItem dataUI={getDataUI()}>
                                <TabItemTitle dataUI={getDataUI()}>User Settings</TabItemTitle>
                            </TabItem>
                            <TabItem dataUI={getDataUI()}>
                                <TabItemTitle dataUI={getDataUI()}>Mobile Settings</TabItemTitle>
                            </TabItem>
                        </TabList>
                        <TabContent dataUI={getDataUI()}>
                            <TabContentItem dataUI={getDataUI()}>
                                <ModalScrollableContainer>
                                    <div className="d-flex mb-m">
                                        <div className="section-container flex-1 mr-m">
                                            <h2 className="mb-m">Profile Picture</h2>
                                            <hr className="mb-m" />
                                            <div className="d-flex align-items-center">
                                                <img
                                                    className="mr-m object-fit-cover"
                                                    style={{ width: '95px', height: '95px', borderRadius: '50%' }}
                                                    src={this.state.image ? this.state.image as any : `${process.env.PUBLIC_URL}/img/empty-avatar.svg`}
                                                    alt="Avatar" />
                                                <div>
                                                    {!this.state.image && <Button
                                                        dataUI={getDataUI()}
                                                        icon="fal fa-upload"
                                                        btnType="secondary"
                                                        onClick={this.onImageUploadClick}>
                                                        Upload picture
                                                    </Button>}
                                                    {this.state.image && <>
                                                        <Button
                                                            dataUI={getDataUI()}
                                                            className="mb-s"
                                                            icon="fal fa-pen"
                                                            btnType="secondary"
                                                            onClick={this.onImageUploadClick}>
                                                            Change picture
                                                        </Button>
                                                        <Button
                                                            dataUI={getDataUI()}
                                                            icon="fal fa-trash"
                                                            btnType="secondary"
                                                            onClick={() => this.setState({ image: null })}>
                                                            Remove picture
                                                        </Button>
                                                    </>}
                                                </div>
                                                <input
                                                    className="d-none"
                                                    ref={(ref) => this.inputFileRef = ref}
                                                    id="image-upload"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={this.handleOnChangeImage}
                                                />
                                            </div>
                                        </div>
                                        <div className="section-container flex-1">
                                            <h2 className="mb-m">User Info</h2>
                                            <hr className="mb-m" />
                                            <div className="d-flex mb-s">
                                                <Label dataUI={getDataUI()} className="mr-m">Login ID</Label>
                                                <Label dataUI={getDataUI()}><strong>IBS/Joro</strong></Label>
                                            </div>
                                            <div className="d-flex mb-s">
                                                <Label dataUI={getDataUI()} className="mr-m">First Name</Label>
                                                <Label dataUI={getDataUI()}><strong>Joro</strong></Label>
                                            </div>
                                            <div className="d-flex">
                                                <Label dataUI={getDataUI()} className="mr-m">Last Name</Label>
                                                <Label dataUI={getDataUI()}><strong>Georgiev</strong></Label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section-container mb-m">
                                        <h2 className="mb-m">Download LoanVantage Add-Ins</h2>
                                        <hr className="mb-m" />
                                        <LinkWithIcon dataUI={getDataUI()} className="d-flex mb-m" icon="fal fa-download" iconPlace="left">
                                            <a href="/">Microsoft Word LoanVantage Add-In 1.0.312</a>
                                        </LinkWithIcon>
                                        <LinkWithIcon dataUI={getDataUI()} className="d-flex" icon="fal fa-download" iconPlace="left">
                                            <a href="/">Microsoft Word LoanVantage Add-In 1.0.200</a>
                                        </LinkWithIcon>
                                    </div>
                                    <div className="d-flex">
                                        <div className="section-container flex-1 mr-m">
                                            <h2 className="mb-m">Notifications</h2>
                                            <hr className="mb-m" />
                                            <Checkbox dataUI={getDataUI()} className="d-block mb-m">Approval</Checkbox>
                                            <Checkbox dataUI={getDataUI()} className="d-block mb-m">Site Visit</Checkbox>
                                            <Checkbox dataUI={getDataUI()} className="d-block">Chat</Checkbox>
                                        </div>
                                        <div className="section-container flex-1">
                                            <h2 className="mb-m">Order of Financial Periods From Left</h2>
                                            <hr className="mb-m" />
                                            <div className="mb-m">
                                                <Radio dataUI={getDataUI()} className="d-block" name="radio-3">Oldest To Most Recent (E.g. 2019, 2020, 2021)</Radio>
                                                <Radio dataUI={getDataUI()} className="d-block" name="radio-3">Recent To Oldest (E.g. 2021, 2020, 2019)</Radio>
                                            </div>
                                        </div>
                                    </div>
                                </ModalScrollableContainer>
                            </TabContentItem>

                            <TabContentItem dataUI={getDataUI()}>
                                <ModalScrollableContainer>
                                    <div className="section-container mb-m">
                                        <h2 className="mb-m">Mobile Registration</h2>
                                        <hr className="mb-m" />
                                        <Toolbar
                                            dataUI={getDataUI()}
                                            className="mb-m"
                                            leftSide={
                                                <Button dataUI={getDataUI()} icon="fal fa-plus">Add Registration</Button>
                                            }
                                        />
                                        <SimpleTableContainer dataUI={getDataUI()}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '54px' }}>Enable</th>
                                                        <th style={{ width: '42px' }}></th>
                                                        <th style={{ width: '42px' }}></th>
                                                        <th>Registration Code</th>
                                                        <th>Complete Date</th>
                                                        <th>Device Type</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td><Checkbox dataUI={getDataUI()} checked onChange={() => { }} /></td>
                                                        <td>
                                                            <Tooltip title="Delete Registration">
                                                                <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-trash" />
                                                            </Tooltip>
                                                        </td>
                                                        <td>
                                                            <Tooltip title="Re-Send E-mail">
                                                                <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-envelope" />
                                                            </Tooltip>
                                                        </td>
                                                        <td>8c3d2a29a4</td>
                                                        <td>05/23/21</td>
                                                        <td>Samsung SM-G988BB Android 11</td>
                                                    </tr>
                                                    <tr>
                                                        <td><Checkbox dataUI={getDataUI()} checked onChange={() => { }} /></td>
                                                        <td>
                                                            <Tooltip title="Delete Registration">
                                                                <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-trash" />
                                                            </Tooltip>
                                                        </td>
                                                        <td>
                                                            <Tooltip title="Re-Send E-mail">
                                                                <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-envelope" />
                                                            </Tooltip>
                                                        </td>
                                                        <td>5c42h7ah7b</td>
                                                        <td>05/23/21</td>
                                                        <td>Samsung SM-N986B Android 10</td>
                                                    </tr>
                                                    <tr>
                                                        <td><Checkbox dataUI={getDataUI()} checked onChange={() => { }} /></td>
                                                        <td>
                                                            <Tooltip title="Delete Registration">
                                                                <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-trash" />
                                                            </Tooltip>
                                                        </td>
                                                        <td>
                                                            <Tooltip title="Re-Send E-mail">
                                                                <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-envelope" />
                                                            </Tooltip>
                                                        </td>
                                                        <td>7g5t3d58h3</td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td><Checkbox dataUI={getDataUI()} checked onChange={() => { }} /></td>
                                                        <td>
                                                            <Tooltip title="Delete Registration">
                                                                <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-trash" />
                                                            </Tooltip>
                                                        </td>
                                                        <td>
                                                            <Tooltip title="Re-Send E-mail">
                                                                <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-envelope" />
                                                            </Tooltip>
                                                        </td>
                                                        <td>3c52h7ah7f</td>
                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </SimpleTableContainer>
                                    </div>
                                    <div className="section-container">
                                        <h2 className="mb-m">Mobile Notifications</h2>
                                        <hr className="mb-m" />
                                        <Checkbox dataUI={getDataUI()} className="d-block mb-m">Approval</Checkbox>
                                        <Checkbox dataUI={getDataUI()} className="d-block">Chat</Checkbox>
                                    </div>
                                </ModalScrollableContainer>
                            </TabContentItem>
                        </TabContent>
                    </Tabs>
                </Modal>

                <DemoMessageDealerModal isVisible={this.state.isVisibleDealerModal} onClose={() => { this.setState({ isVisibleDealerModal: false }) }} />
            </>
        );
    }
}

export default DemoHeader;
