import React, { useState, useRef, useEffect } from 'react';

import {
    WizardStepsContainer, WizardStep, Button, MainContainerHeader, MainContainerScrollable,
    ReactAux, Select, InfoBox, Row, Col, SummaryBox, Toolbar, Label, CardVertical, Carousel, Icon,
    Tooltip, NumericInput, Modal, ModalScrollableContainer, SimpleTableContainer, Checkbox,
    Collapse, Radio, Input, Textarea
} from '@jkhy/vsg-loanvantage-design-system';

import DemoLocationPage from './DemoLocationPage';
import DemoChartsPage from './DemoChartsPage';
import { getDataUI } from '../helpers/helpers';

const toolbarData = [
    { title: 'Principal Financed:', desc: '$100,000' },
    { title: 'Type:', desc: 'Principal & Interest' },
    { title: 'Frequency:', desc: 'Monthly' },
    { title: 'Term:', desc: '12 Months' },
    { title: 'Amorthization:', desc: '24 Months' },
    { title: 'Interest Rate:', desc: '4,250%' },
    { title: 'Amount:', desc: '$2,191.71' },
];

const EditableCell = (props: { value?: string, align?: 'left' | 'right' }) => {
    const inputRef = useRef() as any;
    const { value, align } = props;

    const [cellValue, setCellValue] = useState(value || '');
    const [isEditMode, setEditMode] = useState(false);

    useEffect(() => {
        if (isEditMode && inputRef) {
            inputRef.current.focus();
        }
    }, [isEditMode, inputRef]);

    const onInputChange = (e: any) => {
        setCellValue(e.target.value)
    }

    const cellClasses = [
        align === 'right' ? 'text-right' : '',
        !value ? 'simple-editable-cell' : ''
    ];
    return (
        <td
            className={cellClasses.join(' ').trim()}
            onClick={() => {
                if (!isEditMode) setEditMode(true);
            }}
        >
            {isEditMode ? <Input
                dataUI={getDataUI()}
                ref={inputRef}
                onBlur={(e) => {
                    setEditMode(false)
                }}
                onChange={onInputChange}
                value={cellValue}

            /> : <span className="lv-link-styles">{cellValue}</span>
            }
        </td>
    )
}

class DemoCreditCheck extends React.Component {

    state = {
        currentStepIndex: 2,
        selectValue: '1',
        isEditRequestAmount: false,
        modal: false,
        collapsOne: false,
        collapsTwo: false,
        selectedLoan: null,
    }


    onSelectLoan = (index: number) => {
        this.setState({
            selectedLoan: index
        })
    }

    renderCards = () => {
        return Array.from(Array(6).keys()).map((el, i) => {
            const index = i;
            const isSelected = i === this.state.selectedLoan;
            if (index % 2 === 0) {
                return (
                    <CardVertical
                        dataUI={getDataUI()}
                        selected={isSelected}
                        title={`${index + 1} Year Term`}
                        contentItems={[
                            { title: 'Loan Amount', description: ['US $ 960,000.00'] },
                            { title: 'Base Payment', description: ['N/A'] },
                            { title: 'Term', description: ['36 Monthly'] },
                            { title: 'Interest Rate', description: [<strong>3.450%</strong>] }
                        ]}
                        actionButton={
                            <Button
                                dataUI={getDataUI()}
                                btnType={isSelected ? 'primary' : 'secondary'}
                                icon={isSelected ? "fal fa-pen" : "fal fa-check"}
                                onClick={() => {
                                    this.onSelectLoan(index);
                                    this.openModal();
                                }}>
                                {isSelected ? "Edit Selected" : "Select Loan"}
                            </Button>
                        }
                    />
                )
            }
            return (
                <CardVertical
                    dataUI={getDataUI()}
                    selected={isSelected}
                    title={`${index + 1} Year Term`}
                    actionButton={
                        <Button
                            dataUI={getDataUI()}
                            btnType={isSelected ? 'primary' : 'secondary'}
                            icon={isSelected ? "fal fa-pen" : "fal fa-check"}
                            onClick={() => {
                                this.onSelectLoan(index);
                                this.openModal();
                            }}>
                            {isSelected ? "Edit Selected" : "Select Loan"}
                        </Button>
                    }
                    contentItems={[
                        { title: 'Loan Amount', description: ['US $ 960,000.00'] },
                        { title: 'Base Payment', description: ['N/A'] },
                        { title: 'Term', description: ['36 Monthly'] },
                        { title: 'Interest Rate', description: ['5.000 % (Variable)', 'WSJ Prime + 5.800 %)', '5.0000 % (Ceiling)', '3.000 % (Floor)'] }
                    ]}
                />
            )
        })
    }

    toggleEditRequestAmount = () => {
        this.setState({
            isEditRequestAmount: !this.state.isEditRequestAmount
        });
    }

    openModal = () => {
        this.setState({
            modal: true
        });
    }

    renderToolbarData = (data: any[]) => {
        return data.map((obj, i) => {
            const index = i;
            return (
                <ReactAux key={index}>
                    <Label dataUI={getDataUI()} className="text-bold mr-xs text-small">{obj.title}</Label>
                    <Label dataUI={getDataUI()} className={`text-small${!(index === data.length - 1) ? ' mr-m' : ''}`}>{obj.desc}</Label>
                </ReactAux>
            )
        })
    }

    render() {
        const { isEditRequestAmount } = this.state;
        const currentStepIndex = this.state.currentStepIndex;

        const previousStep = currentStepIndex > 0 ?
            <Button
                dataUI={getDataUI()} icon="fal fa-arrow-circle-left" btnType="secondary" onClick={() => this.setState({ currentStepIndex: currentStepIndex - 1 })}>Previous step</Button>
            : undefined;

        const nextStep = currentStepIndex === 3 ?
            undefined
            : <Button
                dataUI={getDataUI()} className="ml-s" icon="fal fa-arrow-circle-right" onClick={() => this.setState({ currentStepIndex: currentStepIndex + 1 })}  >Next step</Button>;

        return (
            <ReactAux>
                <MainContainerHeader dataUI={getDataUI()} >
                    <WizardStepsContainer
                        dataUI={getDataUI()}
                        title="Credit Check"
                        previousStep={previousStep}
                        nextStep={nextStep}
                        currentStepIndex={currentStepIndex}>
                        <WizardStep dataUI={getDataUI()} title="1. Credit History" status="success" icon="fal fa-history" onClick={() => this.setState({ currentStepIndex: 0 })} />
                        <WizardStep dataUI={getDataUI()} title="2. Debt Schedule" status="warning" icon="fal fa-calendar-alt" onClick={() => this.setState({ currentStepIndex: 1 })} />
                        <WizardStep dataUI={getDataUI()} title="3. Loan Options" status="success" icon="fal fa-tasks" onClick={() => this.setState({ currentStepIndex: 2 })} />
                        <WizardStep dataUI={getDataUI()} title="4. Underwriting Ratio" icon="fal fa-tachometer-alt" onClick={() => this.setState({ currentStepIndex: 3 })} />
                    </WizardStepsContainer>
                </MainContainerHeader>
                {currentStepIndex === 0 && <h2 className="mt-m">1. Credit History Content...</h2>}
                {currentStepIndex === 1 && (
                    <MainContainerScrollable>
                        <InfoBox dataUI={getDataUI()} className="mb-s mt-m">
                            By clicking this button, you confirm that you have a Permissible Purpose for obtaining this credit report.Unauthorized access is prohibited under the Fair Credit Reporting Act (FCRA) and punishable by $2500 and/or 1 year in Federal prison per occurrence. Commercial Customers in this File.
                        </InfoBox>
                        <div className="d-flex justify-content-end mb-l">
                            <Select
                                dataUI={getDataUI()}
                                className="mr-s width-m"
                                options={[{ value: "1", label: "Credit request" }, { value: "2", label: "Option 2" }]}
                                value={this.state.selectValue}
                                onChange={(obj: any) => this.setState({ selectValue: obj.value })}
                            />
                            <Button
                                dataUI={getDataUI()} icon="fal fa-share-square" btnType="secondary">Send request</Button>
                        </div>
                        <DemoLocationPage />
                    </MainContainerScrollable>
                )}
                {currentStepIndex === 2 && (
                    <MainContainerScrollable>
                        <Toolbar
                            dataUI={getDataUI()}
                            className="mt-m mb-m"
                            leftSide={(
                                <ReactAux>
                                    <Label dataUI={getDataUI()} className='mr-s'>Facility</Label>
                                    <Select
                                        dataUI={getDataUI()}
                                        className="width-m mr-s"
                                        value='1'
                                        options={[{ value: "1", label: "37380-1" }, { value: "2", label: "Other" }]}
                                    />
                                    <Button
                                        dataUI={getDataUI()} icon="fal fa-sync">Re-evaluete</Button>

                                    <Button
                                        dataUI={getDataUI()} className="ml-m mr-s" btnType="secondary" icon="fal fa-download">Download in Excel</Button>
                                    <Button
                                        dataUI={getDataUI()} btnType="secondary" icon="fal fa-file-pdf">View in PDF</Button>
                                </ReactAux>
                            )} />

                        <Row>
                            <Col xs="3"><Label dataUI={getDataUI()} className="justify-content-end text-right">Marisol Testcase</Label></Col>
                            <Col xs="9"><Label dataUI={getDataUI()} className="text-bold">Borrower</Label></Col>
                        </Row>
                        <Row>
                            <Col xs="3"><Label dataUI={getDataUI()} className="justify-content-end text-right">Carlos Testcase</Label></Col>
                            <Col xs="9"><Label dataUI={getDataUI()} className="text-bold">Borrower</Label></Col>
                        </Row>
                        <Row>
                            <Col xs="3"><Label dataUI={getDataUI()} className="justify-content-end text-right">Marisol Testcase</Label></Col>
                            <Col xs="9"><Label dataUI={getDataUI()} className="text-bold">Borrower</Label></Col>
                        </Row>


                        <Row className="mt-m">
                            <Col xs="3"><Label dataUI={getDataUI()} className="justify-content-end text-right">Requested Loan Amount:</Label></Col>
                            <Col xs="9">
                                <div className="d-flex align-items-center">
                                    {isEditRequestAmount ?
                                        <ReactAux>
                                            <NumericInput dataUI={getDataUI()} type="currency" autoFocus />
                                            <Button
                                                dataUI={getDataUI()} icon="fas fa-save" className="mr-s ml-s" onClick={this.toggleEditRequestAmount}>Save</Button>
                                            <Button
                                                dataUI={getDataUI()} btnType="secondary" icon="fas fa-times" onClick={this.toggleEditRequestAmount}>Cancel</Button>
                                        </ReactAux>
                                        : <ReactAux>
                                            <Label dataUI={getDataUI()} className="text-bold">US$12,000.00</Label>
                                            <Tooltip title="Edit">
                                                <Button
                                                    dataUI={getDataUI()}
                                                    className="ml-s"
                                                    btnType="icon"
                                                    icon='fal fa-pen'
                                                    onClick={this.toggleEditRequestAmount}
                                                />
                                            </Tooltip>
                                        </ReactAux>
                                    }
                                </div>
                            </Col>
                        </Row>
                        {isEditRequestAmount && <Row>
                            <Col xs={{ offset: 3, size: 9 }}>
                                <InfoBox dataUI={getDataUI()} className="mt-xs mb-s" type="warning" icon="fal fa-exclamation-triangle">
                                    Saving a <strong>new Loan Amount</strong> will update the Requested Loan Amount and override the Calculated Loan Amount result in the displayed loan options.
                                </InfoBox>

                            </Col>
                        </Row>}

                        <Row className="mb-m">
                            <Col xs="3"><Label dataUI={getDataUI()} className="justify-content-end text-right">Calculated Loan Amount:</Label></Col>
                            <Col xs="9">
                                <Label dataUI={getDataUI()} className="text-bold">US$12,000.00</Label>
                            </Col>
                        </Row>

                        <Carousel
                            dataUI={getDataUI()}
                            items={this.renderCards()}
                        />

                        <Toolbar
                            dataUI={getDataUI()}
                            leftSide={(
                                <ReactAux>
                                    <Button
                                        dataUI={getDataUI()} btnType="secondary" className='mr-m' icon="fas fa-times">Withdrawn</Button>
                                    <p className="text-danger text-small">
                                        <Icon dataUI={getDataUI()} size="S" className="text-danger mr-s" icon="fas fa-exclamation-circle" />
                                        By selecting <strong>Withdrawn</strong>, the client selects to withdraw from or decline any and all proposals shown.
                                    </p>
                                </ReactAux>
                            )} />


                        <Modal
                            dataUI={getDataUI()}
                            title="Loan Option"
                            isVisible={this.state.modal}
                            size="L"
                            onClose={() => this.setState({ modal: false })}
                        >
                            <Toolbar
                                dataUI={getDataUI()}
                                className="mb-m"
                                leftSide={
                                    <ReactAux>
                                        <Button
                                            dataUI={getDataUI()} icon="fal fa-check" className="mr-s">Continue with Selection</Button>
                                        <Button
                                            dataUI={getDataUI()} btnType="secondary" icon="far fa-times-circle">Cancel</Button>
                                    </ReactAux>
                                }
                            />

                            <Toolbar
                                dataUI={getDataUI()}
                                className="mb-m"
                                leftSide={<ReactAux> {this.renderToolbarData(toolbarData)}</ReactAux>}
                            />

                            <ModalScrollableContainer>

                                <div className="section-container mb-m">
                                    <h2>Terms Customizations</h2>
                                    <hr className='mb-m mt-m' />

                                    <Row>
                                        <Col className="border-right border-color-2">
                                            <Row>
                                                <Col xs="5">
                                                    <Label dataUI={getDataUI()} className="text-right justify-content-end">Modify Interest Rate:</Label>
                                                </Col>
                                                <Col xs="6">
                                                    <NumericInput dataUI={getDataUI()} type="percent" />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Row className="mb-s">
                                                <Col xs="5">
                                                    <Label dataUI={getDataUI()} className="text-right justify-content-end">Payment Frequency:</Label>
                                                </Col>
                                                <Col xs="6">
                                                    <Select
                                                        dataUI={getDataUI()}
                                                        className="flex-1"
                                                        options={[{ value: "1", label: "Monthly" }, { value: "2", label: "Other" }]}
                                                        value="1"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="mb-s">
                                                <Col xs="5">
                                                    <Label dataUI={getDataUI()} className="text-right justify-content-end">Monthly Payment Frequency:</Label>
                                                </Col>
                                                <Col xs="6">
                                                    <Select
                                                        dataUI={getDataUI()}
                                                        className="flex-1"
                                                        options={[{ value: "1", label: "24 Payments of $ 6,117.29" }, { value: "2", label: "Other" }]}
                                                        value="1"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs="5">
                                                    <Label dataUI={getDataUI()} className="text-right justify-content-end">Interest Rate:</Label>
                                                </Col>
                                                <Col xs="6">
                                                    <Label dataUI={getDataUI()} className="text-bold">4.55%</Label>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>

                                <div className="section-container mb-m">
                                    <h2>Fees</h2>
                                    <hr className='mb-m mt-m' />

                                    <Toolbar
                                        dataUI={getDataUI()}
                                        className="mb-m"
                                        leftSide={
                                            <>
                                                <Button
                                                    dataUI={getDataUI()}
                                                    btnType={"secondary"}
                                                    icon={'fal fa-sync'}
                                                    className="mr-m"
                                                >
                                                    Regenerate Fees
                                                </Button>

                                                <InfoBox dataUI={getDataUI()}>
                                                    <strong>QD Fees - Not Specific Process</strong>
                                                </InfoBox>
                                            </>
                                        }

                                    />

                                    <Collapse
                                        dataUI={getDataUI()}
                                        title="Reccuring and Upfront"
                                        expanded={this.state.collapsOne}
                                        onClick={() => this.setState({ collapsOne: !this.state.collapsOne })}
                                    >
                                        {/* 
                        **************************************************************************************
                             START DEMO TABLE WITH EDITABLE CELLS 
                        *** DO NOT USE THE EXAMPLE FOR IMPLAMENTATION
                        *** USE KENDO GRID
                        *** !!!!IMPORTATNT!!! REFFER TO FIGNMA DESIGN FOR TABLE CONTENT: 
                         https://www.figma.com/file/kVXBhETWcfSE0GX6VUmqdz/LV-Production?node-id=9066%3A245801
                        ***************************************************************************************
                    */}
                                        <SimpleTableContainer dataUI={getDataUI()}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: 1 }}></th>
                                                        <th style={{ width: '10%' }}>Fee Name</th>
                                                        <th style={{ width: '15%' }}>Finance Category</th>
                                                        <th style={{ width: '16%' }}>Fee Paid By</th>
                                                        <th style={{ width: '8%' }}>Collection</th>
                                                        <th style={{ width: '17%' }}>Cost Center</th>
                                                        <th style={{ width: '12%' }}>GL Account #</th>
                                                        <th style={{ width: '11%' }} className="text-right">Fee Amount</th>
                                                        <th style={{ width: '12%' }}>Fee Percent</th>
                                                        <th style={{ width: 1 }}>Cash</th>
                                                    </tr>

                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td></td>
                                                        <td colSpan={9}><span className="text-primary text-bold">Reccuring</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><Checkbox dataUI={getDataUI()} /></td>
                                                        <td>Annual Fee</td>
                                                        <EditableCell value="Financed" />
                                                        <EditableCell value="Borrower Financed" />
                                                        <EditableCell value="Yearly" />
                                                        <EditableCell value="100 Loan Operations" />
                                                        <EditableCell value="15521" />
                                                        <EditableCell value="$ 25.00" align='right' />
                                                        <EditableCell />
                                                        <td><Checkbox dataUI={getDataUI()} /></td>
                                                    </tr>
                                                    <tr>
                                                        <td></td>
                                                        <td colSpan={9}><span className="text-primary text-bold">Upfront</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><Checkbox dataUI={getDataUI()} /></td>
                                                        <td>S-Freq</td>
                                                        <EditableCell value="Prepaid" />
                                                        <EditableCell value="Borrower Cash" />
                                                        <EditableCell value="Monthly" />
                                                        <EditableCell value="100 Loan Operations" />
                                                        <EditableCell value="15521" />
                                                        <EditableCell value="$ 75.00" align='right' />
                                                        <EditableCell />
                                                        <td><Checkbox dataUI={getDataUI()} /></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </SimpleTableContainer>
                                        {/* END DEMO TABLE */}

                                        <div className="d-flex align-items-start mt-m ml-m">
                                            <Label dataUI={getDataUI()} className="mr-s">Fee Notes</Label>
                                            <Textarea dataUI={getDataUI()} className="width-l" />
                                        </div>
                                    </Collapse>

                                    <Collapse
                                        dataUI={getDataUI()}
                                        title="Closing Fees"
                                        expanded={this.state.collapsTwo}
                                        onClick={() => this.setState({ collapsTwo: !this.state.collapsTwo })}
                                    >
                                        <Toolbar
                                            dataUI={getDataUI()}
                                            className="mb-m"
                                            leftSide={
                                                <div className="d-flex">
                                                    <Label dataUI={getDataUI()} className="text-bold">Closing Type:</Label>
                                                    <div className="ml-s">
                                                        <Radio dataUI={getDataUI()} name="radio" className="mr-s">In-House</Radio>
                                                        <Radio dataUI={getDataUI()} name="radio" >Third Party</Radio>
                                                    </div>
                                                </div>
                                            }
                                        />
                                        {/* 
                        **************************************************************************************
                             START DEMO TABLE WITH EDITABLE CELLS 
                        *** DO NOT USE THE EXAMPLE FOR IMPLAMENTATION
                        *** USE KENDO GRID
                        *** !!!!IMPORTATNT!!! REFFER TO FIGNMA DESIGN FOR TABLE CONTENT: 
                         https://www.figma.com/file/kVXBhETWcfSE0GX6VUmqdz/LV-Production?node-id=9066%3A245801
                        ***************************************************************************************
                    */}
                                        <SimpleTableContainer dataUI={getDataUI()}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '30%' }}>Fee Name</th>
                                                        <th style={{ width: '15%' }}>Finance Category</th>
                                                        <th style={{ width: '15%' }}>Fee Paid By</th>
                                                        <th style={{ width: '20%' }} className="text-right">Total</th>
                                                        <th style={{ width: '10%' }} className="text-right">Paid</th>
                                                        <th style={{ width: '10%' }} className="text-right">Balance Due</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Borrower Cash Prepaid</td>
                                                        <EditableCell />
                                                        <EditableCell value="Borrower Cash" />
                                                        <td>
                                                            <div className="d-flex align-items-center flex-auto justify-content-end">
                                                                <span className="mr-s">$ 5.00</span>
                                                                <Tooltip title="Edit">
                                                                    <Button
                                                                        dataUI={getDataUI()} btnType="icon" icon='fal fa-pen' />
                                                                </Tooltip>
                                                            </div>
                                                        </td>
                                                        <EditableCell value="$ 0.00 " align="right" />
                                                        <td className="text-right">$ 5.00 </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Borrower Cash Prepaid Inflated</td>
                                                        <EditableCell />
                                                        <EditableCell value="Borrower Cash" />
                                                        <td>
                                                            <div className="d-flex align-items-center flex-auto justify-content-end">
                                                                <span className="mr-s">$ 45.00</span>
                                                                <Tooltip title="Edit">
                                                                    <Button
                                                                        dataUI={getDataUI()} btnType="icon" icon='fal fa-pen' />
                                                                </Tooltip>
                                                            </div>
                                                        </td>
                                                        <EditableCell value="$ 0.00 " align="right" />
                                                        <td className="text-right">$ 45.00 </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Borrower Financed Finance Charge</td>
                                                        <EditableCell />
                                                        <EditableCell value="Borrower Financed" />
                                                        <td>
                                                            <div className="d-flex align-items-center flex-auto justify-content-end">
                                                                <span className="mr-s">$ 250.00</span>
                                                                <Tooltip title="Edit">
                                                                    <Button
                                                                        dataUI={getDataUI()} btnType="icon" icon='fal fa-pen' />
                                                                </Tooltip>
                                                            </div>
                                                        </td>
                                                        <EditableCell value="$ 0.00 " align="right" />
                                                        <td className="text-right"> $ 250.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Application Fee</td>
                                                        <EditableCell />
                                                        <EditableCell value="Borrower Financed" />
                                                        <td>
                                                            <div className="d-flex align-items-center flex-auto justify-content-end">
                                                                <span className="mr-s">$ 255.00</span>
                                                                <Tooltip title="Edit">
                                                                    <Button
                                                                        dataUI={getDataUI()} btnType="icon" icon='fal fa-pen' />
                                                                </Tooltip>
                                                            </div>
                                                        </td>
                                                        <EditableCell value="$ 0.00 " align="right" />
                                                        <td className="text-right">$ 255.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={4}></td>
                                                        <td className="text-bold">Total Fees:</td>
                                                        <td className="text-right text-bold">$ 550.00</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </SimpleTableContainer>
                                        {/* END DEMO TABLE */}
                                    </Collapse>
                                </div>

                                <div className="section-container">
                                    <h2>Protection Coverage Options</h2>
                                    <hr className='mb-m mt-m' />

                                    <Toolbar
                                        dataUI={getDataUI()}
                                        className="mb-m"
                                        leftSide={
                                            <>
                                                <Label dataUI={getDataUI()} className="mr-s">Insurance Type</Label>
                                                <Select
                                                    dataUI={getDataUI()}
                                                    className="mr-m width-m"
                                                    options={[
                                                        { value: "1", label: 'GrossNetOption' },
                                                        { value: "2", label: 'Other' }
                                                    ]}
                                                    value={'1'}
                                                />

                                                <Label dataUI={getDataUI()} className="mr-s">{"A&H Schedules"}</Label>
                                                <Select
                                                    dataUI={getDataUI()}
                                                    className="width-m"
                                                    options={[
                                                        { value: "1", label: 'Retro14' },
                                                        { value: "2", label: 'Other' }
                                                    ]}
                                                    value={'1'}
                                                />
                                            </>
                                        }
                                    />

                                    {/* 
                        **************************************************************************************
                             START DEMO TABLE 
                        *** DO NOT USE THE EXAMPLE FOR IMPLAMENTATION
                        *** USE KENDO GRID
                        *** !!!!IMPORTATNT!!! REFFER TO FIGNMA DESIGN FOR TABLE CONTENT: 
                         https://www.figma.com/file/kVXBhETWcfSE0GX6VUmqdz/LV-Production?node-id=9197%3A296162
                        ***************************************************************************************
                    */}

                                    <SimpleTableContainer dataUI={getDataUI()}>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th style={{ width: 1 }}></th>
                                                    <th>Coverage Description</th>
                                                    <th className="text-right">Cost Per Payment</th>
                                                    <th className="text-right">Loan Life Cost</th>
                                                    <th className="text-right">Term</th>
                                                    <th>Messages</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="tr-selected">
                                                    <td><Radio dataUI={getDataUI()} name="radio-table" /></td>
                                                    <td>NoIUI / JointLife / NoAH</td>
                                                    <td className="text-right">$0.00</td>
                                                    <td className="text-right">$0.00</td>
                                                    <td className="text-right">24</td>
                                                    <td>Co-buyer Ineligible</td>
                                                </tr>
                                                <tr>
                                                    <td><Radio dataUI={getDataUI()} name="radio-table" /></td>
                                                    <td>NoIUI / JointLife / NoAH</td>
                                                    <td className="text-right">$14.47</td>
                                                    <td className="text-right">$565.47</td>
                                                    <td className="text-right">24</td>
                                                    <td>Insurance Truncated / Co-buyer Ineligible</td>
                                                </tr>
                                                <tr>
                                                    <td><Radio dataUI={getDataUI()} name="radio-table" /></td>
                                                    <td>NoIUI / JointLife / NoAH</td>
                                                    <td className="text-right">$23.70</td>
                                                    <td className="text-right">$768.70</td>
                                                    <td className="text-right">24</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td><Radio dataUI={getDataUI()} name="radio-table" /></td>
                                                    <td>NoIUI / JointLife / NoAH</td>
                                                    <td className="text-right">$14.47</td>
                                                    <td className="text-right">$565.47</td>
                                                    <td className="text-right">24</td>
                                                    <td>Insurance Truncated / Co-buyer Ineligible</td>
                                                </tr>
                                                <tr>
                                                    <td><Radio dataUI={getDataUI()} name="radio-table" /></td>
                                                    <td>NoIUI / JointLife / NoAH</td>
                                                    <td className="text-right">$0.00</td>
                                                    <td className="text-right">$0.00</td>
                                                    <td className="text-right">24</td>
                                                    <td>Co-buyer Ineligible</td>
                                                </tr>
                                                <tr>
                                                    <td><Radio dataUI={getDataUI()} name="radio-table" /></td>
                                                    <td>NoIUI / JointLife / NoAH</td>
                                                    <td className="text-right">$14.47</td>
                                                    <td className="text-right">$565.47</td>
                                                    <td className="text-right">24</td>
                                                    <td>Insurance Truncated / Co-buyer Ineligible</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </SimpleTableContainer>
                                    {/* END DEMO TABLE */}
                                </div>
                            </ModalScrollableContainer>
                        </Modal>
                    </MainContainerScrollable>
                )}
                {currentStepIndex === 3 &&
                    <MainContainerScrollable>
                        <Row className="mt-m">
                            <Col>
                                <SummaryBox dataUI={getDataUI()} className="mb-s mr-s" title="Product:" content={<a href='/'>*BB BL CONSSUMER PRODUCT</a>} />
                                <SummaryBox dataUI={getDataUI()} className="mb-s mr-s" title="Loan amount:" content="$960,000" />
                                <SummaryBox dataUI={getDataUI()} className="mb-s mr-s" title="Payment:" content="0" />
                                <SummaryBox dataUI={getDataUI()} className="mb-s mr-s" title="Interest Rate:" content="4.25%" />
                                <SummaryBox dataUI={getDataUI()} className="mb-s mr-s" title="Term:" content="36 months" />
                            </Col>
                        </Row>

                        <Button
                            dataUI={getDataUI()} icon="fal fa-eye" className="mr-s">Show Secondary Borrower Ratios</Button>
                        <Button
                            dataUI={getDataUI()} icon="fal fa-file-excel" btnType="secondary">Download in Excel</Button>

                        <DemoChartsPage />

                    </MainContainerScrollable>
                }
            </ReactAux>
        );
    }
}

export default DemoCreditCheck;