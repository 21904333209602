import React from 'react';
import { Link } from 'react-router-dom';

import {
    MainContainerScrollable, Button, Icon, Row, Col, Input, Textarea, Select, Tabs,
    TabList, TabItem, TabContent, TabContentItem, TabItemTitle, TabItemDetails, TabItemRow,
    Checkbox, Radio, LinkWithIcon, LeftBarCrumbs, Card, CardSection, LoanOptionCard, ProgressBar,
    WidgetContainer, SummaryBox, SimpleTableContainer, InfoBox, LoanOptionCardContainer,
    VerticalTabs, VerticalTabList, VerticalTabItem, VerticalTabContent, VerticalTabContentItem,
    NumericInput, MaskInput, ValidationMessage, Diagram, Autocomplete, Multiselect,
    MultiselectBox, MultiEmail, Toolbar, Carousel, CardVertical, Label, Tooltip, Collapse,
    Popover,

    Grid, GridColumn as Column, GridContainer, orderBy,
    Editor, EditorTools,
    Datepicker, DatepickerUtils, EditorContainer, Modal, ModalScrollableContainer,
    SelectOptionType
} from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../helpers/helpers';

const selectOptions: SelectOptionType[] = [{ value: "1", label: "Option 1" }, { value: "2", label: "Option 2" }, { value: "3", label: "Option 3" }, { value: "4", label: "Option 4" }]

const selectOptionsAsync: SelectOptionType[] = Array.from(Array(1000).keys()).map((el, i) => {
    return {
        value: i,
        label: `Labelel - ${i}`,
    }
})

const loanOptionCards = [false, true, false, false];

const productsGrid =
    [{
        "ProductID": 1,
        "ProductName": "Chai",
        "SupplierID": 1,
        "CategoryID": 1,
        "QuantityPerUnit": "10 boxes x 20 bags",
        "UnitPrice": 18.0000,
        "UnitsInStock": 39,
        "UnitsOnOrder": 0,
        "ReorderLevel": 10,
        "Discontinued": false,
        "Category": {
            "CategoryID": 1,
            "CategoryName": "Beverages",
            "Description": "Soft drinks, coffees, teas, beers, and ales"
        }
    }, {
        "ProductID": 2,
        "ProductName": "Chang",
        "SupplierID": 1,
        "CategoryID": 1,
        "QuantityPerUnit": "24 - 12 oz bottles",
        "UnitPrice": 19.0000,
        "UnitsInStock": 17,
        "UnitsOnOrder": 40,
        "ReorderLevel": 25,
        "Discontinued": false,
        "Category": {
            "CategoryID": 1,
            "CategoryName": "Beverages",
            "Description": "Soft drinks, coffees, teas, beers, and ales"
        }
    }, {
        "ProductID": 3,
        "ProductName": "Aniseed Syrup",
        "SupplierID": 1,
        "CategoryID": 2,
        "QuantityPerUnit": "12 - 550 ml bottles",
        "UnitPrice": 10.0000,
        "UnitsInStock": 13,
        "UnitsOnOrder": 70,
        "ReorderLevel": 25,
        "Discontinued": false,
        "Category": {
            "CategoryID": 2,
            "CategoryName": "Condiments",
            "Description": "Sweet and savory sauces, relishes, spreads, and seasonings"
        }
    }, {
        "ProductID": 4,
        "ProductName": "Chef Anton's Cajun Seasoning",
        "SupplierID": 2,
        "CategoryID": 2,
        "QuantityPerUnit": "48 - 6 oz jars",
        "UnitPrice": 22.0000,
        "UnitsInStock": 53,
        "UnitsOnOrder": 0,
        "ReorderLevel": 0,
        "Discontinued": false,
        "Category": {
            "CategoryID": 2,
            "CategoryName": "Condiments",
            "Description": "Sweet and savory sauces, relishes, spreads, and seasonings"
        }
    }, {
        "ProductID": 5,
        "ProductName": "Chef Anton's Gumbo Mix",
        "SupplierID": 2,
        "CategoryID": 2,
        "QuantityPerUnit": "36 boxes",
        "UnitPrice": 21.3500,
        "UnitsInStock": 0,
        "UnitsOnOrder": 0,
        "ReorderLevel": 0,
        "Discontinued": true,
        "Category": {
            "CategoryID": 2,
            "CategoryName": "Condiments",
            "Description": "Sweet and savory sauces, relishes, spreads, and seasonings"
        }
    }, {
        "ProductID": 6,
        "ProductName": "Grandma's Boysenberry Spread",
        "SupplierID": 3,
        "CategoryID": 2,
        "QuantityPerUnit": "12 - 8 oz jars",
        "UnitPrice": 25.0000,
        "UnitsInStock": 120,
        "UnitsOnOrder": 0,
        "ReorderLevel": 25,
        "Discontinued": false,
        "Category": {
            "CategoryID": 2,
            "CategoryName": "Condiments",
            "Description": "Sweet and savory sauces, relishes, spreads, and seasonings"
        }
    }, {
        "ProductID": 7,
        "ProductName": "Uncle Bob's Organic Dried Pears",
        "SupplierID": 3,
        "CategoryID": 7,
        "QuantityPerUnit": "12 - 1 lb pkgs.",
        "UnitPrice": 30.0000,
        "UnitsInStock": 15,
        "UnitsOnOrder": 0,
        "ReorderLevel": 10,
        "Discontinued": false,
        "Category": {
            "CategoryID": 7,
            "CategoryName": "Produce",
            "Description": "Dried fruit and bean curd"
        }
    }, {
        "ProductID": 8,
        "ProductName": "Northwoods Cranberry Sauce",
        "SupplierID": 3,
        "CategoryID": 2,
        "QuantityPerUnit": "12 - 12 oz jars",
        "UnitPrice": 40.0000,
        "UnitsInStock": 6,
        "UnitsOnOrder": 0,
        "ReorderLevel": 0,
        "Discontinued": false,
        "Category": {
            "CategoryID": 2,
            "CategoryName": "Condiments",
            "Description": "Sweet and savory sauces, relishes, spreads, and seasonings"
        }
    }, {
        "ProductID": 9,
        "ProductName": "Mishi Kobe Niku",
        "SupplierID": 4,
        "CategoryID": 6,
        "QuantityPerUnit": "18 - 500 g pkgs.",
        "UnitPrice": 97.0000,
        "UnitsInStock": 29,
        "UnitsOnOrder": 0,
        "ReorderLevel": 0,
        "Discontinued": true,
        "Category": {
            "CategoryID": 6,
            "CategoryName": "Meat/Poultry",
            "Description": "Prepared meats"
        }
    }, {
        "ProductID": 10,
        "ProductName": "Ikura",
        "SupplierID": 4,
        "CategoryID": 8,
        "QuantityPerUnit": "12 - 200 ml jars",
        "UnitPrice": 31.0000,
        "UnitsInStock": 31,
        "UnitsOnOrder": 0,
        "ReorderLevel": 0,
        "Discontinued": false,
        "Category": {
            "CategoryID": 8,
            "CategoryName": "Seafood",
            "Description": "Seaweed and fish"
        }
    }];

const diagramData = [
    {
        title: 'Iv Glore',
        titleIcon: 'fal fa-user',
        text: '50.0000%',
        onAddAction: () => console.log('add Iv Glore'),
        children: [
            {
                title: 'Maria Testcase',
                titleIcon: 'fal fa-user',
                text: '50.0000%',
                onAddAction: () => console.log('add action Maria Testcase'),
                onEditAction: () => console.log('edit action Maria Testcase'),
                onDeleteAction: () => console.log('delete action Maria Testcase'),
                children: [
                    {
                        title: 'Teresa Balkam Ginnan',
                        titleIcon: 'fal fa-user',
                        text: '50.0000%',
                        onAddAction: () => console.log('add action Teresa Balkam Ginnan'),
                        onEditAction: () => console.log('edit action Teresa Balkam Ginnan'),
                    },
                    {
                        title: 'Elizabeth K Adams',
                        titleIcon: 'fal fa-user',
                        text: '50.0000%',
                        onEditAction: () => console.log('edit action Elizabeth K Adams'),
                        onDeleteAction: () => console.log('delete action Elizabeth K Adams'),
                        children: [
                            {
                                title: 'MANDERE CONSTRUCTION INC',
                                titleIcon: 'fal fa-user',
                                text: '50.0000%',
                                onDeleteAction: () => console.log('delete action MANDERE CONSTRUCTION INC'),
                            },
                            {
                                title: 'Bobby G Stevence',
                                titleIcon: 'fal fa-user',
                                text: '50.0000%',
                                onEditAction: () => console.log('edit action Bobby G Stevence'),
                                children: [
                                    {
                                        title: 'CHASE M RANDALL',
                                        text: '50.0000%',
                                        onAddAction: () => console.log('add action CHASE M RANDALL'),
                                        onEditAction: () => console.log('edit action CHASE M RANDALL'),
                                        onDeleteAction: () => console.log('delete action CHASE M RANDALL'),
                                    },
                                    {
                                        title: "Sam's GMM",
                                        titleIcon: 'fal fa-user',
                                        text: '50.0000%',
                                        onAddAction: () => console.log("add action Sam's GMM"),
                                        onEditAction: () => console.log("edit action Sam's GMM"),
                                        onDeleteAction: () => console.log("delete action Sam's GMM"),
                                        children: [
                                            {
                                                title: 'Gunner Wright Inc',
                                                text: '50.0000%',
                                                onAddAction: () => console.log("add action Gunner Wright Inc"),
                                                onEditAction: () => console.log("edit action Gunner Wright Inc"),
                                                onDeleteAction: () => console.log("delete action Gunner Wright Inc"),
                                            },
                                            {
                                                title: 'Sprinkles Holdings LLC',
                                                titleIcon: 'fal fa-user',
                                                text: '50.0000%',
                                                onAddAction: () => console.log("add action Sprinkles Holdings LLC"),
                                                onEditAction: () => console.log("edit action Sprinkles Holdings LLC"),
                                                onDeleteAction: () => console.log("delete action Sprinkles Holdings LLC"),
                                            }
                                        ]
                                    },
                                ]
                            }
                        ],

                    }
                ]
            },
            {
                title: 'DUC0N',
                titleIcon: 'fal fa-user',
            },
        ],
    },
];


// TODO This structure is only for demo/test. A real structure must be devised.
const addressForm =
    <Row>
        <Col xs="3" className="align-self-center">
            <p className="text-right mb-m">Address Line 1</p>
        </Col>
        <Col xs="3">
            <Input dataUI={getDataUI()} className="mb-m" type="text" />
        </Col>
        <Col xs="3" className="align-self-center">
            <p className="text-right mb-m">County</p>
        </Col>
        <Col xs="3">
            <Input dataUI={getDataUI()} className="mb-m" type="text" />
        </Col>
        <Col xs="3" className="align-self-center">
            <p className="text-right mb-m">Address Line 2</p>
        </Col>
        <Col xs="3">
            <Input dataUI={getDataUI()} className="mb-m" type="text" />
        </Col>
        <Col xs="3" className="align-self-center">
            <p className="text-right mb-m">County</p>
        </Col>
        <Col xs="3">
            <Input dataUI={getDataUI()} className="mb-m" type="text" />
        </Col>
        <Col xs="3" className="align-self-center">
            <p className="text-right">Address Line 3</p>
        </Col>
        <Col xs="3">
            <Input dataUI={getDataUI()} type="text" />
        </Col>
        <Col xs="3" className="align-self-center">
            <p className="text-right">County</p>
        </Col>
        <Col xs="3">
            <Input dataUI={getDataUI()} type="text" />
        </Col>
    </Row>;

const {
    Bold, Italic, Underline, Strikethrough, Subscript, Superscript,
    ForeColor, BackColor,
    AlignLeft, AlignCenter, AlignRight, AlignJustify,
    Indent, Outdent, OrderedList, UnorderedList,
    Undo, Redo, FontSize, FontName, FormatBlock,
    InsertImage, ViewHtml,
    InsertTable,
    AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter,
    DeleteRow, DeleteColumn, DeleteTable,
    MergeCells, SplitCell, Unlink,
} = EditorTools;


interface ComponentsProps extends React.HtmlHTMLAttributes<HTMLDivElement> { }

type ComponentsState = {
    inputValues: any[],
    textareaValues: any[],
    currentlyActiveTab: number,
    currentlyActiveLargeTab: number,
    loanOptionCards: boolean[],
    showProgress: boolean,
    currentStep: number,
    currentVerticalTab: number,
    radiosChecked: any,
    selectValue1: SelectOptionType['value'],
    selectValue2: SelectOptionType['value'],
    sort: any,
    itemsToShow: number,
    totalSliderItems: number,
    modal5: boolean,
    selectAsync: SelectOptionType['value'],
    selectAsynOptions: SelectOptionType[],
    selectLoading: boolean,
    popoverShow: boolean,
};

class Components extends React.Component<ComponentsProps, ComponentsState> {
    constructor(props: any) {
        super(props);
        this.state = {
            inputValues: [
                'Input', 'Input Error', 'Input with Icon'
            ],
            textareaValues: [
                'Textarea', 'Textarea Error'
            ],
            currentlyActiveTab: 0,
            currentlyActiveLargeTab: 0,
            loanOptionCards: loanOptionCards,
            showProgress: false,
            currentStep: 0,
            currentVerticalTab: 0,
            radiosChecked: {
                'radio-1': [{ value: 0, checked: true }, { value: 1, checked: false }],
                'radio-3': [{ value: 0, checked: true }, { value: 1, checked: false }],
                'radio-4': [{ value: 0, checked: true }, { value: 1, checked: false }],
                'radio-6': [{ value: 0, checked: true }, { value: 1, checked: false }],
            },
            selectValue1: '',
            selectValue2: '1',
            sort: [
                { field: 'ProductID', dir: 'asc' }
            ],
            itemsToShow: 4,
            totalSliderItems: 6,
            modal5: false,
            selectAsync: '',
            selectAsynOptions: [],
            selectLoading: false,
            popoverShow: false,
        };

        this.onChangeHandler = this.onChangeHandler.bind(this);
    }

    onChangeHandler(e: any) {
        if (e.target.name.includes('input')) {
            const { inputValues } = this.state;
            const index = e.target.name.split('-')[1];
            inputValues[index] = e.target.value;
            this.setState({
                inputValues: inputValues
            });
        } else if (e.target.name.includes('textarea')) {
            const { textareaValues } = this.state;
            const index = e.target.name.split('-')[1];
            textareaValues[index] = e.target.value;
            this.setState({
                textareaValues: textareaValues
            });
        }
    }

    onChangeLoanOption(index: number) {
        const { loanOptionCards } = this.state;

        loanOptionCards.map((c, i) => {
            if (i === index) return loanOptionCards[i] = true;
            return loanOptionCards[i] = false;
        });

        this.setState({ loanOptionCards });
    }

    setCurrentlyActiveTab(newTabIndex: number) {
        this.setState({ currentlyActiveTab: newTabIndex });
    }

    setCurrentlyActiveLargeTab(newLargeTabIndex: number) {
        this.setState({ currentlyActiveLargeTab: newLargeTabIndex });
    }

    toggleProgressBar = () => {
        this.setState({
            showProgress: !this.state.showProgress
        });
    }

    addStep = () => {
        this.setState({
            currentStep: this.state.currentStep + 1
        })
    }

    setCurrentlyActiveVerticalTab(newIndex: number) {
        if (this.state.currentVerticalTab !== newIndex) {
            this.setState({ currentVerticalTab: newIndex });
        }
    }

    onRadioChange = (e: any) => {
        const { radiosChecked } = this.state;
        let updatedRadios = { ...radiosChecked };
        const inputName = e.target.name;
        const inputValue = +e.target.value;
        updatedRadios[inputName] = radiosChecked[inputName].map((radio: any, i: any) => {
            if (inputValue === i) {
                radio.checked = true;
            } else {
                radio.checked = false;
            }
            return radio;
        })

        this.setState({
            radiosChecked: updatedRadios
        })
    }


    render() {
        const { radiosChecked, itemsToShow, totalSliderItems } = this.state

        const renderCards = () => {
            return Array.from(Array(totalSliderItems).keys()).map((el, i) => {
                const index = i;
                if (index % 2 === 0) {
                    return (
                        <CardVertical
                            dataUI={getDataUI()}
                            title={`${index + 1} Year Term`}
                            contentItems={[
                                { title: 'Loan Amount', description: ['US $ 960,000.00'] },
                                { title: 'Base Payment', description: ['N/A'] },
                                { title: 'Term', description: ['36 Monthly'] },
                                { title: 'Interest Rate', description: [<strong>3.450%</strong>] }
                            ]}
                            actionButton={<Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-check">Select Loan</Button>}
                        />
                    )
                }
                return (
                    <CardVertical
                        dataUI={getDataUI()}
                        title={`${index + 1} Year Term`}
                        actionButton={<Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-check">Select Loan</Button>}
                        contentItems={[
                            { title: 'Loan Amount', description: ['US $ 960,000.00'] },
                            { title: 'Base Payment', description: ['N/A'] },
                            { title: 'Term', description: ['36 Monthly'] },
                            { title: 'Interest Rate', description: ['5.000 % (Variable)', 'WSJ Prime + 5.800 %)', '5.0000 % (Ceiling)', '3.000 % (Floor)'] }
                        ]}
                    />
                )
            })
        }
        return (
            <MainContainerScrollable>
                <h1 className="mb-xl">Components</h1>
                <h2 className="mb-s">LeftBarCrumbs demo</h2>
                <div style={{ width: '355px' }}>
                    <LeftBarCrumbs
                        dataUI={getDataUI()}
                        className="mb-m lv-side-bar-neggative-margin"
                        links={[<a href="/">Fallow Fruits</a>, <a href="/">36465: Loan Request</a>]} />
                    <LeftBarCrumbs
                        dataUI={getDataUI()}
                        className="mb-m lv-side-bar-neggative-margin"
                        links={[<a href="/">Fallow Fruits Fallow Fruits Fallow Fruits Fallow Fruits</a>, <a href="/">36465: Loan Request</a>]} />
                    <LeftBarCrumbs
                        dataUI={getDataUI()}
                        className="mb-m lv-side-bar-neggative-margin"
                        links={[<a href="/">Fallow Fruits Fallow Fruits Fallow Fruits Fallow Fruits</a>, <a href="/">36465: Loan Request 36465: Loan Request 36465: Loan Request</a>]} />
                    <LeftBarCrumbs
                        dataUI={getDataUI()}
                        className="mb-m lv-side-bar-neggative-margin"
                        links={[<a href="/">Fallow Fruits</a>, <a href="/">36465: Loan Request with a very very veeery long text</a>]} />
                </div>

                <h2 className="mb-s">Butons</h2>
                <div className="mb-m">
                    <h3 className="mb-s">Button Primary</h3>
                    <Button dataUI={getDataUI()} icon="fal fa-check" className="mr-s">Demo button</Button>
                    <Button dataUI={getDataUI()} icon="fal fa-check" disabled className="mr-s">Demo button</Button>
                    <h3 className="mb-s mt-s">Button Secondary</h3>
                    <Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-check" className="mr-s">Demo button</Button>
                    <Button dataUI={getDataUI()} btnType="secondary" icon="fal fa-check" disabled className="mr-s">Demo button</Button>
                    <h3 className="mb-s mt-s">Button Icon</h3>
                    <Tooltip title="File">
                        <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-file-alt" className="mr-s" />
                    </Tooltip>
                    <Tooltip title="File">
                        <Button dataUI={getDataUI()} btnType="icon" icon="fal fa-file-alt" disabled />
                    </Tooltip>
                </div>

                <h2 className="mb-s">Input</h2>
                <div className="mb-m">
                    <Input dataUI={getDataUI()} value={this.state.inputValues[0]} name="input-0" className='mb-s' onChange={this.onChangeHandler} />
                    <Input dataUI={getDataUI()} placeholder={'Input Placeholder'} className='mb-s' />
                    <Input dataUI={getDataUI()} value="Input Disabled" name="input" disabled className='mb-s' onChange={this.onChangeHandler} />
                    <Input dataUI={getDataUI()} value={this.state.inputValues[1]} name="input-1" className='invalid' onChange={this.onChangeHandler} />
                    <ValidationMessage dataUI={getDataUI()} message="This field is required." className="mb-s" />
                    <Input dataUI={getDataUI()} value={this.state.inputValues[2]} name="input-2" className='mb-s' onChange={this.onChangeHandler} icon='fal fa-file-alt' />
                </div>

                <h2 className="mb-s">Mask Input</h2>
                <div className="mb-m">
                    <MaskInput
                        dataUI={getDataUI()}
                        mask="(999) 000-9999"
                        name="phone-mask"
                        onChange={(e) => console.log(e.target.name, e.target.value)}
                        className="mb-s"
                    />
                    <MaskInput
                        dataUI={getDataUI()}
                        className="mb-s"
                        mask="00/00"
                        name="date-mask"
                        onChange={(e) => console.log(e.target.name, e.target.value)}
                    />
                    <MaskInput
                        dataUI={getDataUI()}
                        maskChar='-'
                        alwaysShowMask={true}
                        mask="999999999"
                        name="tin-mask"
                        onChange={(e) => console.log(e.target.name, e.target.value)}
                        className="mb-s"
                    />
                </div>

                <h2 className="mb-s">Textarea</h2>
                <div className="mb-m">
                    <Textarea dataUI={getDataUI()} value={this.state.textareaValues[0]} name="textarea-0" className='mb-s' onChange={this.onChangeHandler} />
                    <Textarea dataUI={getDataUI()} placeholder={'Textarea Placeholder'} className='mb-s' />
                    <Textarea dataUI={getDataUI()} value="Textarea Disabled" disabled className='mb-s' onChange={this.onChangeHandler} />
                    <Textarea dataUI={getDataUI()} value={this.state.textareaValues[1]} name="textarea-1" className='mb-s invalid' onChange={this.onChangeHandler} />
                </div>

                <h2 className="mb-s">Checkbox</h2>
                <div className="mb-s">
                    <h4>Default</h4>
                    <Checkbox dataUI={getDataUI()} checked name="check-2" onChange={() => { }}>Checkbox</Checkbox>
                    <Checkbox dataUI={getDataUI()} disabled checked name="check-3" onChange={() => { }}>Checkbox Disabled</Checkbox>
                    <Checkbox dataUI={getDataUI()} name="check-4">Checkbox</Checkbox>
                    <Checkbox dataUI={getDataUI()} disabled name="check-5">Checkbox Disabled</Checkbox>
                    <h4 className="mb-s">Default no text</h4>
                    <Checkbox dataUI={getDataUI()} name="check-1" checked onChange={() => { }} />
                    <Checkbox dataUI={getDataUI()} name="check-1" checked disabled onChange={() => { }} />
                    <Checkbox dataUI={getDataUI()} name="check-1" />
                    <Checkbox dataUI={getDataUI()} name="check-1" disabled />
                </div>
                <div className="mb-s">
                    <h4>Block with text</h4>
                    <Checkbox dataUI={getDataUI()} checked name="check-11" onChange={() => { }} className="d-block">Checkbox</Checkbox>
                    <Checkbox dataUI={getDataUI()} disabled checked name="check-12" className="d-block">Checkbox Disabled</Checkbox>
                    <Checkbox dataUI={getDataUI()} name="check-13" className="d-block">Checkbox</Checkbox>
                    <Checkbox dataUI={getDataUI()} disabled name="check-14" className="d-block">Checkbox Disabled</Checkbox>
                </div>
                <div className="mb-m">
                    <h4>Block no text</h4>
                    <Checkbox dataUI={getDataUI()} name="check-6" checked onChange={() => { }} className="d-block" />
                    <Checkbox dataUI={getDataUI()} disabled name="check-7" checked onChange={() => { }} className="d-block" />
                    <Checkbox dataUI={getDataUI()} name="check-8" className="d-block" />
                    <Checkbox dataUI={getDataUI()} name="check-9" disabled className="d-block" />
                </div>
                <h2 className="mb-s">Radio</h2>
                <div className="mb-s">
                    <h4>Default</h4>
                    <Radio dataUI={getDataUI()} name="radio-1" checked={radiosChecked['radio-1'][0].checked} onChange={(e) => { this.onRadioChange(e) }} value={radiosChecked['radio-1'][0].value}>Radio 1</Radio>
                    <Radio dataUI={getDataUI()} name="radio-1" checked={radiosChecked['radio-1'][1].checked} onChange={(e) => { this.onRadioChange(e) }} value={radiosChecked['radio-1'][1].value}>Radio 2</Radio>
                    <h4>Default disabled</h4>
                    <Radio dataUI={getDataUI()} name="radio-2" onChange={() => { }} disabled checked>Radio 1 Disabled</Radio>
                    <Radio dataUI={getDataUI()} name="radio-2" onChange={() => { }} disabled>Radio 2 Disabled</Radio>
                    <h4>Default no text</h4>
                    <Radio dataUI={getDataUI()} name="radio-4" checked={radiosChecked['radio-4'][0].checked} onChange={(e) => { this.onRadioChange(e) }} value={radiosChecked['radio-4'][0].value} />
                    <Radio dataUI={getDataUI()} name="radio-4" checked={radiosChecked['radio-4'][1].checked} onChange={(e) => { this.onRadioChange(e) }} value={radiosChecked['radio-4'][1].value} />
                </div>
                <div className="mb-s">
                    <h4>Block with text</h4>
                    <Radio dataUI={getDataUI()} className="d-block" name='radio-3' checked={radiosChecked['radio-3'][0].checked} onChange={(e) => { this.onRadioChange(e) }} value={radiosChecked['radio-3'][0].value}>Radio 1</Radio>
                    <Radio dataUI={getDataUI()} className="d-block" name='radio-3' checked={radiosChecked['radio-3'][0].checked} onChange={(e) => { this.onRadioChange(e) }} value={radiosChecked['radio-3'][0].value}>Radio 2</Radio>
                    <h4>Block no text</h4>
                    <Radio dataUI={getDataUI()} className="d-block" name='radio-6' checked={radiosChecked['radio-6'][0].checked} onChange={(e) => { this.onRadioChange(e) }} value={radiosChecked['radio-6'][0].value} />
                    <Radio dataUI={getDataUI()} className="d-block" name='radio-6' checked={radiosChecked['radio-6'][0].checked} onChange={(e) => { this.onRadioChange(e) }} value={radiosChecked['radio-6'][0].value} />
                    <h4>Block with text disabled</h4>
                    <Radio dataUI={getDataUI()} className="d-block" name="radio-5" onChange={() => { }} disabled>Radio 1</Radio>
                    <Radio dataUI={getDataUI()} className="d-block" name="radio-5" onChange={() => { }} disabled checked>Radio 2</Radio>
                </div>

                <h2 className="mb-s">Select</h2>
                <div className="mb-m">
                    <Select
                        dataUI={getDataUI()}
                        className="mb-s"
                        options={selectOptions}
                        value={this.state.selectValue1}
                        placeholder="Select..."
                        onChange={(obj: any) => {
                            console.log(obj);
                            this.setState({ selectValue1: obj.value });
                        }}
                    />

                    <Select
                        dataUI={getDataUI()}
                        className="mb-s"
                        options={selectOptions}
                        value={this.state.selectValue2}
                        text="Option 1"
                        onChange={(obj: any) => {
                            this.setState({ selectValue2: obj.value });
                        }}
                    />

                    <Select
                        dataUI={getDataUI()}
                        options={selectOptions}
                        value="3"
                        disabled
                    />
                </div>


                <Popover
                    dataUI={getDataUI()}
                    title="Popover with position: top"
                    show={this.state.popoverShow}
                    position="top"
                    render={() => (
                        <>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                            </p>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                            </p>
                        </>
                    )}
                    onClose={() => this.setState({ popoverShow: false })}
                    showOnHover

                >
                    <Button
                        dataUI={getDataUI()}
                        btnType="secondary"
                        icon="fal fa-info"
                        className="mb-l"
                        // onClick={() => this.setState({ popoverShow: true })}
                        onMouseEnter={() => this.setState({ popoverShow: true })}
                        onMouseLeave={() => this.setState({ popoverShow: false })}
                        onWheel={() => {
                            if (!this.state.popoverShow) return;
                            this.setState({ popoverShow: false })
                        }}
                    >
                        Position hover bottom
                    </Button>
                </Popover>


                <h2 className="mb-s">Datepicker and Datetimepicker</h2>
                <div className="mb-m">
                    <Datepicker
                        dataUI={getDataUI()}
                        name="datepicker"
                        placeholder="MM/DD/YY"
                        format="MM/DD/YYYY"
                        onInvalidDate={(isValid: any, isDateInRange: any) => {
                            console.log(`Is Date valid:${isValid}\nIs Date in range: ${isDateInRange}`)
                        }}
                        value={DatepickerUtils.dateFromString('/Date(603064800000+0200)')}
                        onChange={(e) => {
                            console.log(e.target.value)
                        }}
                        className="mb-m"
                        id="dpTest"
                    />
                    <Datepicker
                        dataUI={getDataUI()}
                        placeholder="MM/DD/YYYY hh:mm"
                        format="MM/DD/YYYY hh:mm"
                        name="datetime-picker"
                        dateTime={true}
                        onInvalidDate={(isValid: any, isDateInRange: any) => {
                            console.log(`Is Date valid:${isValid}\nIs Date in range: ${isDateInRange}`)
                        }}
                        value={DatepickerUtils.dateFromString('080920', 'MM/DD/YYYY')}
                        onChange={(e: any) => {
                            console.log(e.target.value)
                        }}
                        className="mb-m"
                        id="dtpTest"
                    />

                    <Button dataUI={getDataUI()} onClick={() => this.setState({ modal5: true })}>Datepicker in modal</Button>
                    <Modal dataUI={getDataUI()} title="Modal" isVisible={this.state.modal5} size="S" onClose={() => { this.setState({ modal5: false }); }}>
                        <ModalScrollableContainer>
                            <div className='mb-m' style={{ height: '200px', background: 'var(--color-select)' }} />

                            <Datepicker
                                name="datepicker"
                                placeholder="MM/DD/YY"
                                format="MM/DD/YYYY"
                                onInvalidDate={(isValid: any, isDateInRange: any) => {
                                    console.log(`Is Date valid:${isValid}\nIs Date in range: ${isDateInRange}`)
                                }}
                                value={DatepickerUtils.dateFromString('/Date(603064800000+0200)')}
                                onChange={(e) => {
                                    console.log(e.target.value, e.target.name)
                                }}
                                className="mb-m"
                                id='dpSimple'
                                dataUI={getDataUI()}
                            />

                            <Datepicker
                                placeholder="MM/DD/YYYY hh:mm"
                                format="MM/DD/YYYY hh:mm"
                                name="datetime-picker"
                                dateTime={true}
                                onInvalidDate={(isValid: any, isDateInRange: any) => {
                                    console.log(`Is Date valid:${isValid}\nIs Date in range: ${isDateInRange}`)
                                }}
                                value={DatepickerUtils.dateFromString('080920', 'MM/DD/YYYY')}
                                onChange={(e: any) => {
                                    console.log(e.target.value, e.target.name)
                                }}
                                className="mb-m"
                                id='dateTimeDP'
                                dataUI={getDataUI()}
                            />
                        </ModalScrollableContainer>
                    </Modal>
                </div>

                <h2 className="mb-s">Icons</h2>
                <h3 className="mb-s">Icons - M</h3>
                <Icon dataUI={getDataUI()} icon="fal fa-angle-down" size="M" style={{ color: '#5088F5' }} />
                <Icon dataUI={getDataUI()} icon="fal fa-angle-up" size="M" style={{ color: '#5088F5' }} />
                <Icon dataUI={getDataUI()} icon="fal fa-angle-right" size="M" />
                <Icon dataUI={getDataUI()} icon="fal fa-bars" size="M" style={{ color: '#5088F5' }} />
                <Icon dataUI={getDataUI()} icon="fal fa-home" size="M" />
                <Icon dataUI={getDataUI()} icon="far fa-ellipsis-h" size="M" />
                <Icon dataUI={getDataUI()} icon="fal fa-lock" size="M" />
                <Icon dataUI={getDataUI()} icon="fal fa-stamp" size="M" />
                <Icon dataUI={getDataUI()} icon="fas fa-exclamation-circle" size="M" style={{ color: '#E33E3C' }} />
                <h3>Icons - S</h3>
                <Icon dataUI={getDataUI()} icon="fal fa-angle-down" size="S" style={{ color: '#5088F5' }} />
                <Icon dataUI={getDataUI()} icon="fal fa-angle-up" size="S" style={{ color: '#5088F5' }} />
                <Icon dataUI={getDataUI()} icon="fal fa-angle-right" size="S" />
                <Icon dataUI={getDataUI()} icon="fal fa-bars" size="S" style={{ color: '#5088F5' }} />
                <Icon dataUI={getDataUI()} icon="fal fa-home" size="S" />
                <Icon dataUI={getDataUI()} icon="far fa-ellipsis-h" size="S" />
                <Icon dataUI={getDataUI()} icon="fal fa-lock" size="S" />
                <Icon dataUI={getDataUI()} icon="fal fa-stamp" size="S" />
                <Icon dataUI={getDataUI()} icon="fas fa-exclamation-circle" size="S" style={{ color: '#E33E3C' }} />
                <Icon dataUI={getDataUI()} icon="fal fa-save" size="S" />
                <Icon dataUI={getDataUI()} icon="fal fa-pen" size="S" />
                <Icon dataUI={getDataUI()} icon="fal fa-trash" size="S" />
                <Icon dataUI={getDataUI()} icon="fal fa-copy" size="S" />
                <Icon dataUI={getDataUI()} icon="far fa-times-circle" size="S" />
                <Icon dataUI={getDataUI()} icon="fal fa-print" size="S" />
                <Icon dataUI={getDataUI()} icon="fal fa-plus" size="S" />
                <Icon dataUI={getDataUI()} icon="far fa-search" size="S" />
                <Icon dataUI={getDataUI()} icon="fal fa-user" size="S" />
                <Icon dataUI={getDataUI()} icon="fal fa-users fa-sm" size="S" />
                <Icon dataUI={getDataUI()} icon="fal fa-map-marker-alt" size="S" />
                <Icon dataUI={getDataUI()} icon="fal fa-dollar-sign" size="S" />
                <Icon dataUI={getDataUI()} icon="fal fa-boxes fa-sm" size="S" />
                <Icon dataUI={getDataUI()} icon="fal fa-list" size="S" />
                <Icon dataUI={getDataUI()} icon="fal fa-folder" size="S" />

                <h3>Custom Icons</h3>
                <Icon dataUI={getDataUI()} icon="custom-icon-application-call-s" size='M' />
                <Icon dataUI={getDataUI()} icon="custom-icon-calculator-locked-s" size='M' />
                <Icon dataUI={getDataUI()} icon="custom-icon-calculator-unlocked-s" size='M' />
                <Icon dataUI={getDataUI()} icon="custom-icon-application-call-s" size='S' />
                <Icon dataUI={getDataUI()} icon="custom-icon-calculator-locked-s" size='S' />
                <Icon dataUI={getDataUI()} icon="custom-icon-calculator-unlocked-s" size='S' />
                <Row>
                    <Col><div style={{ background: 'var(--color-select)', width: '100%', height: '50px' }}><p>Col</p></div></Col>
                    <Col xs="6"><div style={{ background: 'var(--color-select)', width: '100%', height: '50px' }}><p>Col xs="6"</p></div></Col>
                    <Col><div style={{ background: 'var(--color-select)', width: '100%', height: '50px' }}><p>Col</p></div></Col>
                </Row>

                <h3 className="mt-l">Link With Icon</h3>
                <LinkWithIcon dataUI={getDataUI()} className="mr-xl" icon="fal fa-angle-left" iconPlace="left"><a href="/">Link with icon</a></LinkWithIcon>
                <LinkWithIcon dataUI={getDataUI()} icon="fal fa-angle-right" iconPlace="right"><a href="/">Link with icon</a></LinkWithIcon>
                <div className="mt-m"><LinkWithIcon dataUI={getDataUI()} disabled icon="fal fa-angle-right" iconPlace="right"><a href="/">Link with icon</a></LinkWithIcon></div>

                <h3 className="mt-l">Tooltips</h3>
                <div>
                    <Tooltip title="Info text here" placement="right">
                        <p className="d-inline-block mr-l mb-s">Tooltip right</p>
                    </Tooltip>
                    <Tooltip title="Lorem Ipsum is simply dummy text of the printing and typesetting industry.">
                        <p className="d-inline-block mr-l mb-s">Tooltip top</p>
                    </Tooltip>
                    <Tooltip title="Tooltip" placement="bottom">
                        <p className="d-inline-block mr-l mb-s">Tooltip bottom</p>
                    </Tooltip>
                    <Tooltip title="Info text here" placement="left">
                        <p className="d-inline-block mr-l mb-s">Tooltip left</p>
                    </Tooltip>
                </div>

                <Tabs dataUI={getDataUI()} className="mt-xl mb-xl" currentlyActiveTabIndex={this.state.currentlyActiveTab} onTabIndexChange={i => this.setCurrentlyActiveTab(i)}>
                    <TabList dataUI={getDataUI()}>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Request</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Borrowers</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Terms</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>ARM Information</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Schedules</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Participations</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}><Link to="?compliance">Compliance</Link></TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Very long tab item title</TabItemTitle>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Second very long tab item title</TabItemTitle>
                        </TabItem>
                    </TabList>
                    <TabContent dataUI={getDataUI()}>
                        <TabContentItem dataUI={getDataUI()}>Request tab content</TabContentItem>
                        <TabContentItem dataUI={getDataUI()}>Borrowers tab content</TabContentItem>
                        <TabContentItem dataUI={getDataUI()}>Terms tab content</TabContentItem>
                        <TabContentItem dataUI={getDataUI()}>ARM Information tab content</TabContentItem>
                        <TabContentItem dataUI={getDataUI()}>Schedules tab content</TabContentItem>
                        <TabContentItem dataUI={getDataUI()}>Participations tab content</TabContentItem>
                        <TabContentItem dataUI={getDataUI()}>Compliance tab content</TabContentItem>
                        <TabContentItem dataUI={getDataUI()}>Very long tab item title content</TabContentItem>
                        <TabContentItem dataUI={getDataUI()}>Second very long tab item title content</TabContentItem>
                    </TabContent>
                </Tabs>

                <Tabs dataUI={getDataUI()} className="mb-xl" largeTabs currentlyActiveTabIndex={this.state.currentlyActiveLargeTab} onTabIndexChange={i => this.setCurrentlyActiveLargeTab(i)}>
                    <TabList dataUI={getDataUI()}>
                        <TabItem dataUI={getDataUI()} isSummaryTabItem>
                            <TabItemTitle dataUI={getDataUI()}>Exposure</TabItemTitle>
                            <TabItemDetails dataUI={getDataUI()}>
                                <TabItemRow dataUI={getDataUI()} leftCol="Current Exposure:" rightCol="$5,620,105,142"></TabItemRow>
                                <TabItemRow dataUI={getDataUI()} leftCol="Proposed Changes:" rightCol="$0"></TabItemRow>
                                <TabItemRow dataUI={getDataUI()} leftCol="Plus Pending Approvals:" rightCol="$0"></TabItemRow>
                                <TabItemRow dataUI={getDataUI()} leftCol="Total Proposed Exposure:" rightCol="$5,620,105,142"></TabItemRow>
                            </TabItemDetails>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()} icon={
                                <Tooltip title="Some text" placement="top">
                                    <Button dataUI={getDataUI()} btnType="icon" icon='fal fa-file-alt' onClick={(e: any) => { e.stopPropagation(); console.log('Click on icon'); }} />
                                </Tooltip>
                            }>Loans</TabItemTitle>
                            <TabItemDetails dataUI={getDataUI()}>
                                <TabItemRow dataUI={getDataUI()} leftCol="Used:" rightCol="$1,575,892,358"></TabItemRow>
                                <TabItemRow dataUI={getDataUI()} leftCol="Available:" rightCol="$4,044,188,248"></TabItemRow>
                                <TabItemRow dataUI={getDataUI()} leftCol="Exposure:" rightCol="$5,620,080,605"></TabItemRow>
                            </TabItemDetails>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Deposits</TabItemTitle>
                            <TabItemDetails dataUI={getDataUI()}>
                                <TabItemRow dataUI={getDataUI()} leftCol="Curr:" rightCol="$903,035,336"></TabItemRow>
                                <TabItemRow dataUI={getDataUI()} leftCol="Avg:" rightCol="$903,040,599"></TabItemRow>
                                <TabItemRow dataUI={getDataUI()} leftCol="Exp:" rightCol="$24,537"></TabItemRow>
                            </TabItemDetails>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Other products</TabItemTitle>
                            <TabItemDetails dataUI={getDataUI()}>
                                <TabItemRow dataUI={getDataUI()} leftCol="Curr Bal:" rightCol="$0"></TabItemRow>
                                <TabItemRow dataUI={getDataUI()} leftCol="Exposure:" rightCol="$0"></TabItemRow>
                            </TabItemDetails>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Details</TabItemTitle>
                            <TabItemDetails dataUI={getDataUI()}>
                                <p>Custom content paragraph</p>
                            </TabItemDetails>
                        </TabItem>
                        <TabItem dataUI={getDataUI()}>
                            <TabItemTitle dataUI={getDataUI()}>Parties</TabItemTitle>
                        </TabItem>
                    </TabList>
                    <TabContent dataUI={getDataUI()}>
                        <TabContentItem dataUI={getDataUI()}>Loans tab content</TabContentItem>
                        <TabContentItem dataUI={getDataUI()}>Deposits tab content</TabContentItem>
                        <TabContentItem dataUI={getDataUI()}>Checklist content</TabContentItem>
                        <TabContentItem dataUI={getDataUI()}>Other products tab content</TabContentItem>
                        <TabContentItem dataUI={getDataUI()}>Parties tab content</TabContentItem>
                    </TabContent>
                </Tabs>

                <div style={{ margin: '50px 0' }}>
                    <VerticalTabs dataUI={getDataUI()} currentlyActiveTabIndex={this.state.currentVerticalTab} onTabIndexChange={index => this.setCurrentlyActiveVerticalTab(index)}>
                        <VerticalTabList dataUI={getDataUI()}>
                            <VerticalTabItem dataUI={getDataUI()}>General</VerticalTabItem>
                            <VerticalTabItem dataUI={getDataUI()}>First Southeast Insurance Services, Inc</VerticalTabItem>
                            <VerticalTabItem dataUI={getDataUI()}>Party</VerticalTabItem>
                            <VerticalTabItem dataUI={getDataUI()}>Contrary to popular belief, Lorem Ipsum is not simply random text.</VerticalTabItem>
                            <VerticalTabItem dataUI={getDataUI()}><Link to="?guidance">Guidance Line Data</Link></VerticalTabItem>
                        </VerticalTabList>
                        <VerticalTabContent dataUI={getDataUI()}>
                            <VerticalTabContentItem dataUI={getDataUI()}>General content</VerticalTabContentItem>
                            <VerticalTabContentItem dataUI={getDataUI()}>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                <p className="mt-m"><strong>Scrolling content demo:</strong></p>
                                <div style={{ margin: '0 0 20px 0', height: '50px', overflowY: 'scroll' }}>
                                    <div style={{ background: 'var(--color-primary)', color: 'var(--color-1)', padding: '5px' }}>
                                        There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form,
                                        by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything
                                        embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.
                                        It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from
                                        repetition, injected humour, or non-characteristic words etc.
                                    </div>
                                </div>
                            </VerticalTabContentItem>
                            <VerticalTabContentItem dataUI={getDataUI()}>Party content</VerticalTabContentItem>
                            <VerticalTabContentItem dataUI={getDataUI()}>Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.</VerticalTabContentItem>
                            <VerticalTabContentItem dataUI={getDataUI()}>Guidance Line Data</VerticalTabContentItem>
                        </VerticalTabContent>
                    </VerticalTabs>
                </div>

                <h3 className="mb-s">Cards</h3>
                <Card dataUI={getDataUI()} className="mb-s" actionButton={<Button dataUI={getDataUI()} icon="fal fa-trash" btnType="secondary">Delete</Button>}>
                    <CardSection dataUI={getDataUI()} title="Section 1 Title">{addressForm}</CardSection>
                </Card>
                <Card dataUI={getDataUI()} className="mb-xl" actionButton={<Button dataUI={getDataUI()} icon="fal fa-trash" btnType="secondary">Delete</Button>}>
                    <CardSection dataUI={getDataUI()} title="Section 1 Title">{addressForm}</CardSection>
                    <CardSection dataUI={getDataUI()} title="Section 2 Title">{addressForm}</CardSection>
                </Card>

                <h3 className="mb-s">LoanOptionCard</h3>
                <LoanOptionCardContainer
                    dataUI={getDataUI()}
                    cols={[
                        { title: 'Description', percentage: 13 },
                        { title: 'Loan Amount', percentage: 9 },
                        { title: 'Base Payment', percentage: 9 },
                        { title: 'Total Payment', percentage: 9 },
                        { title: 'Term', percentage: 12 },
                        { title: 'Payment Type', percentage: 13 },
                        { title: 'Interest Rate', percentage: 13 },
                        { title: 'APR', percentage: 6 },
                        { title: 'Fees', percentage: 16 },
                    ]}
                >
                    <LoanOptionCard
                        dataUI={getDataUI()}
                        onClick={() => this.onChangeLoanOption(0)}
                        expanded={this.state.loanOptionCards[0]}
                        checked={this.state.loanOptionCards[0]}
                        cols={[
                            '3 years',
                            'US$960,000.00',
                            'US$16,644.00',
                            '500',
                            '12 months',
                            'Interest Only',
                            '0% (Fixed)',
                            '0.00%',
                            '0',
                        ]}
                    >
                        <div>Content...</div>
                    </LoanOptionCard>
                    <LoanOptionCard
                        dataUI={getDataUI()}
                        onClick={() => this.onChangeLoanOption(1)}
                        checked={this.state.loanOptionCards[1]}
                        cols={[
                            '3 years',
                            'US$960,000.00',
                            'US$16,644.00',
                            '500',
                            '36 months',
                            'P & I Due at Maturity',
                            '0% (Fixed)',
                            '0.00%',
                            '0',
                        ]}
                    >
                        <div>Content...</div>
                    </LoanOptionCard>
                    <LoanOptionCard
                        dataUI={getDataUI()}
                        onClick={() => this.onChangeLoanOption(2)}
                        expanded={this.state.loanOptionCards[2]}
                        checked={this.state.loanOptionCards[2]}
                        cols={[
                            '4 Year Line of Credit',
                            '$10,000.00',
                            '$1,166.96',
                            '$1,166.96',
                            '12 months',
                            '4.750% Variable (WSJ Prime + 0.500%) 6.000% (Ceiling) 4.000$ (Floor)',
                            '0% (Fixed)',
                            '4.700%',
                            '$25.00 Application Fee $150.00 New Feed',
                        ]}
                    >
                        <div style={{ height: '500px', backgroundColor: 'var(--color-select)' }}>Content...</div>
                    </LoanOptionCard>
                    <LoanOptionCard
                        dataUI={getDataUI()}
                        onClick={() => this.onChangeLoanOption(3)}
                        expanded={this.state.loanOptionCards[3]}
                        checked={this.state.loanOptionCards[3]}
                        disabled
                        cols={[
                            '3 years',
                            'US$960,000.00',
                            'US$16,644.00',
                            '-',
                            '36 months',
                            'P & I Due at Maturity',
                            '0% (Fixed)',
                            '0.00%',
                            '0',
                        ]}
                    >
                        <div>Content...</div>
                    </LoanOptionCard>
                </LoanOptionCardContainer>

                <h3 className="mb-s">ProgressBar</h3>
                <Button dataUI={getDataUI()}
                    className="mb-xl"
                    btnType="secondary"
                    icon="fal fa-dollar-sign"
                    style={{ zIndex: 101, position: 'relative' }}
                    onClick={() => { this.toggleProgressBar() }}
                >
                    Toggle Progress Bar
                </Button>
                <Button dataUI={getDataUI()}
                    className="mb-xl ml-m"
                    btnType="secondary"
                    icon="fal fa-dollar-sign"
                    style={{ zIndex: 101, position: 'relative' }}
                    onClick={() => { this.addStep() }}
                >
                    Add Step
                </Button>
                <Button dataUI={getDataUI()}
                    className="mb-xl ml-m"
                    btnType="secondary"
                    icon="fal fa-dollar-sign"
                    style={{ zIndex: 101, position: 'relative' }}
                    onClick={() => { this.setState({ currentStep: 0 }) }}
                >
                    Reset
                </Button>
                <ProgressBar dataUI={getDataUI()} isVisible={this.state.showProgress} steps={4} currentStep={this.state.currentStep} />
                <h3>Widget Container</h3>
                <Row>
                    <Col xs="12" className="mb-s">
                        <WidgetContainer dataUI={getDataUI()} title={<a href='/'>Combined Ratio</a>}>
                            test
                        </WidgetContainer>
                    </Col>
                    <Col className="mb-s">
                        <WidgetContainer dataUI={getDataUI()} title="Credit Score Range">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        </WidgetContainer>
                    </Col>
                    <Col className="mb-s">
                        <WidgetContainer dataUI={getDataUI()} title="Trade Line Summary">
                            test
                        </WidgetContainer>
                    </Col>
                    <Col className="mb-s">
                        <WidgetContainer dataUI={getDataUI()} title="Assets vs. Liabilities - Donna Testcase ">
                            test
                        </WidgetContainer>
                    </Col>
                </Row>

                <h3>Summary Box</h3>
                <SummaryBox dataUI={getDataUI()} className="mb-s mr-s" title="Product:" content={<a href='/'>*BB BL CONSSUMER PRODUCT</a>} />
                <SummaryBox dataUI={getDataUI()} className="mb-s mr-s" title="Loan amount:" content="$960,000" />
                <SummaryBox dataUI={getDataUI()} className="mb-s mr-s" title="Payment:" content="0" />
                <SummaryBox dataUI={getDataUI()} className="mb-s mr-s" title="Interest Rate:" content="4.25%" />
                <SummaryBox dataUI={getDataUI()} className="mb-s mr-s" title="Term:" content="36 months" />

                <h3>Simple Table</h3>
                <SimpleTableContainer dataUI={getDataUI()} className="mb-xl">
                    <table>
                        <thead>
                            <tr>
                                <th>Heading</th>
                                <th>Heading</th>
                                <th>Heading</th>
                            </tr>

                        </thead>
                        <tbody>
                            <tr>
                                <td><Checkbox dataUI={getDataUI()} /></td>
                                <td><Radio dataUI={getDataUI()} /></td>
                                <td>
                                    <Tooltip title="File">
                                        <Button dataUI={getDataUI()} icon="fal fa-file-alt" btnType="icon" />
                                    </Tooltip>
                                </td>
                            </tr>
                            <tr>
                                <td>Row Cell</td>
                                <td>Row Cell</td>
                                <td>Row Cell</td>
                            </tr>
                            <tr>
                                <td>Row Cell</td>
                                <td>Row Cell</td>
                                <td><Input dataUI={getDataUI()} /></td>
                            </tr>
                            <tr>
                                <td className="text-bold">Row Cell</td>
                                <td className="text-right text-bold">Row Cell right</td>
                                <td className="text-right text-bold">Row Cell right</td>
                            </tr>
                        </tbody>
                    </table>
                </SimpleTableContainer>



                <h3 className="mb-s">Loader</h3>
                <div className="mb-m">
                    <div className="lv-loader">
                        <div></div><div></div><div></div>
                    </div>
                </div>
                <div className="mb-xl">
                    <div className="lv-loader-container">
                        <div className="lv-loader-big">
                            <div /><div /><div /><div /><div /><div /><div /><div />
                        </div>
                    </div>
                </div>

                <h3 className="mb-s">InfoBox</h3>
                <InfoBox dataUI={getDataUI()} className="mb-l">Review or edit party profile information and save any chages.</InfoBox>
                <InfoBox dataUI={getDataUI()} title="Title" className="mb-l">Review or edit party profile information and save any chages.</InfoBox>
                <InfoBox dataUI={getDataUI()} title="Title" className="mb-l">
                    <ul>
                        <li>1. Item</li>
                        <li>2. Item</li>
                        <li>3. Item</li>
                        <li>4. Item</li>
                    </ul>
                </InfoBox>
                <InfoBox dataUI={getDataUI()} title="Title" className="mb-xl">
                    <p className="lv-small mb-s">Review or edit party profile information and save any chages.</p>
                    <ul className="mb-s">
                        <li>1. Item</li>
                        <li>2. Item</li>
                        <li>3. Item</li>
                        <li>4. Item</li>
                    </ul>
                    <p className="lv-small">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                </InfoBox>

                <NumericInput dataUI={getDataUI()} className="mb-m" type="currency" onChange={(value) => console.log(value, ' ~~~currency value')} />
                <NumericInput dataUI={getDataUI()} className="mb-m" type="percent" onChange={(value) => console.log(value, ' ~~~percent value')} />
                <NumericInput dataUI={getDataUI()} className="mb-xl" type="integer" onChange={(value) => console.log(value, ' ~~~integer value')} />

                <h2 className='mb-s'>Carousel</h2>
                <h3>Demo settings</h3>
                <div className="mb-l">
                    <Label dataUI={getDataUI()}>Cards to show</Label>
                    <div className="d-flex">
                        <Tooltip title="Decrease Cards to show">
                            <Button dataUI={getDataUI()}
                                btnType="icon"
                                icon='fal fa-minus'
                                onClick={() => {
                                    const newValue = itemsToShow - 1;
                                    if (newValue >= 1) {
                                        this.setState({
                                            itemsToShow: newValue
                                        })
                                    }
                                }}
                            />
                        </Tooltip>
                        <NumericInput dataUI={getDataUI()}
                            type="integer"
                            name='items-to-show'
                            className="width-s mr-s ml-s"
                            readOnly
                            value={itemsToShow}
                        />
                        <Tooltip title="Increase Cards to show">
                            <Button dataUI={getDataUI()}
                                btnType="icon"
                                icon='fal fa-plus'
                                onClick={() => {
                                    const newValue = itemsToShow + 1;
                                    this.setState({
                                        itemsToShow: newValue
                                    })
                                }}
                            />
                        </Tooltip>
                    </div>

                    <Label dataUI={getDataUI()}>Total Items</Label>
                    <div className="d-flex">
                        <Tooltip title="Remove Card">
                            <Button dataUI={getDataUI()}
                                btnType="icon"
                                icon='fal fa-minus'
                                onClick={() => {
                                    const newValue = totalSliderItems - 1;
                                    if (newValue >= 1) {
                                        this.setState({
                                            totalSliderItems: newValue
                                        })
                                    }
                                }}
                            />
                        </Tooltip>
                        <NumericInput dataUI={getDataUI()} readOnly className="width-s mr-s ml-s" value={totalSliderItems} type="integer" name='total-items' />
                        <Tooltip title="Add Card">
                            <Button dataUI={getDataUI()}
                                btnType="icon"
                                icon='fal fa-plus'
                                onClick={() => {
                                    const newValue = totalSliderItems + 1;
                                    this.setState({
                                        totalSliderItems: newValue
                                    })
                                }}
                            />
                        </Tooltip>
                    </div>

                </div>
                <Carousel
                    dataUI={getDataUI()}
                    itemsToShow={itemsToShow}
                    items={renderCards()}
                />

                <GridContainer dataUI={getDataUI()}>
                    <Grid
                        data={orderBy(productsGrid, this.state.sort)}
                        sortable
                        sort={this.state.sort}
                        onSortChange={(e) => {
                            console.log(e, "SORT")
                            this.setState({
                                sort: e.sort
                            });
                        }}
                    >
                        <Column field="ProductID" title="ID" />
                        <Column field="ProductName" title="Name" width="250px" />
                        <Column field="Category.CategoryName" title="CategoryName" />
                        <Column field="UnitPrice" title="Price" />
                        <Column field="UnitsInStock" title="In stock" />
                        <Column
                            field="Discontinued"
                            cell={(props: any) => {
                                return (
                                    <td>
                                        <Checkbox dataUI={getDataUI()} disabled checked={props.dataItem[props.field as any]} />
                                    </td>
                                )
                            }}
                        />
                    </Grid>
                </GridContainer>

                <Diagram dataUI={getDataUI()} data={diagramData} className="mb-l" />

                <EditorContainer dataUI={getDataUI()}>
                    <Editor
                        className="mb-m"
                        tools={[
                            [Bold, Italic, Underline, Strikethrough,
                                Subscript, Superscript,
                                ForeColor, BackColor,
                                AlignLeft, AlignCenter, AlignRight, AlignJustify,
                                Indent, Outdent,
                                OrderedList, UnorderedList,
                                FontSize, FontName, FormatBlock,
                                Undo, Redo,
                                InsertImage, ViewHtml,
                                InsertTable, AddRowBefore, AddRowAfter,
                                AddColumnBefore, AddColumnAfter,
                                DeleteRow, DeleteColumn, DeleteTable,
                                MergeCells, SplitCell,
                                EditorTools.Link, Unlink,
                                () => <Tooltip title="DSO"><Button dataUI={getDataUI()} btnType="narrow">DSO</Button></Tooltip>,
                                () => <Tooltip title="UCF"><Button dataUI={getDataUI()} btnType="narrow">UCF</Button></Tooltip>,
                                () => <Tooltip title="TCF"><Button dataUI={getDataUI()} btnType="narrow">TCF</Button></Tooltip>
                            ]
                        ]}
                        contentStyle={{ height: 300 }}

                    />
                </EditorContainer>

                <Toolbar dataUI={getDataUI()} className="mb-m"
                    leftSide={
                        <>
                            <Input dataUI={getDataUI()} className="mr-s mb-m" />
                            <Input dataUI={getDataUI()} className="mr-s mb-m" icon="fal fa-search" />
                            <NumericInput dataUI={getDataUI()} className="mr-s mb-m" type="currency" />
                            <NumericInput dataUI={getDataUI()} className="mr-s mb-m" type="integer" />
                            <NumericInput dataUI={getDataUI()} className="mr-s mb-m" type="percent" />
                            <Select
                                dataUI={getDataUI()} className="mr-s mb-m width-l" value="100%" options={[{ label: '100%', value: '100%' }, { label: '50%', value: '50%' }, { label: '0%', value: '0%' }]} />
                            <Autocomplete
                                dataUI={getDataUI()}
                                className="mr-s mb-m width-m"
                                options={[
                                    { value: '1', label: 'Option 1' },
                                    { value: '4', label: 'Option 4' }
                                ]}
                                value="1"
                            />
                            <Multiselect
                                dataUI={getDataUI()}
                                className="mr-s mb-m"
                                options={[
                                    { value: '1', label: 'Option 1' },
                                    { value: '4', label: 'Option 4' }
                                ]}
                                value={[{ value: '1', label: 'Option 1' }]}
                            />
                            <MultiselectBox
                                dataUI={getDataUI()}
                                className="mr-s mb-m"
                                options={[
                                    { value: '1', label: 'Option 1' },
                                    { value: '4', label: 'Option 4' }
                                ]}
                                value={[{ value: '1', label: 'Option 1' }]}
                            />
                            <MultiEmail
                                dataUI={getDataUI()}
                                className="mr-s mb-m"
                                value={[]}
                                placeholder="Send to..."
                            />
                            <MaskInput
                                dataUI={getDataUI()}
                                className="mr-s mb-m"
                                mask="(999) 000-9999"
                                placeholder="(999) 000-9999"
                                name="phone-mask"
                            />

                            <Datepicker
                                dataUI={getDataUI()}
                                className="mr-s mb-m"
                                name="datepicker"
                                placeholder="MM/DD/YY"
                                format="MM/DD/YYYY"
                                value={DatepickerUtils.dateFromString('/Date(603064800000+0200)')}
                            />
                            <Datepicker
                                dataUI={getDataUI()}
                                className="mr-s mb-m"
                                placeholder="MM/DD/YYYY hh:mm"
                                format="MM/DD/YYYY hh:mm"
                                name="datetime-picker"
                                dateTime
                                value={DatepickerUtils.dateFromString('080920', 'MM/DD/YYYY')}
                            />
                            <Textarea dataUI={getDataUI()} name="textarea" />
                        </>
                    }
                />

                <h3 className='mb-s'>Demo Select with async options with huge option list</h3>

                <Collapse
                    title="Item 1"
                    expanded={true}
                    onClick={() => { }}
                    rightIcon="far fa-user"
                    rightIconTooltip="Description in Tooltip"
                    dataUI="collapse-1"
                >
                    <Select
                        dataUI={getDataUI()}
                        className="mb-s"
                        options={this.state.selectAsynOptions}
                        value={this.state.selectAsync}
                        placeholder="Select..."
                        isLoading={this.state.selectLoading}
                        onChange={(obj: any) => {
                            this.setState({ selectAsync: obj?.value });
                        }}
                        onFocus={() => {
                            if (this.state.selectAsynOptions.length === 0) {
                                this.setState({ selectLoading: true })

                                setTimeout(() => {
                                    this.setState({ selectLoading: false })
                                    this.setState({ selectAsynOptions: selectOptionsAsync })
                                }, 3000);
                            }
                        }}
                    />
                </Collapse>



                <div style={{ height: '50px' }} />

            </MainContainerScrollable>
        );
    }
}

export default Components;