/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';

import {
    ReactAux, Main, MainContainer, WorkQueue, WorkQueueItem,
    MainContainerScrollable, Button, WidgetContainer, PortalHeader, themeChangerHelpers, WorkQueueItemProps, SortableWorkQueueItemsProps
} from '@jkhy/vsg-loanvantage-design-system';

import DemoHeader from './DemoHeader';
import DemoChatComponent from './DemoChatComponent';
import { getDataUI } from '../helpers/helpers';

const Homepage = () => {
    const [workQueueItems, setWorkQueueItems] = useState<SortableWorkQueueItemsProps>([
        { id: 1, title: 'Logan photography', details: '2290', color: 'default' },
        { id: 2, title: 'Invitation WQ', details: '23232', color: 'blue' },
        { id: 3, title: 'Allan A Morgan', details: '2290', color: 'green' },
        { id: 4, title: 'Logan photography', details: '2290', color: 'yellow' },
        { id: 5, title: 'Invitation WQ', details: '23232', color: 'orange' },
        { id: 6, title: 'Allan A Morgan', details: '23232', color: 'pink' },
        { id: 7, title: 'Logan Logan Logan Logan Logan Logan Logan Logan Logan Logan Logan Logan', details: '2290', color: 'default' },
        { id: 8, title: 'Logan photography', details: '2290', color: 'blue' },
        { id: 9, title: 'Invitation WQ', details: '23232', color: 'green' },
        { id: 10, title: 'Allan A Morgan', details: '23232', color: 'yellow' },
        { id: 11, title: 'Logan photography', details: '2290', color: 'orange' },
        { id: 12, title: 'Logan photography', details: '2290', color: 'pink' },
    ]);
    const [isDark, setIsDark] = useState(false);

    const setColorForItem = (color: WorkQueueItemProps['color'], id: string | number) => {
        const updatedWQ = workQueueItems.map(wq => {
            if (wq.id === id) {
                return {
                    ...wq,
                    color: color
                }
            }
            return wq;
        });

        setWorkQueueItems(updatedWQ);
    };

    const addNewItem = () => {
        const item = {
            title: `New item ${workQueueItems.length}`,
            details: '1234',
            color: 'default' as WorkQueueItemProps['color'],
            id: Math.random(),
        };

        const currentItems = [...workQueueItems];
        currentItems.push(item);

        setWorkQueueItems(currentItems);
    };

    return (
        <ReactAux>
            <PortalHeader
                dataUI={getDataUI()}
                loanVantageLogoLight={{ img: './img/JackHenryLending_LoanVantage_Logo_navy.png', alt: 'JackHenry Logo' }}
                loanVantageLogoDark={{ img: './img/JackHenryLending_LoanVantage_Logo_white.png', alt: 'JackHenry Logo' }}
                bankLogoLight={{ img: './img/first-state-com-bank-logo.png', alt: 'Bank logo' }}
                // bankLogoDark={{ img: './img/first-state-com-bank-logo-dark.png', alt: 'Bank logo' }}
                isDark={isDark}
            />
            <DemoHeader onThemeChange={(theme) => setIsDark(theme.base === themeChangerHelpers.THEME_BASE_DARK ? true : false)} />
            <Main dataUI={getDataUI()}>
                <MainContainer dataUI={getDataUI()}>
                    <MainContainerScrollable>
                        <div>
                            <WidgetContainer dataUI={getDataUI()} title="Work Queues">
                                <WorkQueue
                                    dataUI={getDataUI()}
                                    reordable
                                    workQueueItems={workQueueItems}
                                    onReorderItems={(items) => {
                                        setWorkQueueItems(items)
                                    }}
                                >
                                    {
                                        workQueueItems.map((item, index) => {
                                            return (
                                                <WorkQueueItem
                                                    dataUI={getDataUI()}
                                                    reordable
                                                    key={`${item.title}-${index}`}
                                                    onColorChange={(color: WorkQueueItemProps['color']) => setColorForItem(color, item.id)}
                                                    title={item.title}
                                                    details={item.details}
                                                    color={item.color}
                                                    onClick={() => console.log(`Click on WorkQueueItem ${index}`)}
                                                />
                                            )
                                        })
                                    }
                                </WorkQueue>
                            </WidgetContainer>
                        </div>
                        <Button dataUI={getDataUI()} className="mt-m" icon="fal fa-plus" onClick={() => addNewItem()}>Add new Work Queue item</Button>
                    </MainContainerScrollable>
                </MainContainer>
                <DemoChatComponent />
            </Main>
        </ReactAux>
    );
}

export default Homepage;