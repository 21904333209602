import React from 'react';
import { Link } from 'react-router-dom';
import Highlight from 'react-highlight';

import {
    Button,
    FileUpload, InfoBox, Modal, ModalScrollableContainer, SimpleTableContainer, Toolbar,
    UploadedFileRow,
} from '@jkhy/vsg-loanvantage-design-system';

import {
    renderFileRows, onAddFile, onDeleteFile, simulateUploading, updateUploadedFileRowProp,
    checkFileOnServer, exampleText, examplePDF, UploadedFileRowProps
} from '../../helpers/fileUploadHelpers';
import { getDataUI } from '../../helpers/helpers';

class FileUploadPage extends React.Component {
    private demoFilesUnmountedRows = React.createRef() as any;
    private demoFilesSeUnmountedRows = React.createRef() as any;
    private demoFilesThUnmountedRows = React.createRef() as any;
    private demoFilesFrUnmountedRows = React.createRef() as any;
    private demoFilesSixUnmountedRows = React.createRef() as any;

    state = {
        demoFiles: [examplePDF, exampleText] as UploadedFileRowProps[],
        demoFilesSe: [] as UploadedFileRowProps[],
        demoFilesTh: [] as UploadedFileRowProps[],
        demoFilesFr: [] as UploadedFileRowProps[],
        demoFilesSix: [] as UploadedFileRowProps[],
        showModal: false,
        showSuccessInfo: true,
        isServerLockCheck: false,
        uploadingPropName: 'demoFilesTh'
    } as any

    componentDidMount() {
        this.demoFilesUnmountedRows.current = {};
        this.demoFilesSeUnmountedRows.current = {};
        this.demoFilesThUnmountedRows.current = {};
        this.demoFilesFrUnmountedRows.current = {};
        this.demoFilesSixUnmountedRows.current = {};
        document.body.classList.remove('lv-modal-open');
    }


    resetStateTableDemo = () => {
        this.setState({
            showSuccessInfo: false,
            isServerLockCheck: false,
        });
    }

    renderFileRowsTable = (statePropName: any) => {
        const copyState = { ...this.state } as any;
        const files = copyState[statePropName];

        if (files.length === 0) {
            return null;
        }

        return files.map((fileData: any) => {
            const isError = fileData.uploadedFile.uploadError;
            const isUploading = fileData.uploadedFile.uploading;
            return (
                <UploadedFileRow
                    dataUI={getDataUI()}
                    key={fileData.key}
                    viewMode={fileData.viewMode}
                    uploadedFile={{
                        ...fileData.uploadedFile,
                        uploadError: isError,
                    }}
                    onProgress={(updateProgress: any) => {
                        if (isUploading) {
                            simulateUploading(updateProgress, fileData, statePropName, this, true)
                        }
                    }}
                    onSuccessUploaded={(progress: any, setUploadSuccessful: any) => {
                        if (progress === 100) {
                            setUploadSuccessful(true);
                            updateUploadedFileRowProp(statePropName, fileData, "uploading", false, this)
                        }
                    }}
                    onUploadDone={(uploadSuccessful: any, setUploadDone: any) => {
                        if (uploadSuccessful) {
                            setTimeout(() => {
                                setUploadDone(true);
                                updateUploadedFileRowProp(statePropName, fileData, "uploadDone", true, this);
                                if (this.state.showModal && statePropName === this.state.uploadingPropName) {
                                    this.resetStateTableDemo();
                                }

                            }, 3000);
                        }
                    }}
                    onDelete={() => {
                        onDeleteFile(fileData.key, statePropName, this);
                        this.resetStateTableDemo();
                    }}
                />
            );
        })
    }


    renderFileRowsModal = (statePropName: any) => {
        const copyState = { ...this.state } as any;
        const files = copyState[statePropName];

        if (files.length === 0) {
            return null;
        }

        return files.map((fileData: any) => {
            const isError = fileData.uploadedFile.uploadError;
            return (
                <UploadedFileRow
                    dataUI={getDataUI()}
                    key={fileData.key}
                    viewMode={fileData.viewMode}
                    uploadedFile={{
                        ...fileData.uploadedFile,
                        uploadError: isError,
                    }}
                    onSuccessUploaded={(progress: any, setUploadSuccessful: any) => {
                        if (progress === 100) {
                            setUploadSuccessful(true);
                            updateUploadedFileRowProp(statePropName, fileData, "uploading", false, this)
                        }
                    }}
                    onUploadDone={(uploadSuccessful: any, setUploadDone: any) => {
                        if (uploadSuccessful) {
                            setTimeout(() => {
                                setUploadDone(true);
                                updateUploadedFileRowProp(statePropName, fileData, "uploadDone", true, this);
                            }, 3000);
                        }
                    }}
                    onDelete={() => {
                        onDeleteFile(fileData.key, statePropName, this);
                        this.resetStateTableDemo();
                    }}
                />
            );
        })
    }

    render() {

        return (
            <div>
                <h1 className="mb-s">FileUpload</h1>

                <p className="mb-s">Custom FileUpload component, enables to file upload through browsing or drag and drop.</p>
                <p className="mb-s">For documentation of the component, which displays only the progress of the uploaded files
                    refer to <Link target='_blank' to="/docs/uploaded-file-row">UploadedFileRow</Link>.</p>
                <p className="mb-l">
                    Demo pages examples: <br/>
                   1. <Link target='_blank' to="/demo/required-docs">Required Documents</Link><br/>
                   2. <Link target='_blank' to="/demo/fileupload-in-table">FileUpload in table</Link>
                </p>

                <h3 className='mb-s'>Default(Regular)</h3>
                <FileUpload
                    dataUI={getDataUI()}
                    className="mb-xl"
                    onChange={(files: any) => {
                        onAddFile(files, 'demoFiles', this);
                    }}
                >
                    {renderFileRows('demoFiles', this)}
                </FileUpload>

                <h3 className='mb-s'>Large</h3>
                <FileUpload
                    dataUI={getDataUI()}
                    className="mb-xl"
                    visualType="large"
                    onChange={(files: any) => {
                        onAddFile(files, 'demoFilesSe', this);
                    }}
                    isMultiple
                >
                    {renderFileRows('demoFilesSe', this)}
                </FileUpload>

                <h3 className='mb-s'>Small</h3>

                <FileUpload
                    dataUI={getDataUI()}
                    visualType="small"
                    className="mb-xl"
                    onChange={(files: any) => {
                        onAddFile(files, 'demoFilesFr', this);
                    }}

                >
                    {renderFileRows('demoFilesFr', this)}
                </FileUpload>

                <h3 className='mb-s'>Small in table</h3>
                <SimpleTableContainer dataUI={getDataUI()} className='mb-xl'>
                    <table style={{ tableLayout: 'fixed' }}>
                        <thead>
                            <tr>
                                <th>Party</th>
                                <th>Payment</th>
                                <th style={{ width: '50%' }}>File</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Long’s Drugs of Bakersville</td>
                                <td>123</td>
                                <td>
                                    <FileUpload
                                        dataUI={getDataUI()}
                                        visualType="small"
                                        onChange={(files: any) => {
                                            onAddFile(files, 'demoFilesTh', this);
                                            this.setState({
                                                showModal: true,
                                                uploadingPropName: 'demoFilesTh',
                                                showSuccessInfo: true,
                                                isServerLockCheck: checkFileOnServer(files)
                                            });
                                        }}
                                    >
                                        {this.renderFileRowsTable('demoFilesTh')}
                                    </FileUpload>
                                </td>
                            </tr>
                            <tr>
                                <td>Another Child</td>
                                <td>456</td>
                                <td>
                                    <FileUpload
                                        dataUI={getDataUI()}
                                        visualType="small"
                                        onChange={(files: any) => {
                                            onAddFile(files, 'demoFilesSix', this);
                                            this.setState({
                                                showModal: true,
                                                uploadingPropName: 'demoFilesSix',
                                                showSuccessInfo: true,
                                                isServerLockCheck: checkFileOnServer(files)
                                            });
                                        }}
                                    >
                                        {this.renderFileRowsTable('demoFilesSix')}
                                    </FileUpload>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </SimpleTableContainer>

                <Modal
                    dataUI={getDataUI()}
                    title="Required Documents Details 3529232"
                    disableCloseBtn={this.state.isServerLockCheck}
                    isVisible={true}
                    isHidden={!this.state.showModal}
                    size="S"
                    onClose={() => this.setState({
                        showModal: false,
                        showSuccessInfo: this.state[this.state.uploadingPropName].length === 0 ? true : false,
                    })}
                >
                    <Toolbar
                        dataUI={getDataUI()}
                        className="mb-m"
                        leftSide={
                            <>
                                <Button dataUI={getDataUI()} className="mr-s" icon="fal fa-save" disabled={this.state.isServerLockCheck} onClick={() => { this.setState({ showModal: false }); }}>Save</Button>
                                <Button dataUI={getDataUI()} icon="far fa-times-circle" disabled={this.state.isServerLockCheck} btnType="secondary" onClick={() => this.setState({ showModal: false })}>Cancel</Button>
                            </>
                        }
                    />
                    <ModalScrollableContainer>
                        {this.state.showSuccessInfo && this.state[this.state.uploadingPropName][0] && !this.state[this.state.uploadingPropName][0].uploadedFile.uploadError &&
                            <InfoBox dataUI={getDataUI()} className='mb-m' type={this.state.isServerLockCheck ? "warning" : "success"} icon={this.state.isServerLockCheck ? "fal fa-exclamation-triangle" : "fal fa-file-upload"} title={this.state.isServerLockCheck ? "System is uploading your file." : 'Your documents are uploading in the background.'}>
                                {this.state.isServerLockCheck ? "Please wait while your file is currently uploading, so it can be checked. If you wish to stop the proccess, you can stop the uploading of the file." : "You can safely close this dialogue and the files will continue uploading in the background."}
                            </InfoBox>}

                        <FileUpload
                            dataUI={getDataUI()}
                            visualType="large"
                            onChange={(files: any) => {
                                if (this.state[this.state.uploadingPropName].length >= 1) {
                                    return;
                                }
                                onAddFile(files, this.state.uploadingPropName, this);
                                this.setState({
                                    showSuccessInfo: true,
                                    isServerLockCheck: checkFileOnServer(files)
                                })

                            }}
                            viewMode={this.state[this.state.uploadingPropName].length >= 1}
                            {...this.state[this.state.uploadingPropName].length >= 1 && {
                                description: {
                                    visible: true,
                                    value: "Wish to replace? First remove the uploaded file."
                                }
                            }}
                        >
                            {this.renderFileRowsModal(this.state.uploadingPropName)}
                        </FileUpload>
                    </ModalScrollableContainer>
                </Modal>


                <Highlight className="React">
                    {
                        `
import React from 'react';
import {
    Button,
    FileUpload, InfoBox, Modal, ModalScrollableContainer, SimpleTableContainer, Toolbar,
    UploadedFileRow,
} from '@jkhy/vsg-loanvantage-design-system';

// DEMO IMPLEMENTATION HELPER FUNCTIONS
import {
    renderFileRows, onAddFile, onDeleteFile, simulateUploading, updateUploadedFileRowProp,
    checkFileOnServer, exampleText, examplePDF, UploadedFileRowProps
} from '../../helpers/fileUploadHelpers';
import { getDataUI } from '../../helpers/helpers';

class Example extends React.Component {

    state = {
        demoFiles: [examplePDF, exampleText] as UploadedFileRowProps[],
        demoFilesSe: [] as UploadedFileRowProps[],
        demoFilesTh: [] as UploadedFileRowProps[],
        demoFilesFr: [] as UploadedFileRowProps[],
        demoFilesSix: [] as UploadedFileRowProps[],
        showModal: false,
        showSuccessInfo: true,
        isServerLockCheck: false,
        uploadingPropName: 'demoFilesTh'
    } as any

    componentDidMount() {
        this.demoFilesUnmountedRows.current = {};
        this.demoFilesSeUnmountedRows.current = {};
        this.demoFilesThUnmountedRows.current = {};
        this.demoFilesFrUnmountedRows.current = {};
        this.demoFilesSixUnmountedRows.current = {};
    }

    resetStateTableDemo = () => {
        this.setState({
            showSuccessInfo: false,
            isServerLockCheck: false,
        });
    }

    renderFileRowsTable = (statePropName: any) => {
        const copyState = { ...this.state } as any;
        const files = copyState[statePropName];

        if (files.length === 0) {
            return null;
        }

        return files.map((fileData: any) => {
            const isError = fileData.uploadedFile.uploadError;
            const isUploading = fileData.uploadedFile.uploading;
            return (
                <UploadedFileRow
                    dataUI={getDataUI()}
                    key={fileData.key}
                    viewMode={fileData.viewMode}
                    uploadedFile={{
                        ...fileData.uploadedFile,
                        uploadError: isError,
                    }}
                    onProgress={(updateProgress: any) => {
                        if (isUploading) {
                            simulateUploading(updateProgress, fileData, statePropName, this, true)
                        }
                    }}
                    onSuccessUploaded={(progress: any, setUploadSuccessful: any) => {
                        if (progress === 100) {
                            setUploadSuccessful(true);
                            updateUploadedFileRowProp(statePropName, fileData, "uploading", false, this)
                        }
                    }}
                    onUploadDone={(uploadSuccessful: any, setUploadDone: any) => {
                        if (uploadSuccessful) {
                            setTimeout(() => {
                                setUploadDone(true);
                                updateUploadedFileRowProp(statePropName, fileData, "uploadDone", true, this);
                                if (this.state.showModal && statePropName === this.state.uploadingPropName) {
                                    this.resetStateTableDemo();
                                }

                            }, 3000);
                        }
                    }}
                    onDelete={() => {
                        onDeleteFile(fileData.key, statePropName, this);
                        this.resetStateTableDemo();
                    }}
                />
            );
        })
    }

    renderFileRowsModal = (statePropName: any) => {
        const copyState = { ...this.state } as any;
        const files = copyState[statePropName];

        if (files.length === 0) {
            return null;
        }

        return files.map((fileData: any) => {
            const isError = fileData.uploadedFile.uploadError;
            return (
                <UploadedFileRow
                    dataUI={getDataUI()}
                    key={fileData.key}
                    viewMode={fileData.viewMode}
                    uploadedFile={{
                        ...fileData.uploadedFile,
                        uploadError: isError,
                    }}
                    onSuccessUploaded={(progress: any, setUploadSuccessful: any) => {
                        if (progress === 100) {
                            setUploadSuccessful(true);
                            updateUploadedFileRowProp(statePropName, fileData, "uploading", false, this)
                        }
                    }}
                    onUploadDone={(uploadSuccessful: any, setUploadDone: any) => {
                        if (uploadSuccessful) {
                            setTimeout(() => {
                                setUploadDone(true);
                                updateUploadedFileRowProp(statePropName, fileData, "uploadDone", true, this);
                            }, 3000);
                        }
                    }}
                    onDelete={() => {
                        onDeleteFile(fileData.key, statePropName, this);
                        this.resetStateTableDemo();
                    }}
                />
            );
        })
    }

    render() {
        return(

            // Default(Regular)
            <FileUpload
                dataUI={getDataUI()}
                className="mb-xl"
                onChange={(files: any) => {
                    onAddFile(files, 'demoFiles', this);
                }}
            >
                {renderFileRows('demoFiles', this)}
            </FileUpload>


            // Large
            <FileUpload
                dataUI={getDataUI()}
                className="mb-xl"
                visualType="large"
                onChange={(files: any) => {
                    onAddFile(files, 'demoFilesSe', this);
                }}
                isMultiple
            >
                {renderFileRows('demoFilesSe', this)}
            </FileUpload>

            // Small
            <FileUpload
                dataUI={getDataUI()}
                visualType="small"
                className="mb-xl"
                onChange={(files: any) => {
                    onAddFile(files, 'demoFilesFr', this);
                }}

            >
                {renderFileRows('demoFilesFr', this)}
            </FileUpload>


            // Small in table
            <SimpleTableContainer dataUI={getDataUI()} className='mb-xl'>
                <table style={{ tableLayout: 'fixed' }}>
                    <thead>
                        <tr>
                            <th>Party</th>
                            <th>Payment</th>
                            <th style={{ width: '50%' }}>File</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Long's Drugs of Bakersville</td>
                            <td>123</td>
                            <td>
                                <FileUpload
                                    dataUI={getDataUI()}
                                    visualType="small"
                                    onChange={(files: any) => {
                                        onAddFile(files, 'demoFilesTh', this);
                                        this.setState({
                                            showModal: true,
                                            uploadingPropName: 'demoFilesTh',
                                            showSuccessInfo: true,
                                            isServerLockCheck: checkFileOnServer(files)
                                        });
                                    }}
                                >
                                    {this.renderFileRowsTable('demoFilesTh')}
                                </FileUpload>
                            </td>
                        </tr>
                        <tr>
                            <td>Another Child</td>
                            <td>456</td>
                            <td>
                                <FileUpload
                                    dataUI={getDataUI()}
                                    visualType="small"
                                    onChange={(files: any) => {
                                        onAddFile(files, 'demoFilesSix', this);
                                        this.setState({
                                            showModal: true,
                                            uploadingPropName: 'demoFilesSix',
                                            showSuccessInfo: true,
                                            isServerLockCheck: checkFileOnServer(files)
                                        });
                                    }}
                                >
                                    {this.renderFileRowsTable('demoFilesSix')}
                                </FileUpload>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </SimpleTableContainer>

            <Modal
                dataUI={getDataUI()}
                title="Required Documents Details 3529232"
                disableCloseBtn={this.state.isServerLockCheck}
                isVisible={true}
                isHidden={!this.state.showModal}
                size="S"
                onClose={() => this.setState({
                    showModal: false,
                    showSuccessInfo: this.state[this.state.uploadingPropName].length === 0 ? true : false,
                })}
            >
                <Toolbar
                    dataUI={getDataUI()}
                    className="mb-m"
                    leftSide={
                        <>
                            <Button className="mr-s" icon="fal fa-save" disabled={this.state.isServerLockCheck} onClick={() => { this.setState({ showModal: false }); }}>Save</Button>
                            <Button icon="far fa-times-circle" disabled={this.state.isServerLockCheck} btnType="secondary" onClick={() => this.setState({ showModal: false })}>Cancel</Button>
                        </>
                    }
                />
                <ModalScrollableContainer>
                    {this.state.showSuccessInfo && this.state[this.state.uploadingPropName][0] && !this.state[this.state.uploadingPropName][0].uploadedFile.uploadError &&
                        <InfoBox dataUI={getDataUI()} className='mb-m' type={this.state.isServerLockCheck ? "warning" : "success"} icon={this.state.isServerLockCheck ? "fal fa-exclamation-triangle" : "fal fa-file-upload"} title={this.state.isServerLockCheck ? "System is uploading your file." : 'Your documents are uploading in the background.'}>
                            {this.state.isServerLockCheck ? "Please wait while your file is currently uploading, so it can be checked. If you wish to stop the proccess, you can stop the uploading of the file." : "You can safely close this dialogue and the files will continue uploading in the background."}
                        </InfoBox>}

                    <FileUpload
                        dataUI={getDataUI()}
                        visualType="large"
                        onChange={(files: any) => {
                            if (this.state[this.state.uploadingPropName].length >= 1) {
                                return;
                            }
                            onAddFile(files, this.state.uploadingPropName, this);
                            this.setState({
                                showSuccessInfo: true,
                                isServerLockCheck: checkFileOnServer(files)
                            })

                        }}
                        viewMode={this.state[this.state.uploadingPropName].length >= 1}
                        {...this.state[this.state.uploadingPropName].length >= 1 && {
                            description: {
                                visible: true,
                                value: "Wish to replace? First remove the uploaded file."
                            }
                        }}
                    >
                        {this.renderFileRowsModal(this.state.uploadingPropName)}
                    </FileUpload>
                </ModalScrollableContainer>
            </Modal>
            
        )
    }
}

export default Example;
        `}
                </Highlight>

                <h2 className="mb-m">API</h2>
                <table className="doc-table mb-l">
                    <thead>
                        <tr>
                            <th colSpan={4}>Props</th>
                        </tr>
                        <tr>
                            <td>
                                <strong>Name</strong>
                            </td>
                            <td>
                                <strong>Options</strong>
                            </td>
                            <td>
                                <strong>Default</strong>
                            </td>
                            <td>
                                <strong>Description</strong>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>visualType</td>
                            <td>'small' || 'regular' || 'large'</td>
                            <td>'regular'</td>
                            <td>Set component style variant.</td>
                        </tr>
                        <tr>
                            <td>supportedFormats</td>
                            <td>string</td>
                            <td>"image/*,.xlsx,.xls,.pdf,.doc,.docx"</td>
                            <td>Specify what file types the user can upload. Types need to be separate with <strong>comma</strong>.</td>
                        </tr>
                        <tr>
                            <td>isMultiple</td>
                            <td>boolean</td>
                            <td>false</td>
                            <td>Specifies that the user is allowed to enter more than one file.</td>
                        </tr>
                        <tr>
                            <td>maxFileSizeMB</td>
                            <td>number</td>
                            <td>50</td>
                            <td>Set maximum file size to upload in MB.</td>
                        </tr>
                        <tr>
                            <td>icon</td>
                            <td>
                                object with props:<br />
                                "visible": boolean,<br />
                                "value": string,
                            </td>
                            <td>
                                "value": "fal fa-cloud-upload"<br />
                                "visible": true
                            </td>
                            <td>Set component icon settings. Available only on visualType="large"</td>
                        </tr>
                        <tr>
                            <td>titleMain</td>
                            <td>
                                object with props:<br />
                                "visible": boolean,<br />
                                "value": string,
                            </td>
                            <td>
                                "value": "Upload Files"<br />
                                "visible": true
                            </td>
                            <td>Set component titleMain settings. Available only on visualType="large"</td>
                        </tr>
                        <tr>
                            <td>description</td>
                            <td>
                                object with props:<br />
                                "visible": boolean,<br />
                                "value": string,
                            </td>
                            <td>
                                "value": "Drop your files here or" or "Enter edit mode to upload/delete files."(in "viewMode")<br />
                                "visible": true
                            </td>
                            <td>Set component description settings.</td>
                        </tr>
                        <tr>
                            <td>button</td>
                            <td>
                                object with props:<br />
                                "visible": boolean,<br />
                                "value": string,<br />
                                "icon": string
                            </td>
                            <td>
                                "value": "Browse"<br />
                                "visible": true,<br />
                                "icon": "fal fa-folder-open"
                            </td>
                            <td>Set component description settings.</td>
                        </tr>
                        <tr>
                            <td>tabIndex</td>
                            <td>number</td>
                            <td className="text-center">-</td>
                            <td>Sets the tabIndex attribute on the button.</td>
                        </tr>
                        <tr>
                            <td>disabled</td>
                            <td>boolean</td>
                            <td>false</td>
                            <td>Set disabled state.</td>
                        </tr>
                        <tr>
                            <td>viewMode</td>
                            <td>boolean</td>
                            <td>false</td>
                            <td>When set to true, you can't upload files. Also applies different styles than "disabled" state and changes description text</td>
                        </tr>
                        <tr>
                            <td>onChange</td>
                            <td>{'(files: { file: File, error: string }[]) => void'}</td>
                            <td className="text-center">-</td>
                            <td>
                                Callback when user upload file. Returns array with objects. Object contains 'file' and 'error'.
                                <br />
                                <br />
                                Errors: 'pdf file is password protected', 'exceeded maximum size', 'invalid type' or null
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table className="doc-table mb-l">
                    <thead>
                        <tr>
                            <th colSpan={4}>Helpers</th>
                        </tr>
                        <tr>
                            <td>
                                <strong>Name</strong>
                            </td>
                            <td>
                                <strong>Props</strong>
                            </td>
                            <td>
                                <strong>Description</strong>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>isValidFile</td>
                            <td>{"(file : File, acceptedFilesIn: string) => boolean"}</td>
                            <td>Function that check is file some of the types given in  "acceptedFilesIn". Types need to be separate with <strong>comma</strong>. Example: "image/*,.xlsx,.xls,.pdf,.doc,.docx"</td>
                        </tr>
                        <tr>
                            <td>FILE_UPLOAD_DEFAULT_ERROR_MSG</td>
                            <td>
                                object with props:<br/>
                                "invalidType": 'invalid type',<br/>
                                "sizeExceeded": 'exceeded maximum size',<br/>
                                "pdfPasswordProtected": 'pdf file is password protected',
                            </td>
                            <td>Object with default File Upload error message. Can be used to generate function for getting component error depend on default error types.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default FileUploadPage;
