import React, { useState } from 'react';
import Highlight from 'react-highlight';
import { Notification } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const NotificationPage: React.FC = () => {

    const [isUnread, setIsUnread] = useState(true);

    return (
        <div>
            <h1 className="mb-s">Notification</h1>
            <p className="mb-l">
                There are two types of notification : "facility" and "notification-enter".
            </p>

            <div className="d-flex flex-column mb-m" style={{ maxWidth: '500px' }}>
                <Notification
                    type="facility"
                    title="Dealer"
                    description="Thank you!!!"
                    dateTime="1 m"
                    dataUI={getDataUI()}
                />
                <Notification
                    type="notification-enter"
                    fileNumber="6554-1"
                    title="Drive Time Used Cars"
                    description="Thank you!!!"
                    dateTime="1 m"
                    isUnread={isUnread}
                    onClickFileNumber={() => { console.log(' fileNumber Click ') }}
                    onClickMessageDealer={() => { console.log(' MessageDealer Click ') }}
                    onClickMarkAsRead={() => {
                        console.log(' MarkAsRead Click ')
                        setIsUnread(false);
                    }}
                    dataUI={getDataUI()}
                />
            </div>

            <Highlight className="React">
                {
                    `
import React, { useState } from 'react';
import { Notification } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const Example: React.FC = (props) => {
    const [isUnread, setIsUnread] = useState(true);

    return (
        <div className="d-flex flex-column" style={{ maxWidth: '500px' }}>
            <Notification
                type="facility"
                title="Dealer"
                description="Thank you!!!"
                dateTime="1 m"
                dataUI={getDataUI()}
            />
            <Notification
                type="notification-enter"
                fileNumber="6554-1"
                title="Drive Time Used Cars"
                description="Thank you!!!"
                dateTime="1 m"
                isUnread={isUnread}
                onClickFileNumber={() => { console.log(' fileNumber Click ') }}
                onClickMessageDealer={() => { console.log(' MessageDealer Click ') }}
                onClickMarkAsRead={() => {
                    console.log(' MarkAsRead Click ')
                    setIsUnread(false);
                }}
                dataUI={getDataUI()}
            />
        </div>
    );
}

export default Example;
    `}
            </Highlight>

            <h2 className="mb-m">API</h2>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>type</td>
                        <td>"facility", "notification-enter"</td>
                        <td className="text-center">-</td>
                        <td>Sets different style to the notification.</td>
                    </tr>
                    <tr>
                        <td>icon</td>
                        <td>string</td>
                        <td>'fal fa-user'</td>
                        <td>Sets the icon to the avatar.</td>
                    </tr>
                    <tr>
                        <td>fileNumber</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets the text for facility number.</td>
                    </tr>
                    <tr>
                        <td>title</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets the text for title.</td>
                    </tr>
                    <tr>
                        <td>description</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets the text for description.</td>
                    </tr>
                    <tr>
                        <td>dateTime</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Sets the text for dateTime.</td>
                    </tr>
                    <tr>
                        <td>isUnread</td>
                        <td>boolean</td>
                        <td>false</td>
                        <td>If true, shows dot and button for 'Mark as Read'.</td>
                    </tr>
                    <tr>
                        <td>onClickFileNumber</td>
                        <td>void</td>
                        <td className="text-center">-</td>
                        <td>Callback when the 'facility number' button is clicked.</td>
                    </tr>
                    <tr>
                        <td>onClickMessageDealer</td>
                        <td>void</td>
                        <td className="text-center">-</td>
                        <td>Callback when the 'message dealer' button is clicked.</td>
                    </tr>
                    <tr>
                        <td>onClickMarkAsRead</td>
                        <td>void</td>
                        <td className="text-center">-</td>
                        <td>Callback when the 'mark as read' button is clicked.</td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}

export default NotificationPage;
