import React, { useState, useMemo } from 'react';
import Highlight from 'react-highlight';

import { ShadePicker, ShadePickerColor } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';


const ShadePickerPage: React.FC = () => {

    const shades = ['#F8F9FC', '#E6E8EF', '#CDD1DF', '#B4BACF', '#9BA3BF'];
    const [activeColor, setActiveColor] = useState(shades[1]);

    const highlightElement = useMemo(() =>
        <Highlight className="React">
            {
                `
import React, { useState } from 'react';

import { ShadePicker } from '@jkhy/vsg-loanvantage-design-system';
import { getDataUI } from '../../helpers/helpers';

const Example: React.FC = () => {

    const shades = ['#F8F9FC', '#E6E8EF', '#CDD1DF', '#B4BACF', '#9BA3BF'];
    const [activeColor, setActiveColor] = useState(shades[1]);

    return (
        <div>
            <ShadePicker
                dataUI={getDataUI()}
                activeColor={activeColor}
                contentElement={<>
                    <ShadePickerColor dataUI={getDataUI()} color={shades[0]} isSelected={shades[0] === activeColor} onClick={() => setActiveColor(shades[0])} className="mr-s" />
                    <ShadePickerColor dataUI={getDataUI()} color={shades[1]} isSelected={shades[1] === activeColor} onClick={() => setActiveColor(shades[1])} className="mr-s" />
                    <ShadePickerColor dataUI={getDataUI()} color={shades[2]} isSelected={shades[2] === activeColor} onClick={() => setActiveColor(shades[2])} className="mr-s" />
                    <ShadePickerColor dataUI={getDataUI()} color={shades[3]} isSelected={shades[3] === activeColor} onClick={() => setActiveColor(shades[3])} className="mr-s" />
                    <ShadePickerColor dataUI={getDataUI()} color={shades[4]} isSelected={shades[4] === activeColor} onClick={() => setActiveColor(shades[4])} />
                </>
                }
            />
        </div>
    );
}

export default Example;
        `}
        </Highlight>
        , []);

    return (
        <div>
            <h1 className="mb-s">ShadePicker</h1>

            <p className="mb-l">ShadePicker is a component that shows a popover which helps you to select a color.</p>

            <h2 className="mb-m">ShadePicker module components:</h2>
            <p className="mb-s"><strong><a href="#shade-picker">ShadePicker</a></strong> - The main component that shows popover.</p>
            <p className="mb-l"><strong><a href="#shade-picker-color">ShadePickerColor</a></strong> - The element that shows the color that you can pick.</p>

            <ShadePicker
                dataUI={getDataUI()}
                activeColor={activeColor}
                contentElement={<>
                    <ShadePickerColor dataUI={getDataUI()} color={shades[0]} isSelected={shades[0] === activeColor} onClick={() => setActiveColor(shades[0])} className="mr-s" />
                    <ShadePickerColor dataUI={getDataUI()} color={shades[1]} isSelected={shades[1] === activeColor} onClick={() => setActiveColor(shades[1])} className="mr-s" />
                    <ShadePickerColor dataUI={getDataUI()} color={shades[2]} isSelected={shades[2] === activeColor} onClick={() => setActiveColor(shades[2])} className="mr-s" />
                    <ShadePickerColor dataUI={getDataUI()} color={shades[3]} isSelected={shades[3] === activeColor} onClick={() => setActiveColor(shades[3])} className="mr-s" />
                    <ShadePickerColor dataUI={getDataUI()} color={shades[4]} isSelected={shades[4] === activeColor} onClick={() => setActiveColor(shades[4])} />
                </>
                }
            />

            {highlightElement}

            <h2 className="mb-m mt-l">API</h2>

            <h3 id="shade-picker">ShadePicker</h3>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>activeColor</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set color that will be displayed.</td>
                    </tr>
                    <tr>
                        <td>contentElement</td>
                        <td>JSX.Element</td>
                        <td className="text-center">-</td>
                        <td>Set the element for popover that will be shows.</td>
                    </tr>
                </tbody>
            </table>

            <h3 id="shade-picker-color">ShadePickerColor</h3>
            <table className="doc-table mb-l">
                <thead>
                    <tr>
                        <th colSpan={4}>Props</th>
                    </tr>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                        <td>
                            <strong>Options</strong>
                        </td>
                        <td>
                            <strong>Default</strong>
                        </td>
                        <td>
                            <strong>Description</strong>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>color</td>
                        <td>string</td>
                        <td className="text-center">-</td>
                        <td>Set color that will be displayed.</td>
                    </tr>
                    <tr>
                        <td>isSelected</td>
                        <td>boolean</td>
                        <td className="text-center">-</td>
                        <td>If true, the selected state will be shown with check mark.</td>
                    </tr>
                    <tr>
                        <td>all native button attributes</td>
                        <td className="text-center">-</td>
                        <td className="text-center">-</td>
                        <td>You can use all native button attributes like: "type", "onChange", "name", etc.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
};

export default ShadePickerPage;
